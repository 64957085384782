import React, {  } from "react";
import { Select,Form } from 'antd';
import { isValid, isValidArray } from "../../../utils/utilities";

const { Option } = Select;

const renderStaff=(data)=>{
    let staffArray=[];
    if(isValidArray(data)&&data.length>0){
        data.forEach(element => {
            if(isValidArray(element)&&isValid(element.staff_type)){
                staffArray.push(<Option value={element.staff_type} staff={element}>{element.staff_type}</Option>)
            }else{
                staffArray.push(<Option value={element} staff={element}>{element}</Option>)

            }
        });
    }
    return staffArray;
}

export const StaffTypesSelect = ({
    data=[],
    showLabel=true,
    isManditory=true,
    selectStaff=null,
    isFormItem=true,
    onChange=(value)=>{}
}) => {
if(isFormItem){
        return (
            <Form.Item
            name="staffType"
            label={showLabel?"Staff Type":''}
            rules={[{ required: isManditory, message: 'Please select staff type!' }]}
            >
                <Select
                    size="large"
                    showSearch
                    placeholder="Select a staff type"
                    optionFilterProp="childrens"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    bordered={true}
                    defaultValue={selectStaff}
                >
                    {renderStaff(data)}
                </Select>
            </Form.Item>
        )
    }else{
        return (
                 <Select
                    size="large"
                    showSearch
                    placeholder="Select a staff type"
                    optionFilterProp="childrens"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    bordered={true}
                    defaultValue={selectStaff}
                    onChange={onChange}
                >
                    {renderStaff(data)}
                </Select>
        )
    }
}