import React, { useEffect, useState } from "react";
import { Form , Button  ,Row ,Col , DatePicker  , Typography , Checkbox ,Table , Modal} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { fetchAbandonedTransactionSheet } from './../../actions/reports.action';
import { useDispatch } from "react-redux";
import { getAPIDateFormat   , getLocalDate , convertDateStringToDate ,getUIDateFormat , getLocalDateAndTime} from './../../utils/date.utils';
import { ScheduleSelect } from './../../components/search-components/schedule-select/schedule-select.component';
import { isValidArray , isValid, isValidNumber } from "../../utils/utilities";
import { AgentsSelect } from './../../components/search-components/agents-select/agents-select.component';
import { Printer } from './../../components/custom-components/custom-component';
import { TicketSummary } from './../../components/ticket-summary-components/ticket-summary.component';

const {Text} =Typography;

let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;


const getSchedules=(data)=>{
    let schedulesDesc=[];
    data.forEach(element => {
        if(schedulesDesc.indexOf(element.description)==-1){
            schedulesDesc.push(element.description);
        }
    });
    return {schedulesDesc};
}

const getFilterData=({
    isSelectAllAgents,
    isSelectAllSchedules,
    selectedSchedule,
    selectedAgentName,
    abandonedData
})=>{
    let filterData = [];
    if(isSelectAllAgents&&isSelectAllSchedules){
        return abandonedData;
    }else{
        abandonedData.forEach((ele)=>{
            if((isSelectAllSchedules||ele.description===selectedSchedule)&&(isSelectAllAgents||ele.name===selectedAgentName)){
                filterData.push(ele);
            }
        })
        return filterData;
    }

}

const RenderTable=({
    abandonedData,
    isPrinter,
    setTicketId,
    selectedDate
})=>{

    const columns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Seat nos',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Agency',
            key:'name',
            dataIndex : 'name'
        },
        {
            title:'Email',
            key:'email_id',
            dataIndex : 'email_id'
        },
        {
            title:'Booking time',
            key:'booking_time',
            dataIndex : 'booking_time',
            render : (text, record, index) => {
                if(isValid(record.booking_time)){
                    return getLocalDate(convertDateStringToDate(record.booking_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Phone numbers',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description'
        },
        {
            title:'Travel date',
            key:"start_date",
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Release time',
            key:'release_time',
            dataIndex : 'release_time',
            render : (text, record, index) => {
                if(isValid(record.release_time)){
                    return getLocalDateAndTime(record.release_time)
                }else{
                    return "Still Blocked"
                }
            }
        },
        {
            title:"Abandoned",
            key:"isAbandoned",
            dataIndex:"isAbandoned",
            render: (text) => text ? "Yes" : "No"
        }
    ];

    if(!isPrinter){
        columns.push( {
            title:"Passenger Info",
            key:"isAbandoned",
            render :(text,record,index)=>{

                return <Button onClick={()=>{
                    setTicketId(record.ticket_id);
                }}>Passenger Info</Button>
            }            
        })
    }

    return (<div className="marginTop">
                <Row justify="center" gutter={[16,16]}>
                    <Col>
                        <Text strong>Abandoned Transactions report</Text>
                    </Col>
                </Row>
                <Row gutter={[16,0]} justify={"space-between"}>
                    <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Text>
                            <Text strong>Date : </Text>{getUIDateFormat(selectedDate)}
                        </Text>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                            </Text> 
                    </Col>
                </Row>
                <div  className="marginTop">
                    <Table 
                        columns={columns}
                        dataSource={abandonedData}
                        pagination={false}
                    />
                </div>
           </div>)
}

export const AbandonedReport = () => {

    const [form] = Form.useForm();
    const [scheduleForm] = Form.useForm();
    const [agentsForm] = Form.useForm();
    
    const [showReport,setShowReport]=useState(false);
    const [abandonedData,setAbandonedData]=useState(undefined);
    const [visible, setVisible] = useState(false);
    const [ticketId, setTicketId] = useState(null);


    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
        setTicketId(null);
    };

    useEffect(()=>{
        if(isValidNumber(ticketId)){
            showModal();
        }else{
            handleCancel();
        }
    },[ticketId]);


    let [filters,setFilters]=useState({
        selectedDate:undefined,
        isSelectAllAgents:true,
        isSelectAllSchedules:false,
        selectedAgentName:undefined,
        selectedSchedule:undefined,
        schedules:[],
        agentNames:[],
        abandonedData:[]
    });

    const onSuccess=(data)=>{
        let {
            abandonedTransactions,
            agents
        } = data;

        filters.agentNames = agents;
        filters.abandonedData = abandonedTransactions;
        setLoading(false);
        let {
            schedulesDesc
        }=getSchedules(abandonedTransactions);
        filters.schedules=schedulesDesc;
        setFilters(filters);
        setAbandonedData(abandonedTransactions);
        setShowReport(true);
    }

    const onFailure=()=>{

    }


    const onFinish=(values)=>{
        const selectedDate = getAPIDateFormat(values.selectedDate);
        filters.selectedDate = values.selectedDate;
        setLoading(true);
        fetchAbandonedTransactionSheet(selectedDate,onSuccess,onFailure);  
        setFilters(filters)      
    }

    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    return (
        <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="selectedDate" label="Date" rules={[{ required: true , message: 'Please input your date!'}]}>
                                    <DatePicker 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {isValidArray(filters.agentNames)&&filters.agentNames.length>0&&
                    <Form
                    form={agentsForm}
                    initialValues={{isSelectAllAgents:true,isSelectAllSchedules:true}}
                    onFinish={(values)=>{
                        let {
                            agentName,
                            isSelectAllAgents,
                            isSelectAllSchedules,
                            returnId
                        }=values;
                        let filterAbandonedData = getFilterData({
                            selectedAgentName:agentName,
                            isSelectAllAgents,
                            isSelectAllSchedules,
                            selectedSchedule:returnId,
                            abandonedData:abandonedData
                        });
                        filters.isSelectAllAgents=isSelectAllAgents;
                        filters.isSelectAllSchedules=isSelectAllSchedules;
                        filters.abandonedData=filterAbandonedData;
                        filters.selectedAgentName=agentName;
                        filters.selectedSchedule=returnId;

                        setFilters({...filters});
                    }}
                    >
                        <Row gutter={[16,16]}>
                            <Col>
                                <ScheduleSelect 
                                    schedules={filters.schedules}
                                    optionKey={null}
                                    showLabel={true}
                                    isManditory={false}
                                />
                            </Col>
                            <Col>
                                <Form.Item name="isSelectAllSchedules"  valuePropName="checked">
                                    <Checkbox>All</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col>
                                <AgentsSelect 
                                    agents={filters.agentNames}
                                    isManditory={false}
                                    showLabel={true}
                                />
                            </Col>
                            <Col>
                                <Form.Item name="isSelectAllAgents"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit">Filter</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                }
                {showReport&&
                <div>
                    <Printer 
                        showComponent={false}
                        zoomValue={0.8}
                        renderComponent={()=>{
                            return (
                                <RenderTable 
                                    {...filters}
                                    isPrinter={true}
                                    setTicketId={setTicketId}
                                />
                        )
                    }} />
                    <RenderTable 
                            {...filters}
                            setTicketId={setTicketId}
                    />
                </div>
                }
                <Modal title="Ticket Details" 
                    visible={visible} 
                    width="90vw"
                    footer={null}
                    onCancel={handleCancel}
                    >
                        <TicketSummary 
                            ticketId={ticketId}
                        />
                </Modal>
        </div>
    )
}