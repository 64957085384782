import { message ,Select,Row,Col, Button} from "antd";
import React, { useEffect, useState } from "react";
import '../../styles/user-actions.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { deleteUserActions, getAgentActions, getAgents, getUserActions, insertUserActions } from "../../actions/agents.actions";
import { agentsSelector } from "../../selector/agents.selector";
import { isValid, isValidArray } from "../../utils/utilities";
import { loadingAction } from "../../actions/loading-actions";
import { showConfirm } from "../../components/custom-components/custom-component";

const { Option } = Select;

const renderBtns=(gActions,pActions,uActions,agent,setLoading,refetch)=>{
    let finalDiv=[],othersDiv=[];
    let isAgentSelected = false;
    if(isValid(agent)){
        isAgentSelected = true;
    }

    for (const key in gActions) {
        if (Object.prototype.hasOwnProperty.call(gActions, key)) {
            const actions = gActions[key];
            for (const subkey in actions) {
                if (Object.prototype.hasOwnProperty.call(actions, subkey)) {
                    const subActions = actions[subkey];
                    let btnsDiv =[];
                    for (let index = 0; index < subActions.length; index++) {
                        const element = subActions[index];
                        let formatedStr = formateString(element.action_name);
                        if(pActions.indexOf(element.action_name) != -1){
                            btnsDiv.push(<Button type="text" className="outline-button" disabled>{formatedStr}</Button>);
                        }else if(uActions.indexOf(element.action_name) != -1){
                            btnsDiv.push(<Button type="text" className="outline-button"
                            onClick={()=>{
                                showConfirm(()=>{
                                    setLoading(true);
                                    deleteUserActions(agent.user_name,element.action_name,()=>{
                                        setLoading(false);
                                        refetch(agent);
                                    },()=>{
                                        setLoading(false);
                                    });
                                },`Alert`,`Are you sure to remove action ${formatedStr}?`,'Ok');
                            }}
                            >{formatedStr}</Button>);
                        }else{
                            btnsDiv.push(<Button 
                                type="text" className="outline-button add-action-btn" 
                                onClick={()=>{
                                    showConfirm(()=>{
                                        setLoading(true);
                                        insertUserActions(agent.user_name,element.action_name,()=>{
                                            setLoading(false);
                                            refetch(agent);
                                        },()=>{
                                            setLoading(false);
                                        });
                                    },'Alert',`Are you sure to add action ${formatedStr}?`,'Ok');
                                }}
                                disabled={!isAgentSelected}>{formatedStr}</Button>);
                        } 
                    }   
                    if(key == "Others"){
                        othersDiv = btnsDiv;
                    }else{
                        if(subkey == "Others"){
                            finalDiv.push(<div>
                                <div>{key}</div>
                                <div>{btnsDiv}</div>
                            </div>);
                        }else{
                            finalDiv.push(<div>
                                <div>{`${subkey}`}</div>
                                <div>{btnsDiv}</div>
                            </div>);
                        }
                    }
                }
            }
            
        }
    }

    finalDiv.push(<div>
        <div>Others</div>
        <div>{othersDiv}</div>
    </div>);
    return finalDiv;
}

const formateString=(string)=>{
    var newString = ''
    var currentWord = '';

    for (var j = 0; j <= string.length; j++) {
      if(string[j]=='_'&&currentWord.toLowerCase()!='allowed'){
        newString=newString+currentWord.charAt(0) + currentWord.substring(1).toLowerCase()+" ";
        currentWord='';
      }else {
        currentWord=currentWord+string[j];
      }
    }
    return newString;
  }


const renderOptions=(agents)=>{
    let agentsArray=[];
    if(isValidArray(agents)&&agents.length>0){
        agents.forEach(element => {
            agentsArray.push(<Option searchValue ={element.name} value={element.id} agent={element}>{`${element.name}`}</Option>);
        });
    }
    return agentsArray;
}

const getFormatedData=(data)=>{
    let formatedInfo = {
        "Others":{
            "Others":[]
        }
    };
    
    if(isValidArray(data)){
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(isValid(element.action_section)){
                if(!isValid(formatedInfo[element.action_section])){
                    formatedInfo[element.action_section] = {
                        "Others":[]
                    };
                }
                if(isValid(element.action_subsection)){
                    if(!isValidArray(formatedInfo[element.action_section][element.action_subsection])){
                        formatedInfo[element.action_section][element.action_subsection] = [];
                    }
                    formatedInfo[element.action_section][element.action_subsection].push(element);
                }else{
                    formatedInfo[element.action_section]["Others"].push(element);
                }
            }else{
                formatedInfo["Others"]["Others"].push(element);
            }
        }
        return formatedInfo;
    }else{
        return {};
    }
}

export const UserActionsPage = (props) => {

    const dispatch = useDispatch();

    const agents = useSelector(agentsSelector);
    const [globalActions,setGlobalActions] = useState({});
    const [userActions,setUserActions] = useState([]);
    const [privilegeActions,setPrivilegeActions] = useState([]);
    const [selectedAgent,setSelectedAgent] = useState(null);

    const onSuccess =()=>{

    }

    const onFailure=(errMsg)=>{
        message.error(errMsg);
    }

    const getAgentsDispatch = () =>{
        dispatch(getAgents(onSuccess,onFailure));
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        getAgentsDispatch();
        getAgentActions((info)=>{
            setGlobalActions(getFormatedData(info));
        },()=>{

        });
    },[]);

    useEffect(()=>{
        if(isValid(selectedAgent)){
            fetchUserActions(selectedAgent);
        }
    },[selectedAgent]);

    const fetchUserActions=(agent)=>{
        setLoading(true);
        getUserActions(agent.user_name,agent.privilege,(info)=>{
            setPrivilegeActions(info.privilegeActions);
            setUserActions(info.userActions);
            setSelectedAgent(agent);
            setLoading(false);
        },(errMsg)=>{
            message.error(errMsg);
            setLoading(false);
        });     
    }

    return (
        <div className="user-action-page-root">
            <Row gutter={[16,16]}>
                <Col>
                    <div className="user-action-header">
                        User actions
                    </div>
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                <Col>
                    <Select
                        size="large"
                        showSearch
                        placeholder="Select a agent"
                        optionFilterProp="childrens"
                        filterOption={(input, option) =>
                            option.searchValue.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        bordered={true}
                        className='passengerNameLabel'
                        onChange={(value,{agent})=>{     
                            setSelectedAgent(agent);                
                        }}
                    >
                        {renderOptions(agents)}                    
                    </Select>
                </Col>
            </Row>
            <Row gutter={[16,16]}>
                <Col>
                    {renderBtns(globalActions,privilegeActions,userActions,selectedAgent,setLoading,fetchUserActions)}
                </Col>
            </Row>            
        </div>
    )
}