/**
 * @description Network util methods
 */

import { FAILURE_STATUS, SUCCESS_STATUS } from './constant';
import { isValid, isValidArray } from './utilities';

function status(response) {
    if (response.status >= 200 && response.status < 300) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(new Error(response.statusText));
    }
}

function text(response) {
    return response.text()
}

function json(response) {
    return response.json();
}


const getHeaders=()=>{
    let headers = {"Content-Type": "application/json"};
    let token = global.accessCode;
    if (token) {
        headers["Authorization"] = `AccessToken ${token}`;
      }
      return headers;
}

export const postCall = (URL, payload) => {
    return new Promise((succeed, fail) => {
        if(!isValidArray(payload)||!isValid(payload['operatorCode'])){
            payload['operatorCode'] = global.operatorCode;
        }
        if(!isValidArray(payload)||!isValid(payload['agentId'])){
            payload['agentId'] = global.agentId;
        }
        if(!isValidArray(payload)||!isValid(payload['travelsId'])){
            payload['travelsId'] = global.travelsId;
        }        

        payload['isAgentPortal'] = 1;
        fetch(URL, {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify(payload)
        })
        .then(status)
        .then(json)
        .then(response => {
            succeed({
                "status": SUCCESS_STATUS,
                "payload": response
            });
        })
        .catch(error=>{
            fail({
                "status": FAILURE_STATUS,
                "error": error
            });
        })
    });
}

export const getCall = (URL, payload) => {
    return new Promise((succeed, fail) => {
        var additionalParam = '';
        var param = "&isAgentPortal=1";
        if(!isValidArray(payload)||!isValid(payload['operatorCode'])){
            param = param + `&operatorCode=${global.operatorCode}`;
        }else{
            param = param + `&operatorCode=${payload.operatorCode}`;
        }

        if(!isValidArray(payload)||!isValid(payload['agentId'])){
            param = param + `&agentId=${global.agentId}`;
        }else{
            param = param + `&agentId=${payload.agentId}`;
        }

        additionalParam = URL.indexOf('?') > -1 ? '&'+param : '?'+param;
        fetch(URL + additionalParam, {
            method: "GET",
            headers: getHeaders()
        })
        .then(status)
        .then(json)
        .then(response => {
            succeed({
                "status": SUCCESS_STATUS,
                "payload": response
            });
        })
        .catch(error => {
            fail({
                "status": FAILURE_STATUS,
                "error": error
            });
        })
    });
}

export const postCallForm = (URL,payload) => {
    return new Promise((succeed, fail) => {
        // payload['operatorCode'] = OPERATOR_CODE;
        fetch(URL, {
            method: "POST",
            headers: {'Content-Type':'application/x-www-form-urlencoded'},
            body: payload
        })
        .then(status)
        .then(text)
        .then(response => {
            succeed({
                "status": SUCCESS_STATUS,
                "payload": response
            });
        })
        .catch(error=>{
            fail({
                "status": FAILURE_STATUS,
                "error": error
            });
        })
    });
}