import { Button, Modal, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { userPartnerSelectors } from "../../selector/user.selector";
import { isValid, isValidArray } from "../../utils/utilities";
import { useDispatch, useSelector } from "react-redux";
import { updatePartner } from "./admin-tab.module";
import { loadingAction } from "../../actions/loading-actions";

const { Option } = Select;

const printPartnerOption = (option) => {
    return <Option  value={option.id} data={option} key={option.partner_code}>{option.partner_name}</Option>
};


export const AssignPartnerCode = (props) => {

    const {
        tripId,
        partnerCode
    } = props;

    const [isModalVisible,setModalVisible] = useState(false);
    const [partnerSelected,setSelectedPartner] = useState(null);

    const partnerDetails = useSelector(userPartnerSelectors);
    const dispatch = useDispatch();

    const handleOk=()=>{
        if(isValid(partnerSelected)){
            setLoading(true);
            updatePartner(tripId,partnerSelected,()=>{
                setModalVisible(false);
                setLoading(false);
            },()=>{
                setModalVisible(false);
                setLoading(false);
            });
        }else{
            message.warning("Please select partner code");
        }
    }

    const handleCancel=()=>{
        setModalVisible(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        setSelectedPartner(partnerCode);
    },[partnerCode])

    if(isValidArray(partnerDetails)&&partnerDetails.length>0){
        return (
            <div>
                <Button
                onClick={()=>{
                    setModalVisible(true);
                }}
                className="bordered-btn send-driver-details-btn"
                >Assign Partner Code</Button>
                <Modal
                    title={"Assign partner code"}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    className=""
                >
                    {isValidArray(partnerDetails)&&partnerDetails.length>0&&
                        <Select
                            showSearch
                            placeholder="Select partner"
                            onChange={(value , componentData)=>{
                                setSelectedPartner(componentData.props.data.partner_code);
                            }}
                            optionFilterProp="children"
                            value={partnerSelected}
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {partnerDetails.map(printPartnerOption)}
                        </Select> 
                    }
                </Modal>
            </div>
        )
    }else{
        return null;
    }
}
