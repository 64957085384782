

import { GET_STAFF_TYPES_URL, CREATE_STAFF_URL ,GET_STAFF_URL , DELETE_STAFF_URL , UPDATE_STAFF_URL, CREATE_DRIVER_URL, DELETE_DRIVER_URL, UPDATE_DRIVER_URL, UPDATE_SUBSCRIBED_VALUE_URL } from "./../utils/api-url";
import { postCall } from "../utils/network.util";
import { message } from 'antd';

export const getStaffTypes = (onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(GET_STAFF_TYPES_URL,{})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload);
         }else{
          onFailure();
           if(findresponce.status === "SUCCESS"){
            message.error(findresponce.payload.Message);
           }else{
            message.error("Failed to get staff types!");
           }
         }
      })
}


export const getStaff = (onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(GET_STAFF_URL,{})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to get staff!");
         }
       }
    })
}




export const createStaff = ({name,staffType,phoneNumber},onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(CREATE_STAFF_URL,{staff:{name,staffType,phoneNumber,operatorCode:global.operatorCode}})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload);
         }else{
          onFailure();
           if(findresponce.status === "SUCCESS"){
            message.error(findresponce.payload.Message);
           }else{
            message.error("Failed to create staff!");
           }
         }
      })
}



export const updateStaff = ({name,staffType,phoneNumber,id},onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(UPDATE_STAFF_URL,{staff:{name,staffType,phoneNumber,operatorCode:global.operatorCode,id}})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to update staff!");
         }
       }
    })
}


export const deleteStaff = ({staffId,phone_number},onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(DELETE_STAFF_URL,{staffId,phone_number,operatorCode:global.operatorCode})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to delete staff!");
         }
       }
    })
}

export const createDriver = ({name,phoneNumber},onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(CREATE_DRIVER_URL,{driverData:{name,phone_number:phoneNumber,operatorCode:global.operatorCode}})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to create driver!");
         }
       }
    })
}

export const deleteDriver = ({name,phone_number},onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(DELETE_DRIVER_URL,{driverData:{name,phone_number,operatorCode:global.operatorCode}})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to delete driver!");
         }
       }
    })
}

export const updateDriver = ({name,phone_number,oldName,oldPhoneNumber},onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(UPDATE_DRIVER_URL,{driverData:{name,phone_number,oldName,oldPhoneNumber,operatorCode:global.operatorCode,}})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
         onSuccess(findresponce.payload.Payload);
       }else{
        onFailure();
         if(findresponce.status === "SUCCESS"){
          message.error(findresponce.payload.Message);
         }else{
          message.error("Failed to update driver!");
         }
       }
    })
}

export const updateSubscribedValue = (phoneNumber,value,onSuccess=()=>{},onFailure=()=>{})=>{
  postCall(UPDATE_SUBSCRIBED_VALUE_URL,{phoneNumber,value,operatorCode:global.operatorCode})
  .then((findresponce) =>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess();
      }else{
        onFailure();
      }
    })

}