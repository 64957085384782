import { Button, Table , Row , Col , InputNumber} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getUniqueActiveRoutesByOperatorCode, updateRoutePriority } from "../../actions/admin.action";
import { loadingAction } from "../../actions/loading-actions";
import '../../styles/popular-route.style.scss';
import { isValidArray } from "../../utils/utilities";
import useColumnSearch from "./../../ui-components/table-column-search";


const DrawLine =()=>{
    return(
        <div className="popular-routes-underline">

        </div>
    )
}


export const RoutePriorityPage = (props) => {


    const [activeRoutes,setActiveRoutes]=useState([]);

    const [editData,setEditData]=useState(null);
    const dispatch = useDispatch();

    const onSuccess=(info)=>{
        setLoading(false);
        let newData=[];
        info.forEach(element => {
            newData.push({
                ...element,
                route:`${element.origin} - ${element.destination}`
            })
        });
        setActiveRoutes(newData);
    }

    const onFailure=(info)=>{
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        getUniqueActiveRoutesByOperatorCode(onSuccess,onFailure);
    },[]);

    const onChange=(value)=>{
        editData.priority = value;
        setEditData(editData);
    }

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }


    const onUpdateSuccess=()=>{
        setEditData(null);
        setLoading(false);
    }

    const onUpdateFailure=()=>{
        setLoading(false);
    }

    return (
        <div className="staff-cancel-root">
            <div className="staff-cancel-inner-div">
                <div>
                    <h3>
                        Popular Routes
                    </h3>            
                    <DrawLine />
                </div>
                <div>
                    <Table 
                        dataSource={activeRoutes}
                        columns={[
                            {
                                title:'Route',
                                dataIndex : 'route',
                                key:'route',
                                ...useColumnSearch(
                                    {dataIndexExt:"route"}
                                ),
                            },
                            {
                                title:'Priority',
                                dataIndex : 'priority',
                                key:'priority',
                                width: '10%',
                                defaultSortOrder: 'descend',
                                sorter: (a, b) => a.priority - b.priority,
                                render:(text,record)=>{
                                    if(isValidArray(editData)&&editData.origin === record.origin && editData.destination === record.destination){
                                    return(
                                        <InputNumber 
                                            defaultValue={editData.priority} 
                                            onChange={onChange}
                                        />
                                    )
                                    }else{
                                        return text;
                                    }
                                }
                            },
                            {
                                title:'Operation',
                                dataIndex : 'priority',
                                key:'priority',
                                width: '30%',
                                render:(text,record)=>{
                                    if(isValidArray(editData)&&editData.origin === record.origin && editData.destination === record.destination){
                                       return(
                                        <Row gutter={[16,16]}>
                                            <Col>
                                                <Button className="exit-btn"
                                                onClick={()=>{
                                                    setEditData(null);
                                                }}
                                                >Cancel</Button>
                                            </Col>
                                            <Col>
                                                <Button
                                                    onClick={()=>{
                                                        setLoading(true);
                                                        updateRoutePriority(editData.origin,editData.destination,editData.priority,onUpdateSuccess,onUpdateFailure);
                                                    }}
                                                >Update</Button>
                                            </Col>
                                        </Row>
                                       )
                                    }else{
                                        return(
                                            <Row gutter={[16,16]}>
                                            <Col>
                                            <Button onClick={()=>{
                                                setEditData(record);
                                            }}>Edit</Button>
                                            </Col>
                                        </Row> 
                                        )
                                    }
                                }
                            }
                        ]}
                        onRow={(record, rowIndex) => {
                            return {
                              onClick: (event) => {}, // click row
                              onDoubleClick: (event) => {
                              }, // double click row
                              onContextMenu: (event) => {}, // right button click row
                              onMouseEnter: (event) => {}, // mouse enter row
                              onMouseLeave: (event) => {}, // mouse leave row
                            };
                          }}
                    />
                </div>
            </div>
        </div>
    )
}