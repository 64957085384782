import React from "react";
import { checkActionAllowed, isValidNumber } from "../../utils/utilities";
import { ACTION_VIEW_TRIP_REPORT_ALLOWED } from './../../utils/constant';
import { TripSheetReport } from './../../pages/reports/trip-sheet.report';

export const PrintTripSheet = (props) => {

    const{
        tripId,
        selectedSchedule,
        vechicleInfoExits,
        tripSheetPermission
    }=props;

    
    if(checkActionAllowed(ACTION_VIEW_TRIP_REPORT_ALLOWED)&&(!isValidNumber(tripSheetPermission)||tripSheetPermission === 1)){
        return (
            <div>
                <TripSheetReport 
                    showInModal={true} 
                    tripId={tripId}
                    showPrintIcon={false}
                    toolTipText={"Print Trip Sheet"}
                    intialSchedules={[selectedSchedule]}
                    intialSelectedSchedule={selectedSchedule}
                    showVechicleWarn={vechicleInfoExits}
                />
            </div>
        )
    }else{
        return null
    }
}