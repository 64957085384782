import React from "react";
import { Col, Row } from "antd";
import { BusCancellationModal } from "../bus-cancel-components/bus-cancellation-modal/bus-cancellation-modal.component";
import { BusDelayModal } from "../bus-cancel-components/bus-delay-modal/bus-delay-modal.component";
import { SendDriverDetails } from "../bus-cancel-components/send-driver-details-modal/send-driver-details-modal.component";
import { checkActionAllowed } from "../../utils/utilities";
import { VIEW_ADMIN_TAB_IN_SEARCH_PAGE } from "../../utils/constant";
import './admin-tab.style.scss';
import { RemoveCities } from "../remove-cities/remove-cities.component";
import { AssignPartnerCode } from "./assign-partner-code.component";
import { InformOtas } from "../search-components/infrom-ota/infrom-ota.component";
import { ChangeSeatLayout } from "../change-seat-layout/change-seat-layout.component";


export const AdminTabComponent = (props) => {

    const {
        schedule,
        vechicleInfoExits
    } = props;

    if(checkActionAllowed(VIEW_ADMIN_TAB_IN_SEARCH_PAGE)){
        return(
            <div className="tab-block">
                <div className="search-admin-tab-root">
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                            <BusCancellationModal schedule={schedule} />
                        </Col>
                        <Col>
                            <RemoveCities schedule={schedule}/>
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                            <BusDelayModal schedule={schedule}/>
                        </Col>
                        <Col>
                            {/* <AdjustBoardingPoints
                                scheduleId={schedule.schedule_id} 
                                tripId={schedule.trip_id} 
                                date={schedule.start_date}
                            />   */}
                            <SendDriverDetails 
                                scheduleVechicleId={schedule.schedule_vechicle_id}  
                                tripVechicleId={schedule.vechicle_id}
                                vechicleInfoExits={vechicleInfoExits}
                                seatsAvailbility={schedule.seatsAvailbility}
                                tripId={schedule.trip_id}
                            />
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                            {/* <ChangeSeatLayout
                                tripId = {schedule.trip_id}
                            /> */}
                        </Col>
                        <Col>
                            <InformOtas 
                                tripId={schedule.trip_id} 
                            />
                        </Col>
                    </Row>
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                             <AssignPartnerCode 
                                tripId={schedule.trip_id} 
                                partnerCode={schedule.partner_code}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }else{
        return null;
    }
  
}
