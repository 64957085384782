import { CHANGE_SEAT_LAYOUT_URL } from "../../utils/api-url";
import { postCall } from "../../utils/network.util";

export const changeSeatLayoutReq=(tripId , newBusId,onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(CHANGE_SEAT_LAYOUT_URL,{
        tripId , newBusId , updatedBy:global.userName
    })
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload);
         }else{
            onFailure(findresponce.payload.Message);
         }
      })
}