/**
 * @author Rejo Varghese
 * @description Module related to schedule trip to fetch reports
 */
import { GET_DROP_LOCATION_BY_SCHEDULE_ID_URL, GET_PICKUP_LOCATION_BY_SCHEDULE_ID_URL } from './../../../utils/api-url';
import { postCall } from "./../../../utils/network.util";


export const getDropLocationByScheduleId=(scheduleId,onSuccess=()=>{},onFailure=()=>{})=>{
    var dropLocation = postCall(GET_DROP_LOCATION_BY_SCHEDULE_ID_URL, {
        scheduleId: scheduleId,
    });
    dropLocation.then((data) => {
        if(data){
            onSuccess(data.payload.Payload.dropLocations);
        }else{
          onFailure();
        }
    }).catch((reason)=> {
        onFailure();
    })
}

export const getPickupLocationByScheduleId=(scheduleId,onSuccess=()=>{},onFailure=()=>{})=>{
    var pickupLocation = postCall(GET_PICKUP_LOCATION_BY_SCHEDULE_ID_URL, {
        scheduleId: scheduleId,
    });
    pickupLocation.then((data) => {
        if(data){
          onSuccess(data.payload.Payload.pickupLocations);
        }else{
          onFailure();
        }
    }).catch((reason)=> {
        onFailure();
    })
}