/**
 * @author Rejo Varghese
 * @description Logs Table
 */
import React, { useState } from 'react';
import { Table } from 'antd';
import { isValid } from '../../../utils/utilities';
import { LogsModalRoot } from '../logs-modal/logs-modal.root';
import { getLocalDateAndTime, getLocalTime, getUIDateFormat } from '../../../utils/date.utils';


export const LogsTable = (props) => {
    const {
        dataSource,
        scheduleDescription,
        travelDate,
        startTime,
        supportedFare,
        seatGroupIds
    } = props;

    const [selectedLog, setSelectedLog] = useState(null);
    const [showModal, setShowModal] = useState(false);

    const onDetailsClicked = (row) => {
        setSelectedLog(row);
        setShowModal(true);
    }

    const logsColumns = [
    
        {
            title: 'Action',
            dataIndex: 'action',
            key: "action",
        },
        {
            title: 'Date & Time',
            dataIndex: 'date',
            key: "date",
            render:(text)=>{
                if(isValid(text)){
                    return getLocalDateAndTime(text);
                }else{
                    return text
                }
            }
        },
        {
            title: 'Agent',
            dataIndex: 'agent',
            key: "agent",
        },
        {
            title: 'Details',
            dataIndex: '',
            key: "",
            render: (text,row)=> {
                if (isValid(row.rawData)){
                    return (<a style= {{textDecorationLine: 'underline'}} onClick= {()=>{onDetailsClicked(row)}}>Details</a>)
                }
                else{
                    return null
                }
            }
        }
    ];


    return (
        <div>
            <Table
                columns= {logsColumns}
                dataSource = {dataSource}
                pagination={false}
            />
            <LogsModalRoot
                showModal = {showModal}
                setShowModal = {setShowModal}
                selectedLog = {selectedLog}
                scheduleDescription = {scheduleDescription}
                travelDate={travelDate}
                startTime = {startTime}
                supportedFare = {supportedFare}
                seatGroupIds = {seatGroupIds}
            />
        </div>
    )
}