import React, { useState } from "react";
import { Form , Button , Radio ,Row ,Col , DatePicker , Checkbox, message , Typography , Table} from 'antd';
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import { fetchTripsByBookingDate , fetchTripsByTravelDate , fetchSalesSheet} from './../../actions/reports.action';
import { getAPIDateFormat , convertDateStringToDate ,getLocalDate , getLocalDateAndTime} from './../../utils/date.utils';
import { ScheduleSelect } from './../../components/search-components/schedule-select/schedule-select.component';
import { isValid, isValidArray } from "../../utils/utilities";
import { AgentsSelect } from './../../components/search-components/agents-select/agents-select.component';
import { addFloatingPoints as AFP , subtractFloatingPoints as SFP , roundNumber } from './../../utils/math.utils';
import { Printer } from './../../components/custom-components/custom-component';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const {Text} =Typography;

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const filterValidation = (filters,ticketAgentName)=>{
    let {
        isSelectAllAgents,
        agentName
    } = filters;
    if(isSelectAllAgents){
        return true;
    }else{
        if(agentName == ticketAgentName){
            return true;
        }else{
            return false;
        }
    }

}

const generateJsonToExport=(filters,confirmedTickets,cancelledTickets)=>{

    let reportsConfirmedXmlData=[];
    let reportsCancelledXmlData=[];
    if(isValidArray(confirmedTickets)&&confirmedTickets.length>0){
        confirmedTickets.forEach(element => {
            if(filterValidation(filters,element.name)){
                let gst= AFP(element.cgst,element.sgst);
                let netComm=SFP(element.commission,element.tds);
                let amountPaid=roundNumber(element.amountPaid-(element.commission-element.tds));
                let bookingTIme=getLocalDateAndTime(element.booking_time);
                let startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]))
                reportsConfirmedXmlData.push([
                    element.pnr,element.name,element.seat_count,
                    element.seat_number,element.fare,gst,
                    element.total_ticket_cost,element.direct_discount,element.commission,
                    element.tds,netComm,amountPaid,bookingTIme,startDate,element.description
                ])
            }
        });
    }

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach(element => {
            if(filterValidation(filters,element.name)){
                let gst= AFP(element.cgst,element.sgst);
                let netComm=SFP(element.commission,element.tds);
                let bookingTIme=getLocalDateAndTime(element.booking_time);
                let startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]));
                let refund = roundNumber(element.amountPaidWithMiles-element.commission-element.cancellation_penalty);
                reportsCancelledXmlData.push([
                    element.pnr,element.name,element.seat_count,
                    element.seat_number,element.fare,gst,
                    element.total_ticket_cost,element.cancellation_penalty,element.commission,
                    element.tds,netComm,refund,bookingTIme,startDate,element.description
                ])
            }
        });
    }

    let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());
    const multiDataSet = [
        {
            columns: ["Report","Agents","From Date", "To Date","Type of date" , "Generated day/time"],
            data: [[
                "Sales report",agentName,filters.fromDate,filters.toDate,dateType,generatedTime
            ]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "PNR", "Agent" , "Seat count" , 
                "Seat number" , "Bus Fare" , "GST" , 
                "Total amount","Discount" , "Commission",
                "TDS","Net commision","Net amount","Booking time","Travel date","Trip description"],
            data: reportsConfirmedXmlData
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "PNR", "Agent" , "Seat count" , 
                "Seat number" , "Bus Fare" , "GST" , 
                "Total amount","Cancellation penality" , "Commission",
                "TDS","Net commision","Refund","Booking time","Travel date","Trip description"],
            data: reportsCancelledXmlData
        }
    ];
    

    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"sales-report"}> 
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}


const getTripIds=(trips,isSelectAll,scheduleId)=>{
        let tripIds=[];
        if(isValidArray(trips)&&trips.length>0){
            trips.forEach(element => {
                if(isSelectAll){
                    tripIds.push(element.id);
                }else if(scheduleId===element.schedule_id){
                    tripIds.push(element.id);
                }
            });
        }

        return tripIds;
}


const getTicketsByAgentName=(tickets,agentName,isSelectAllAgents)=>{
    let formatedTickets=[];
    if(isSelectAllAgents){
        return tickets;
    }else if(isValid(agentName)&&isValidArray(tickets)&&tickets.length>0){
        tickets.forEach(element => {
            if(element.name===agentName){
                formatedTickets.push(element)
            }
        });
    }
    return formatedTickets;
}


const RenderReport=({fromDate,toDate,isBookingDate,agentName,confirmedTickets,cancelledTickets,pagination,isSelectAllAgents})=>{

    const [confirmPage, setConfirmPage] = useState(1);
    const [cancelPage, setCancelPage] = useState(1);
    const [confirmPaginationSize, setConfirmPaginationSize] = useState(getDefaultPageSize);
    const [cancelPaginationSize, setCancelPaginationSize] = useState(getDefaultPageSize);

    const confirmTicketsColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (confirmPage - 1) * confirmPaginationSize + index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Agent',
            key:'name',
            dataIndex : 'name'
        },
        {
            title:'Seat count',
            key:'seat_count',
            dataIndex : 'seat_count'
        },
        {
            title:'Seat number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Bus Fare',
            key:'fare',
            dataIndex : 'fare'
        },
        {
            title:'GST',
            key:'gst',
            render : (text, record, index) => AFP(record.cgst,record.sgst)
        },
        {
            title:'Total amount',
            key:'total_ticket_cost',
            dataIndex : 'total_ticket_cost'
        },
        {
            title:'Discount',
            key:'direct_discount',
            dataIndex : 'direct_discount'
        },
        {
            title:'Commission',
            key:'commission',
            dataIndex : 'commission'
        },
        {
            title:'TDS',
            key:'tds',
            dataIndex : 'tds'
        },
        {
            title:'Net commision',
            key:'net_commision',
            render : (text, record, index) => SFP(record.commission,record.tds)
        },
        {
            title:'Net amount',
            key:'net_amount',
            render : (text, record, index) => roundNumber(record.amountPaid-(record.commission-record.tds))
        },
        {
            title:'Booking time',
            key:'booking_time',
            render : (text, record, index) => getLocalDateAndTime(record.booking_time)
        },
        {
            title:'Travel date',
            key:'pnr',
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description'
        }
    ];
    
    const cancelTicketsColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (cancelPage - 1) * cancelPaginationSize + index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Agent',
            key:'name',
            dataIndex : 'name'
        },
        {
            title:'Seat count',
            key:'seat_count',
            dataIndex : 'seat_count'
        },
        {
            title:'Seat number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Bus Fare',
            key:'fare',
            dataIndex : 'fare'
        },
        {
            title:'GST',
            key:'gst',
            render : (text, record, index) => AFP(record.cgst,record.sgst)
        },
        {
            title:'Total amount',
            key:'total_ticket_cost',
            dataIndex : 'total_ticket_cost'
        },
        {
            title:'Penality',
            key:'cancellation_penalty',
            dataIndex : 'cancellation_penalty'
        },
        {
            title:'Commission',
            key:'commission',
            dataIndex : 'commission'
        },
        {
            title:'TDS',
            key:'tds',
            dataIndex : 'tds'
        },
        {
            title:'Net commision',
            key:'net_commision',
            render : (text, record, index) => SFP(record.commission,record.tds)
        },
        {
            title:'Refund amount',
            key:'net_amount',
            render : (text, record, index) => roundNumber(record.amountPaidWithMiles-record.commission-record.cancellation_penalty)
        },
        {
            title:'Booking time',
            key:'booking_time',
            render : (text, record, index) => getLocalDateAndTime(record.booking_time)
        },
        {
            title:'Travel date',
            key:'pnr',
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description'
        }
    ];

    return(
        <div>
            <Row justify="center" gutter={[16,16]}>
                <Col>
                    <Text strong>Sales report</Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From date : </Text>{fromDate}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To date : </Text>{toDate}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Type of date : </Text>{isBookingDate?"Booking Date":"Travel Date"}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                            <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,0]}>
                <Col>
                    <Text strong>Agent : {isSelectAllAgents?"All agents":agentName}</Text>
                </Col>
            </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table 
                        columns={confirmTicketsColumns}
                        dataSource={getTicketsByAgentName(confirmedTickets,agentName,isSelectAllAgents)}
                        pagination={getPaginationDetails(pagination,setConfirmPage,confirmPaginationSize, setConfirmPaginationSize)}
                        className="bordered-table"
                    />
               </Col>
           </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table 
                        columns={cancelTicketsColumns}
                        dataSource={getTicketsByAgentName(cancelledTickets,agentName,isSelectAllAgents)}
                        pagination={getPaginationDetails(pagination,setCancelPage,getDefaultPageSize, cancelPaginationSize, setCancelPaginationSize)}
                        className="bordered-table"
                    />
               </Col>
           </Row>
        </div>
    )
}

export const SalesTicketReport = () => {

    const [form] = Form.useForm();
    const [scheduleForm] = Form.useForm();
    const [agentsForm] = Form.useForm();


    const [schedules,setSchedules]=useState(undefined);
    const [trips,setTrips]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
        isSelectAllAgents:true,
        isSelectAllSchedules:false,
        agentNames:[]
    });
    const [cancelledTickets,setCancelledTickets]=useState(undefined);
    const [confirmedTickets,setConfirmedTickets]=useState(undefined);


    const onSuccess=(data)=>{
        setSchedules(data.schedules);
        setTrips(data.trips);
    }

    const onFailure=()=>{
        setSchedules(undefined);
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            isBooking
        }=values;
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setShowReport(false);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        filters.isBookingDate=isBooking;
        setFilters({...filters});
        if(isBooking){
            fetchTripsByBookingDate(fromDate,toDate,onSuccess,onFailure);
        }else{
            fetchTripsByTravelDate(fromDate,toDate,onSuccess,onFailure);
        }
    }


    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                            <Radio.Group>
                                <Radio value={true}>Booking Date</Radio>
                                <Radio value={false}>Travel Date</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {isValidArray(schedules)&&
                        <Form
                        form={scheduleForm}
                        initialValues={{isSelectAllSchedules:false}}
                        onFinish={(values)=>{
                            let {
                                returnId,
                                isSelectAllSchedules
                            }=values;
                            setShowReport(false);
                            if(isValid(returnId)||isSelectAllSchedules){
                                let selectedTripIds = getTripIds(trips,isSelectAllSchedules,returnId);
                                fetchSalesSheet(selectedTripIds,filters.fromDate,filters.toDate,filters.isBookingDate,(data,salesSheetFilters)=>{
                                    filters.agentNames=salesSheetFilters.agentNames;
                                    setFilters({...filters});
                                setCancelledTickets(data.cancelledTransactions);
                                setConfirmedTickets(data.confirmedTransactions);
                                setShowReport(true);
                                },()=>{});
                            }else{
                                message.error("Select schedule!");
                            }
                        }}
                        >
                            <Row gutter={[16,16]}>
                                <Col>
                                    <ScheduleSelect 
                                        schedules={schedules}
                                        optionKey="id"
                                        showLabel={true}
                                        isManditory={false}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item name="isSelectAllSchedules"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button htmlType="submit">Get</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                }

                {showReport&&
                <div>
                    <Form
                    form={agentsForm}
                    initialValues={{isSelectAllAgents:true}}
                    onFinish={(values)=>{
                        let {
                            agentName,
                            isSelectAllAgents
                        }=values;
                        if(isValid(agentName)||isSelectAllAgents){
                            filters.agentName=agentName;
                            filters.isSelectAllAgents=isSelectAllAgents;
                            setFilters({...filters});
                        }else{
                            message.error("Select agent!");
                        }
                    }}
                    >
                        <Row gutter={[16,16]}>
                            <Col>
                                <AgentsSelect 
                                    agents={filters.agentNames}
                                    isManditory={false}
                                    showLabel={true}
                                />
                            </Col>
                            <Col>
                                <Form.Item name="isSelectAllAgents"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button htmlType="submit">Filter</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Row gutter={16}>
                        <Col>
                        {
                            generateJsonToExport(filters,confirmedTickets,cancelledTickets)
                        }
                        </Col>
                        <Col>
                                <Printer 
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                        <RenderReport 
                                            fromDate={filters.fromDate}
                                            toDate={filters.toDate}
                                            isBookingDate={filters.isBookingDate}
                                            agentName={filters.agentName}
                                            isSelectAllAgents={filters.isSelectAllAgents}
                                            confirmedTickets={confirmedTickets}
                                            cancelledTickets={cancelledTickets}
                                            pagination={false}
                                        />
                                )
                            }} />
                        </Col>
                    </Row>
                    <RenderReport 
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        isBookingDate={filters.isBookingDate}
                        agentName={filters.agentName}
                        isSelectAllAgents={filters.isSelectAllAgents}
                        confirmedTickets={confirmedTickets}
                        cancelledTickets={cancelledTickets}
                        pagination={true}
                    />
                </div>
                }
            </div>
    )
}