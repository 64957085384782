import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    SET_DASHBOARD_DATA,
    GET_DASHBOARD_DATA
} from '../actions/action-types';
import { message } from 'antd';
import { isValidArray } from '../utils/utilities';

export function* getDashboardData(action) {
    try {
    const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
    
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    
    if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){

        yield put({
            type: SET_DASHBOARD_DATA,
            payload: apiResult.payload.Payload
        });
        onSuccess();
    }else{
        onFailure("Failed to get dashboard"); 
    }
}
    catch (error) {
        console.log("fetching dashboard:",{error});
        message.error("Failed to get dashboard")
    }
};

function* watchDashboardSaga() {
    yield takeLatest(GET_DASHBOARD_DATA, getDashboardData)
}

export default function* watchDashboardPageSaga() {
    yield all([
        fork(watchDashboardSaga),
    ])
}

