import { takeLatest, put , all ,fork,call , delay} from 'redux-saga/effects';
import {
    GET_ORIGIN_CITIES,
    GET_DESTINATION_CITIES,
    SET_ORIGIN_CITIES,
    SET_DESTINATION_CITIES,
    FETCH_SCHEDULES,
    FETCH_SEAT_LAYOUT,
    BOOK_TICKET,
    BLOCK_TICKET,
    LOADING_ACTION,
    SET_BOOKED_TICKET,
    CHANGE_TICKET
} from '../actions/action-types';
import { message } from 'antd';
import { isValid, isValidArray } from '../utils/utilities';

export function* getOriginCities(action) {
    try {
    const {
            api,
            url,
            apiPayload
    } = {...action.payload};
      const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload){
            let clientPayload=apiResult.payload;
            yield put({
                type: SET_ORIGIN_CITIES,
                payload: clientPayload
            });            
        }else{
            message.error("Failed to get origins")
        }
    }
    catch (error) {
        console.log("fetching origin:",{error});
        message.error("Failed to get origins")
    }
};


function* watchOriginCitiesSaga() {
    yield takeLatest(GET_ORIGIN_CITIES, getOriginCities)
}


export function* getDestinationCities(action) {
    try {
        const {
            api,
            url,
            apiPayload
    } = {...action.payload};
      const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload){
            let clientPayload=apiResult.payload;
            yield put({
                type: SET_DESTINATION_CITIES,
                payload: clientPayload
            });            
        }else{
            message.error("Failed to get destinations"); 
        }
    }
    catch (error) {
        console.log("fetching destination",{error});
        message.error("Failed to get destinations"); 
    }
};


function* watchDestinationsCitiesSaga() {
    yield takeLatest(GET_DESTINATION_CITIES, getDestinationCities)
}

export function* fetchSchedules(action) {
    try {
        yield put({
            type:LOADING_ACTION,
            payload:true
          })
        const {
            api,
            url,
            apiPayload,
            onSuccess,
            showError
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&apiResult.payload){
                if(isValid(apiResult.payload.message)){
                    message.error(apiResult.payload.message); 
                    yield delay(500);
                    if(apiResult.payload.reload){
                        window.location.reload();
                    }
                }
                let clientPayload=apiResult.payload.schedules;
                if(showError&&isValidArray(clientPayload)&&clientPayload.length===0){
                    message.error("Schedules not found!"); 
                }
                onSuccess(clientPayload);
            }else{
                message.error("Failed to get schedules"); 
            }
            yield put({
                type:LOADING_ACTION,
                payload:false
              })
    }
    catch (error) {
        console.log("fetching schedules",{error});
        message.error("Failed to get schedules"); 
        yield put({
            type:LOADING_ACTION,
            payload:false
          })
    }
};


function* watchfetchSchedulesSaga() {
    yield takeLatest(FETCH_SCHEDULES, fetchSchedules)
}

export function* fetchSeatLayout(action) {
    try {
        const {
            api,
            url,
            apiPayload,
            onSuccess,
            onFailure
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
                let clientPayload=apiResult.payload.Payload;
                onSuccess(clientPayload);
            }else{
                if(isValid(apiResult.payload.Message)){
                    onFailure(apiResult.payload.Message); 
                    yield delay(500);
                    if(apiResult.payload.reload){
                        window.location.reload();
                    }
                }else{
                    onFailure("Unable to fetch seat layout. Please search again."); 
                }
            }
    }
    catch (error) {
        console.log("fetching schedules",{error});
        message.error("Failed to get seats"); 
        yield put({
            type:LOADING_ACTION,
            payload:false
          })
    }
};


function* watchFetchSeatLayoutSaga() {
    yield takeLatest(FETCH_SEAT_LAYOUT, fetchSeatLayout)
}

export function* bookTicket(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        }); 
        const {
            api,
            url,
            apiPayload,
            onSuccess,
            onBookFailure
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status==="SUCCESS"){
                message.success("Ticket booked successfully!");
                yield put({
                    type:SET_BOOKED_TICKET,
                    payload:apiResult.payload.TicketObject
                })
                onSuccess();
            }else{
                if(isValidArray(apiResult.payload)){
                    message.error(apiResult.payload.Message); 
                    if (apiResult.payload.ResponseCode == 100) {
                        onBookFailure(true);
                    }
                    else {
                        onBookFailure(false);
                    }
                }else{
                    message.error("Failed to book ticket"); 
                    onBookFailure(false);
                }
            }
            yield put({
                type: LOADING_ACTION,
                payload: false
            }); 
    }
    catch(error){
        console.error(error);
        yield put({
            type: LOADING_ACTION,
            payload: false
        }); 
        message.error("Server is busy. Failed to book ticket."); 
    }
}


function* watchBookTicketSaga() {
    yield takeLatest(BOOK_TICKET, bookTicket)
}

export function* blockTicket(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        }); 
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status==="SUCCESS"){
                message.success("Ticket blocked successfully!");
                onSuccess();
            }else{
                if(isValidArray(apiResult.payload)){
                    message.error(apiResult.payload.Message); 
                }else{
                    message.error("Failed to block ticket"); 
                }
            }
            yield put({
                type: LOADING_ACTION,
                payload: false
            }); 
    }
    catch(error){
        console.error(error);
        yield put({
            type: LOADING_ACTION,
            payload: false
        }); 
        message.error("Failed to block ticket"); 
    }
}


function* watchBlockTicketSaga() {
    yield takeLatest(BLOCK_TICKET, blockTicket)
}


export function* changeTicket(action){
    try{
        yield put({
            type: LOADING_ACTION,
            payload: true
        }); 
        const {
            api,
            url,
            apiPayload,
            onSuccess
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status==="SUCCESS"){
                message.success("Ticket booked successfully!");
                yield put({
                    type:SET_BOOKED_TICKET,
                    payload:apiResult.payload.TicketObject
                })
                onSuccess();
            }else{
                if(isValidArray(apiResult.payload)){
                    message.error(apiResult.payload.Message); 
                }else{
                    message.error("Failed to change bus"); 
                }
            }
            yield put({
                type: LOADING_ACTION,
                payload: false
            }); 
    }
    catch(error){
        console.error(error);
        yield put({
            type: LOADING_ACTION,
            payload: false
        }); 
        message.error("Failed to change bus"); 
    }
}


function* watchChangeTicketSaga() {
    yield takeLatest(CHANGE_TICKET, changeTicket)
}

  export default function* watchHomePageSagas() {
      yield all([
          fork(watchOriginCitiesSaga),
          fork(watchDestinationsCitiesSaga),
          fork(watchfetchSchedulesSaga),
          fork(watchFetchSeatLayoutSaga),
          fork(watchBookTicketSaga),
          fork(watchBlockTicketSaga),
          fork(watchChangeTicketSaga)
      ])
  }
  