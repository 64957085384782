import React ,{useState} from "react";
import { Button , Form, Input, Modal , Select , Tooltip , Row , Col} from "antd";
import { checkActionAllowed , isValidArray} from "../../utils/utilities";
import { ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL } from './../../utils/constant';
import Icon from '@ant-design/icons';
import LocationGrayIcon from './../../images/location-gray.png';
import { getAPIDateFormat, getFormatedDate } from './../../utils/date.utils';
import './edit-vechicle.style.scss';
import { updatePhoneNoandVechicleNo} from './edit-vechicle.module';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { StaffSelect } from "../search-components/staff-select-component/staff-select.component";

const { 
    Option
} = Select;

const getVechicleNo=(vechicleId,vechicles)=>{
    let vechicleNo='';
    for (let index = 0; index < vechicles.length; index++) {
        const element = vechicles[index];
        if(element.id == vechicleId){
            vechicleNo = element.vechicle_no;
        }
    }
    return vechicleNo;
}

const renderVechicles=(vechicles,busId)=>
{
    let vechicleOptions=[<Option value={0}>None</Option>] ;
    if(isValidArray(vechicles)&&vechicles.length>0){
        vechicles.forEach(element => {
            if(busId === element.bus_id){
                vechicleOptions.push(<Option value={element.id}>{element.vechicle_no}</Option>);
            }
        });
    }
    return vechicleOptions;
}

const EditDrawLine =()=>{

    return(
        <div className="edit-vechicle-underline">

        </div>
    )
}

export const EditVechicleComponent = (props) => {

    const{
        scheduleDescription,
        travelDate,
        selectedTrip,
        phoneNo,
        vechicleId,
        vechicleInfoExits,
        busId,
        onVechicleUpdateSuccess,
        secondDriverName,
        driverName,
        secondPhoneNo,
        vechicles=[]
    }=props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }
    
    const getVehicleNoFromId = (vehicleId) => {
        let vehicleNo = null;
        vechicles.map((vehicle) => {
            if (vehicleId == vehicle.id) {
                vehicleNo = vehicle.vechicle_no;
            }
        });
        return vehicleNo;
    }
    
    const handleSubmit =  (e) => {
        e.preventDefault();
        return form.validateFields()
        .then(values => {
            let vehicleId = values.vechicleId==0?null:values.vechicleId;
            let vehicleNo = getVehicleNoFromId(vehicleId)
            let trip = {
                id:selectedTrip.id,
                schedule_id:selectedTrip.schedule_id,
                vechicle_id:vehicleId,
                vehicleNo,
                phone_no:values.phoneNo,
                driver_name:values.driverName,
                second_driver_name:values.secondDriverName,
                second_driver_phone_no:values.secondPhoneNo,
                operator_code:global.operatorCode,
                doj:getAPIDateFormat(selectedTrip.start_date)
            };
            setLoading(true);
            updatePhoneNoandVechicleNo(trip,()=>{
                setLoading(false);
                handleCancel();
                onVechicleUpdateSuccess(vehicleId,values.phoneNo);
            },()=>{
                setLoading(false);  
            })
          return true;
        }).catch((err)=>{
          return false;
        })
    }

    if(checkActionAllowed(ACTION_TO_EDIT_VECHICLE_INFO_FROM_AGENT_PORTAL)){
        let className = isModalVisible?"displayNone":'';
        return (
            <div>
                <Tooltip placement="topLeft" title={"Assign Vehicle number & Driver Phone"} overlayClassName={className}>
                    <Button 
                    onClick={showModal} 
                    ghost 
                    className="open-select-seat-btn">
                        {vechicleInfoExits?
                        getVechicleNo(vechicleId,vechicles)
                        :
                        <Icon component={() => (<img src={LocationGrayIcon} style={{width:'23px',padding:'1px'}} />)} />
                        }
                    </Button>
                </Tooltip>
                <Modal 
                centered={true}
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel}
                className="edit-fare-modal"
                okText="Update"
                footer={null}
                >
                    <div>
                        <div className='fontWeightBold'>
                            Assign Vehicle & Driver
                        </div>
                        <EditDrawLine />
                        <div className="fontWeightBold">
                            {scheduleDescription}
                        </div>
                        <div>
                            Travel Date : {getFormatedDate(travelDate,true,true,true,'.')}
                        </div>
                        <div>
                            <Form
                             form={form}
                             name="basic"
                             initialValues={{
                                 vechicleId,
                                 phoneNo,
                                 secondDriverName,
                                 driverName,
                                 secondPhoneNo}}
                             labelCol={{ span: 8 }}
                                wrapperCol={{ span: 16 }}
                            >
                                <Form.Item
                                    label="Vechicle #"
                                    name="vechicleId"
                                    rules={[{ required: true, message: 'Please select vechicle' }]}
                                >
                                        <Select
                                                size="large"
                                                showSearch
                                                placeholder="Select a vechicle"
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                bordered={true}
                                                >
                                                {renderVechicles(vechicles,busId)}
                                        </Select>
                                </Form.Item>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <StaffSelect 
                                            formName={"driverName"}
                                            isFormItem={true}
                                            isManditory={false}
                                            showLabel={true}
                                            showLabelName="Driver name-1"
                                            onChange={(value,info)=>{
                                                form.setFieldsValue({phoneNo:info.staff.phone_number});
                                            }}
                                            isOnlyDriver={true}
                                        /> 
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="Phone #"
                                            name="phoneNo"
                                            rules={[{ required: true, message: 'Please enter phone number' }]}
                                        >
                                                <Input />
                                        </Form.Item> 
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <StaffSelect 
                                            formName={"secondDriverName"}
                                            isFormItem={true}
                                            isManditory={false}
                                            showLabel={true}
                                            showLabelName="Driver name-2"
                                            onChange={(value,info)=>{
                                                form.setFieldsValue({secondPhoneNo:info.staff.phone_number});
                                            }}
                                            isOnlyDriver={true}
                                        /> 
                                    </Col>
                                    <Col>
                                        <Form.Item
                                            label="Phone #"
                                            name="secondPhoneNo"
                                            rules={[{ required: false, message: 'Please enter phone number' }]}
                                        >
                                                <Input />
                                        </Form.Item>  
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        <div className="footer-block">
                            <div className="cancel-button-root" onClick={handleCancel}>
                                <Button className="book-button">Cancel</Button>
                            </div>
                            <div>
                                <Button onClick={handleSubmit}>Update</Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }else{
        return null
    }
}