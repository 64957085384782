import React, {useEffect, useState} from 'react'
import { Form, Input, Button, message } from 'antd';
import { isValid, isValidArray } from '../../../utils/utilities';
import { insertAddress , updateAddress , setCacheAddress} from '../../../actions/trip-create.actions';
import { getLatLngofAddress } from './../../../actions/admin.action';
import { useDispatch } from 'react-redux';
import { loadingAction } from './../../../actions/loading-actions';

export const AddressForm = (props) => {
    const {
        isVisible,
        setVisible,
        cityName,
        getCityAddress,
        setAddressFormData,
        addressFromData,
        setEnableLocationPick,
        childRef,
        editableAddressIndex
    } = props;


    const [form] = Form.useForm();
    const [disableAdd, setDisableAdd] = useState(true);
    const [disableOk, setDisableOk] = useState(true);
    const [disAbleSetMapLocation,setDisableSetMapLocation] = useState(true);

    useEffect(() => {
        if (isVisible)
        {
            form.resetFields();
        }
    },[isVisible]);

    useEffect(() => {
        childRef.current = callBackOnSetLocation
    }, [])
  
    const callBackOnSetLocation=(lat,lng)=>{
        let changedLatLng = {
            ...addressFromData,
            latitude:lat,
            longitude:lng,
            draggable:true
        };
        onChange(changedLatLng);
        setDisableAdd(false);
    }
  

    useEffect(()=>{
        if(isValidArray(addressFromData)&&isValid(addressFromData.id)){
            form.setFieldsValue({
                stop_name: addressFromData.name,
                landmark: addressFromData.landmark,
                address: addressFromData.address,
                latitude:addressFromData.latitude,
                longitude:addressFromData.longitude,
                phone_number:addressFromData.phone_number
            });
            setDisableSetMapLocation(false);
        }
    },[addressFromData]);

    const onChange=(newAddress)=>{
        setAddressFormData({...newAddress});
    }

    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onOk = () => {
        setVisible(false);
    }

    const onCancel = () => {
        setVisible(false);
    }

    const onSuccess= (address) => {
        getCityAddress();
        setVisible(false);
        onChange(address);
        setLoading(false);
    }

    const onFailure = (data) => {
        message.error(data);
        setLoading(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
        .then((values) => {
            setLoading(true);
            let latitude = null , longitude = null;
            if(isValid(addressFromData.latitude)){
                latitude = addressFromData.latitude;
                longitude = addressFromData.longitude;
            }
            if(isValid(values.latitude)){
                latitude = values.latitude;
                longitude = values.longitude;
            }
            let data = 
            { 
                name: values.stop_name,
                latitude: latitude,
                longitude: longitude,
                landmark: values.landmark,
                address: values.address,
                phone_number: values.phone_number,
                landline_number: values.landline_number,
                city: cityName,
                agent_user_name: null,
                precedence_number: null,
                is_office: 0,
                operator_code : global.operatorCode
            }
            if(isValid(addressFromData.id)){
                data.id = addressFromData.id;
                updateAddress(data,()=>{
                    onSuccess({...data});
                }, onFailure);
            }else{
                insertAddress(data,(addressId)=>{
                    data.id = addressId;
                    onSuccess({...data})
                }, onFailure);
            }
        })
    }

    let intialPhoneNumber = global.phoneNumber;
    // if(isValid(addressFromData.phone_number)){
    //     intialPhoneNumber= addressFromData.phone_number;
    // }

    return (
        <div visible= {isVisible} onOk={onOk} onCancel={onCancel}>
            {isVisible&&
                <div className='create-new-stop-block'>
                    <div>
                        <h3>
                            {isValid(addressFromData.id)?
                            `Update Stop : ${addressFromData.markerText}`
                                :
                            `New Stop`
                            }
                        </h3>
                    </div>
                <div className="create-trip-underline"></div>
                <Form form = {form} name = "userForm" 
                    initialValues={{
                            phone_number:intialPhoneNumber
                    }}>
                    <Form.Item name = {'stop_name'} label= "" rules={[{ required: true, message: "" }]}>
                        <Input placeholder='Stop name'
                        autoFocus={true}
                        />
                    </Form.Item>
                    <Form.Item name= {'phone_number'} label = "" rules={[{ required: true, message: "" }]}>
                        <Input placeholder='Phone number'
                        />
                    </Form.Item>
                    <Form.Item name = {'landmark'} label = "" rules={[{ required: true, message: "" }]}>
                        <Input placeholder='Landmark' 
                        />
                    </Form.Item>
                    <Form.Item name = {'address'} label = "" rules={[{ required: true, message: "" }]}>
                        <Input.TextArea 
                            placeholder='Address'
                            onChange={(e)=>{
                                if(e.target.value.length >= 4){
                                    setDisableOk(false);
                                }else{
                                    setDisableOk(true);
                                }
                            }}
                        />
                    </Form.Item>
                    <div className='marginBottom fetch-latlng-ok-button'>
                        <Button 
                            className='ok-button-address'
                            size='small'
                            disabled={disableOk}
                            onClick={()=>{
                                let{
                                    address
                                } = form.getFieldsValue();
                                setDisableOk(true);
                                setDisableSetMapLocation(false);
                                let googleSuggestName = `${address},${cityName}`
                                getLatLngofAddress(googleSuggestName,(lat,lng)=>{
                                    callBackOnSetLocation(lat,lng);
                                },(lat,lng)=>{
                                    console.log("Failed",{lat,lng});
                                });
                            }}
                        >Add to map</Button>
                    </div>
                    <div className='setLocationOnMapBlock'>
                        <Button className='setLocationOnMapButton'
                            disabled={disAbleSetMapLocation}
                            onClick={()=>{
                                setEnableLocationPick(true);
                                dispatch(setCacheAddress(addressFromData,editableAddressIndex));
                            }}
                        >
                            Set Location On Map
                        </Button>
                    </div>
                    <div className='address-form-footer'>
                        <Form.Item>
                            {!isValid(addressFromData.id)?
                                <div className='add-button'>
                                    <Button 
                                    disabled={disableAdd}
                                    type="primary" 
                                    onClick={(e) => {
                                                handleSubmit(e);
                                            }}>
                                        Add
                                    </Button>
                                </div>
                                :
                                <div className='add-button'>
                                    <Button 
                                    type="primary" 
                                    onClick={(e) => {
                                                handleSubmit(e);
                                            }}>
                                        Update
                                    </Button>
                                </div>
                            }
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button
                                    onClick={onCancel}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </Form.Item>
                    </div>
                </Form>
                </div>
            }
        </div>
    )
}