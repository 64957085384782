import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table , Radio ,Select, message, Checkbox} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch, useSelector } from "react-redux";
import { fetchSettlementSheet, fetchTripsByTravelDate } from './../../actions/reports.action';
import { Printer } from './../../components/custom-components/custom-component';
import { arrayToString , isValid, isValidArray, isValidNumber } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDate, getLocalDateAndTime } from './../../utils/date.utils';
import { getRangeOptions } from './reports.module';
import { roundNumber, roundOffWithCeilToInteger } from "../../utils/math.utils";
import ReactExport from "react-export-excel";
import { RED_BUS_USER_NAME , ABHI_BUS_USER_NAME, PAYTM_BUS_USER_NAME } from './../../utils/constant';
import { userPartnerSelectors } from "../../selector/user.selector";
import { ScheduleSelect } from "../../components/search-components/schedule-select/schedule-select.component";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const redbusType = 'red';
const abhibustype = 'abhi';
const paytmType = 'paytm';
const qwikbus = 'QwikBus';

const {
    Option
} = Select;

const printPartnerOption = (option) => {
    return <Option  value={option.partner_code} data={option} key={option.partner_code}>{option.partner_name}</Option>
};


const verifyOTAUser=()=>{
    let userName = global.userName;
    if(isValid(userName)){
        userName = userName.toLowerCase();
        let redBusIndex = userName.search(RED_BUS_USER_NAME);
        let abhibusIndex = userName.search(ABHI_BUS_USER_NAME);
        let paytmBusIndex = userName.search(PAYTM_BUS_USER_NAME);
        if(redBusIndex!=-1 || abhibusIndex != -1 || paytmBusIndex != -1){
            return false
        }
    }
    return true;
}

const getBookingType=()=>{
    let userName = global.userName;
    if(isValid(userName)){
        userName = userName.toLowerCase();
        let redBusIndex = userName.search(RED_BUS_USER_NAME);
        let abhibusIndex = userName.search(ABHI_BUS_USER_NAME);
        let paytmBusIndex = userName.search(PAYTM_BUS_USER_NAME);
        if(redBusIndex!=-1){
            return redbusType;
        }
        else if(abhibusIndex!=-1){
            return abhibustype;
        }
        else if(paytmBusIndex!=-1){
            return paytmType;
        }
    }
    return ''
}

const generateJsonToExport=(filters,settlementTickets,confirmedTickets,cancelledTickets,busCancelledTickets)=>{

    let masterTable=[],masterConfimedTickets=[] , masterCancelledTickets=[] , masterBusCancelledTickets = [];
    let rootColums =[
        "Category", "Ticket Fare" , "Commission" , 
        "GST On Commission" , "Total"
    ];

    if(filters.bookingType === abhibustype){
        rootColums =[
            "Category", "Ticket Fare" , "Commission" ,"Tds","Total"
        ];
    }

    if(isValidArray(settlementTickets)&&settlementTickets.length>0){
        settlementTickets.forEach(element => {
            if(filters.bookingType === redbusType || filters.bookingType === paytmType){
                let commission = 0 , gstOnCommsion=0;
                if(isValid(element.commission)){
                    commission = roundNumber(element.commission/1.18);
                    gstOnCommsion = roundNumber((element.commission/1.18)*0.18);
                }
                masterTable.push([
                    element.title,element.ticketFare,commission,gstOnCommsion,element.total
                ]);
            }else{
                let tds =  roundOffWithCeilToInteger((1.05*element.ticketFare)/100);
                let total = roundNumber(element.total - tds);
                masterTable.push([
                    element.title,element.ticketFare,element.commission,tds,total
                ]);
            }
        });
    }

    if(isValidArray(confirmedTickets)&&confirmedTickets.length){
        let totalPaymentTicketsFare = 0 , totalCommission = 0 , totalNetpayable = 0; 
        confirmedTickets.forEach(element =>{
            let bookingTime = '',journeyTime = '' , seatNumbers='',fare=0,commission=0,netPayable=0;
            if(isValid(element.booking_time)){
                bookingTime = getLocalDate(element.booking_time);
            }
            if(isValid(element.start_date)){
                journeyTime = getLocalDate(element.start_date);
            }
            if(isValid(element.seat_numbers)){
                seatNumbers = arrayToString(element.seat_numbers);
            }
            if(isValid(element.fare)){
                fare =  roundNumber(element.fare);
                totalPaymentTicketsFare = totalPaymentTicketsFare + fare;
            }
            if(isValid(element.commission)){
                commission = roundNumber(element.commission);
                totalCommission = totalCommission + commission
            }
            if(isValid(element.fare)){
                netPayable = roundNumber(element.fare - element.commission);
                totalNetpayable = totalNetpayable + netPayable;
            }

            masterConfimedTickets.push([
                element.pnr,
                bookingTime,
                journeyTime,
                element.route,
                seatNumbers,
                fare,
                commission,
                netPayable
            ]);
        });
        masterConfimedTickets.push([
            "Total Payment",'','','','',roundNumber(totalPaymentTicketsFare),roundNumber(totalCommission),roundNumber(totalNetpayable)
        ]);
    }

    if(isValidArray(cancelledTickets)&&cancelledTickets.length){
        let totalPaymentTicketsFare = 0 , totalCancellationPenality = 0 , totalNetReversed = 0; 
        cancelledTickets.forEach(element =>{
            let cancellationTime = '',journeyTime = '' , seatNumbers='',fare=0,cancellationPenalty=0,netReversed=0;
            if(isValid(element.cancellation_time)){
                cancellationTime = getLocalDate(element.cancellation_time);
            }
            if(isValid(element.start_date)){
                journeyTime = getLocalDate(element.start_date);
            }
            if(isValid(element.seat_numbers)){
                seatNumbers = arrayToString(element.seat_numbers);
            }
            if(isValid(element.fare)){
                fare =  roundNumber(element.fare);
                totalPaymentTicketsFare = totalPaymentTicketsFare + fare;
            }
            if(isValid(element.cancellation_penalty)){
                cancellationPenalty = roundNumber(element.cancellation_penalty);
                totalCancellationPenality = totalCancellationPenality + element.cancellation_penalty;
            }
            if(isValid(element.fare)){
                netReversed = roundNumber(element.fare - cancellationPenalty);
                totalNetReversed = totalNetReversed + netReversed;
            }

            masterCancelledTickets.push([
                element.pnr,
                cancellationTime,
                journeyTime,
                element.route,
                seatNumbers,
                fare,
                cancellationPenalty,
                netReversed
            ]);
        });
        masterCancelledTickets.push([
            "Total Cancelled",'','','','',roundNumber(totalPaymentTicketsFare),roundNumber(totalCancellationPenality),roundNumber(totalNetReversed)
        ]);
    }

    if(isValidArray(busCancelledTickets)&&busCancelledTickets.length){
        let totalPaymentTicketsFare = 0 , totalCommission = 0 , totalNetReversed = 0; 
        busCancelledTickets.forEach(element =>{
            let cancellationTime = '',journeyTime = '' , seatNumbers='',fare=0,commission=0,netReversed=0;
            if(isValid(element.cancellation_time)){
                cancellationTime = getLocalDate(element.cancellation_time);
            }
            if(isValid(element.start_date)){
                journeyTime = getLocalDate(element.start_date);
            }
            if(isValid(element.seat_numbers)){
                seatNumbers = arrayToString(element.seat_numbers);
            }
            if(isValid(element.fare)){
                fare =  roundNumber(element.fare);
                totalPaymentTicketsFare = totalPaymentTicketsFare + fare;
            }
            if(isValid(element.commission)){
                commission = roundNumber(element.commission);
                totalCommission = totalCommission + element.commission;
            }
            if(isValid(element.fare)){
                netReversed = roundNumber(element.fare - commission);
                totalNetReversed = totalNetReversed + netReversed;
            }

            masterBusCancelledTickets.push([
                element.pnr,
                cancellationTime,
                journeyTime,
                element.route,
                seatNumbers,
                fare,
                commission,
                netReversed
            ]);
        });
        masterBusCancelledTickets.push([
            "Total Bus Cancelled",'','','','',roundNumber(totalPaymentTicketsFare),roundNumber(totalCommission),roundNumber(totalNetReversed)
        ]);
    }

    const multiDataSet = [
        {
            columns: ["Report","From date", "To date","Agent type" , "Generated day/time",'','',''],
            data: [[
                    "Settlement report",filters.fromDate,filters.toDate,global.privilege,filters.generatedTime,'','',''
            ]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: rootColums,
            data: masterTable
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "Payments"
            ],
            data: [[]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 1, //will put space of 5 rows,
            columns: [
                "PNR","DOE","DOJ","Route","Seats","Ticket fare","Commission","Net Payable"
            ],
            data: masterConfimedTickets
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "Cancelled tickets"
            ],
            data: [[]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 1, //will put space of 5 rows,
            columns: [
                "PNR","DOE","DOJ","Route","Seats","Ticket fare","Cancellation penality","Net Reversed"
            ],
            data: masterCancelledTickets
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "Bus Cancelled tickets"
            ],
            data: [[]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 1, //will put space of 5 rows,
            columns: [
                "PNR","DOE","DOJ","Route","Seats","Ticket fare","COmmission","Pervious Net Paid"
            ],
            data: masterBusCancelledTickets
        }
    ];
    

    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"settlement-report"}> 
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}

const getSettlementColumns=(bookingType)=>{
    const settlementColumns=[
        {
            title:"Category",
            key:"title",
            dataIndex:'title'
        },
        {
            title:"Ticket Fare",
            key:"ticketFare",
            dataIndex:'ticketFare'
        }
    ];
    if(bookingType === redbusType || bookingType === paytmType){
        settlementColumns.push({
            title:"Commission",
            key:"commission",
            dataIndex:"commission",
            render:(text)=>{
                if(isValid(text)){
                    return(
                        <div>{roundNumber(text/1.18)}</div>
                    )
                }else{
                    return text;
                }

            }
        });

        settlementColumns.push({
            title:"GST On Commission",
            key:"gstComm",
            dataIndex:"commission",
            render:(text)=>{
                if(isValid(text)){
                    return(
                        <div>{roundNumber((text/1.18)*0.18)}</div>
                    )
                }else{
                    return text
                }
            }
        });

        // settlementColumns.push( {
        //     title:"AC Bus Gst",
        //     key:"gst",
        //     dataIndex:"gst"
        // });

        settlementColumns.push({
            title:"Total",
            key:"total",
            dataIndex : "total"
        });
    }else{
        settlementColumns.push({
            title:"Total Commission",
            key:"commission",
            dataIndex:"commission",
            render:(text,record)=>{
                if(isValid(text)){
                    return(
                        <div>{roundNumber(text)}</div>
                    )
                }else{
                    return text;
                }

            }
        });
        // settlementColumns.push( {
        //     title:"AC Bus Gst",
        //     key:"gst",
        //     dataIndex:"gst"
        // });

        settlementColumns.push({
            title:"TDS",
            key:"ticketFare",
            dataIndex : "ticketFare",
            render:(text)=>{
                if(bookingType == qwikbus){
                    return 0
                }else{
                    if(isValid(text)){
                        return(
                            <div>{roundOffWithCeilToInteger((1.05*text)/100)}</div>
                        )
                    }else{
                        return text
                    }
                }
            }
        });
        settlementColumns.push({
            title:"Total",
            key:"total",
            dataIndex : "total",
            render:(text,record)=>{
                if(isValid(text)){
                    let tds = roundOffWithCeilToInteger((1.05*record.ticketFare)/100);
                    return(
                        <div>{roundNumber(text-tds)}</div>
                    )
                }else{
                    return text
                }
            }
        });
    }
    return settlementColumns;
}

const confirmedTicketsColumns = [
    {
        title:'PNR',
        dataIndex:'pnr',
        key:'pnr'
    },
    {
        title:'DOE',
        dataIndex:'booking_time',
        key:'booking_time',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'DOJ',
        dataIndex:'start_date',
        key:'start_date',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'Route',
        dataIndex:'route',
        key:'route'
    },
    {
        title:'Seats',
        dataIndex:'seat_numbers',
        key:'seat_numbers',
        render:(seatNumbers)=>{
            return(
                arrayToString(seatNumbers)
            )
        }
    },
    {
        title:'Ticket Fare',
        dataIndex:'fare',
        key:'fare',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Commission',
        dataIndex:'commission',
        key:'commission',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Net Payable',
        dataIndex:'netPayable',
        key:'netPayable',
        render:(value,row)=>{
            return(
                roundNumber(row.fare - row.commission)
            )
        }
    }
];

const cancelledTicketsColumns = [
    {
        title:'PNR',
        dataIndex:'pnr',
        key:'pnr'
    },
    {
        title:'DOE',
        dataIndex:'cancellation_time',
        key:'cancellation_time',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'DOJ',
        dataIndex:'start_date',
        key:'start_date',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'Route',
        dataIndex:'route',
        key:'route'
    },
    {
        title:'Seats',
        dataIndex:'seat_numbers',
        key:'seat_numbers',
        render:(seatNumbers)=>{
            return(
                arrayToString(seatNumbers)
            )
        }
    },
    {
        title:'Ticket Fare',
        dataIndex:'fare',
        key:'fare',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Cancellation penality',
        dataIndex:'cancellation_penalty',
        key:'cancellation_penalty',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Net Reversed',
        dataIndex:'commission',
        key:'commission',
        render:(value,row)=>{
            return(
                roundNumber(row.fare - row.cancellation_penalty)
            )
        }
    }
];


const busCancelledTicketsColumns = [
    {
        title:'PNR',
        dataIndex:'pnr',
        key:'pnr'
    },
    {
        title:'DOE',
        dataIndex:'cancellation_time',
        key:'cancellation_time',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'DOJ',
        dataIndex:'start_date',
        key:'start_date',
        render:(value)=>{
            if(isValid(value)){
                return getLocalDate(value);
            }else{
                return null;
            }
        }
    },
    {
        title:'Route',
        dataIndex:'route',
        key:'route'
    },
    {
        title:'Seats',
        dataIndex:'seat_numbers',
        key:'seat_numbers',
        render:(seatNumbers)=>{
            return(
                arrayToString(seatNumbers)
            )
        }
    },
    {
        title:'Ticket Fare',
        dataIndex:'fare',
        key:'fare',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Commission',
        dataIndex:'commission',
        key:'commission',
        render:(value)=>{
            return(
                roundNumber(value)
            )
        }
    },
    {
        title:'Pervious Net Paid',
        dataIndex:'commission',
        key:'commission',
        render:(value,row)=>{
            return(
                roundNumber(row.fare - row.commission)
            )
        }
    }
];


const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({confirmedTickets,formValues,settlementTickets,cancelledTickets,busCancelledTickets})=>{

    let {
        dateRange,
        bookingType
    }=formValues;
    if((isValidArray(settlementTickets))&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Settlement report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Payment mode : </Text>
                                        DOJ
                                    </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table
                                        className="bordered-table"
                                        columns={getSettlementColumns(bookingType)}
                                        dataSource={settlementTickets}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Text strong>Payments</Text>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table 
                                        className="bordered-table"
                                        columns={confirmedTicketsColumns}
                                        dataSource={confirmedTickets}
                                        pagination={false}
                                        summary={(pageData ) => {
                                            let summaryTotalFare = 0;
                                            let summaryCommission = 0;
                                            let summaryNetPayable = 0;
                                            pageData.forEach(({ fare, commission,netPayable}) => {
                                               if(isValidNumber(fare)){
                                                summaryTotalFare = summaryTotalFare + fare;
                                               }
                                               if(isValidNumber(commission)){
                                                summaryCommission = summaryCommission + commission;
                                               }
                                               netPayable = fare-commission
                                               if(isValidNumber(netPayable)){
                                                summaryNetPayable = summaryNetPayable + netPayable;
                                               }
                                            });
              
                                            return(
                                                <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                  <Table.Summary.Cell index={0}>Total Payment</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={1}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={4}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={5}>{roundNumber(summaryTotalFare)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={6}>{roundNumber(summaryCommission)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={7}>{roundNumber(summaryNetPayable)}</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                              </Table.Summary>
                                            )
                                    }}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Text strong>Cancelled Tickets</Text>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table 
                                        className="bordered-table"
                                        columns={cancelledTicketsColumns}
                                        dataSource={cancelledTickets}
                                        pagination={false}
                                        summary={(pageData ) => {
                                            let summaryTotalFare = 0;
                                            let summayCancellationPenality = 0;
                                            let summaryNetReversed = 0;
                                            pageData.forEach(({ fare, cancellation_penalty}) => {
                                               if(isValidNumber(fare)){
                                                summaryTotalFare = summaryTotalFare + fare;
                                               }
                                               if(isValidNumber(cancellation_penalty)){
                                                summayCancellationPenality = summayCancellationPenality + cancellation_penalty;
                                               }
                                               let netReversed = fare-cancellation_penalty
                                               if(isValidNumber(netReversed)){
                                                summaryNetReversed = summaryNetReversed + netReversed;
                                               }
                                            });
              
                                            return(
                                                <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                  <Table.Summary.Cell index={0}>Total Cancelled</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={1}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={4}>{}</Table.Summary.Cell> 
                                                  <Table.Summary.Cell index={5}>{roundNumber(summaryTotalFare)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={6}>{roundNumber(summayCancellationPenality)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={7}>{roundNumber(summaryNetReversed)}</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                              </Table.Summary>
                                            )
                                    }}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Text strong>Bus Cancelled Tickets</Text>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table
                                        className="bordered-table"
                                        columns={busCancelledTicketsColumns}
                                        dataSource={busCancelledTickets}
                                        pagination={false}
                                        summary={(pageData ) => {
                                            let summaryTotalFare = 0;
                                            let summaryCommission = 0;
                                            let summaryNetReversed = 0;
                                            pageData.forEach(({ fare,commission}) => {
                                               if(isValidNumber(fare)){
                                                summaryTotalFare = summaryTotalFare + fare;
                                               }
                                               if(isValidNumber(commission)){
                                                summaryCommission = summaryCommission + commission;
                                               }
                                               let netReversed = fare-commission
                                               if(isValidNumber(netReversed)){
                                                summaryNetReversed = summaryNetReversed + netReversed;
                                               }
                                            });
              
                                            return(
                                                <Table.Summary fixed>
                                                <Table.Summary.Row>
                                                  <Table.Summary.Cell index={0}>Total Bus Cancelled</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={1}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={4}>{}</Table.Summary.Cell> 
                                                  <Table.Summary.Cell index={5}>{roundNumber(summaryTotalFare)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={6}>{roundNumber(summaryCommission)}</Table.Summary.Cell>
                                                  <Table.Summary.Cell index={7}>{roundNumber(summaryNetReversed)}</Table.Summary.Cell>
                                                </Table.Summary.Row>
                                              </Table.Summary>
                                            )
                                    }}
                                    />
                                </Col>
                            </Row>
                        </div>
            )
    }else{
        return null
    }
}

export const SettlementReport = () => {


    const [confirmedTickets,setConfirmedTickets]=useState(undefined);
    const [settlementTickets,setSettlementTickets]=useState(undefined);
    const [cancelledTickets,setCancelledTickets]=useState(undefined);
    const [busCancelledTickets,setBusCancelledTickets]=useState(undefined);
    const [schedules,setSchedules]=useState(undefined);
    const [trips,setTrips]=useState(undefined);

    const partnerDetails = useSelector(userPartnerSelectors);

    const [filters,setFilters]=useState({});

    const [showReport,setShowReport]=useState(false);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        let {
            settlementReportData,
            confirmedTicketsData,
            cancelledTicketsData,
            busCancelledTicketsData
        }= data;
        setLoading(false);
        setShowReport(true);
        setConfirmedTickets(confirmedTicketsData);
        setSettlementTickets(settlementReportData);
        setCancelledTickets(cancelledTicketsData);
        setBusCancelledTickets(busCancelledTicketsData);
    }

    const onFailure=()=>{
        setLoading(false);
        setShowReport(true);
        setConfirmedTickets([]);
        setSettlementTickets([]);
        setCancelledTickets([]);
        setBusCancelledTickets([]);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onTripsFetchSuccess = (data)=>{
        setSchedules(data.schedules);
        setTrips(data.trips);

    }

    const onTripFetchFailure = ()=>{

    }

    const onFinish=(values)=>{
        let {
            dateRange,
            partnerCode
        }=values;
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);

        fetchTripsByTravelDate(fromDate,toDate,onTripsFetchSuccess,onTripFetchFailure,partnerCode);
        setFilters({
            fromDate,
            toDate,
            generatedTime: getLocalDateAndTime(new Date()),
            partnerCode: partnerCode
        })
    }

    const [form] = Form.useForm();
    const [scheduleForm] = Form.useForm();

    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            {isValidArray(partnerDetails)&&partnerDetails.length>0&&
                                <Form.Item label="Partner Code: " name="partnerCode" 
                                    rules={[{ required: true, message: "Please input your partner code!"}]}>
                                        <Select
                                            showSearch
                                            placeholder="Select partner"
                                            className=""
                                            style={{ width: 100 }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {partnerDetails.map(printPartnerOption)}
                                        </Select>
                                </Form.Item>
                            }
                        </Col> 
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {isValidArray(schedules)&&
                        <Form
                        form={scheduleForm}
                        initialValues={{isSelectAllSchedules:true,returnId:[]}}
                        onFinish={(values)=>{
                            let {
                                returnId,
                                isSelectAllSchedules,
                                bookingType
                            }=values;

                            if(!isValid(bookingType)){
                                bookingType = getBookingType();
                            }
                            setShowReport(false);
                            if(isValid(returnId)||isSelectAllSchedules){
                                setLoading(true);
                                let scheduleIds = returnId;
                                console.log({returnId});
                                
                                if(isValidArray(scheduleIds)&&scheduleIds.length==0&&isSelectAllSchedules){
                                    scheduleIds = [];
                                    for (let index = 0; index < schedules.length; index++) {
                                        const element = schedules[index];
                                        scheduleIds.push(element.id);
                                    }
                                }                                
                                fetchSettlementSheet(filters.fromDate,filters.toDate,bookingType,filters.partnerCode,scheduleIds,onSuccess,onFailure);
                            }else{
                                message.error("Select schedule!");
                            }
                        }}
                        >
                            <Row gutter={[16,16]}>
                                <Col>
                                    <ScheduleSelect
                                        schedules={schedules}
                                        optionKey="id"
                                        showLabel={true}
                                        isManditory={false}
                                        mode={"multiple"}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item name="isSelectAllSchedules"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[16,16]}>
                                {verifyOTAUser()&&
                                    <Col>
                                        <Form.Item 
                                            name="bookingType" 
                                            label="" 
                                            rules={[{ required: true , message: 'Please select any option!'}]}>
                                                <Radio.Group>
                                                    <Radio value={redbusType}>Redbus</Radio>
                                                    <Radio value={abhibustype}>Abhibus</Radio>
                                                    <Radio value={paytmType}>Paytm</Radio>
                                                    <Radio value={qwikbus}>QwikBus</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                    </Col>
                                    }
                                <Col>
                                    <Form.Item>
                                        <Button htmlType="submit">Get</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                }
                {showReport?
                    <Row gutter={16}>
                        <Col>
                            {generateJsonToExport(filters,settlementTickets,confirmedTickets,cancelledTickets,busCancelledTickets)}
                        </Col>
                        <Col>
                            <Printer 
                                showComponent={false}
                                renderComponent={()=>{
                                    return (
                                        <RenderReport 
                                            confirmedTickets={confirmedTickets}
                                            settlementTickets={settlementTickets}
                                            cancelledTickets={cancelledTickets}
                                            busCancelledTickets={busCancelledTickets} 
                                            formValues={form.getFieldsValue()}
                                            pagination={false}
                                        />
                                    )
                                }} 
                                zoomValue={0.5}
                                />
                        </Col>
                    </Row>
                    :
                    null
                }
                <RenderReport 
                    confirmedTickets={confirmedTickets}
                    settlementTickets={settlementTickets}
                    cancelledTickets={cancelledTickets}
                    busCancelledTickets={busCancelledTickets} 
                    formValues={form.getFieldsValue()}
                    pagination={true}
                 />
            </div>
    )
}