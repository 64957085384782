import React from "react";
import { LoginPage } from './../pages/login.page';
import { HomePage } from "../pages/home.page";
import { BusCancelPage } from "../pages/bus-cancel.page";
import { TripExpensePage } from "../pages/trip-expense.page";
import { NotificationPage } from "../pages/notification.page";
import { ViewTicketPage } from "../pages/view-ticket.page";
import { BrowserRouter as Router, Route , Switch} from 'react-router-dom';
import  PrivateRoute from "./private-route";
import { AppHeader} from './../components/app-header/app-header.component';
import { BookedTicketsReport } from './../pages/reports/booked-tickets.report';
import { SettlementReport } from './../pages/reports/settlement.report';
import { ChangePasswordPage } from'./../pages/change-password.page';
import { LoadingComponent } from './../components/loading-component/loading.component';
import { TripSheetReport } from './../pages/reports/trip-sheet.report';
import { SalesTicketReport } from './../pages/reports/sales-ticket.report';
import { MasterTripSheetReport } from './../pages/reports/master-trip-sheet.report';
import { TicketSearchReport } from './../pages/reports/ticket-search-report';
import { TicketSearchReportV2 } from './../pages/reports/ticket-search-report-v2';
import { RescheduledTicketReport } from './../pages/reports/rescheduled-ticket-report';
import { ViewPassengersInfoReport } from './../pages/reports/view-passengers-info.report';
import { PerformanceReport } from "./../pages/reports/performance.report";
import { BlockedSeatsReport } from './../pages/reports/blocket-seats.report';
import { TotalTransactionReport } from './../pages/reports/total-transaction.report';
import { CancelledTicketsReport } from './..//pages/reports/cancel-tickets.report';
import { AbandonedReport } from './..//pages/reports/abandoned.report';
import { WebSalesTicketReport } from './../pages/reports/web-ticket-sales.report';
import { WebTicketCancelReport } from './../pages/reports/web-ticket-cancel.report';
import { SmsReportComponent } from './../pages/reports/sms.report';
import { StaffPage } from './../pages/staff.page';
import { TicketsBookedAfterTripStartTime } from './../pages/reports/tickets-booked-after-start-time.report';
import { DeviceProvider } from './../components/device-provider/device-provider';
import { ActiveBookingsReport } from './../pages/reports/active-bookings.report';
import { RevenueReport } from "./../pages/reports/revenue.report";

import { 
    checkSearchPage,
    checkCancelPage,
    checkViewNotificationPage,
    checkBusCancellationPage,
    checkStaffPage,
    viewBookedTicketsReport,
    viewSettlementReport,
    viewMasterTripReport,
    viewPassengerInfoReport,
    viewSalesReport,
    viewTicketSearchReport,
    viewTicketSearchReportOld,
    viewTripReport,
    blockedSeatsReport,
    viewPerformanceReport,
    viewTotalTransactionReport,
    cancelledSeatsReport,
    checkViewAbandonedReport,
    checkWebTicketSalesReport,
    checkWebTicketCancelReport,
    tripExpenseReport,
    viewTicketBookedAfterTripStartTime,
    viewSmsReport,
    checkAgentsTransactionPage,
    checkAgentsPage,
    viewDemandDataReport,
    tripCreate,
    checkToViewTripsPageOfAdmin,
    checkToViewPopularRoutesPageAdmin,
    viewActiveBookings,
    viewServiceCountReport,
    checkToViewStandardStopsPage,
    checkToViewTripSheetPermissionPage,
    checkToViewSchedulesPageOfAdmin,
    viewRescheduledTicketReport,
    viewTicketSharingReport,
    viewCostSummaryReportReport,
    viewTransactionSummaryReportReport,
    viewRevenueReport,
    checkToViewCitiesPageAdmin,
    checkPrepaidOTAPage,
    viewPrePaidReport,
    viewTrackingSummaryReport,
    checkToViewUserActions,
    checkDashBoardPage
   } from './../components/app-header/app.header.module';
import {Helmet} from "react-helmet";
import { Row, Tooltip } from 'antd';
import { AgentsPage } from "../pages/agents.page";
import { AgentTransactonsPage } from "../pages/agent-transactions.page";
import { DemandDataReport } from "../pages/reports/demand-data.report";
import { TripCreatePage } from './../pages/trip-create.page'
import { IncompleteTripPage } from "../pages/incomplete-trip.page";
import { TripCreateSuccessPage } from './../pages/trip-create-success.page';
import { ServiceCountReport } from "../pages/reports/service-count.report";
import { StandardStopsPage } from "../pages/standard-stops.page";
import { CreateStandardStopsPage } from "../pages/create-standard-stop.page";
import { TripSheetPermissionPage } from "../pages/trip-sheet-permission.page";
import { SchedulePage } from "../pages/admin/schedule.page";
import { GstFilingReport } from "../pages/reports/gst-filing.reports";
import { RoutePriorityPage } from "../pages/admin/route-priority.page";
import { useSelector } from "react-redux";
import { userLoginSelector } from "../selector/user.selector";
import { TicketSharingReport } from "../pages/reports/ticket-sharing.report";
import { CostSummaryReport } from "../pages/reports/cost-summary.report";
import { TransactionSummaryReport } from "../pages/reports/transaction-summary.report";
import { DashboardPage } from "../pages/dash-board.page";
import { CitiesPage } from "../pages/admin/cities.page";
import { PrepaidOTAPage } from "../pages/prepaid-ota.page";
import { ManualTripPage } from "../pages/manual-trip.page";
import { AddressBookPage } from "../pages/address-book.page";
import { PrePaidTransactionsSummary } from "../pages/reports/pre-paid-transactions-summary.report";
import { TrackingSummaryReport } from "../pages/reports/tracking-data.report";
import { UserActionsPage } from "../pages/admin/user-actions.page";
import { isValid } from "../utils/utilities";

const DrawLine =({className})=>{
    return(
        <div className={className}>

        </div>
    )
}


export const SchedulePageComponent=(props)=>{
    const {
        location
    } = props;
    if(checkToViewSchedulesPageOfAdmin()){
        return<div className="schedule-page-root">
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <Row>
                        <h3 role="schedule-heading">
                            Schedule
                        </h3>            
                    </Row>
                    <DrawLine className="schedule-underline"/>
                    <SchedulePage
                        selectedScheduleId = {isValid(location)&&isValid(location.state)&&isValid(location.state.selectedScheduleId)?location.state.selectedScheduleId:null}
                    />
            </div>
    }else{
        return null
    }
}

export const PopularRoutesPageComponent=()=>{
    if(checkToViewPopularRoutesPageAdmin()){
        return<div className="popular-routes-page-root">
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <RoutePriorityPage />
            </div>
    }else{
        return null
    }
}

export const CitiesPageComponent=()=>{
    if(checkToViewCitiesPageAdmin()){
        return<div className="popular-routes-page-root">
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <CitiesPage />
            </div>
    }else{
        return null
    }
}

export const UserActionsPageComponent=()=>{
    if(checkToViewUserActions()){
        return<div className="popular-routes-page-root">
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <Row>
                        <UserActionsPage />
                    </Row>
            </div>
    }else{
        return null
    }
}

const TotalTransactionReportComponent=()=>{
    if(viewTotalTransactionReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Total transaction sheet
                        </h3>            
                    </Row>
                    <TotalTransactionReport />
            </div>
    }else{
        return null
    }
}

const PerformanceReportComponent=()=>{
    if(viewPerformanceReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Performance sheet
                        </h3>            
                    </Row>
                    <PerformanceReport />
            </div>
    }else{
        return null
    }
}

const RevenueComponent=()=>{
    if(viewRevenueReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Expected Revenue sheet
                        </h3>            
                    </Row>
                    <RevenueReport />
            </div>
    }else{
        return null
    }
}

const PrePaidTransactionSummary=()=>{
    if(viewPrePaidReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Maven Payments
                        </h3>            
                    </Row>
                    <PrePaidTransactionsSummary />
            </div>
    }else{
        return null
    }
}

const MasterTripSheetReportComponent=()=>{
    if(viewMasterTripReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Master trip sheet
                        </h3>            
                    </Row>
                    <MasterTripSheetReport />
            </div>
    }else{
        return null
    }
}

const TicketSearchReportComponent=()=>{
    if(viewTicketSearchReportOld()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Ticket search
                        </h3>            
                    </Row>
                    <TicketSearchReport />
            </div>
    }else{
        return null
    }
}

const TicketSearchReportV2Component=()=>{
    if(viewTicketSearchReport()){
        return<div className="ticket-search-report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <TicketSearchReportV2 />
            </div>
    }else{
        return null
    }
}

const RescheduledTicketReportComponent=() => {
    if(viewRescheduledTicketReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <Tooltip placement="topRight" title = "This report is based on booking date of initial ticket.">
                            <h3>
                                Rescheduled Ticket
                            </h3>
                        </Tooltip>
                    </Row>
                    <RescheduledTicketReport />
            </div>
    }else{
        return null
    }
}

const ViewPassengersInfoReportComponent=()=>{
    if(viewPassengerInfoReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            View passenger info
                        </h3>            
                    </Row>
                    <ViewPassengersInfoReport />
              </div>
    }else{
        return null
    }
}

const TripSheetReportComponent=()=>{
    if(viewTripReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Trip sheet
                        </h3>            
                    </Row>
                    <TripSheetReport />
              </div>
    }else{
        return null
    }
}


const BookedTicketPageComponent=()=>{
    if(viewBookedTicketsReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                           Booked ticket 
                        </h3>            
                    </Row>
                    <BookedTicketsReport />
            </div>
    }else{
        return null
    }
}

const TicketSharingPageComponent=()=>{
    if(viewTicketSharingReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                           Seat sharing 
                        </h3>            
                    </Row>
                    <TicketSharingReport />
            </div>
    }else{
        return null
    }
}

const SettlementPageComponent=()=>{
    if(viewSettlementReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                           Settlement Report 
                        </h3>            
                    </Row>
                    <SettlementReport />
            </div>
    }else{
        return null
    }
}

const GstFilingPageComponent=()=>{
    if(viewSettlementReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                           GST Filing Report 
                        </h3>            
                    </Row>
                    <GstFilingReport />
            </div>
    }else{
        return null
    }
}

const SalesTicketPageComponent=()=>{
    if(viewSalesReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Sales report
                        </h3>            
                    </Row>
                    <SalesTicketReport />
            </div>
    }else{
        return null
    }
}

const BlockedSeatsReportComponent=()=>{
    if(blockedSeatsReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Blocket Seats
                        </h3>            
                    </Row>
                    <BlockedSeatsReport />
            </div>
    }else{
        return null
    }
}

const CancelledSeatsReportComponent=()=>{
    if(cancelledSeatsReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Cancelled Seats
                        </h3>            
                    </Row>
                    <CancelledTicketsReport />
            </div>
    }else{
        return null
    }
}

const HomePageComponent=()=>{
    if(checkSearchPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <HomePage />
            </div>
    }else{
        return null
    }
}

const EditTripComponent=()=>{
    return(
        <ManualTripPage />
    )
}
const AddressBookComponent=()=>{
    return(
        <AddressBookPage />
    )
}

const DashboardPageComponent=()=>{
    if(checkDashBoardPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <DashboardPage />
            </div>
    }else{
        return null
    }
}

const LoginPageComponent=()=>{
    return<div>
                <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                </Helmet>
                <LoginPage />
          </div>
}


const BusCancelPageComponent=()=>{
    if(checkBusCancellationPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <BusCancelPage />
            </div>
    }else{
        return null
    }
}

const StaffPageComponent=()=>{
    if(checkStaffPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <StaffPage />
            </div>
    }else{
        return null
    }
}

const AgentsPageComponent=()=>{
    if(checkAgentsPage()){
        return<div>
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <AgentsPage />
            </div>
    }else{
        return null
    }
}

const AgentTransactionsPageComponent=()=>{
    if(checkAgentsTransactionPage()){
        return<div>
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <AgentTransactonsPage />
            </div>
    }else{
        return null
    }
}

const TripExpensePageComponent=()=>{
    if(tripExpenseReport()){
        return<div>
                   <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                   </Helmet>
                   <TripExpensePage />
        </div>
    }else{
        return null
    }
}

const TripCreatePageComponent = () => {
    if(tripCreate()){
        return<div>
                   <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                   </Helmet>
                   <TripCreatePage />
        </div>
    }else{
        return null
    }
}

const IncompleteTripPageComponent = () =>{
    if(checkToViewTripsPageOfAdmin()){
        return(
            <div>
                 <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                </Helmet>
                <IncompleteTripPage />
            </div>
        )
    }else{  
        return null;
    }
}

const StandardStopsComponent = () =>{
    if(checkToViewStandardStopsPage()){
        return(
            <div>
                 <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                </Helmet>
                <StandardStopsPage />
            </div>
        )
    }else{  
        return null;
    }
}

const CreateStandardStopComponent = () =>{
    if(checkToViewStandardStopsPage()){
        return(
            <div>
                 <Helmet>
                        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                </Helmet>
                <CreateStandardStopsPage />
            </div>
        )
    }else{  
        return null;
    }
}

const NotificationPageComponent=()=>{
    if(checkViewNotificationPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <NotificationPage />
            </div>
    }else{
        return null
    }
}

const ViewTicketPageComponent=()=>{
    if(checkCancelPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <ViewTicketPage />
            </div>
    }else{
        return null
    }
}

const ChangePasswordPageComponent=()=>{
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <ChangePasswordPage />
            </div>
}

const ViewAbandonedComponent=()=>{
    if(checkViewAbandonedReport()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Abandoned sheet
                        </h3>            
                    </Row>
                    <AbandonedReport />
            </div>)
    }else{
        return null
    }
}

const WebTicketSalesComponent=()=>{
    if(checkWebTicketSalesReport()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Web ticket sales sheet
                        </h3>            
                    </Row>
                    <WebSalesTicketReport />
            </div>)
    }else{
        return null
    }
}

const WebTicketCancelComponent=()=>{
    if(checkWebTicketCancelReport()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Web ticket cancel sheet
                        </h3>            
                    </Row>
                    <WebTicketCancelReport />
            </div>)
    }else{
        return null
    }
}

const TripSheetPermissionComponent=()=>{
    if(checkToViewTripSheetPermissionPage()){
        return(<div className="report-root">
                    <Row>
                        <h3>
                            Trip Sheet Permissions
                        </h3>            
                    </Row>
                    <DrawLine className="trip-sheet-permissions-underline"/>
                    <TripSheetPermissionPage />
            </div>)
    }else{
        return null
    }
}


const TicketsBookedAfterTripStartTimeComponent=()=>{
    if(viewTicketBookedAfterTripStartTime()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Tickets Booked After Departure
                        </h3>            
                    </Row>
                    <TicketsBookedAfterTripStartTime />
            </div>)
    }else{
        return null
    }
}


const SmsReport=()=>{
    if(viewSmsReport()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            SMS Report
                        </h3>            
                    </Row>
                    <SmsReportComponent />
            </div>)
    }else{
        return null
    }
}

const DemandDataReportComponent = () => {
    if (viewDemandDataReport()){
        return(<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Demand Data report
                        </h3>
                    </Row>
                    <DemandDataReport />
        </div>)
    }else {
        return null;
    }
}

const ActiveBookingsReportComponent=()=>{
    if(viewActiveBookings()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Live Bookings
                        </h3>            
                    </Row>
                    <ActiveBookingsReport />
            </div>
    }else{
        return null
    }
}

const ServiceCountReportComponent=()=>{
    if(viewServiceCountReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Service count
                        </h3>            
                    </Row>
                    <ServiceCountReport />
            </div>
    }else{
        return null
    }
}

const CostSummaryReportComponent=()=>{
    if(viewCostSummaryReportReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Billing Report
                        </h3>
                    </Row>
                    <CostSummaryReport />
            </div>
    }else{
        return null
    }
}

const TransactionSummaryReportComponent=()=>{
    if(viewTransactionSummaryReportReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Transaction Summary Report
                        </h3>
                    </Row>
                    <TransactionSummaryReport />
            </div>
    }else{
        return null
    }
}

const TrackingSummaryReportComponent=()=>{
    if(viewTrackingSummaryReport()){
        return<div className="report-root">
                    <Helmet>
                        <meta name="viewport" content="initial-scale=.5, maximum-scale=12.0, minimum-scale=.25, user-scalable=yes"/>
                    </Helmet>
                    <Row>
                        <h3>
                            Tracking Summary Report
                        </h3>
                    </Row>
                    <TrackingSummaryReport />
            </div>
    }else{
        return null
    }
}

const PrepaidOTAPageComponent=()=>{
    if(checkPrepaidOTAPage()){
        return<div>
                    <Helmet>
                            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
                    </Helmet>
                    <PrepaidOTAPage />
            </div>
    }else{
        return null
    }
}

export const RouteRoot = () => {

    var isLoggedIn = useSelector(userLoginSelector);

    return (
        <Router>
            <DeviceProvider>
                {isLoggedIn&&
                    <AppHeader />
                }
                <LoadingComponent />
                <Switch>
                    <Route path="/login" component={LoginPageComponent} />
                    <PrivateRoute path="/accounts/agent-transactions" component={AgentTransactionsPageComponent} />
                    <PrivateRoute path="/trip-create/success" component={TripCreateSuccessPage} />
                    <PrivateRoute path="/accounts/agents" component={AgentsPageComponent} />
                    {/* <PrivateRoute path="/notify/bus-cancel" component={BusCancelPageComponent} /> */}
                    <PrivateRoute path="/staff" component={StaffPageComponent} />
                    <PrivateRoute path="/trip-expense" component={TripExpensePageComponent} />
                    <PrivateRoute path="/trip-create" component={TripCreatePageComponent} />
                    <PrivateRoute path="/new-trip" component={IncompleteTripPageComponent} />
                    <PrivateRoute path="/standard-stops" component={StandardStopsComponent} />
                    <PrivateRoute path="/create-standard-stop" component={CreateStandardStopComponent} />
                    {/* <PrivateRoute path="/notify/notification" component={NotificationPageComponent} /> */}
                    <PrivateRoute path="/view-cancel" component={ViewTicketPageComponent} />
                    <PrivateRoute path="/view-ticket-search" component={TicketSearchReportV2Component} />
                    <PrivateRoute path="/change-password" component={ChangePasswordPageComponent} />
                    <PrivateRoute path="/report/tripsheet" component={TripSheetReportComponent} />
                    <PrivateRoute path="/report/bookedtickets" component={BookedTicketPageComponent} />
                    <PrivateRoute path="/report/ticketsharingreport" component={TicketSharingPageComponent} />
                    <PrivateRoute path="/report/settlementreport" component={SettlementPageComponent} />      
                    <PrivateRoute path="/report/gstfilingreport" component={GstFilingPageComponent} />                              
                    <PrivateRoute path="/report/salesreport" component={SalesTicketPageComponent} />
                    <PrivateRoute path="/report/viewActiveBookings" component={ActiveBookingsReportComponent} />
                    <PrivateRoute path="/report/viewServiceCountReport" component={ServiceCountReportComponent} />
                    <PrivateRoute path="/report/costSummaryReport" component={CostSummaryReportComponent} />
                    <PrivateRoute path="/report/transactionSummaryReport" component={TransactionSummaryReportComponent} />
                    <PrivateRoute path="/report/trackingSummaryReport" component={TrackingSummaryReportComponent} />
                    <PrivateRoute path="/report/mastertripsheetreport" component={MasterTripSheetReportComponent} />
                    <PrivateRoute path="/report/ticketsearchreport" component={TicketSearchReportComponent} />
                    <PrivateRoute path="/report/rescheduledTicketreport" component={RescheduledTicketReportComponent} />
                    <PrivateRoute path="/report/viewpassengersreport" component={ViewPassengersInfoReportComponent} />
                    <PrivateRoute path="/report/blockedreport" component={BlockedSeatsReportComponent} />
                    <PrivateRoute path="/report/totaltransactionreport" component={TotalTransactionReportComponent} />
                    <PrivateRoute path="/report/cancelledticketreport" component={CancelledSeatsReportComponent} />
                    <PrivateRoute path="/report/performancereport" component={PerformanceReportComponent} />
                    <PrivateRoute path="/report/revenuereport" component={RevenueComponent} />
                    <PrivateRoute path="/report/abandonedreport" component={ViewAbandonedComponent} />
                    <PrivateRoute path="/report/webticketsalesreport" component={WebTicketSalesComponent} />
                    <PrivateRoute path="/report/webticketcancelreport" component={WebTicketCancelComponent} />
                    <PrivateRoute path="/report/prepaidtransactions" component={PrePaidTransactionSummary} />

                    <PrivateRoute path="/accounts/trip-sheet-permission" component={TripSheetPermissionComponent} />
                    <PrivateRoute path="/report/smsreport" component={SmsReport} />
                    <PrivateRoute path="/report/ticketbookedafterdeparture" component={TicketsBookedAfterTripStartTimeComponent} />
                    <PrivateRoute path="/report/demandDataReport" component={DemandDataReportComponent} />
                    <PrivateRoute path="/admin/schedule" component={SchedulePageComponent} />
                    <PrivateRoute path="/admin/popular-routes" component={PopularRoutesPageComponent} />
                    <PrivateRoute path="/admin/cities" component={CitiesPageComponent} />
                    <PrivateRoute path="/admin/user-actions" component={UserActionsPageComponent} />

                    <PrivateRoute path="/home" component={HomePageComponent} />
                    <PrivateRoute path="/prepaid-ota" component={PrepaidOTAPageComponent} />
                    <PrivateRoute path="/dashboard" component={DashboardPageComponent} />
                    <PrivateRoute path="/edittrip" component={EditTripComponent} />
                    <PrivateRoute path="/admin/addressbook" component={AddressBookComponent} />
                    <PrivateRoute path="/" component={HomePageComponent} />
                </Switch>
            </DeviceProvider>
        </Router>
    );
} 