import React, { useState } from "react";
import { Form , Button , Radio ,Row ,Col , DatePicker , Checkbox, message , Typography , Table} from 'antd';
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import { fetchTripsByBookingDate , fetchTripsByTravelDate , fetchPerformanceSheet} from './../../actions/reports.action';
import { getAPIDateFormat ,  getLocalDateAndTime} from './../../utils/date.utils';
import { ScheduleSelect } from './../../components/search-components/schedule-select/schedule-select.component';
import { isValid, isValidArray } from "../../utils/utilities";
import { Printer } from './../../components/custom-components/custom-component';
import { floatingPointRoundOff as FPR } from './../../utils/math.utils';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";


const {Text} =Typography;

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;


const getTripIds=(trips,isSelectAll,scheduleId)=>{
        let tripIds=[];
        if(isValidArray(trips)&&trips.length>0){
            trips.forEach(element => {
                if(isSelectAll){
                    tripIds.push(element.id);
                }else if(scheduleId===element.schedule_id){
                    tripIds.push(element.id);
                }
            });
        }

        return tripIds;
}


const getTicketsDetails=(performanceReport)=>{
    let formatedTicketDetails=[];
    let summaryTable=[];

    summaryTable.push({
        description:'Seats Sold',
        total:performanceReport.bookedSeats
    })
    summaryTable.push({
        description:'Vacant Seats',
        total:performanceReport.availableSeats
    })

    let seatsBookedByWeb=performanceReport.seatsBookedByWeb;
    let percentageSeatsBookedByWeb=FPR((performanceReport.seatsBookedByWeb/performanceReport.bookedSeats)*100,2);
    let seatsBookedByWebAmount=performanceReport.seatsBookedByWebAmount;
    let percentageSeatsBookedByWebAmount=FPR((performanceReport.seatsBookedByWebAmount/performanceReport.bookedSeatsAmount)*100,2);
    let websiteRevenue=performanceReport.websiteRevenue;
    let percentageWebsiteRevenue=FPR((websiteRevenue/performanceReport.totalRevenue)*100,2);

    let seatsBookedByRedBus=performanceReport.seatsBookedByRedBus;
    let percentageSeatsBookedByRedBus=FPR((performanceReport.seatsBookedByRedBus/performanceReport.bookedSeats)*100,2);
    let seatsBookedByRedBusAmount=performanceReport.seatsBookedByRedBusAmount;
    let percentageSeatsBookedByRedBusAmount=FPR((performanceReport.seatsBookedByRedBusAmount/performanceReport.bookedSeatsAmount)*100,2);
    let redBusRevenue=performanceReport.redBusRevenue;
    let percentageRedbusRevenue=FPR((redBusRevenue/performanceReport.totalRevenue)*100,2);

    let seatsBookedByAbhiBus=performanceReport.seatsBookedByAbhiBus;
    let percentageSeatsBookedByAbhiBus=FPR((performanceReport.seatsBookedByAbhiBus/performanceReport.bookedSeats)*100,2);
    let seatsBookedByAbhiBusAmount=performanceReport.seatsBookedByAbhiBusAmount;
    let percentageSeatsBookedByAbhiBusAmount=FPR((performanceReport.seatsBookedByAbhiBusAmount/performanceReport.bookedSeatsAmount)*100,2);
    let abhiBusRevenue=performanceReport.abhiBusRevenue;
    let percentageAbhiBusRevenue=FPR((abhiBusRevenue/performanceReport.totalRevenue)*100,2);

    let seatsBookedByPaytmBus=performanceReport.seatsBookedByPaytmBus;
    let percentageSeatsBookedByPaytmBus=FPR((performanceReport.seatsBookedByPaytmBus/performanceReport.bookedSeats)*100,2);
    let seatsBookedByPaytmBusAmount=performanceReport.seatsBookedByPaytmBusAmount;
    let percentageSeatsBookedByPaytmBusAmount=FPR((performanceReport.seatsBookedByPaytmBusAmount/performanceReport.bookedSeatsAmount)*100,2);
    let paytmRevenue=performanceReport.paytmRevenue;
    let percentagePaytmRevenue=FPR((paytmRevenue/performanceReport.totalRevenue)*100,2);

    let seatBookedByQwikBus=performanceReport.seatBookedByQwikBus;
    let percentageSeatsBookedByQwikBus=FPR((performanceReport.seatBookedByQwikBus/performanceReport.bookedSeats)*100,2);
    let seatsBookedByQwikBusAmount=performanceReport.seatsBookedByQwikBusAmount;
    let percentageSeatsBookedByQwikBusAmount=FPR((performanceReport.seatsBookedByQwikBusAmount/performanceReport.bookedSeatsAmount)*100,2);
    let qwikBusRevenue=performanceReport.qwikbusRevenue;
    let percentageQwikBusRevenue=FPR((qwikBusRevenue/performanceReport.totalRevenue)*100,2);


    let seatsBookedBYOffice=performanceReport.seatsBookedBYOffice;
    let percentageSeatsBookedBYOffice=FPR((performanceReport.seatsBookedBYOffice/performanceReport.bookedSeats)*100,2);
    let seatsBookedBYOfficeAmount=performanceReport.seatsBookedBYOfficeAmount;
    let percentageSeatsBookedBYOfficeAmount=FPR((performanceReport.seatsBookedBYOfficeAmount/performanceReport.bookedSeatsAmount)*100,2);
    let officeRevenue=performanceReport.officeRevenue;
    let percentageOfficeRevenue=FPR((officeRevenue/performanceReport.totalRevenue)*100,2);

    let seatsBookedBYAgents=performanceReport.seatsBookedBYAgents;
    let percentageSeatsBookedBYAgents=FPR((performanceReport.seatsBookedBYAgents/performanceReport.bookedSeats)*100,2);
    let seatsBookedBYAgentsAmount=performanceReport.seatsBookedBYAgentsAmount;
    let percentageSeatsBookedBYAgentsAmount=FPR((performanceReport.seatsBookedBYAgentsAmount/performanceReport.bookedSeatsAmount)*100,2);
    let agentsRevenue=performanceReport.agentsRevenue;
    let percentageAgentsRevenue=FPR((agentsRevenue/performanceReport.totalRevenue)*100,2);

    let bookedSeats=performanceReport.bookedSeats;
    let percentageSeatsBooked=FPR((performanceReport.bookedSeats/performanceReport.bookedSeats)*100,2);
    let seatsBookedAmount=performanceReport.bookedSeatsAmount;
    let percentageSeatsBookedAmount=FPR((performanceReport.bookedSeatsAmount/performanceReport.bookedSeatsAmount)*100,2);
    let totalRevenue=performanceReport.totalRevenue;
    let percentageTotalRevenue=FPR((totalRevenue/performanceReport.totalRevenue)*100,2);

    formatedTicketDetails.push({
        description:"No Of Seats Sold",
        website:seatsBookedByWeb,
        redbus:seatsBookedByRedBus,
        office:seatsBookedBYOffice,
        agents:seatsBookedBYAgents,
        abhibus:seatsBookedByAbhiBus,
        paytm:seatsBookedByPaytmBus,
        qwikbus:seatBookedByQwikBus,
        total:bookedSeats
    })

    formatedTicketDetails.push({
        description:"% Of Seats Sold",
        website:percentageSeatsBookedByWeb,
        redbus:percentageSeatsBookedByRedBus,
        office:percentageSeatsBookedBYOffice,
        agents:percentageSeatsBookedBYAgents,
        abhibus:percentageSeatsBookedByAbhiBus,
        paytm:percentageSeatsBookedByPaytmBus,
        qwikbus:percentageSeatsBookedByQwikBus,
        total:percentageSeatsBooked
    })

    formatedTicketDetails.push({
        description:"Total Fare",
        website:seatsBookedByWebAmount,
        redbus:seatsBookedByRedBusAmount,
        office:seatsBookedBYOfficeAmount,
        agents:seatsBookedBYAgentsAmount,
        abhibus:seatsBookedByAbhiBusAmount,
        paytm:seatsBookedByPaytmBusAmount,
        qwikbus:seatsBookedByQwikBusAmount,
        total:seatsBookedAmount
    })

    formatedTicketDetails.push({
        description:"% Of Fare",
        website:percentageSeatsBookedByWebAmount,
        redbus:percentageSeatsBookedByRedBusAmount,
        office:percentageSeatsBookedBYOfficeAmount,
        agents:percentageSeatsBookedBYAgentsAmount,
        abhibus:percentageSeatsBookedByAbhiBusAmount,
        paytm:percentageSeatsBookedByPaytmBusAmount,
        qwikbus:percentageSeatsBookedByQwikBusAmount,
        total:percentageSeatsBookedAmount
    })

    formatedTicketDetails.push({
        description:"Revenue",
        website:websiteRevenue,
        redbus:redBusRevenue,
        office:officeRevenue,
        agents:agentsRevenue,
        abhibus:abhiBusRevenue,
        paytm:paytmRevenue,
        qwikbus:qwikBusRevenue,
        total:totalRevenue
    });

    formatedTicketDetails.push({
        description:"% of Revenue",
        website:percentageWebsiteRevenue,
        redbus:percentageRedbusRevenue,
        office:percentageOfficeRevenue,
        agents:percentageAgentsRevenue,
        abhibus:percentageAbhiBusRevenue,
        paytm:percentagePaytmRevenue,
        qwikbus:percentageQwikBusRevenue,
        total:percentageTotalRevenue
    })

    return {formatedTicketDetails,summaryTable};
}

const performanceTicketsColumns=[
    {
        title:'Description',
        key:'description',
        dataIndex:'description'
    },
    {
        title:'Web site',
        key:'website',
        dataIndex : 'website'
    },
    {
        title:'Redbus',
        key:'redbus',
        dataIndex : 'redbus'
    },
    {
        title:'Abhibus',
        key:'abhibus',
        dataIndex : 'abhibus'
    },
    {
        title:'Paytm',
        key:'paytm',
        dataIndex : 'paytm'
    },
    {
        title:'Office',
        key:'office',
        dataIndex : 'office'
    },
    {
        title:'Qwikbus',
        key:'qwikbus',
        dataIndex : 'qwikbus'
    },
    {
        title:'Agents',
        key:'agents',
        dataIndex : 'agents'
    },
    {
        title:'Total',
        key:'total',
        dataIndex : 'total'
    }
];

const summaryColumns=[
    {
        title:'Description',
        key:'description',
        dataIndex:'description'
    },
    {
        title:'Total',
        key:'total',
        dataIndex : 'total'
    }
]

const RenderReport=({fromDate,toDate,isBookingDate,agentName,performanceReport,pagination,isSelectAllAgents})=>{

    let {
        formatedTicketDetails,
        summaryTable
    } = getTicketsDetails(performanceReport)

    return(
        <div>
            <Row justify="center" gutter={[16,16]}>
                <Col>
                    <Text strong>Sales report</Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From date : </Text>{fromDate}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To date : </Text>{toDate}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Type of date : </Text>{isBookingDate?"Booking Date":"Travel Date"}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                            <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table 
                        className="bordered-table"
                        columns={performanceTicketsColumns}
                        dataSource={formatedTicketDetails}
                        pagination={getPaginationDetails(pagination)}
                    />
               </Col>
           </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table 
                        className="bordered-table"
                        columns={summaryColumns}
                        dataSource={summaryTable}
                        pagination={getPaginationDetails(pagination)}
                    />
               </Col>
           </Row>
        </div>
    )
}

export const PerformanceReport = () => {

    const [form] = Form.useForm();
    const [scheduleForm] = Form.useForm();
    const [agentsForm] = Form.useForm();

    const dispatch = useDispatch();

    const [schedules,setSchedules]=useState(undefined);
    const [trips,setTrips]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
        isSelectAllSchedules:false,
    });
    const [performanceReport,setPerformanceReport]=useState(undefined);

    const onSuccess=(data)=>{
        setSchedules(data.schedules);
        setTrips(data.trips);
        setLoading(false);
    }

    const onFailure=()=>{
        setSchedules(undefined);
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            isBooking
        }=values;
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setShowReport(false);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        filters.isBookingDate=isBooking;
        setFilters({...filters});
        scheduleForm.resetFields();
        setLoading(true);
        if(isBooking){
            fetchTripsByBookingDate(fromDate,toDate,onSuccess,onFailure);
        }else{
            fetchTripsByTravelDate(fromDate,toDate,onSuccess,onFailure);
        }
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                            <Radio.Group>
                                <Radio value={true}>Booking Date</Radio>
                                <Radio value={false}>Travel Date</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {isValidArray(schedules)&&
                        <Form
                        form={scheduleForm}
                        initialValues={{isSelectAllSchedules:true}}
                        onFinish={(values)=>{
                            let {
                                returnId,
                                isSelectAllSchedules
                            }=values;
                            setShowReport(false);
                            setLoading(true);
                            if(isValid(returnId)||isSelectAllSchedules){
                                let selectedTripIds = getTripIds(trips,isSelectAllSchedules,returnId);
                                fetchPerformanceSheet(selectedTripIds,filters.fromDate,filters.toDate,filters.isBookingDate,(data)=>{
                                    setPerformanceReport(data.performanceReport);
                                    setShowReport(true);
                                    setLoading(false);
                                },()=>{
                                    setLoading(false); 
                                });
                            }else{
                                message.error("Select schedule!");
                            }
                        }}
                        >
                            <Row gutter={[16,16]}>
                                <Col>
                                    <ScheduleSelect 
                                        schedules={schedules}
                                        optionKey="id"
                                        showLabel={true}
                                        isManditory={false}
                                    />
                                </Col>
                                <Col>
                                    <Form.Item name="isSelectAllSchedules"  valuePropName="checked">
                                        <Checkbox>All</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Form.Item>
                                        <Button htmlType="submit">Get</Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                }

                {showReport&&
                <div>
                    <Form
                    form={agentsForm}
                    initialValues={{isSelectAllAgents:true}}
                    onFinish={(values)=>{
                        let {
                            agentName,
                            isSelectAllAgents
                        }=values;
                        if(isValid(agentName)||isSelectAllAgents){
                            filters.agentName=agentName;
                            filters.isSelectAllAgents=isSelectAllAgents;
                            setFilters({...filters});
                        }else{
                            message.error("Select agent!");
                        }
                    }}
                    >
                    </Form>
                    <Printer 
                        showComponent={false}
                        zoomValue={0.5}
                        renderComponent={()=>{
                            return (
                            <RenderReport 
                                fromDate={filters.fromDate}
                                toDate={filters.toDate}
                                isBookingDate={filters.isBookingDate}
                                agentName={filters.agentName}
                                isSelectAllAgents={filters.isSelectAllAgents}
                                performanceReport={performanceReport}
                                pagination={false}
                            />
                    )
                }} />
                    <RenderReport 
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        isBookingDate={filters.isBookingDate}
                        agentName={filters.agentName}
                        isSelectAllAgents={filters.isSelectAllAgents}
                        performanceReport={performanceReport}
                        pagination={false}
                    />
                </div>
                }
            </div>
    )
}