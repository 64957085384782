/**
 * @author Manne Hareesh
 * @description Reducer for the components in Category page
 */
import {
    FETCH_SCHEDULES,
    UPDATE_SEAT_DETAILS,
    FETCH_SEAT_LAYOUT,
    LOG_OUT_ACTION,
    ON_PASSENGER_FORM_CHANGE,
    RESET_PASSENGER_FORM,
    ADD_CLIENT_SIDE_DISCOUNT
 } from '../actions/action-types';
import { roundNumber } from '../utils/math.utils';
import { getValuesOfJSonObject, isValid, isValidArray, isValidString } from '../utils/utilities';

/**
 * Seat Object
 * {
      adjacent_seat_number: "1"
      amount_for_travels: 800
      amount_paid: null
      blocked_by: ""
      cgst: 20
      cgst_of_discounted_price: 20
      commission: 0
      direct_discount: null
      discount: 0
      discounted_cost_with_gst: 840
      discounted_cost_without_gst: 800
      fare: 800
      full_name: null
      gender: null
      gents_seat: 0
      gst: 40
      gst_of_discounted_price: 40
      id: 142504
      igst: 0
      igst_of_discounted_price: 0
      ladies_seat: 0
      phone_number: null
      seat_column_number: 2
      seat_number: "2"
      seat_row_number: 1
      seat_status: ""
      sgst: 20
      sgst_of_discounted_price: 20
      sleeper: 0
      ticket_cost: 840
      ticket_id: null
      travel_miles: 0
      trip_id: 1928
      upper_deck: 0
 * }
 */
  
const getFormatedSeatObject=(seatObject)=>{
  let {
    discounted_cost_with_gst,
    fare,
    discounted_cost_without_gst,
    id,
    phone_number,
    sgst,
    cgst,
    igst,
    ticket_cost,
    seat_number,
    full_name,
    gender,
    amount_for_travels,
    blocked_by,
    cgst_of_discounted_price,
    sgst_of_discounted_price,
    igst_of_discounted_price,
    seat_status,
    gst,
    trip_id,
    ticket_id,
    ladies_seat,
    gents_seat 
  }=seatObject;

  return {
    discountedCostWithGst:discounted_cost_with_gst,
    fare:fare,
    discountedCostWithoutGst:discounted_cost_without_gst,
    id:id,
    phoneNumber:phone_number,
    sgst:sgst,
    cgst:cgst,
    igst:igst,
    ticketCost:ticket_cost,
    seatNumber:seat_number,
    fullName:full_name,
    gender:gender,
    amountForTravels:amount_for_travels,
    blockedBy:blocked_by,
    cgstOfDiscountedPrice:cgst_of_discounted_price,
    sgstOfDiscountedPrice:sgst_of_discounted_price,
    igstOfDiscountedPrice : igst_of_discounted_price,
    seatStatus:seat_status,
    gst:gst,
    tripId:trip_id,
    ticketId:ticket_id,
    ladies_seat,
    gents_seat 
  }
}
  const initialState = {
    selectedSeats:undefined,
    boardingId:undefined,
    dropId:undefined,
    phoneNumber:undefined,
    emailId:undefined,
    blockedSeatTicketId:undefined,
    timeOfRelease : null,
    relaseTimeSeatId : null
  }
  
  export const passengerFormReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
          case UPDATE_SEAT_DETAILS :
                let {
                    seatId,seatObject,
                    passengerInfo
                }=action.payload;


                if(!isValid(newState.blockedSeatTicketId)&&isValid(seatObject.ticket_id)){
                  newState.blockedSeatTicketId=seatObject.ticket_id;
                }
                let selectedSeats=newState["selectedSeats"];
                if(!isValidArray(selectedSeats)){
                  selectedSeats={}
                }
                if(!isValid(selectedSeats[seatId])){
                    let {
                      phone_number,
                      drop_location,
                      pickup_location,
                      email_id,
                      time_of_release
                    }=passengerInfo;
                    newState.phoneNumber=phone_number;
                    newState.emailId=email_id;
                    if(isValidString(time_of_release)){
                      newState.timeOfRelease = time_of_release;
                      newState.relaseTimeSeatId = seatId;
                    }
                    if(isValidArray(pickup_location)&&isValid(pickup_location.id)){
                      newState.boardingId=pickup_location.id;
                    }else{
                      newState.boardingId=pickup_location;
                    }
                    if(isValidArray(drop_location)&&isValid(drop_location.id)){
                      newState.dropId=drop_location.id;
                    }else{
                      newState.dropId=drop_location;
                    }
                    selectedSeats[seatId] = getFormatedSeatObject(seatObject);
                    if(isValid(passengerInfo)&&isValid(passengerInfo.age)){
                      selectedSeats[seatId].age=passengerInfo.age;
                    }
                    if(isValid(passengerInfo)&&isValid(passengerInfo.full_name)){
                      selectedSeats[seatId].fullName=passengerInfo.full_name;
                    }
                    if(isValid(passengerInfo)&&isValid(passengerInfo.gender)){
                      selectedSeats[seatId].gender=passengerInfo.gender;
                    }
                }else{
                    delete selectedSeats[seatId];

                    let blockedTicketId = null;
                    for (const key in selectedSeats) {
                      if (Object.hasOwnProperty.call(selectedSeats, key)) {
                        const element = selectedSeats[key];
                        if(element.seatStatus == "Blocked"){
                          blockedTicketId = element.ticketId;
                        }
                      }
                    }

                    newState.blockedSeatTicketId = blockedTicketId;
                    if(seatId === newState.relaseTimeSeatId){
                      newState.timeOfRelease = null;
                    }
                }
                newState["selectedSeats"]={...selectedSeats};
                return newState;

          case FETCH_SCHEDULES :{
            newState["selectedSeats"]=undefined;
            return newState;
          }

          case FETCH_SEAT_LAYOUT:{
            newState["selectedSeats"]=undefined;
            return newState;
          } 

          case ON_PASSENGER_FORM_CHANGE:{
            let{
              seatId,
              fullName,
              gender,
              age
            }=action.payload;
              let selectedSeats=newState["selectedSeats"];
              if(isValidArray(selectedSeats[seatId])){
                if(isValid(fullName)){
                  selectedSeats[seatId].fullName=fullName;
                }
                if(isValid(age)){
                  selectedSeats[seatId].age=age;
                }
                if(isValid(gender)){
                  selectedSeats[seatId].gender=gender;
                }
              }
            return newState;
          }


          case ADD_CLIENT_SIDE_DISCOUNT :{
            let {
              discountPerSeat
            }=action.payload;
            let selectedSeats=newState["selectedSeats"];
            if(isValidArray(selectedSeats)){
              let seatIds = getValuesOfJSonObject(selectedSeats);
              seatIds.forEach(seat => {
                let id = seat.id;
                selectedSeats[id].discountedCostWithoutGst = roundNumber(selectedSeats[id].discountedCostWithoutGst - discountPerSeat);
              });
            }
            newState["selectedSeats"]=selectedSeats;
            return newState;
          }

          case LOG_OUT_ACTION :
            return initialState;

          case RESET_PASSENGER_FORM:
            return initialState;

          default:
              return state;
      }
  }
  