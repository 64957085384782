import React from 'react';
import { Menu } from 'antd';
import './app-header.style.scss';
import { 
    checkSearchPage,
    checkCancelPage,
    checkViewNotificationPage,
    checkBusCancellationPage,
    viewBookedTicketsReport,
    viewMasterTripReport,
    viewPassengerInfoReport,
    viewSalesReport,
    viewTicketSearchReport,
    viewTicketSearchReportOld,
    viewRescheduledTicketReport,
    viewTripReport,
    blockedSeatsReport,
    viewPerformanceReport,
    viewTotalTransactionReport,
    cancelledSeatsReport,
    checkViewAbandonedReport,
    checkWebTicketSalesReport,
    checkWebTicketCancelReport,
    tripExpenseReport,
    checkStaffPage,
    viewSmsReport ,
    viewTicketBookedAfterTripStartTime,
    checkAgentsPage,
    checkAgentsTransactionPage,
    viewSettlementReport,
    checkToViewTripsPageOfAdmin,
    checkToViewPopularRoutesPageAdmin,
    checkToViewSchedulesPageOfAdmin,
    checkToViewStandardStopsPage,
    checkToViewAgentsPageOfAdmin,
    viewDemandDataReport,
    viewServiceCountReport,
    tripCreate,
    viewActiveBookings,
    checkToViewTripSheetPermissionPage,
    viewTicketSharingReport,
    viewCostSummaryReportReport,
    viewTransactionSummaryReportReport,
    viewTrackingSummaryReport,
    viewRevenueReport,
    checkToViewCitiesPageAdmin,
    checkPrepaidOTAPage,
    checkToViewAddressBookPageAdmin,
    viewPrePaidReport,
    checkToViewUserActions,
    checkDashBoardPage
   } from './app.header.module';
import { useLocation } from 'react-router-dom';
import Icon from '@ant-design/icons';
import dashboardIcon from './../../images/dashboard.png';

const { SubMenu } = Menu;

export const MenuWeb = (props) => {

    const {
        onClickAddressBookPage,
        onTicketSearchReportClick,
        onTicketSearchV2ReportClick,
        onRescheduledTicketReportClick,
        onMasterTripSheetReport,
        onViewPassengerInfoReportClick,
        onSalesReportClick,
        onBookedTicketsClick,
        onTripSheetClick,
        onActiveBookingsClick,
        notificationClick,
        logout,
        changePassword,
        viewCancelClick,
        searchClick,
        busCancelClick,
        staffClick,
        onBlockedReportClick,
        performanceReport,
        totalTransactionReport,
        cancelledSeatsReportClick,
        viewAbandonedReportClick,
        webTicketSalesReportClick,
        webTicketCancelReportClick,
        tripExpenseClick,
        onClickSmsReport,
        onClickTicketsBookedAfterTripStartTime,
        onClickAgentTransactionsPage, 
        onClickAgentTripSheetPermissionPage,
        onClickAgentsPage,
        onClickSettlementReport,
        onClickGstFilingReport,
        onClickDemandDataReport,
        onClickServiceCountReport,
        onClickCostSummaryReport,
        onClickTransactionSummaryReport,
        onClickTrackingSummaryReport,
        openAdminTripsPage,
        openAdminSchedulesPage,
        openAdminAgentsPage,
        tripCreatePage,
        popularRoutesPage,
        onClickNewTrip,
        onClickStandardStops,
        openSchedulesPage,
        onClickTicketSharing,
        onClickRevenue,
        onClickCitiesPage,
        onClickTransactions,
        onPrepaidOTADashboardClick,
        userActionsPage,
        dashboardClick
      }=props;

    const location = useLocation();

    const preventDefault=(event)=>{
          event.preventDefault();
    }

    const renderMenuTxt=(txt,path)=>{
      if(txt=="Dashboard"){
        return(
          <Icon component={() => (<img src={dashboardIcon} className='dashboard-icon' />)} width={26} height={26} />
          );
      }else{
        return(
          <a href={path} onClick={preventDefault} target="_blank">{txt}</a>
        );
      }
    }
    
    return (
        <div>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[location.pathname]}
                  breakpoint="lg" collapsedWidth="0">
                {checkDashBoardPage()&&
                  <Menu.Item key="/dashboard" onClick={dashboardClick}>
                    {renderMenuTxt("Dashboard","/dashboard")}
                  </Menu.Item>
                }
                {checkSearchPage()&&
                <Menu.Item key="/home" onClick={searchClick}>
                      {renderMenuTxt("Search","/home")}
                </Menu.Item>
                }
                {viewTicketSearchReport()&&
                <Menu.Item key="/view-ticket-search" onClick={onTicketSearchV2ReportClick}>
                  {renderMenuTxt("Ticket Search","/view-ticket-search")}
                </Menu.Item>
                }
                {checkPrepaidOTAPage()&&
                  <Menu.Item key="/prepaid-ota" onClick={onPrepaidOTADashboardClick}>Dashboard</Menu.Item>
                }
                {checkCancelPage()&&
                  <Menu.Item key="/view-cancel" onClick={viewCancelClick}>
                    {renderMenuTxt("Modify/Cancel","/view-cancel")} 
                  </Menu.Item>
                }
                <SubMenu key="report" title="Report">      
                  {viewTripReport()||viewMasterTripReport()?
                      <SubMenu title="Trip Sheet">
                          {viewTripReport()&&
                            <Menu.Item key="/report/tripsheet" onClick={onTripSheetClick}>
                              {renderMenuTxt("Trip Sheet","/report/tripsheet")}
                            </Menu.Item>
                          }
                          {viewMasterTripReport()&&
                            <Menu.Item key="/report/mastertripsheetreport" onClick={onMasterTripSheetReport}>
                              {renderMenuTxt("Master Trip Sheet Report","/report/mastertripsheetreport")} 
                            </Menu.Item>
                          }
                      </SubMenu>
                      :null
                    }

                {viewSalesReport()||viewBookedTicketsReport()?
                  <SubMenu title="Sales">
                      {viewSalesReport()&&
                        <Menu.Item key="/report/salesreport" onClick={onSalesReportClick}>
                          {renderMenuTxt("Sales Report","/report/salesreport")}
                        </Menu.Item>
                      }
                      {viewBookedTicketsReport()&&
                        <Menu.Item key="/report/bookedtickets" onClick={onBookedTicketsClick}>
                          {renderMenuTxt("Booked Tickets","/report/bookedtickets")}
                        </Menu.Item>
                      }
                  </SubMenu>
                  :null
                }

                {checkWebTicketSalesReport()||checkWebTicketCancelReport()?
                    <SubMenu title="Web Tickets">
                        {checkWebTicketSalesReport()&&
                            <Menu.Item key="/report/webticketsalesreport" onClick={webTicketSalesReportClick}>
                              {renderMenuTxt("Web Ticket Sales Report","/report/webticketsalesreport")} 
                            </Menu.Item>
                        }
                        {checkWebTicketCancelReport()&&
                            <Menu.Item key="/report/webticketcancelreport" onClick={webTicketCancelReportClick}>
                              {renderMenuTxt("Web Ticket Cancellation Report","/report/webticketcancelreport")} 
                            </Menu.Item>
                        }
                    </SubMenu>
                    :null
                }
                <SubMenu title="Other Reports">
                      {viewActiveBookings()&&
                        <Menu.Item key="/report/ActiveBookings" onClick={onActiveBookingsClick}>
                          {renderMenuTxt("Active Bookings","/report/ActiveBookings")}
                        </Menu.Item>
                        }
                        {viewTotalTransactionReport()&&
                        <Menu.Item key="/report/totaltransactionreport" onClick={totalTransactionReport}>
                          {renderMenuTxt("Total Transaction Summary","/report/totaltransactionreport")}
                        </Menu.Item>
                        }
                         {cancelledSeatsReport()&&
                        <Menu.Item key="/report/cancelledticketreport" onClick={cancelledSeatsReportClick}>
                          {renderMenuTxt("Ticket Cancellation Report","/report/cancelledticketreport")} 
                        </Menu.Item>
                        }
                         {blockedSeatsReport()&&
                        <Menu.Item key="/report/blockedreport" onClick={onBlockedReportClick}>
                          {renderMenuTxt("Blocked Tickets","/report/blockedreport")} 
                        </Menu.Item>
                        }
                                                 {viewPassengerInfoReport()&&
                        <Menu.Item key="/report/viewpassengersreport" onClick={onViewPassengerInfoReportClick}>
                          {renderMenuTxt("View Passenger Info","/report/viewpassengersreport")} 
                        </Menu.Item>
                        }
                         {viewTicketSearchReportOld()&&
                        <Menu.Item key="/report/ticketsearchreport" onClick={onTicketSearchReportClick}>
                          {renderMenuTxt("Ticket Search","/report/ticketsearchreport")} 
                        </Menu.Item>
                        }
                        {viewRescheduledTicketReport()&&
                        <Menu.Item key="/report/rescheduledTicketreport" onClick={onRescheduledTicketReportClick}>
                          {renderMenuTxt("Rescheduled Ticket","/report/rescheduledTicketreport")}
                        </Menu.Item>
                        }
                        {viewPerformanceReport()&&
                        <Menu.Item key="/report/performancereport" onClick={performanceReport}>
                          {renderMenuTxt("Performance Report","/report/performancereport")} 
                        </Menu.Item> 
                        }
                        {viewSmsReport()&&
                        <Menu.Item key="/report/smsreport" onClick={onClickSmsReport}>
                          {renderMenuTxt("SMS Report","/report/smsreport")} 
                        </Menu.Item>
                        }
                                                {checkViewAbandonedReport()&&
                        <Menu.Item key="/report/abandonedreport" onClick={viewAbandonedReportClick}>
                          {renderMenuTxt("Abandoned Transactions Report","/report/abandonedreport")} 
                        </Menu.Item>
                        }                
                        {viewTicketBookedAfterTripStartTime()&&
                        <Menu.Item key="/report/ticketbookedafterdeparture" onClick={onClickTicketsBookedAfterTripStartTime}>
                          {renderMenuTxt("Tickets Booked After Departure","/report/ticketbookedafterdeparture")} 
                        </Menu.Item>
                        }
                        {viewSettlementReport()&&
                        <Menu.Item key="/report/settlementreport" onClick={onClickSettlementReport}>
                          {renderMenuTxt("Settlement Report","/report/settlementreport")} 
                        </Menu.Item>
                        }
                        {viewSettlementReport()&&
                        <Menu.Item key="/report/gstfilingreport" onClick={onClickGstFilingReport}>
                          {renderMenuTxt("GST Filing Report","/report/gstfilingreport")} 
                        </Menu.Item>
                        }
                         {viewDemandDataReport()&&
                        <Menu.Item key = "/report/demandDataReport" onClick = {onClickDemandDataReport}>
                          {renderMenuTxt("Demand Data Report","/report/demandDataReport")} 
                        </Menu.Item>
                        }
                        {viewServiceCountReport()&&
                        <Menu.Item key = "/report/viewServiceCountReport" onClick = {onClickServiceCountReport}>
                          {renderMenuTxt("Service Count","/report/viewServiceCountReport")} 
                        </Menu.Item>
                        }
                        {viewCostSummaryReportReport()&&
                        <Menu.Item key = "/report/costSummaryReport" onClick = {onClickCostSummaryReport}>
                          {renderMenuTxt("Billing Report","/report/costSummaryReport")}
                        </Menu.Item>
                        }
                        {viewTransactionSummaryReportReport()&&
                        <Menu.Item key = "/report/transactionSummaryReport" onClick = {onClickTransactionSummaryReport}>
                          {renderMenuTxt("Transaction Summary","/report/transactionSummaryReport")}
                        </Menu.Item>
                        }
                        {viewTrackingSummaryReport()&&
                        <Menu.Item key = "/report/trackingSummaryReport" onClick = {onClickTrackingSummaryReport}>
                          {renderMenuTxt("Tracking Summary","/report/trackingSummaryReport")}
                        </Menu.Item>
                        }
                        {viewTicketSharingReport()&&
                        <Menu.Item key = "/report/ticketsharingreport" onClick = {onClickTicketSharing}>Seat Sharing Report</Menu.Item>
                        }
                        {viewRevenueReport()&&
                        <Menu.Item key = "/report/revenuereport" onClick = {onClickRevenue}>Revenue Report</Menu.Item>
                        }
                        {viewPrePaidReport()&&
                        <Menu.Item key = "/report/prepaidtransactions" onClick = {onClickTransactions}>Maven Payments</Menu.Item>
                        }
                  </SubMenu>
                </SubMenu>
                {tripExpenseReport()&&
                <Menu.Item key="/trip-expense" onClick={tripExpenseClick}>
                  {renderMenuTxt("Trip Expense","/trip-expense")} 
                </Menu.Item>    
                }
                {(checkAgentsPage()||checkAgentsTransactionPage()||checkToViewTripSheetPermissionPage())?
                <SubMenu key="accounts" title="Accounts">
                  {checkAgentsPage()&&
                    <Menu.Item key="/accounts/agents" onClick={onClickAgentsPage}>
                      {renderMenuTxt("Agents","/accounts/agents")}
                    </Menu.Item>
                  }
                  {checkAgentsTransactionPage()&&
                    <Menu.Item key="/accounts/agent-transactions" onClick={onClickAgentTransactionsPage}>
                      {renderMenuTxt("Agents Transactions","/accounts/agent-transactions")}
                    </Menu.Item>
                  }
                  {checkToViewTripSheetPermissionPage()&&
                    <Menu.Item key="/accounts/trip-sheet-permission" onClick={onClickAgentTripSheetPermissionPage}>
                      {renderMenuTxt("Trip sheet permission","/accounts/trip-sheet-permission")}
                    </Menu.Item>
                  }  
                </SubMenu>
                :
                null
                } 
                {checkToViewCitiesPageAdmin()||checkToViewAddressBookPageAdmin()||checkToViewAgentsPageOfAdmin()||checkToViewTripsPageOfAdmin()||checkToViewSchedulesPageOfAdmin()||checkToViewStandardStopsPage()||checkToViewPopularRoutesPageAdmin()?
                   <SubMenu key="admin" title="Admin">
                      {checkToViewCitiesPageAdmin()&&
                        <Menu.Item key="/cities" onClick={onClickCitiesPage}>
                          {renderMenuTxt("Cities","/admin/cities")}
                        </Menu.Item>
                      }
                      {checkToViewAddressBookPageAdmin()&&
                        <Menu.Item key="/admin/addressbook" onClick={onClickAddressBookPage}>
                          {renderMenuTxt("Address Book","/admin/addressbook")}
                        </Menu.Item>
                      }
                      {checkToViewTripsPageOfAdmin()&&
                        <Menu.Item key="/trips" onClick={openAdminTripsPage}>
                          {renderMenuTxt("Trips","/trips")}
                        </Menu.Item>
                      }
                      {checkToViewTripsPageOfAdmin()&&
                        <Menu.Item key="/new-trip" onClick={onClickNewTrip}>
                          {renderMenuTxt("In active trips","/new-trip")}
                        </Menu.Item>
                      }
                      {checkToViewStandardStopsPage()&&
                      <Menu.Item key="/standard-stops" onClick={onClickStandardStops}>
                        {renderMenuTxt("Standard stops","/standard-stops")}
                      </Menu.Item>
                      }
                      {checkToViewSchedulesPageOfAdmin()&&
                      <Menu.Item key="/admin/schedule" onClick={openSchedulesPage}>
                        {renderMenuTxt("Schedules","/admin/schedule")}
                      </Menu.Item>
                      }
                      {checkToViewSchedulesPageOfAdmin()&&
                        <Menu.Item key= "/admin-schedules" onClick= {openAdminSchedulesPage}>
                          {renderMenuTxt("Admin Schedules","/admin-schedules")} 
                        </Menu.Item>
                      }
                      {checkToViewAgentsPageOfAdmin()&&
                        <Menu.Item key="/agents" onClick={openAdminAgentsPage}>
                          {renderMenuTxt("Agents","/agents" )} 
                        </Menu.Item>
                      }
                      {checkStaffPage()&&
                      <Menu.Item key="/staff" onClick={staffClick}>
                        {renderMenuTxt("Staff","/staff")} 
                      </Menu.Item>
                      }
                      {tripCreate()&&
                        <Menu.Item key= "/trip-create" onClick= {tripCreatePage}>
                          {renderMenuTxt("Create Trip", "/trip-create")} 
                        </Menu.Item>
                      }
                      {checkToViewPopularRoutesPageAdmin()&&
                        <Menu.Item key= "/popular-routes" onClick= {popularRoutesPage}>
                          {renderMenuTxt("Popular Routes","/popular-routes")} 
                        </Menu.Item>
                      }
                      {checkToViewUserActions()&&
                        <Menu.Item key= "/user-actions" onClick= {userActionsPage}>
                          {renderMenuTxt("User Actions","/user-actions")} 
                        </Menu.Item>
                      }
                    </SubMenu>
                  :
                  null
                }
                {checkToViewSchedulesPageOfAdmin()&&
                  <Menu.Item key="/admin/scheduleonhome" onClick={openSchedulesPage}>
                  {renderMenuTxt("Schedules","/admin/schedule")}                  
                  </Menu.Item>
                 }
            </Menu>
        </div>
    );
};

MenuWeb.defaultProps = {
};

MenuWeb.propTypes = {
    
};
