import React, { useEffect, useState } from "react";
import { Button, Modal , Radio, message} from "antd";
import { isValidArray } from "../../utils/utilities";
import { handleSetSeatGender } from "./change-gender-reservation.module";
import './change-gender-reservation.style.scss';

const getDefaultGender =(seats)=>{
  let gender = "G";
  let seatNumbers=[]
  if(isValidArray(seats)){
    seats.forEach(element => {
      seatNumbers.push(element.seatNumber);
      if(element.ladies_seat == 1){
        gender = "F";
      }else if(element.gents_seat == 1){
        gender = "M";
      }else{
        gender = "G";
      }
    });
  }
  return {gender,seatNumbers};
}


export const ChangeGenderReservation = (props) => {

    const{
        disableSetPrice,
        selectedSeats,
        tripId,
        onSuccess
    }=props;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [value, setValue] = useState('G');
    const [selectedSeatNumbers, setSelectedSeatNumbers] = useState([]);

    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        handleSetSeatGender(tripId,selectedSeatNumbers,value,(msg)=>{
          setIsModalOpen(false);
          message.success(msg);
          onSuccess();
        },(errMsg)=>{
          message.error(errMsg);
          setIsModalOpen(false);
        })
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };

      const onChange = (e) => {
        setValue(e.target.value);
      };

      useEffect(()=>{
        let {
          seatNumbers,
          gender
        } = getDefaultGender(selectedSeats);
        setSelectedSeatNumbers(seatNumbers);
        setValue(gender);
      },[selectedSeats]);

    return(
        <div className="">
            <Button onClick={showModal} disabled={disableSetPrice} className="set-gender-btn">
               Set Gender
            </Button>
            <Modal 
            title="Set Gender" 
            visible={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}
            okText="Submit"
            >
                <Radio.Group onChange={onChange} value={value}>
                    <Radio value={'G'}>General</Radio>
                    <Radio value={'M'}>Male</Radio>
                    <Radio value={'F'}>Female</Radio>
                </Radio.Group>
            </Modal>
        </div>
    )
}
