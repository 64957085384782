import React, { useEffect, useState } from "react";
import '../styles/view-ticket.style.scss';
import { Button, Form, Input , Row ,Col, message, Table,Checkbox,Typography, InputNumber } from 'antd';
import { GET_TICKET_BY_PNR_URL } from './../utils/api-url';
import { isValid, isValidArray , checkActionAllowed, isValidIndex } from "../utils/utilities";
import { getAPIDateFormat, getLocalDateAndTime , getTimeStampInReadableFormat, getUIDateFormat} from './../utils/date.utils';
import { getPercentage, getValueFromPercentage, roundNumber} from './../utils/math.utils';
import { CustomModal, WhatsAppTextShare } from './../components/custom-components/custom-component';
import { TicketSummary } from './../components/ticket-summary-components/ticket-summary.component';
import { Printer } from './../components/custom-components/custom-component';
import { cancelTicket } from './../actions/ticket.action';
import { loadingAction } from './../actions/loading-actions';
import { useDispatch } from "react-redux";
import { ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED , ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED , ACTION_CANCEL_TICKET_ALLOWED , ACTION_CANCEL_BKBS_TICKET_ALLOWED, PRIVILEGE_HEAD_OFFICE, ACTION_TO_VIEW_NOT_BOARDED_BUTTON, getSameUser, isQBUserCheck, isOTAUser, PRIVILEGE_MASTER, QB_USER } from './../utils/constant';
import { getUrlVars } from './../utils/utilities';
import {
    useHistory,
    useLocation
  } from "react-router-dom";
import { OpenBookedTicketModal, getTextOfTicket } from './../components/ticket-summary-components/open-booked-ticket-modal.component';
import { PercentageOutlined } from '@ant-design/icons';

import { 
    OverRidePenality
} from './../components/over-ride-penality/over-ride-penality.component';
import { ChangeBusButton , ChangeBusComponent} from "../components/change-bus/change-bus.component";
import moment from "moment";
import { SendDriverDetails } from "../components/bus-cancel-components/send-driver-details-modal/send-driver-details-modal.component";
import { TicketLogsComponent } from "../components/ticket-summary-components/ticket-logs.component";
import { ConfirmSeatsBtn } from "../components/confirm-seat/confirm-seat.component";
import { ClearSeatBtn } from "../components/clear-seat/clear-seat.component";

const CANCELLED_STATUS = 'Cancelled';

let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const {
    Text
} = Typography;

const DrawLine =()=>{
    return(
        <div className="view-cancel-underline">

        </div>
    )
}
const getShowNotBoarded =(trip,selectedTravellers)=>{

    if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
        if(checkActionAllowed(ACTION_TO_VIEW_NOT_BOARDED_BUTTON)){
            let apiDate = getAPIDateFormat(trip.start_date);
            let todayApiDate = getAPIDateFormat(moment().utcOffset("+05:30"));
            if(apiDate===todayApiDate){
                // let currentTime = getTimeFormat(moment());
                // if(trip.start_time < currentTime){
                //     return true;
                // }else{
                //     return false;
                // }
                return true;
            }else if(apiDate<todayApiDate){
                return true;
            }else{
                return false;
            }
        }else{
            return false;
        }
    }else{
        return false;
    }
}

const getCancelAllowed=(traveller,selectedTravellers)=>{
    if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
        if(global.privilege === PRIVILEGE_HEAD_OFFICE || global.privilege === QB_USER){
            return true;   
        }else{
            let isCancelTicketAllowed = false;
            if(isValidArray(traveller)&&traveller.length>0){
                traveller.forEach((element)=>{
                    if(element.cancellation_penalty>-1){
                        isCancelTicketAllowed = true;
                    }
                });
            }
        
            return isCancelTicketAllowed;
        }
    }else{
        return false
    }
}

const getSelectedTravellerInfo=(selectedTravellers)=>{
    let totalTicketCost=0,totalCancellationPenalty=0,totalRefundAmount=0,fare=0 , totalAmountPaid = 0 ;

    if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
        selectedTravellers.forEach(element => {
                totalTicketCost = totalTicketCost + element.amount_paid;
                totalCancellationPenalty = totalCancellationPenalty + element.cancellation_penalty;
                if(element.cancellation_penalty == element.amount_paid){
                    fare = fare + element.amount_paid;
                }else{
                    fare = fare + element.discounted_fare;
                }
                totalAmountPaid = totalAmountPaid + element.amount_paid;
        });
        totalRefundAmount = totalRefundAmount +(totalTicketCost-totalCancellationPenalty)
    }

    return{
        totalTicketCost:roundNumber(totalTicketCost),
        totalCancellationPenalty:roundNumber(totalCancellationPenalty),
        totalRefundAmount:roundNumber(totalRefundAmount),
        totalCancellationPenaltyPercentage :roundNumber(getPercentage(totalCancellationPenalty,fare)),
        totalFare : roundNumber(fare),
        totalAmountPaid : totalAmountPaid
    }
}

export const getFormatedTravells=(travellers)=>{
    let formatedTrallers=[];
    if(isValidArray(travellers)&&travellers.length>0){
        travellers.forEach((traveller)=>{
            if(isValidArray(traveller)){
                formatedTrallers.push({
                    "id":traveller.id,
                    "fullName":traveller.full_name,
                    "gender":traveller.gender,
                    "age":traveller.age,"pickup_location":traveller.pickup_location,"seatNumber":traveller.seat_number
                }) 
            }
        })
    }
    return formatedTrallers;
}

const fetchTicketDetails=(pnr,onSuccess)=>{
    fetch(GET_TICKET_BY_PNR_URL, {
         method: "POST",
         headers: {'Content-Type':'application/json'},
         body: JSON.stringify({"pnr" : pnr ,"operatorCode":global.operatorCode,"agentId":global.agentId,getLogs:true,getStatusOfSeat:true})
      })
    .then(response => response.json())
    .then(transactionDetails => {
        if (transactionDetails.Message === "Unable to get ticket details"||transactionDetails.Message === "PNR not found"||transactionDetails.Status==="FAILURE"){
            message.error("Please enter a valid PNR");
        }
        else{
            onSuccess(transactionDetails);
        }
     })
    }


const CancelAlretBody=({totalCancellationPenalty,sendNotification,setSendNotification,setCancellationPenality,cancellationPenality,isNotBoardedBody=false})=>{

    let isSendNotificationShow = checkActionAllowed(ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED);
    let isWaiveCancellationPenality = checkActionAllowed(ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED);
    return(
        <div>   
            <Row>
                {isNotBoardedBody?
                <Col>Do you want to mark ticket as not boarded?</Col>
                :
                <Col>Do you want to cancel the ticket?</Col>
                }
            </Row>
            {isSendNotificationShow&&!isNotBoardedBody&&
                <Row>
                    <Col>Send Notification:
                    <Checkbox checked={sendNotification} onChange={(e)=>{
                            setSendNotification(e.target.checked);
                    }} /></Col>
                </Row>
            }
            {isWaiveCancellationPenality&&!isNotBoardedBody&&
                <Row gutter={[8,8]} align="middle">
                    {/* <Col>Waive Cancellation penality:
                    <Checkbox checked={waiveCancellationPenality} onChange={(e)=>{
                            setWaiveCancellationPenality(e.target.checked);
                    }} /></Col> */}
                    <Col justify="center" align="center">Cancellation Penalty Percentage</Col>
                    <Col justify="center" align="center">
                        <InputNumber 
                            value={cancellationPenality}
                            min={0}
                            max={100}
                            onChange={(value)=>{
                                setCancellationPenality(value);
                            }}
                            className="percentageInput"
                        />
                    </Col>
                    <Col justify="center" align="center">
                        <PercentageOutlined  />
                    </Col>
               </Row>
            }
            <Row>
                <Col>You will be charged a penalty of Rs.{totalCancellationPenalty}</Col>
            </Row>
        </div>
    )
}

const TicketDesc=({ticketDetails,getTicketDetails,setShowChangeBus,showChangeBus,isConfirmedTicket,reload})=>{
    let {
        schedule,
        ticket,
        agent,
        traveller,
        route,
        pickupDetails,
        trip,
        dropDetails
    } = ticketDetails;
    
    const [ selectedTravellers,setSelectedTravellers] = useState(null);
    const [ sendNotification,setSendNotification] = useState(true);
    const [ cancellationPenality,setCancellationPenality] = useState(0);
    const [ cancelDetails,setCancelDetails] = useState({});

    const [ checkedRowKeys  , setCheckedRowKeys] = useState(null);
    let [ travellIdsToOverRidePenality  , setTravellIdsTOOverRidePenality] = useState([]);

    const dispatch=useDispatch();

    const history = useHistory();

    const rowSelection = {
        selectedRowKeys:checkedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedTravellers(selectedRows);
            setCheckedRowKeys(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
            disabled: record.traveller_status === CANCELLED_STATUS, // Column configuration not to be checked
          })
      };

      useEffect(()=>{
        let {
            totalTicketCost,
            totalCancellationPenalty,
            totalRefundAmount,
            totalCancellationPenaltyPercentage,
            totalFare,
            totalAmountPaid
        } = getSelectedTravellerInfo(selectedTravellers);
        if(totalCancellationPenaltyPercentage<0){
            setCancellationPenality(100);
        }else{
            setCancellationPenality(totalCancellationPenaltyPercentage);
        }
        setCancelDetails({
            totalTicketCost,
            totalCancellationPenalty,
            totalRefundAmount,
            totalFare,
            totalAmountPaid,
            defalutPenalityPercentage:totalCancellationPenaltyPercentage
        })
      },[selectedTravellers]);

      useEffect(()=>{
        let totalFare = cancelDetails.totalFare;
        if(cancellationPenality>=100){
            totalFare = cancelDetails.totalAmountPaid;
        }
        cancelDetails.totalCancellationPenalty = roundNumber(getValueFromPercentage(totalFare,cancellationPenality));
        setCancelDetails({...cancelDetails});
      },[cancellationPenality]);

      const loadingFunc=(bool)=>{
          dispatch(loadingAction(bool));
      }

      const onSuccess=()=>{
          setCheckedRowKeys(null);
          setSelectedTravellers(null);
          getTicketDetails(ticket.pnr);
      }

    const moveToPnr=(pnr)=>{
        history.push('/view-cancel?pnr='+pnr);
    }

      
const travellerColumns=[
    {
        title: 'Traveller ID',
        dataIndex: 'id',
        key: 'id'
    },
    {
        title: 'Passenger Name',
        dataIndex: 'full_name',
        key: 'full_name'
    },
    {
        title: 'Seat No',
        dataIndex: 'seat_number',
        key: 'seat_number'
    },
    {
        title: 'Gender',
        dataIndex: 'gender',
        key: 'gender'   
    },
    {
        title: isOTAUser()?'Base Fare':'Amount Paid',
        dataIndex: isOTAUser()?'fare':'amount_paid',
        key: isOTAUser()?'fare':'amount_paid'
    },
    {
        title: 'Status',
        dataIndex: 'traveller_status',
        key: 'traveller_status'   
    },
    {
        title:"Penalty",
        dataIndex:"cancellation_penalty",
        key:"cancellation_penalty",
        render:(text,record)=>{
            if(text>0 && record.traveller_status === CANCELLED_STATUS){
                return(
                    <div>{roundNumber(text)}</div>
                )
            }else{
                return null
            }
        }
    },
    {
        title:"Boarding Point",
        key:"location",
        render : (text, record, index) => {
            if(isValidArray(record.pickup_location)&&isValid(record.pickup_location.location)){
                return  record.pickup_location.location
            }else{
                return ''
            }
        }
    },
    {
        title:"Boarded",
        dataIndex:"boarded",
        key:"boarded",
        render:(text)=>{
            if(isValidIndex(text)){
                if(text == 0){
                    return "No";
                }else{
                    return "Yes";
                }
            }else{
                return "Not Marked";
            } 
        }
    },
    {
        title:"Drop Point",
        dataIndex:"dropDetails",
        key:"id",
        render:()=>{
            if(isValid(dropDetails)){
                return dropDetails.location;
            }else{
                return null;
            }
        }
    }	 
];

if(isValid(global.allowedActions[ACTION_OVERRIDE_CANCELLATION_PENALTY_ALLOWED])){
    travellerColumns.push({
        title:"Cancellation time",
        dataIndex:'cancellation_time',
        key:"cancellation_time",
        render : (text, record, index) => {
            if(isValid(record.cancellation_time)){
                return getLocalDateAndTime(record.cancellation_time)
            }else{
                return ''
            }
        }
    });
    travellerColumns.push( {
        title:"Cancelled agent",
        dataIndex:'cancelled_by',
        key:"cancelled_by"
    });
    travellerColumns.push({
        title:"Override cancellation",
        dataIndex:"Override",
        key:"Override",
        render: (text,record,index) => {
            if(record.traveller_status==="Cancelled"){
                return (
                    <React.Fragment>
                        <Checkbox 
                        onChange={(e)=>{
                            if(e.target.checked){
                            travellIdsToOverRidePenality.push(record.id);
                            }else{
                            travellIdsToOverRidePenality = travellIdsToOverRidePenality.filter(item => item !== record.id);
                            }
                            setTravellIdsTOOverRidePenality([...travellIdsToOverRidePenality]);
                        }}
                        />
                  </React.Fragment>
                )

            }else{
                return null
            }
        }
    })

    if(checkActionAllowed('OVERRIDE_SEAT_STATUS')){
        travellerColumns.push({
            title:"Confirm Seats",
            dataIndex:"confirmseats",
            key:"confirmseats",
            render:(text,record,index)=>{
               return(
                <ConfirmSeatsBtn 
                    seatStatus={record.seat_status}
                    travellerStatus={record.traveller_status}
                    ticketId={record.ticket_id}
                    travellerId={record.id}
                    tripRouteId={ticket.trip_route_id}
                    seatNumber={record.seat_number}
                    cutRouteEnabled={trip.cut_route_enabled}
                    reload={reload}
                />
               )
            }
        });

        travellerColumns.push({
            title:"Clear Seats",
            dataIndex:"clearseats",
            key:"clearseats",
            render:(text,record,index)=>{
               return(
                <ClearSeatBtn
                    seatStatus={record.seat_status}
                    tripId = {ticket.trip_id}
                    travellerStatus={record.traveller_status}
                    ticketId={record.ticket_id}
                    seatNumber={record.seat_number}
                    cutRouteEnabled={trip.cut_route_enabled}
                    reload={reload}
                />
               )
            }
        });
    }
}
    let isCancelTicketAllowed=isValid(global.allowedActions[ACTION_CANCEL_TICKET_ALLOWED])||(isValid(global.allowedActions[ACTION_CANCEL_BKBS_TICKET_ALLOWED]) && getSameUser(ticket.agent_id))?true:false;

    let cancelledAllowed = getCancelAllowed(traveller,selectedTravellers);
    let isNotBoardingAllowed = getShowNotBoarded(trip,selectedTravellers);
    let isSelectedTravellers = isValidArray(selectedTravellers)&&selectedTravellers.length>0?true:false;

    return(
        <div>
            <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                                Trip Name :
                        </Text>
                        {schedule.description}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                            Booked Date/Time :
                        </Text>
                        {getLocalDateAndTime(ticket.booking_time)}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify="space-between">
                {isValidArray(pickupDetails)&&isValid(pickupDetails.timing)?
               <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                            Boarding Date/Time :
                        </Text>
                        {getUIDateFormat(pickupDetails.travel_date) + "," + getTimeStampInReadableFormat(pickupDetails.timing)}
                    </Text>
                </Col>
                :null
                }
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                            Passenger Phone No :
                        </Text>
                        {ticket.phone_number}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                            From/To :
                        </Text>
                        {route.origin}/{route.destination}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>
                            Booking office name :
                        </Text>
                        {agent.name}
                    </Text>
                </Col>
            </Row>

            <Row gutter={[16,0]}>
                {isValid(ticket.email_id)?
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Text>
                            <Text strong>
                                Email :
                            </Text>
                            {ticket.email_id}
                        </Text>
                    </Col>
                :
                null
                }
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <div>
                        <Text strong>Reschedule Allowed : </Text>
                        {ticket.is_reschedule_allowed === 1 ?"Yes":"No"}
                    </div>                    
                </Col>
            </Row>
            {isValid(ticket.rescheduled_pnr)&&
                <Row>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text strong>Rescheduled from : </Text>
                            <a 
                            onClick={()=>{
                                moveToPnr(ticket.rescheduled_pnr);
                            }} type="link" className="outline-button">{ticket.rescheduled_pnr}</a>
                    </Col>
                </Row>
            }
             {isValid(ticket.onward_ticket_pnr)&&
                <Row>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text strong>Onward Ticket PNR : </Text>
                            <a 
                            onClick={()=>{
                                moveToPnr(ticket.onward_ticket_pnr);
                            }} type="link" className="outline-button">{ticket.onward_ticket_pnr}</a>
                    </Col>
                </Row>
            }
            {isValid(ticket.return_ticket_pnr)&&
                <Row>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text strong>Return Ticket PNR : </Text>
                            <a 
                            onClick={()=>{
                                moveToPnr(ticket.return_ticket_pnr);
                            }} type="link" className="outline-button">{ticket.return_ticket_pnr}</a>
                    </Col>
                </Row>
            }
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Printer 
                        renderComponent={()=>(
                            <TicketSummary 
                            ticketDetails={ticketDetails}
                            isPrinter={true}
                        />
                        )}
                        zoomValue={1}
                    />
                </Col>
                <Col>
                    <WhatsAppTextShare
                        shareId="ticketSummary"
                        zoomValue={0.5}
                        buildText={getTextOfTicket(ticketDetails)}
                        phoneNumber={ticketDetails.ticket.phone_number}
                    />
                </Col>
                <Col>
                    <TicketLogsComponent 
                    logs={ticketDetails.logs}
                    />
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table 
                        columns={travellerColumns}
                        dataSource={traveller}
                        rowSelection={rowSelection}
                        rowKey={(record) => {
                            return record.id;
                        }}
                        pagination={false}
                    />
                </Col>
            </Row>
                <div className="marginTop">
                    {cancelDetails.totalTicketCost>0&&
                        <div>
                            <Row gutter={[16,0]}>
                                <Col>Amount Paid : {cancelDetails.totalTicketCost}</Col>
                            </Row>
                            {cancelDetails.totalCancellationPenalty>0&&
                                <Row gutter={[16,0]}>
                                    <Col>Cancellation Penalty : {cancelDetails.totalCancellationPenalty}</Col>
                                </Row>
                            }
                            {cancelDetails.totalCancellationPenalty>0&&
                                <Row gutter={[16,0]}>
                                    <Col>Refund Amount : {cancelDetails.totalRefundAmount}</Col>
                                </Row>
                            }
                            {isValid(ticket.payment_type)&&
                           <Row gutter={[16,0]}>
                                <Col>Payment Type : {ticket.payment_type}</Col>
                           </Row>
                            }
                        </div>
                    }
                    <Row gutter={[16,16]} className="marginTop">
                        {isCancelTicketAllowed&&isSelectedTravellers?
                            <Col>
                                <CustomModal 
                                        disabled={!cancelledAllowed}
                                        buttonName={"Cancel"}
                                        buttonClassName={""}
                                        onButtonClick={()=>{
                                            if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
                                                return true;
                                            }else{
                                                message.error("Please atleast one traveller!")
                                                return false;
                                            }
                                        }}
                                        children={<CancelAlretBody totalCancellationPenalty={cancelDetails.totalCancellationPenalty} sendNotification={sendNotification} setSendNotification={setSendNotification} setCancellationPenality={setCancellationPenality} cancellationPenality={cancellationPenality} isNotBoardedBody={false} />}
                                        onOk={async ()=>{
                                            loadingFunc(true);
                                            let partialCancelStatus=selectedTravellers.length===traveller.length?false:true;
                                            let formatedTrallers=getFormatedTravells(selectedTravellers)
                                            let isSendNotication=checkActionAllowed(ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED)?sendNotification:false;
                                            let responce=await cancelTicket(ticket,formatedTrallers,partialCancelStatus,isSendNotication,cancellationPenality,null,onSuccess);
                                            loadingFunc(false);
                                            return responce;
                                        }}
                                        okButtonName={"Yes"}
                                        exitButtonName={"No"}
                                        modalTitle="Cancel Ticket"
                                    />
                            </Col>
                            :
                            null
                        }
                        {isConfirmedTicket&&isSelectedTravellers&&
                        <Col>
                            <ChangeBusButton 
                                setShowChangeBus={setShowChangeBus}
                                showChangeBus={showChangeBus}
                            />
                        </Col>
                        }
                        {isNotBoardingAllowed&&
                        <Col>
                            <CustomModal 
                                disabled={!isNotBoardingAllowed}
                                buttonName={"Not Boarded"}
                                buttonClassName={""}
                                onButtonClick={()=>{
                                    if(isValidArray(selectedTravellers)&&selectedTravellers.length>0){
                                        setCancellationPenality(cancelDetails.defalutPenalityPercentage);
                                        return true;
                                        }else{
                                            message.error("Please atleast one traveller!")
                                            return false;
                                        }
                                    }}
                                    children={<CancelAlretBody totalCancellationPenalty={cancelDetails.totalCancellationPenalty} sendNotification={sendNotification} setSendNotification={setSendNotification} setCancellationPenality={setCancellationPenality} cancellationPenality={cancellationPenality} isNotBoardedBody={true}/>}
                                    onOk={async ()=>{
                                    loadingFunc(true);
                                    let partialCancelStatus=selectedTravellers.length===traveller.length?false:true;
                                    let formatedTrallers=getFormatedTravells(selectedTravellers)
                                    let isSendNotication=checkActionAllowed(ACTION_SEND_CANCEL_NOTIFICATION_ALLOWED)?sendNotification:false;
                                    let responce=await cancelTicket(ticket,formatedTrallers,partialCancelStatus,isSendNotication,cancellationPenality,false,onSuccess);
                                    loadingFunc(false);
                                    return responce;
                                }}
                                okButtonName={"Yes"}
                                exitButtonName={"No"}
                                modalTitle="Not Boarded Ticket"
                            />
                        </Col>
                        }
                        <Col>
                            <OverRidePenality 
                                travellIdsToOverRidePenality={travellIdsToOverRidePenality}
                                getTicketDetails={()=>{
                                    getTicketDetails(ticket.pnr);
                                }}
                            />
                        </Col>
                        <Col>
                            <SendDriverDetails 
                                ticketId={ticket.id} 
                                scheduleVechicleId={schedule.vechicle_id}  
                                tripVechicleId={trip.vechicle_id}
                            />
                        </Col>
                    </Row>
                </div>
        </div>
    )
}

export const ViewTicketPage = () => {

    const [form] = Form.useForm();
    const [ticketDetails,setTicketDetails] = useState(undefined)
    const [showChangeBus,setShowChangeBus] = useState(false);

    let location = useLocation();
    let {
        pnr
    } = getUrlVars(location.search);

    useEffect(()=>{
        if(isValid(pnr)){
            form.setFieldsValue({
                pnrId:pnr
            })
            reload();
        }
    },[pnr]);

    const reload = ()=>{
        onFinish({
            pnrId:pnr
        });
    }

    const history = useHistory();

    const onFinish=(values)=>{
        let {
            pnrId
        }=values;
        getTicketDetails(pnrId);
        setShowChangeBus(false);
        history.push('/view-cancel?pnr='+pnrId);
    }

    const getTicketDetails=(pnrId)=>{
        setTicketDetails(null);
        fetchTicketDetails(pnrId,(ticketDetails)=>{
            setTicketDetails(ticketDetails);
        })
    }

    let isConfirmedTicket = isValidArray(ticketDetails)&&isValidArray(ticketDetails.ticket)&&ticketDetails.ticket.ticket_status === 'Confirmed'?true:false;

    return (
        <div className="view-ticket-root">
            <div className="view-ticket-inner-div">
                <div>
                    <h3>
                        Modify/Cancel ticket
                    </h3>            
                    <DrawLine />
                </div>
                <div className="view-ticket-form">
                    <Form
                        form={form}
                        onFinish={onFinish}
                        >
                        <Row gutter={[16,0]} justify="center" align="middle">
                            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                <Form.Item
                                    label="PNR :"
                                    name="pnrId"
                                    rules={[{ required: true, message: 'Please input your pnr!' }]}
                                >
                                    <Input placeholder="PNR" className="pnrInput"/>
                                </Form.Item> 
                            </Col>
                            <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                <Form.Item>
                                    <Button htmlType="submit">View</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
                {isValidArray(ticketDetails)&&isValidArray(ticketDetails.ticket)&&isValid(ticketDetails.ticket.id)&&
                    <div>
                        <TicketDesc 
                            ticketDetails={ticketDetails}
                            getTicketDetails={getTicketDetails}
                            showChangeBus={showChangeBus}
                            isConfirmedTicket={isConfirmedTicket}
                            setShowChangeBus={setShowChangeBus}
                            reload={reload}
                        />    
                    </div>
                }
                {isConfirmedTicket&&
                    <ChangeBusComponent 
                    setShowChangeBus={setShowChangeBus}
                    showChangeBus={showChangeBus}
                    ticketDetails={ticketDetails}
                    />
                }
            </div>
            <OpenBookedTicketModal />
        </div>
    )
}