import React from "react";
import { Button, message } from "antd";
import { handleConfirmSeats } from "./confirm-seat.module";
import { showConfirm } from "../custom-components/custom-component";


export const ConfirmSeatsBtn = (props) => {

    const{
        seatStatus,
        travellerStatus,
        ticketId,
        travellerId,
        tripRouteId,
        seatNumber,
        cutRouteEnabled,
        reload=()=>{}
    }=props;

    const onFailure=()=>{
        message.error("Failed to to update seat status!")
    }

    const onSuccess =()=>{
        reload();
    }

    const onClick=()=>{
        showConfirm(()=>{
            handleConfirmSeats(ticketId,travellerId,tripRouteId,seatNumber,cutRouteEnabled,onSuccess,onFailure);
        },"Confirm Seat Status","Are you sure to confirm seat status?");
    }

    if(seatStatus != "Confirmed" && travellerStatus == "Confirmed"){
        return (
            <div><Button onClick={onClick}>Confirm</Button></div>
        )
    }else{
        return null
    }
}