import React, { useEffect, useState } from "react";
import { Button ,Row,Col, Select, Calendar , Typography} from "antd";
import './render-tabs.style.scss';
import { addMonth, firstDayOfMonth, getAPIDateFormat, getDate, getDateMonthInReadable , getMonth, lastDayOfMonth, subtractMonth } from "../../../utils/date.utils";
import { removeArrayIndexValue } from "../../../utils/utilities";
import { formateDataByDate, getActiveStatus, getDates } from "./render-tabs.module";
import { getSchedulesByIdForDates, getDemandDaysAction } from "../../../actions/admin.action";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { RenderScheduleFareBlock } from "../render-fare/render-fare.component";
import { CalenderColorLegend } from "../calender-color-legend/calender-color-legend";

const {Option} = Select;
const { Text } = Typography;

export const ScheduleEditFareExtend = (props) => {

        const{
            selectedDate,
            scheduleData,
            isMobile,
            isEditFareOpened
        } = props;

        const [currentDate , setCurrentDate] = useState(selectedDate);
        const [nextDate , setNextDate] = useState(selectedDate);
        const [currentSelectedDates , setCurrentSelectedDates] = useState([]);
        const [openCalenderData , setOpenCalenderData] = useState({});
        const [demandDays, setDemandDays] = useState(null);

        const dispatch = useDispatch();

        useEffect(()=>{
            if(isEditFareOpened){
                reload();
            }
        },[isEditFareOpened,currentDate]);

        const reload=()=>{
            let sqlStartDate = firstDayOfMonth(currentDate);
            let sqlNextDate = lastDayOfMonth(currentDate);
            if(!isMobile){
                sqlNextDate = lastDayOfMonth(addMonth(currentDate,1));
            }            
            setLoading(true);
            let originList = ["All", scheduleData.origin];
            let destinaltionList = ["All", scheduleData.destination];
            getDemandDaysAction(originList,destinaltionList,(demandElements) => {
                setDemandDays(demandElements);
                getSchedulesByIdForDates(scheduleData.id,sqlStartDate,sqlNextDate,(activeData)=>onSuccess(activeData,demandElements['dates']),onFailure);
            }, (err)=> {console.log(err)});
        }

        useEffect(()=>{
            setCurrentDate(selectedDate);
        },[selectedDate]);

        useEffect(()=>{
            let secondDate = addMonth(currentDate,1);
            setNextDate(secondDate);
        },[currentDate]);

        let currentMonth = getMonth(currentDate);
        let nextMonth = getMonth(nextDate);

        const onSuccess=(activeData, demandDates)=>{
            setLoading(false);
            setOpenCalenderData(formateDataByDate(activeData, demandDates));
            setCurrentSelectedDates([]);
        }

        const setLoading = (value)=>{
            dispatch(loadingAction(value));
        }

        const onFailure=()=>{
            setLoading(false);
        }


        const onPreviousClick =()=>{
            if(isMobile){
                setCurrentDate(subtractMonth(currentDate,1));
            }else{
                setCurrentDate(subtractMonth(currentDate,2));
            }
        }

        const onNextClick =()=>{
            if(isMobile){
                setCurrentDate(addMonth(currentDate,1));
            }else{
                setCurrentDate(addMonth(currentDate,2));
            }
        }


        return(
            <div className="extend-block-root">
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <div className="site-extended-calendar">
                            <Calendar 
                                value={currentDate}
                                fullscreen={false}
                                headerRender={()=>{
                                    return (
                                    <Row gutter={[16,16]} align="middle">
                                        <Col>
                                            <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                                <Text className="normal-txt">Previous</Text>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                                        </Col>  
                                        {isMobile&&
                                            <Col>
                                                <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                    <Text className="normal-txt">Next</Text>
                                                </Button>
                                            </Col>
                                        }      
                                    </Row>
                                    )              
                                }}
                                dateFullCellRender={(date)=>{
                                    let day = getDate(date);
                                    let month = getMonth(date);
                                    if(month===currentMonth){
                                        let apidate = getAPIDateFormat(date);
                                        let {
                                            className,
                                            styles,
                                            onlyDateClassName,
                                            minFare,
                                            confirmedSeats,
                                            cutRouteEnabledClassName
                                        } = getActiveStatus(openCalenderData,apidate);
                                        let index = currentSelectedDates.indexOf(apidate);
                                        let selectedClassName = index!=-1?"schedule-date-selected":"";
                                        return (
                                            <div 
                                            title={`Confirmed Seats:${confirmedSeats}`} 
                                            className={`current-date ${className} edit-fare-date-block ${cutRouteEnabledClassName}`} style={styles}>
                                                <div className={`${selectedClassName} ${onlyDateClassName}`}>{day}</div>
                                                <div className="min-fare-in-calender">{minFare}</div>
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div className={`next-month-date`}>
                                                <div>{day}</div>
                                            </div>
                                        )
                                    }
                                }}
                                mode="month"
                                onSelect={(date)=>{
                                    let oldDates = currentSelectedDates;
                                    let apidate = getAPIDateFormat(date);
                                    let index = oldDates.indexOf(apidate);
                                    if(index === -1){
                                        oldDates.push(apidate);
                                    }else{
                                        removeArrayIndexValue(oldDates,index);
                                    }
                                    setCurrentSelectedDates([...oldDates]);
                                }}
                            />
                        </div>
                    </Col>
                    {!isMobile&&
                    <Col>
                            <div className="site-extended-calendar">
                                <Calendar 
                                            value={nextDate}
                                            fullscreen={false}
                                            headerRender={()=>{
                                                return (
                                                <Row gutter={[16,16]} align="middle" justify="end">
                                                    <Col>
                                                        <Text strong>{getDateMonthInReadable(nextDate)}</Text>
                                                    </Col>
                                                    <Col>
                                                        <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                            <Text className="normal-txt">Next</Text>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                )              
                                            }}
                                            dateFullCellRender={(date)=>{
                                                let day = getDate(date);
                                                let month = getMonth(date);
                                                if(month===nextMonth){
                                                    let apidate = getAPIDateFormat(date);
                                                    let {
                                                        className,
                                                        styles,
                                                        onlyDateClassName,
                                                        minFare,
                                                        confirmedSeats,
                                                        cutRouteEnabledClassName
                                                    } = getActiveStatus(openCalenderData,apidate);
                                                    let index = currentSelectedDates.indexOf(apidate);
                                                    let selectedClassName = index!=-1?"schedule-date-selected":"";
                                                    return (
                                                        <div 
                                                        title={`Confirmed Seats:${confirmedSeats}`} 
                                                        className={`current-date ${className} edit-fare-date-block ${cutRouteEnabledClassName}`} style={styles}>
                                                            <div className={`${selectedClassName} ${onlyDateClassName}`}>{day}</div>
                                                            <div className="min-fare-in-calender">{minFare}</div>
                                                        </div>
                                                    )
                                                }else{
                                                    return (
                                                        <div className={`next-month-date`}>
                                                            <div>{day}</div>
                                                        </div>
                                                    )
                                                }
                                            }}
                                            mode="month"
                                            onSelect={(date)=>{
                                                let oldDates = currentSelectedDates;
                                                let apidate = getAPIDateFormat(date);
                                                let index = oldDates.indexOf(apidate);
                                                if(index === -1){
                                                    oldDates.push(apidate);
                                                }else{
                                                    removeArrayIndexValue(oldDates,index);
                                                }
                                                setCurrentSelectedDates([...oldDates]);
                                            }}
                                        />
                            </div>
                        </Col>
                    }
                </Row>
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <Select
                        placeholder="Select Dates"
                        className="schedule-date-select"
                        onChange={(value)=>{
                                let dates = getDates(value);
                                setCurrentSelectedDates(dates);
                        }}
                        >
                            <Option value={'today'}>Today</Option>
                            <Option value={'tomorrow'}>Tomorrow</Option>
                            <Option value={'thisweek'}>This Week</Option>
                            <Option value={'thismonth'}>This Month</Option>
                            <Option value={'nextmonth'}>Next Month</Option>
                        </Select>
                    </Col>
                </Row>
                <Row justify="space-around" align="center">
                    <Col>
                        <CalenderColorLegend />
                    </Col>
                    <Col>
                    </Col>
                </Row>
                {scheduleData.trips_created === 1&&
                    <Row gutter={[16,16]} justify='center' align="middle">
                        <Col>
                            <RenderScheduleFareBlock
                                    selectedDate={selectedDate}
                                    scheduleData={scheduleData}
                                    selectedDates={currentSelectedDates}
                                    openCalenderData={openCalenderData}
                            />
                        </Col>
                    </Row>
                }
            </div>
        )
}
