import React, { useEffect } from 'react';
import { Steps, Button , Row,Col } from 'antd';
import { isValidArray } from '../../../utils/utilities';

const {Step} = Steps;

export const ShowCity = (props) => {
    const{
        selectedCities,
        setShowSelectCity,
        updateCurrentPosition,
        setTripCityHeader,
        addStopComponent
    } = props;

    let cityNum = selectedCities.length - 2;
    let numCity = cityNum<=1?`${cityNum} city`:`${cityNum} cities`;

    useEffect(() => {
        setTripCityHeader("Select Cities");
    });

    const onchangeButton = () => {
        setShowSelectCity(false);
        updateCurrentPosition(1);
        setTripCityHeader("Create Trip")
    }

    const showSteps = () => {
        let returnSteps = [];
        if(isValidArray(selectedCities)&&selectedCities.length>0){
            returnSteps.push(<Step title={selectedCities[0].city_name} />);
            if (cityNum>=1){
                returnSteps.push(<Step title={numCity}  />)
            }
            returnSteps.push(<Step title={selectedCities[selectedCities.length-1].city_name}  />);
        }
        return returnSteps;
    }

    return (
        <div>
            <Row align='middle'>
                <Col>
                    <Steps direction = "vertical" current={2} progressDot>
                        {showSteps()}
                    </Steps>
                </Col>
                <Col>
                    <Button onClick={()=> onchangeButton()}>Edit</Button>
                </Col>
            </Row>           
            {addStopComponent}
        </div>
    )
}