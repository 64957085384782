/**
 * map directions related actions can be created here
 */

 import {
     SET_MAP_CENTER,
    SET_MAP_DIRECTIONS
   } from "./action-types";

export const setMapDirections = ({wayPoints,
    center,
    origin,
    destination,
    zoom},preserveViewport)=>{
    return{
        type:SET_MAP_DIRECTIONS,
        payload:{  
            wayPoints,
            origin,
            destination,
            zoom,
            center,
            preserveViewport
        }
    }
}

export const setCenter = ({lat,lng})=>{
    return{
        type:SET_MAP_CENTER,
        payload:{  
           lat,lng
        }
    }
}