import { Button, Table , Row , Col,Popover} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import '../styles/agents.style.scss';
import { isValidArray } from "../utils/utilities";
import { getAgents } from './../actions/agents.actions';
import { agentsSelector } from './../selector/agents.selector';
import { PlusOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import { POST_PAID_USER, PRE_PAID_USER } from "../utils/constant";
import { PostPaidEdit } from './../components/post-paid-edit/post-paid-edit.component';
import { MoreOutlined } from '@ant-design/icons';


const getFilteredAgents =(allAgents)=>{
    let filteredAgents = []
    if(isValidArray(allAgents)){
        allAgents.forEach(element => {
            if(element.privilege !== 'Head Office' && element.privilege !== "Driver"){
                filteredAgents.push(element);
            }
        });
    }
    return filteredAgents;
}

const DrawLine =()=>{

    return(
        <div className="agents-underline">

        </div>
    )
}

const Title = () =>{
        return (
            <div className="title-agent">
                <h3>
                    Agents
                </h3>
                <DrawLine />
            </div>
        )
}


export const AgentsPage = (props) => {

    const dispatch = useDispatch();

    
    let allAgents = useSelector(agentsSelector);

    const [ filteredAgents , setFilteredAgents ] = useState([]);

    const getAgentsDispatch = () =>{
        dispatch(getAgents());
    }

    useEffect(()=>{
        getAgentsDispatch();
    },[]);

    useEffect(()=>{
        setFilteredAgents(getFilteredAgents(allAgents));
    },[allAgents]);

    let history = useHistory();

    const onClickPlus=(agent)=>{
        history.push({
            pathname:"/accounts/agent-transactions",
            state:{agentId:agent.id}
        });
    }

    const onClickViewTripSheetPermission=(agent)=>{
        history.push({
            pathname:"/accounts/trip-sheet-permission",
            state:{agentId:agent.id}
        });
    }

    const columns = [
        {
            title:'Sl.no',
            key:'index',
            render : (text, record, index) => index+1
        },
        {
            title:'Agent Name',
            dataIndex : 'name',
            key:'index',
            render:(text,record)=>{
                const content = (
                    <div className="pophover-button-block">
                      <Button className="edit-button-stop-name" onClick={()=>{
                        onClickViewTripSheetPermission(record);
                      }}>View Trip Sheet Permission</Button>
                    </div>
                  );
                return(
                    <Row className="stop-name-details">
                        <Col className="stop-name-txt">
                            {text}
                        </Col>
                        <Col>
                            <Popover content={content}>
                                <MoreOutlined className="stop-more-icon"/>
                            </Popover>
                        </Col>
                    </Row>
                )
            }
        },
        {
            title:'Agent Type',
            key:'agent_type',
            dataIndex : 'agent_type'
        },
        {
            title:'Phone Number',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Account Balance',
            key:'amount_limit',
            dataIndex : 'amount_limit'
        },
        {
            title:"Due Amount",
            key:'amount_due',
            dataIndex:'amount_due'
        },
        {
            title:'Details',
            key:'details',
            dataIndex : 'details',
            render : (text,row)=>{
                let agentType = row.agent_type;
                if(agentType === POST_PAID_USER){
                    return(
                        <PostPaidEdit 
                            agentId={row.id}
                            postPaidLimit={row.postpaid_amount_limit}
                            onCallBack={(agentId,postPaidAmountLimit)=>{
                                filteredAgents.forEach((element)=>{
                                    if(element.id===agentId){
                                        element.postpaid_amount_limit = postPaidAmountLimit;
                                    }
                                })
                                setFilteredAgents([...filteredAgents]);
                            }}
                        />
                    );
                }else{
                    return null;
                }
            }
        },
        {
            title:'New Transaction',
            key:'new_transaction',
            dataIndex : 'new_transaction',
            render : (text,row) =>{
                let agentType = row.agent_type;
                if(agentType === PRE_PAID_USER){
                    return (<div className="plus-button-root">
                                <Button
                                className="plus-button"
                                onClick={()=>{
                                    onClickPlus(row);
                                }}
                                icon={<PlusOutlined />}
                                />
                        </div>)
                }else{
                    return null
                }
            }
        }
    ];

    return (
        <div className="agents-page-root">
            <div className="agents-page-inner-div">
                <Title />
                <Table 
                    columns={columns}
                    dataSource={filteredAgents}
                    pagination={false}
                />
            </div>
        </div>
    )
}