import {
    FETCH_MASTER_CITIES,GET_GOOGLE_DISTANCE_MATRIX, GET_BUSES_ACTION, CREATE_TRIP_DATA, UPDATE_TRIP_DATA,
    GET_ADDRESS_LOCATIONS, INSERT_TRIP_STOPS , SET_SELECTED_CITIES ,SET_SELECTED_STOPS , SET_ACTIVE_CITY, 
    SET_TRIP_DATA , ON_CHANGE_SCHEDULE_FORM , SET_ACTIVE_STOP,SET_REORDER_ACTIVE_STOPS,SET_ON_MARKER_DRAG_END,
    SET_CITIES_WITH_STOPS,RESET_TRIP_CREATE , SET_CACHE_ADDRESS , SET_ADDRESS_FROM_CACHE, CLONE_AS_RETURN_TRIP, CLONE_TRIP
} from './action-types';
import {
    FETCH_MASTER_CITIES_URL, GET_GOOGLE_DISTANCE_MATRIX_URL, GET_BUS_URL, CREATE_TRIP_DATA_URL, UPDATE_TRIP_DATA_URL,
    GET_SCHEDULE_WITH_STOPS_URL,INSERT_SCHEDULE_BY_STOPS_URL,
    GET_ADDRESS_LOCATIONS_URL, INSERT_TRIP_STOPS_URL, CREATE_ADDRESS_URL, UPDATE_ADDRESS_URL,UPDATE_STOP_TIMINGS_URL, CLONE_TRIP_URL, CLONE_AS_RETURN_TRIP_URL
} from '../utils/api-url';
import { postCall } from "../utils/network.util";

export  const  cloneTrip=(scheduleId,offset,busId,tripIdentifier,onSuccess)=>{
  return {
      type:CLONE_TRIP,
      payload:{
          url:CLONE_TRIP_URL,
          api:postCall,
          apiPayload:{
              scheduleId,offset,busId,tripIdentifier
          },
          onSuccess:onSuccess
      }
  }
}


export  const  cloneAsReturnTrip=(scheduleId,newStartTime,busId,tripIdentifier,onSuccess)=>{
  return {
      type:CLONE_AS_RETURN_TRIP,
      payload:{
          url:CLONE_AS_RETURN_TRIP_URL,
          api:postCall,
          apiPayload:{
              scheduleId,newStartTime,busId,tripIdentifier
          },
          onSuccess:onSuccess
      }
  }
}

export const resetTripCreate=()=>{
  return{
    type:RESET_TRIP_CREATE
  }
}

export const setCitiesWithStops =(citiesWithStops)=>{
  return{
    type:SET_CITIES_WITH_STOPS,
    payload:{
      citiesWithStops
    }
  }
}

export const setOnMarkerDragEnd=(lat,lng,index)=>{
  return{
    type:SET_ON_MARKER_DRAG_END,
    payload:{
      lat,lng,index
    }
  }
}

export const reOrderActiveStops =(sourceIndex,destinationIndex)=>{
  return{
    type:SET_REORDER_ACTIVE_STOPS,
    payload:{
      sourceIndex,
      destinationIndex
    }
  }
}

export const setActiveStop = (address,index)=>{
  return{
    type:SET_ACTIVE_STOP,
    payload:{
      address,
      index
    }
  }
}

export const onChangeScheduleForm=(changedInfo)=>{
  return{
    type : ON_CHANGE_SCHEDULE_FORM,
    payload:{
      changedInfo
    }
  }
}

export const setTripData=(tripsData)=>{
  return{
    type:SET_TRIP_DATA,
    payload:{
      tripsData
    }
  }
}

export const setActiveCity=(activeCity)=>{
  return{
    type:SET_ACTIVE_CITY,
    payload:{
      activeCity
    }
  }
}

export const setSelectedStops = (selectedStops) =>{
  return{
    type:SET_SELECTED_STOPS,
    payload:{
      selectedStops
    }
  }
}

export const setSelectedCities=(selectedCities)=>{
  return{
    type:SET_SELECTED_CITIES,
    payload:{
      selectedCities 
    }
  }
}

export const fetchMaterCitiesAction=()=>{
    return {
        type: FETCH_MASTER_CITIES, 
        payload: {
            url: FETCH_MASTER_CITIES_URL,
            api: postCall,
            apiPayload:{}
        }
    }
}

export const createTripAction = (tripData,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: CREATE_TRIP_DATA,
        payload: {
            url: CREATE_TRIP_DATA_URL,
            api: postCall,
            apiPayload: {
                'schedule': tripData
            },
            onSuccess,
            onFailure
        }
    }
}

export const updateTripAction = (scheduleId, tripData , onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: UPDATE_TRIP_DATA,
        payload: {
            url: UPDATE_TRIP_DATA_URL,
            api: postCall,
            apiPayload: {
                'scheduleId': scheduleId,
                'scheduleData': tripData
            },
            onSuccess,
            onFailure
        }
    }
}

export const getBusDetailsAction = () => {
    return {
        type: GET_BUSES_ACTION,
        payload: {
            url: GET_BUS_URL,
            api: postCall,
            apiPayload:{
                travelsId:global.travelsId
            }
        }
    }
}

export const getDataBetweenCities=(origin,destination,onSuccess=()=>{})=>{
    return {
        type:GET_GOOGLE_DISTANCE_MATRIX,
        payload:{
            url:GET_GOOGLE_DISTANCE_MATRIX_URL,
            api:postCall,
            apiPayload: {
                'origin': origin,
                'destination': destination
            },
            onSuccess:onSuccess,
        }
    }
}

export const getAddressLocations = (city, isFetchStandardStops,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: GET_ADDRESS_LOCATIONS,
        payload: {
            url: GET_ADDRESS_LOCATIONS_URL,
            api: postCall,
            apiPayload: {
                'city': city,
                'operatorCode': global.operatorCode,
                'isFetchStandardStops':isFetchStandardStops
            },
            onSuccess: onSuccess,
            onFailure: onFailure
        }
    }
}

export const insertTripStops = (tripStops, scheduleId, onSuccess=()=>{}) => {
    return {
        type: INSERT_TRIP_STOPS,
        payload: {
            url: INSERT_TRIP_STOPS_URL,
            api: postCall,
            apiPayload: {
                'tripStops': tripStops,
                'scheduleId': scheduleId,
            },
            onSuccess: onSuccess,
        }
    }
}

export const getScheduleWithStops = (scheduleId,isGoogleTimings,onSuccess=()=>{},onFailure=()=>{})=>{
    var fecthed = postCall(GET_SCHEDULE_WITH_STOPS_URL, {
        scheduleId : scheduleId,
        isGoogleTimings : isGoogleTimings
      });
      fecthed.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Falied to get schedule info!");
      })
}

export const insertScheduleByStops = (tripCitiesWithStops,minimumMinsTocreateRoute,avoidRoutes,schedule,tripClosePointStopId,onSuccess=()=>{},onFailure=()=>{})=>{
    var inserted = postCall(INSERT_SCHEDULE_BY_STOPS_URL, {
        citiesWithStops : tripCitiesWithStops,
        minimumMinsTocreateRoute : minimumMinsTocreateRoute,
        schedule : schedule,
        avoidRoutes : avoidRoutes,
        tripClosePointStopId : tripClosePointStopId
      });
      inserted.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.payload);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Falied to insert schedule info!");
      })
}

export const insertAddress = (address,onSuccess=()=>{},onFailure=()=>{})=>{
    var inserted = postCall(CREATE_ADDRESS_URL, {
        location: address
      });
      inserted.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload[0]);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        console.log({reason});
        onFailure("Falied to insert address info!");
      })
}

export const updateAddress = (address,onSuccess=()=>{},onFailure=()=>{})=>{
    var updated = postCall(UPDATE_ADDRESS_URL, {
        location: address
      });
      updated.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload[0]);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        console.log({reason});
        onFailure("Falied to insert address info!");
      })
}

export const updateStopTimings = (stops)=>{
  var updated = postCall(UPDATE_STOP_TIMINGS_URL, {
      stops: stops
    });
    updated.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
      }else{
        // onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      console.log({reason});
      // onFailure("Falied to update stop timings!");
    })
}

export const setCacheAddress = (cacheAddress,cacheAddressIndex)=>{
  return{
    type:SET_CACHE_ADDRESS,
    payload:{
      cacheAddress,
      cacheAddressIndex
    }
  }
}

export const resetAddressFromCache = () =>{
  return{
    type:SET_ADDRESS_FROM_CACHE
  }
}