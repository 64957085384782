import moment from 'moment';
import { isValid, isValidNumber } from './utilities';

//moment date java script libs
/*
  Returns date object for given datetime
  input moment date object
  output formatted string
*/

export const getFormatedDate=(date,showDate=true,showMonth=true,showYear=true,separator='/')=>{
  let formatedDate = moment(date);
  let desc='';
  if(showDate){
     desc=desc+formatedDate.format('D');
  }
  if(showMonth){
    if(desc.length>0){
      desc=desc+separator;
    }
     desc = desc+formatedDate.format('M');
  }
  if(showYear){
    if(desc.length>0){
      desc=desc+separator;
    }
     desc = desc+formatedDate.format('YYYY')
  }
  return desc
}

export const getAPIDateFormat = (datetime) => {
 return moment.parseZone(datetime).format('YYYY-MM-DD');
}

export const getTimeFormat = (datetime) => {
  return moment.parseZone(datetime).format('HH:mm:ss');
 }

export const getUIDateFormat = (datetime,symbol='/') => {
  return moment.parseZone(datetime).format(`DD${symbol}MM${symbol}YYYY`);
 }

export const convertDateStringToDate = (date) => {
  let dateDetails = date.split("-");
  let currentDate = new Date();
  let parsedDate = new Date(dateDetails[0],dateDetails[1]-1,
                            dateDetails[2],currentDate.getHours(),
                            currentDate.getMinutes(),currentDate.getSeconds());
  return parsedDate;
}

export const getLocalDate = (utcTimeStamp) => {
  let date;
  if( typeof(utcTimeStamp) !== "object"){
    date = new Date(utcTimeStamp);
    return date.toLocaleDateString('en-GB',{timeZone:'Asia/Kolkata'})
  }
  else{
    date = utcTimeStamp;
    return date.toLocaleDateString('en-GB')
  }
}

// Converts HH:MM:SS to 3:00 AM, 4:30 PM etc
export const getTimeStampInReadableFormat = (timestamp) => {
  let timeDetails = timestamp.split(":");
  let isAM = timeDetails[0] > 11 ? false : true;
  let hours = timeDetails[0];
  if (isAM === false) {
    if (timeDetails[0] > 12) {
      hours = timeDetails[0] - 12;
    }
  }
  let minutes = timeDetails[1];
  return `${hours}:${minutes} ${isAM === true ? "AM" : "PM"}`
}

export const getLocalTime = (utcTimeStamp) => {
  let date = new Date(utcTimeStamp);
  return date.toLocaleTimeString('en-US',{ hour: 'numeric', minute: 'numeric', hour12: true ,timeZone:'Asia/Kolkata'})
}

//Format of timestamp in HH:MM:SS
export const convertTimeStringToDate = (timestamp) => {
  let timeDetails = timestamp.split(":");
  let currentDate = new Date();
  let parsedDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),
                            currentDate.getDay(),timeDetails[0],timeDetails[1]
                            ,timeDetails[2]);
  return parsedDate;
}

export const getLocalDateAndTime = (utcTimeStamp) => {
  return `${getLocalDate(utcTimeStamp)} ${getLocalTime(utcTimeStamp)}`
}

export const setTimeToDateObject = (dateObject,timeStamp,timeSeperator = ':') => {
  var date1 = moment.parseZone(dateObject).format("YYYY-MM-DD")
  date1+=" "+timeStamp
  var date2 = moment.parseZone(date1);
  return date2;
}

/**
 * Subracts hours and minutes
 */
 export const subractTime = (date,hours = 0,minutes = 0) => {
  date = moment.parseZone(date);
  var newDate = date.subtract({ hours: hours, minutes: minutes});
  return newDate;
}

export const getDateTimeReadableFormat = (datetime) => {
  var date = moment.parseZone(datetime).format('dddd, DD MMMM YYYY [at] h:mm a');
  return date;
}


export const getDateTimeFormat = (datetime) => {
  var date = moment.parseZone(datetime).format('DD MMM YYYY h:mm a');
  return date;
}

export const currentDate = ()=>{
  return moment().utcOffset("+05:30");
}

export const incrementDays = (momentObject,incrementValue)=>{
  return moment(momentObject).add(incrementValue, 'days')
}

export const addMonth=(momentObject,incrementNo)=>{
  return moment(momentObject).add(incrementNo, 'M') ;
}

export const subtractMonth=(momentObject,decrementNo)=>{
  return moment(momentObject).subtract(decrementNo, 'M');
}

export const decrementDays = (momentObject,decrementValue)=>{
  return moment(momentObject).subtract(decrementValue, 'days')
}

export const noOfDaysInMonth = (momentObject)=>{
  return moment(momentObject).daysInMonth();
}

export const firstDayOfMonth=(momentObject)=>{
  return moment(momentObject).startOf('month').format('YYYY-MM-DD');
}

export const lastDayOfMonth=(momentObject)=>{
  return moment(momentObject).endOf('month').format('YYYY-MM-DD');
}

export const addMin = (momentObject,mins)=>{
  if(!isValid(momentObject)){
    momentObject = moment().utcOffset("+05:30");
  }
  return moment.parseZone(momentObject).add(mins, 'minutes');
}

export const getMinsBetweenTwoTimes=(time1,time2)=>{
  let diff = moment(time2, 'HH:mm').diff(moment(time1, 'HH:mm'))
  let d = moment.duration(diff);
   return Math.floor(d.asMinutes());
}

export const getSecsBetweenTwoTimes=(time1,time2)=>{
  let diff = moment(time2, 'HH:mm:ss').diff(moment(time1, 'HH:mm:ss'))
  let d = moment.duration(diff);
   return Math.floor(d.asSeconds());
}

// input moment object
export const get24HoursTime=(time)=>{
  return moment(time,'HH:mm').format("HH:mm:ss")
}

export const get12HoursTime=(time) => {
  return moment(time,'HH:mm').format("hh:mm A")
}

export const getFormattedTime =(time) => {
  var timeArray = time.split(':');
  var d = new Date();
  d.setHours  (timeArray[0]);
  d.setMinutes(timeArray[1]);
  var hours = d.getHours() > 12 ? d.getHours() - 12 : d.getHours();
  var am_pm = d.getHours() >= 12 ? "PM" : "AM";
  hours = hours < 10 ? "0" + hours : hours;
  var minutes = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  var formattedTime = hours + ":" + minutes + " " + am_pm;
  return formattedTime
}

export const getHoursAndMinsFromMins=(minutes)=>{
  if(isValid(minutes)){
    let hours = Math.floor(minutes / 60);
    let mins = minutes % 60;
    if(isValidNumber(hours)&&hours>0&&isValidNumber(mins)&&mins>0){
      return `${hours}h ${mins}m`;
    }else if (isValidNumber(hours)&&hours>0){
      return `${hours}h`;
    }else if (isValidNumber(mins)&&mins>0){
      return `${mins}m`;
    }else{
      return '';
    }
  }else{
    return null;
  }
}

export const getMinsFromSecs=(seconds,decimal=0)=>{
  if(isValid(seconds)){
    var m = seconds / 60;
    return m.toFixed(decimal);
  }else{
    return null;
  }
}


export const getMonth=(date)=>{
  return date.month();
}

export const getYear=(date)=>{
  return date.year();
}

export const getDay=(date)=>{
  return date.day();
}

export const getDate=(date)=>{
  return date.date()
}

export const getDateMonthInReadable=(momentObject)=>{
  return moment.parseZone(momentObject).format('MMM YYYY');
}

export const getDateMonthYearInReadable=(momentObject)=>{
  return moment.parseZone(momentObject).format('MMM,DD,YYYY');
}

export const getDayMonthInReadable=(momentObject)=>{
  return moment.parseZone(momentObject).format('MMM DD');
}

export const getWeekDay=(momentObject)=>{
  return moment.parseZone(momentObject).format('ddd');
}

export const getReadableDate = (date) => {
  return moment.parseZone(date).format('DD/MM/YYYY')
}

export const isMomentDate = (date) => {
  return moment.isMoment(date);
}

export const getIstMomentDate = (date) => {
  return moment.parseZone(date).format();
}

export const getCurrentDateInIST = (date = null) => {
  let currentDate = moment().utcOffset("+05:30").format('YYYY-MM-DD-HH:mm');
  if (isValid(date)) {
    currentDate = moment(date).utcOffset("+05:30").format('YYYY-MM-DD-HH:mm');
  }
  let formatDate = currentDate.split('-')
  let formatTime = formatDate[3].split(':');
  let istTime = new Date(formatDate[0],formatDate[1]-1,formatDate[2],formatTime[0],formatTime[1]);
  return istTime;
}

export const calendarDateFormat = (date) => {
  return moment.parseZone(date).format('ddd, D MMM');
}

export const setDateFormat = (date, formatType = "DD-MM-YYYY") => {
  return moment.parseZone(date).format(formatType);
}

export const isDateInRange = (checkDate, fromDate, toDate) => {
  let compareDate = moment(checkDate, 'YYYY-MM-DD');
  let startDate = moment(fromDate, 'YYYY-MM-DD');
  let endDate = moment(toDate, 'YYYY-MM-DD');
  let isInRange = compareDate.isBetween(startDate, endDate);
  return isInRange;
}