import React from "react";
import { Progress, Button } from 'antd';
import '../styles/trip-create-success.style.scss';
import { isValid } from "../utils/utilities";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export const TripCreateSuccessPage = (props) => {
    const {
        location
    } = props;

    const history = useHistory();

    return (
        <div className="trip-create-success-root">
            <div className="trip-create-success-inner-div">
                <div className="circle-block">
                    <Progress type="circle" percent={100} />
                </div>
                <div className="text-block">
                    <h3>Trip successfully created.</h3>
                </div>
                {
                    isValid(location.state)&&isValid(location.state.selectedScheduleId)&&
                    <div className="text-block">
                        <Button onClick={()=> {
                            history.push({
                                pathname: '/admin/schedule',
                                state: { selectedScheduleId: location.state.selectedScheduleId  }
                            });
                        }}>
                            Activate Trip
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}