import { roundNumber } from "../../utils/math.utils";

export const COMM_TDS = "COMM_TDS";
export const NET_AGENT_COMM = "NET_AGENT_COMM";
export const TOTAL_CANCEL_PENALITY = "TOTAL_CANCEL_PENALITY";
export const NET_AMOUNT = "NET_AMOUNT";
export const AVAIABLE_SEATS = "avaibale seats";
export const ADMIN_BLOCKED_SEATS = "admin blocked seats";
export const COMMISSION = "Comm";
export const AMOUNT = "Amt";
export const BOARDING_LOCATION = "boarding location";

const BOOKING_AGENT_TYPES = ['Agent BT','Booking Agent','Agent'];

export const totalTransactionReportColumns={
    SRMS:[
        COMM_TDS,NET_AGENT_COMM,TOTAL_CANCEL_PENALITY,NET_AMOUNT
    ]
}


export const tripSheetReportConfig={
    SRMS:{
        tablesToAvoid:[AVAIABLE_SEATS,ADMIN_BLOCKED_SEATS],
        allAgentsTable:{
            showOnlySpecificAgent:true,
            showSeats:false,
            showTdsOnCommision:true,
            showNetCommision:true
        },
        showTripSheetInSingleTable:false
    },
    KAALAYAPPA:{
        operatorCity:"Chennai",
        allAgentsTable:{
        },
        tablesToAvoid:[]
    },
    CAMPBELL:{
        allAgentsTable:{},
        tablesToAvoid:[ADMIN_BLOCKED_SEATS],
        tripSheet:{
            isAgentSpecific:true,
            agentSpecificPermissions:{
                bookingAgents:BOOKING_AGENT_TYPES,
                showComm:false,
                showAmt:false,
                tables:{
                    isShowAllAgentsTable:false
                }
            }
        }
    },

    SUBHETHRA:{
        allAgentsTable:{},
        tablesToAvoid:[ADMIN_BLOCKED_SEATS]
    },
    NANTHI:{
        operatorCity:"Chennai",
        allAgentsTable:{
        },
        tablesToAvoid:[]
    } 
}
