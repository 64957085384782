import React, { useEffect, useState } from "react";
import { Row,Col } from "antd";
import { isValidArray, getValuesOfJSonObject, sortJsonObject, isValidArrayWithValues } from "../../utils/utilities";
import './dashboard.style.scss';
import { useDispatch } from "react-redux";
import { getRevenueForWeekOTA } from "../../actions/revenue.actions";
import moment from "moment";
import { getAPIDateFormat } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";
import { roundNumber, convertToCurrencyNumber } from "../../utils/math.utils";

const renderRevenueDetails=(revenueForWeek)=>{
    if(isValidArray(revenueForWeek)){
        let total = 0 , offlineTotal = 0, onlineTotal = 0;
        let formatedOnlineInfo =[], formatedOfflineInfo = [];
        let revenueList = getValuesOfJSonObject(revenueForWeek);
        let revenueSortList = sortJsonObject(revenueList, 'totalrevenue', true);
        revenueSortList.toReversed().map((revenue) => {
            if (["ota", "website"].includes((revenue.privilege).toLowerCase())) {
                formatedOnlineInfo.push(<Row>
                    <Col span={12} className="agentName">{revenue.agentName}</Col>
                    <Col className="agent-value">₹ {convertToCurrencyNumber(roundNumber(revenue.totalrevenue))}</Col>
                </Row>);
                onlineTotal= onlineTotal + roundNumber(revenue.totalrevenue);
            }
            else {
                formatedOfflineInfo.push(<Row>
                    <Col span={12} className="agentName">{revenue.agentName}</Col>
                    <Col className="agent-value">₹ {convertToCurrencyNumber(roundNumber(revenue.totalrevenue))}</Col>
                </Row>);
                offlineTotal= offlineTotal + roundNumber(revenue.totalrevenue);
            }
            total= total + roundNumber(revenue.totalrevenue);
        });
        formatedOnlineInfo.push(<Row>
            <Col span={12} className="online-agents">Total</Col>
            <Col className="online-agents">₹ {convertToCurrencyNumber(roundNumber(onlineTotal))}</Col>
        </Row>);
        formatedOfflineInfo.push(<Row>
            <Col span={12} className="online-agents">Total</Col>
            <Col className="online-agents">₹ {convertToCurrencyNumber(roundNumber(offlineTotal))}</Col>
        </Row>);
        return (
            <div className="revenue-for-week-extention-block">
                <div className="revenue-total">₹ {convertToCurrencyNumber(roundNumber(total))}</div>
                <div className="online-agents">Online agents</div>
                {formatedOnlineInfo}
                <br/>
                <div className="online-agents">Offline agents</div>
                {formatedOfflineInfo}
            </div>
        )
    }else{
        return null;
    }
}

export const RevenueForWeekComponent = (props) => {
    const {
        
    } = props;

    const [revenueForWeek , setRevenueForWeek] = useState(null);
    const [dateRange, setDateRange] = useState([]);

    const dispatch = useDispatch();

    const onSuccess=(info)=>{
        setRevenueForWeek(info);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{

    },[]);
        
   return(
    <div className="revenue-for-week-root">
        <div 
        className="revenue-for-week"
        onClick={()=>{
                    setLoading(true);
                    let currentDate = moment();
                    let weekStart = currentDate.clone().startOf('isoweek');
                    let weekEnd = currentDate.clone().endOf('isoweek');
                    let startDate = getAPIDateFormat(weekStart);
                    let endDate = getAPIDateFormat(weekEnd);
                    setDateRange([startDate, endDate]);
                    dispatch(getRevenueForWeekOTA(startDate,endDate,onSuccess,onFailure));
        }}>
            <Row justify="center">
                <Col span={12}>
                    Revenue This Week
                </Col>
                {isValidArrayWithValues(dateRange)&&<Col span= {12}>
                    <Row justify="center">
                            Mon - Sun
                    </Row>
                    <Row justify="center">
                        {moment(dateRange[0]).format('Do MMM')} - {moment(dateRange[1]).format("Do MMM")}
                    </Row>
                </Col>}
            </Row>
        </div>
        {renderRevenueDetails(revenueForWeek)}
    </div>
   )
}


