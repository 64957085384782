import React, { useState, useEffect, useRef } from "react";
import { DirectionsService, DirectionsRenderer } from "@react-google-maps/api";
import { isValidArray } from "../../utils/utilities";

// const checkPolyline =(oldResult,newResult)=>{
//     if(isValidArray(oldResult)){
//       let routes = oldResult.routes;
//       if(isValidArray(routes)&&routes.length>0){
//         let oldPolyline = routes[0].overview_polyline;
//         if(isValidArray(newResult)&&isValidArray(newResult.routes)&&newResult.routes.length>0){
//           let newPloyline = newResult.routes[0].overview_polyline;
//           if(newPloyline === oldPolyline){
//             return false;
//           }else{
//             return true;
//           }
//         }else{
//           return true;
//         }
//       }else{
//         return true;
//       }
//     }else{
//       return true
//     }
// }

const Directions = (props) => {
  const [directions, setDirections] = useState();
  const { origin, destination , wayPoints , preserveViewport} = props;
  const count = useRef(0);

  const options = {
    polylineOptions: {
      strokeColor: "#1890ff",
      strokeWeight: 6,
      strokeOpacity: 0.8
    },
    preserveViewport: preserveViewport,
    suppressMarkers:true,
    provideRouteAlternatives:false
  };

  useEffect(() => {
    count.current = 0;
  }, [origin, destination,wayPoints]);

  const directionsCallback = (result, status) => {
    if (status === "OK" && count.current === 0) {
      count.current += 1;
      setDirections(result);
    }
  };

  return (
    <>
      <DirectionsService
        options={{
          destination,
          origin,
          waypoints:wayPoints,
          travelMode: "DRIVING",
          provideRouteAlternatives:false
        }}
        callback={directionsCallback}
      />
      {directions && (
        <DirectionsRenderer 
          directions={directions} 
          options={options} 
          onDirectionsChanged={()=>{

          }}/>
      )}
    </>
  );
};

export { Directions };
