export const RESET_ALL="RESET_ALL";

export const SET_RUNNING_CACHE="SET_RUNNING_CACHE";
export const SET_LOGIN_USER_DETAILS="SET_LOGIN_USER_DETAILS";
export const LOGIN_ACTION="LOGIN_ACTION";
export const LOG_OUT_ACTION="LOG_OUT_ACTION";

export const LOADING_ACTION="LOADING_ACTION";

/**
 * Bus operators actions
 */

export const GET_BUS_OPERATORS = "GET_BUS_OPERATORS";
export const SET_BUS_OPERATORS = "SET_BUS_OPERATORS";
export const SET_BUS_OPERATORS_LOADING = "SET_BUS_OPERATORS_LOADING";

/**
 * Home page action types
 */

export const GET_ORIGIN_CITIES="GET_ORIGIN_CITIES";
export const GET_DESTINATION_CITIES="GET_DESTINATION_CITIES";
export const FETCH_SCHEDULES="FETCH_SCHEDULES"

export const SET_ORIGIN_CITIES="SET_ORIGIN_CITIES";
export const SET_DESTINATION_CITIES="SET_DESTINATION_CITIES";
export const SET_SELECTED_SCHEDULE_SEATS="SET_SELECTED_SCHEDULE_SEATS";
export const SET_SELECTED_SCHEDULE = "SET_SELECTED_SCHEDULE";
export const SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE = "SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE";
export const SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE = "SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE";
export const ON_PASSENGER_FORM_CHANGE="ON_PASSENGER_FORM_CHANGE";
export const ADD_CLIENT_SIDE_DISCOUNT ="ADD_CLIENT_SIDE_DISCOUNT";

export const FETCH_SEAT_LAYOUT = "FETCH_SEAT_LAYOUT";
export const UPDATE_SEAT_DETAILS = "UPDATE_SEAT_DETAILS";

export const BOOK_TICKET="BOOK_TICKET";
export const CHANGE_TICKET ="CHANGE_TICKET";
export const BLOCK_TICKET="BLOCK_TICKET";
export const SET_BOOKED_TICKET = "SET_BOOKED_TICKET";
export const RESET_PASSENGER_FORM = "RESET_PASSENGER_FORM";

export const OVER_RIDE_PENALITY="OVER_RIDE_PENALITY_URL";

export const CREATE_TRIP_EXPENSE="CREATE_TRIP_EXPENSE";
export const GET_TRIP_EXPENSE="GET_TRIP_EXPENSE";
export const SET_TRIP_EXPENSE="SET_TRIP_EXPENSE";
export const UPDATE_TRIP_EXPENSE="UPDATE_TRIP_EXPENSE";
export const CLONE_TRIP ="CLONE_TRIP";
export const CLONE_AS_RETURN_TRIP ="CLONE_AS_RETURN_TRIP";

//Agents

export const GET_AGENTS="GET_AGENTS";
export const SET_AGENTS="SET_AGENTS";
export const SET_AGENT_TRANSACTIONS ="SET_AGENT_TRANSACTIONS";
export const GET_AGENTS_TRANSACTIONS = "GET_AGENTS_TRANSACTIONS";
export const CREATE_AGENT_TRANSACTION ="CREATE_AGENT_TRANSACTION";
export const GET_AGENTS_ACTIONS = "GET_AGENTS_ACTIONS";
export const GET_USER_ACTIONS = "GET_USER_ACTIONS";

// dashboard

export const GET_DASHBOARD_DATA = "GET_DASHBOARD_DATA";
export const SET_DASHBOARD_DATA = "SET_DASHBOARD_DATA";

// Staff Registration actions

export const GET_STAFF = 'GET_STAFF';
export const CREATE_STAFF = 'CREATE_STAFF';
export const GET_STAFF_TYPES = 'GET_STAFF_TYPES';
export const SET_CACHE_ADDRESS = 'SET_CACHE_ADDRESS';
export const SET_ADDRESS_FROM_CACHE = 'SET_ADDRESS_FROM_CACHE';

//login
export const VERIFY_OTP="VERIFY_OTP";

//Trip Create related actions
export const FETCH_MASTER_CITIES = "FETCH_MASTER_CITIES";
export const SET_MASTER_CITIES = "SET_MASTER_CITIES";
export const GET_GOOGLE_DISTANCE_MATRIX = "GET_GOOGLE_DISTANCE_MATRIX";
export const GET_BUSES_ACTION = "GET_BUSES_ACTION";
export const BUS_TABLE_LOADING = "BUS_TABLE_LOADING";
export const BUSES_INFO = "BUSES_INFO";
export const CREATE_TRIP_DATA = "CREATE_TRIP_DATA";
export const SET_TRIP_SCHEDULE_ID = "SET_TRIP_SCHEDULE_ID";
export const UPDATE_TRIP_DATA = "UPDATE_TRIP_DATA";
export const IS_TRIP_CITY_CREATED = "IS_TRIP_CITY_CREATED";
export const GET_ADDRESS_LOCATIONS = "GET_ADDRESS_LOCATIONS";
export const INSERT_TRIP_STOPS = "INSERT_TRIP_STOPS";
export const SET_MAP_DIRECTIONS = "SET_MAP_DIRECTIONS";
export const SET_MAP_CENTER = "SET_MAP_CENTER";
export const SET_SELECTED_CITIES = "SET_SELECTED_CITIES";
export const SET_SELECTED_STOPS = "SET_SELECTED_STOPS";
export const SET_ACTIVE_CITY = "SET_ACTIVE_CITY";
export const SET_TRIP_DATA = "SET_TRIP_DATA";
export const ON_CHANGE_SCHEDULE_FORM = "ON_CHANGE_SCHEDULE_FORM";
export const SET_ACTIVE_STOP = "SET_ACTIVE_STOP";
export const SET_REORDER_ACTIVE_STOPS ="SET_REORDER_ACTIVE_STOPS";
export const SET_ON_MARKER_DRAG_END = "SET_ON_MARKER_DRAG_END";
export const SET_CITIES_WITH_STOPS = "SET_CITIES_WITH_STOPS";
export const RESET_TRIP_CREATE = "RESET_TRIP_CREATE";
export const GET_CITY_DETAILS = "GET_CITY_DETAILS";
export const SET_CITY_DETAILS = "SET_CITY_DETAILS";

// Address actions

export const GET_STANDARD_STOPS = "GET_STANDARD_STOPS";
export const SET_STANDARD_STOPS = "SET_STANDARD_STOPS";

export const CREATE_ADDRESS_LOCATION = "CREATE_ADDRESS_LOCATION";
export const UPDATE_ADDRESS_LOCATION = "UPDATE_ADDRESS_LOCATION";
export const DELETE_ADDRESS_LOCATION = "DELETE_ADDRESS_LOCATION";

export const FETCH_REVENUE_REPORT = "FETCH_REVENUE_REPORT";
export const FETCH_REVENUE_FOR_WEEK_OTA_REPORT = "FETCH_REVENUE_FOR_WEEK_OTA_REPORT";
export const FETCH_TODAYS_TICKET_COUNT = "FETCH_TODAYS_TICKET_COUNT";

export const SET_REVENUE_REPORT = "SET_REVENUE_REPORT";

export const UPDATE_CITY_PHONE_NO= "UPDATE_CITY_PHONE_NO";