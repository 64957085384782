import { roundToWholeNumber } from "../../utils/math.utils";
import { isValid,isValidArray } from "../../utils/utilities";

export const getWayPointsInVerifyPage=(tripCitiesWithStops)=>{
  let waypoints = [], origin = null , destination = null;
    let markerText = 0;
    tripCitiesWithStops.forEach(element => {
      let {
        stops
      } = element;
        stops.forEach((newElement)=>{
          markerText=markerText + 1;
          waypoints.push({
              location:{
                lat:newElement.latitude,
                lng:newElement.longitude,
                markerText:`${markerText}`,
                name:newElement.name,
                draggable:false
              },
              stopover:true
            });
        })
    });

    if(waypoints.length>0){
      origin = {
        lat:waypoints[0].location.lat,
        lng:waypoints[0].location.lng,
        markerText:`${1}`,
        name:waypoints[0].location.name,
        draggable:false
      };
      let finalIndex = waypoints.length - 1;
      destination = {
        lat:waypoints[finalIndex].location.lat,
        lng:waypoints[finalIndex].location.lng,
        markerText:waypoints[finalIndex].location.markerText,
        name:waypoints[finalIndex].location.name,
        draggable:false
      } 
      waypoints.shift(); // removes first element
      waypoints.pop(); // removes last element
    }
    return{
      wayPoints:waypoints,
      zoom:13,
      origin,
      destination
    }
}

export const getWayPointsInAddStops = (activeStops,cityCenter=null) =>{
  let waypoints = [], origin = null , destination = null , center = cityCenter , zoom = 10;
    if(isValidArray(activeStops)){
      let centerIndex = roundToWholeNumber(activeStops.length/2);
      if(isValidArray(activeStops)&&activeStops.length>0){
        activeStops.forEach((element,index)=>{
          if(isValidArray(element)&&isValid(element.latitude)){
              waypoints.push({
                location:{
                  lat:element.latitude,
                  lng:element.longitude,
                  markerText:`${index+1}`,
                  name:element.name,
                  draggable:element.draggable
                },
                stopover:true
              });
          }
          if(index === centerIndex - 1){
            center = {
              lat:element.latitude,
              lng:element.longitude
            }
          }
        });
      }
      if(waypoints.length>0){
        zoom=15;
        origin = {
          lat:waypoints[0].location.lat,
          lng:waypoints[0].location.lng,
          markerText:`${1}`,
          name:waypoints[0].location.name,
          draggable:waypoints[0].location.draggable
        };
        let finalIndex = waypoints.length - 1;
        if(finalIndex!=0){
          destination = {
            lat:waypoints[finalIndex].location.lat,
            lng:waypoints[finalIndex].location.lng,
            markerText:waypoints[finalIndex].location.markerText,
            name:waypoints[finalIndex].location.name,
            draggable:waypoints[finalIndex].location.draggable
          } 
        }
        waypoints.shift(); // removes first element
        waypoints.pop(); // removes last element
      }
    }
  return{
    wayPoints:waypoints,
    zoom:zoom,
    origin,
    destination,
    center
  }
}

export const getWayPointsCities=(selectedMasterCities)=>{
  let waypoints = [], origin = null , destination = null;

        if(isValidArray(selectedMasterCities)&&selectedMasterCities.length>0){
          selectedMasterCities.forEach((element,index) => {
            let finalIndex = selectedMasterCities.length -1;
            if(index === 0){
              origin = {
                lat:element.latitude,lng:element.longitude,
                markerText:`${index+1}`,
                name:selectedMasterCities[index].city_name
              }
            }else if(index === finalIndex){
              destination = {
                lat:element.latitude,lng:element.longitude,
                markerText:`${index+1}`,
                name:selectedMasterCities[index].city_name
              }
            }else{
              waypoints.push({
                location:{
                  lat:element.latitude,lng:element.longitude,
                  markerText:`${index+1}`,
                  name:selectedMasterCities[index].city_name,
                  draggable:false
                },
                stopover:true
              });
            }
          });
          return {
            wayPoints:waypoints,
            zoom:13,
            origin,
            destination
          };
        }else{
          return {
            wayPoints:waypoints,      
            zoom:13,
            origin,
            destination
          };
        }
  }

 export const getTripStops = (tripStops) =>{
    let selectedStops ={},exitingTripStopsAddressIds=[];
    if(isValidArray(tripStops)){
        tripStops.forEach(element => {
            if(!isValidArray(selectedStops[element.city_name])){
                selectedStops[element.city_name]={
                    stops:[]
                }
            }
            element.id = element.address_book_id;
            exitingTripStopsAddressIds.push(element.address_book_id);
            selectedStops[element.city_name].stops.push(element);
        });
    }
    return {selectedStops,exitingTripStopsAddressIds};
}