import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBusDetailsAction } from "../../actions/trip-create.actions";
import { tripCreateReducerSelector } from "../../selector/trip-create.selector";
import { Button, Modal, Select, message,Row,Col } from "antd";
import { getSeatLayOut } from "../../actions/admin.action";
import './change-seat-layout.style.scss';
import { SeatLayoutContainer } from "../seat-layout/seat-layout-container.component";
import { changeSeatLayoutReq } from "./change-seat-layout.module";
import { loadingAction } from "../../actions/loading-actions";
import { showConfirm } from "../custom-components/custom-component";

const {
    Option
} = Select;

const printBusesOption = (option) => {
    return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
};

export const ChangeSeatLayout = (props) => {

    const{
        tripId
    }=props;

    const [isModalVisible,setModalVisible] = useState(false);
    const [seatLayoutData,setSeatLayoutData] = useState([]);
    const [busId,setBusId] = useState(null);

    const dispatch = useDispatch();

    const handleOk=()=>{
       
    }

    const handleCancel=()=>{
        setModalVisible(false);
    }
    
    useEffect(()=>{
        dispatch(getBusDetailsAction());
    },[]);

    const setLoading=(val)=>{
        dispatch(loadingAction(val));
    }

    const tripCreateReducer = useSelector(tripCreateReducerSelector);
    let { busesData } = tripCreateReducer;

    const onSuccess=(data)=>{
        setSeatLayoutData(data.seat_layout);
    }

    const onFailure=()=>{

    }

    return(
        <div className="">
                {/* <div className="selectBusTxt">Select Bus</div> */}
                <Button 
                className="bordered-btn send-driver-details-btn"  
                onClick={()=>{
                    setModalVisible(true);
                }}
                >
                    Change Layout
                </Button>
                <Modal
                      title={"Change Seat Layout"}
                      visible={isModalVisible}
                      onCancel={handleCancel}
                      footer={null}
                >
                    <Select
                        showSearch
                            className="changeBusSelect marginBottom"
                            placeholder="Select bus"
                            onChange={(value)=>{
                                setBusId(value);
                                setSeatLayoutData([]);
                                getSeatLayOut(value,onSuccess,onFailure);
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                    >
                        {busesData.map(printBusesOption)}
                    </Select>
                    <SeatLayoutContainer
                        seatsData={seatLayoutData} 
                        showLegend={false}
                        showFarePopOver={false}
                        blockedSeatAllowed={false}
                        noOfSeatsAllowedToClick={0}
                    />
                    {seatLayoutData.length>0&&
                    <Row justify="center" align="middle" gutter={[16,16]}>
                        <Col>
                            <Button 
                            onClick={()=>{
                                showConfirm(()=>{
                                    setLoading(true);
                                    changeSeatLayoutReq(tripId,busId,()=>{
                                        setLoading(false);
                                        message.success("Seat Layout Changed succuessfully!")
                                    },(err)=>{
                                        message.error(err);
                                        setLoading(false);
                                    });
                                },"Are you sure to change seat layout?");
                            }}
                            >Change Layout</Button>
                        </Col>
                    </Row>
                    }
                </Modal>
        </div>
    )
}
