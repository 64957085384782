import React, { useEffect, useState, useRef } from "react";
import { Button , Table , message , Modal ,Row, Col, Input, Space} from "antd";
import { SearchOutlined } from '@ant-design/icons';
import '../styles/address-book.style.scss';
import { getAddressLocations } from "../actions/trip-create.actions";
import { useDispatch } from "react-redux";
import { CreateStandardStopsPage } from "./create-standard-stop.page";
import { updatePhoneNumbersOfAddressBook } from "../actions/admin.action";
import { loadingAction } from "../actions/loading-actions";
import { showConfirm } from "../components/custom-components/custom-component";
import { deleteAddressLocation } from "../actions/address.actions";

const DrawLine =()=>{

    return(
        <div className="address-book-underline">

        </div>
    )
}

const Title = () =>{
        return (
            <div className="title-address-book">
                <h3>
                    Address Book
                </h3>
                <DrawLine />
            </div>
        )
}

const getUniqueCities = (info)=>{
    let cities = [] , citiesInfo = [];
        for (let index = 0; index < info.length; index++) {
            const element = info[index];
            if(cities.indexOf(element.city) == -1){
                cities.push(element.city);
                citiesInfo.push({
                    text:element.city,
                    value:element.city
                  });
            }
        }
        return citiesInfo;
}

export const AddressBookPage = (props) => {
 
    const [addressBook,setAddressBook] = useState([]);
    const [modalVisiable,setModalVisiable] = useState(false);
    const [selectedRecord,setSelectedRecord] = useState(null);
    const [selectedIds,setSelectedIds] = useState([]);
    const [uniqueCities,setUniqueCities] = useState([]);

    const [phNoModalVisiable,setPhNoModalVisiable] = useState(false);
    const [phoneNumber,setPhoneNumber] = useState(null);
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
    };
    const handleReset = (clearFilters) => {
        clearFilters();
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div
            style={{
            padding: 8,
            }}
            onKeyDown={(e) => e.stopPropagation()}
        >
            <Input
                ref={searchInput}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                style={{
                    marginBottom: 8,
                    display: 'block',
                }}
            />
            <Space>
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{
                width: 90,
                }}
            >
                Search
            </Button>
            <Button
                onClick={() => clearFilters && handleReset(clearFilters)}
                size="small"
                style={{
                width: 90,
                }}
            >
                Reset
            </Button>
            </Space>
        </div>
        ),
        filterIcon: (filtered) => (
        <SearchOutlined
            style={{
            color: filtered ? '#1677ff' : undefined,
            }}
        />
        ),
        onFilter: (value, record) =>
        record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
        if (visible) {
            setTimeout(() => searchInput.current?.select(), 100);
        }
        },
        render: (text) =>
            text
        ,
    });


    const dispatch = useDispatch();

    const getLocations = ()=>{
        dispatch(getAddressLocations(null,false,(data)=>{
            setAddressBook(data.addressLocations);
            setUniqueCities(getUniqueCities(data.addressLocations));
        },(errMsg)=>{
            message.error(errMsg);
        }));
    }

    useEffect(()=>{
        getLocations();
    },[]);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedIds(selectedRowKeys);
        },
      };

      const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onDeleteClick=()=>{
        showConfirm(()=>{
            setLoading(true);
            deleteAddressLocation(selectedIds,()=>{
                setLoading(false);
                getLocations();
                message.success("Delete location successfully!")
            },(errMsg)=>{
                setLoading(false);
                message.error(errMsg);
            });
        },"Delete Location","Are you sure you want to delete this location?");
    }

    return (
        <div className="address-book-page-root">
            <div className="address-book-page-inner-div">
                <Title />
                <Row gutter={[16,16]}>
                    <Col>
                        <Button
                        onClick={()=>{
                            setModalVisiable(true);
                        }}
                        >Create new</Button>
                    </Col>
                    <Col>                    
                        <Button 
                            onClick={()=>{
                                setPhNoModalVisiable(true);
                            }}
                            disabled={selectedIds.length>0?false:true}
                        >Update Phone number</Button>
                    </Col>
                    <Col>
                        <Button
                            disabled={selectedIds.length>0?false:true}
                            onClick={()=>{
                                onDeleteClick();
                            }}
                        >Delete</Button>
                    </Col>
                </Row>
                <Table 
                    dataSource={addressBook}
                    columns={[
                        {
                            title: 'Name',
                            dataIndex: 'name',
                            key: 'name',
                            ...getColumnSearchProps('name'),
                        },
                        {
                            title: 'City Name',
                            dataIndex: 'city',
                            key: 'city',
                            filters:uniqueCities,
                            onFilter: (value, record) => record.city.indexOf(value) === 0
                        },
                        {
                            title: 'LANDMARK',
                            dataIndex: 'landmark',
                            key: 'landmark'
                        },
                        {
                            title: 'TYPE',
                            dataIndex: 'is_office',
                            key: 'is_office',
                            render:(text)=>{
                                if(text){
                                    return "Office";
                                }else{
                                    return "Location";
                                }
                            }
                        },
                        {
                            title: 'ADDRESS',
                            dataIndex: 'address',
                            key: 'address'
                        } ,
                        {
                            title: 'PHONE',
                            dataIndex: 'phone_number',
                            key: 'phone_number'
                        }   
                    ]}
                    onRow={(record, rowIndex) => {
                        return {
                          onClick: event => {
                            setSelectedRecord({...record});
                            setModalVisiable(true);
                          }, // click row
                          onDoubleClick: event => {
  
                          }, // double click row
                          onContextMenu: event => {
  
                          }, // right button click row
                          onMouseEnter: event => {
  
                          }, // mouse enter row
                          onMouseLeave: event => {
  
                          }, // mouse leave row
                        };
                      }}
                      rowSelection={rowSelection}
                      rowKey={(record) => {
                        return record.id;
                      }}
                      rowClassName="cursorPointer"
                />
                  <Modal
                            title={null} 
                            className="createNewStopModal"
                            modalTitle="Delay Notification"
                            visible={modalVisiable} 
                            width="95vw"
                            onOk={()=>{
                                setModalVisiable(false);
                            }} 
                            onCancel={()=>{
                                setModalVisiable(false);
                                setSelectedRecord(null);
                            }} 
                            centered={true}
                            footer={null}
                        >
                            <div>
                                <CreateStandardStopsPage
                                    fromModal={true} 
                                    isAddressBook={true}
                                    selectedCity={null} 
                                    onSuccessRefresh={(id)=>{
                                        setModalVisiable(false);
                                        setSelectedRecord(null);
                                        getLocations();
                                    }}
                                    operatorCode={global.operatorCode}
                                    onFailure={(errMsg)=>{
                                        message.error(errMsg);
                                    }}
                                    formDetailsFromprops={selectedRecord}
                                />
                            </div>
                </Modal>
                <Modal
                    title="Update Phone Number"
                    visible={phNoModalVisiable} 
                    onOk={()=>{
                        setLoading(true);
                        updatePhoneNumbersOfAddressBook(selectedIds,phoneNumber,(msg)=>{
                            setLoading(false);
                            message.success(msg);
                            getLocations();
                            setPhNoModalVisiable(false);
                        },(errMsg)=>{
                            setLoading(false);
                            message.error(errMsg);
                        });
                    }} 
                    onCancel={()=>{
                        setPhNoModalVisiable(false);
                    }} 
                    centered={true}
                    okText="Update"
                >
                    <div>
                        <Input 
                            placeholder="Phone Number"
                            value={phoneNumber}
                            onChange={(e)=>{
                                setPhoneNumber(e.target.value);
                            }}
                        />
                    </div>
                </Modal>
            </div>
        </div>
    );
}