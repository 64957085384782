import React from "react";
import './send-driver-details-modal.style.scss';
import {  Button, message } from 'antd';
import { showConfirm } from "../../custom-components/custom-component";
import { sendTrackingNotificationByTicketId, sendTrackingNotificationByTripId } from "./send-driver-details-modal.module";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { checkActionAllowed, isValid } from "../../../utils/utilities";
import { VIEW_ADMIN_TAB_IN_SEARCH_PAGE } from "../../../utils/constant";

const getVechicleDetails = (scheduleVechicleId,tripVechicleId)=>{

    let vechicleInfoExits = false;
    if(isValid(tripVechicleId)){
        vechicleInfoExits = true;
    }

    if(isValid(scheduleVechicleId)){
        vechicleInfoExits = true;
    }

    return vechicleInfoExits
}

export const SendDriverDetails = (props) => {
    const{
        scheduleVechicleId,
        ticketId,
        tripVechicleId,
        seatsAvailbility,
        tripId
    } =props;

    const dispatch = useDispatch();
    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    if(checkActionAllowed(VIEW_ADMIN_TAB_IN_SEARCH_PAGE)){
        return (
            <div>
                <Button
                    className="bordered-btn send-driver-details-btn"  
                    onClick={()=>{
                        if(getVechicleDetails(scheduleVechicleId,tripVechicleId)){
                            if(isValid(tripId)){
                                showConfirm(()=>{
                                    setLoading(true);
                                    sendTrackingNotificationByTripId(tripId,()=>{
                                        message.success("Driver details sms sent successfully!");
                                        setLoading(false);
                                    },(msg)=>{
                                        setLoading(false);
                                        message.error(msg);
                                    })
                                },`Resend tracking info`,
                                `Are you sure to resend tracking info to all ${seatsAvailbility.booked} customers.`
                                );
                            }else{
                                showConfirm(()=>{
                                    setLoading(true);
                                    sendTrackingNotificationByTicketId(ticketId,()=>{
                                        message.success("Driver details sms sent successfully!");
                                        setLoading(false);
                                    },(msg)=>{
                                        setLoading(false);
                                        message.error(msg);
                                    })
                                },`Resend tracking info`,
                                `Are you sure to resend tracking info ?`
                                );
                            }
                        }else{
                            message.warning(`Driver phone number missing`);
                        } 
                    }}
                >
                    Send Driver Details
                </Button>
            </div>
        )
    }else{
        return null
    }
}
