import {Seat} from "./seat.component"
import React from 'react';
import { isValid, isValidArray } from "../../utils/utilities";

export const getSeatGrid=(seats,onSeatCLick,selectedSeats,showFarePopOver,dontAllowSelection)=>{
    let seatGrid = [[]];
    let lowerSeatGrid = [[]];
    let upperSeatGrid = [[]];
    let seattype=''
    let seatbooked=''
    let seatgender=''
    let className = ''
    let row_number = 0
    // let col_number = 0
    let col_number_array = []
    let available_seats = 0
    let available_windowSeats = 0
    let upperDeckSeatCount = 0
    let lowerDeckSeatCount = 0
    let maximumRowNumber = 0
    let maximumColumnNumber =0
    let lowestFare = Number.MAX_SAFE_INTEGER;
    let maximumRowNumberByColumnForLower = {};
    let maximumRowNumberByColumnForUpper = {}
   
   if(seats.length >0){
       seats.map((item,index)=>{
           seatGrid = item.upper_deck ? upperSeatGrid : lowerSeatGrid
           seatgender=''
           className=''
           seattype = item.sleeper === 1 ? "sleeper " : "seater "
           if(item.seat_status === 'Confirmed'){
            if(item.gender==="F"){
              seatbooked='ladies-booked '
            }else{
              seatbooked='booked '
            }
            }else if(item.seat_status === 'Blocked'){
                if(isValid(item.time_of_release)){
                    seatbooked="phone-blocked";
                }else{
                    seatbooked=item.blocked_by === global.userName?"self-blocked":"others-blocked";
                }
            }else{
               seatbooked=''
               if(item.upper_deck) {
                   upperDeckSeatCount++
               }else {
                   lowerDeckSeatCount++
               }
           }
           if(item.amount_for_travels < lowestFare){
               lowestFare = item.amount_for_travels;
           }
           if(item.window_seat === 1){
               available_windowSeats++
           }
           if(item.seat_status !== 'Confirmed' && item.seat_status !== 'Blocked') {
               if (item.ladies_seat === 1) {
                   seatgender = ' ladies'
               }
               if (item.gents_seat === 1) {
                   seatgender = ' gents'
               }
           }
           if (item.seat_row_number !== row_number) {
               row_number++
           }
           if(col_number_array.indexOf(item.seat_column_number) === -1) {
               col_number_array.push(item.seat_column_number)
           }

           maximumRowNumber = item.seat_row_number > maximumRowNumber ? item.seat_row_number : maximumRowNumber;
           if(item.upper_deck){
            if(isValid(maximumRowNumberByColumnForUpper[item.seat_column_number])){
                if(maximumRowNumberByColumnForUpper[item.seat_column_number]['maximumRowNumber']<item.seat_row_number){
                    maximumRowNumberByColumnForUpper[item.seat_column_number]={maximumRowNumber:item.seat_row_number}
                }
            }else{
                maximumRowNumberByColumnForUpper[item.seat_column_number]={maximumRowNumber:item.seat_row_number}
            }
           }else{
            if(isValid(maximumRowNumberByColumnForLower[item.seat_column_number])){
                if(maximumRowNumberByColumnForLower[item.seat_column_number]['maximumRowNumber']<item.seat_row_number){
                    maximumRowNumberByColumnForLower[item.seat_column_number]={maximumRowNumber:item.seat_row_number}
                }
            }else{
                maximumRowNumberByColumnForLower[item.seat_column_number]={maximumRowNumber:item.seat_row_number}
            }
           }
          

           maximumColumnNumber = item.seat_column_number > maximumColumnNumber ? item.seat_column_number : maximumColumnNumber;

           className = seattype+seatbooked+seatgender
           let isSelected = false;
           if(isValidArray(selectedSeats)){
            if(selectedSeats.length>0 && selectedSeats.indexOf(item.seat_number)!==-1){
                isSelected = true;
            }else if(selectedSeats[item.id]!== undefined){
                isSelected = true;
            }
           } 
           const seat = <Seat type={seattype} classname={className} object={item} 
            key={item.seat_number} 
            onSeatCLick={onSeatCLick}
            isSelected = {isSelected}
            showFarePopOver = {showFarePopOver} 
            dontAllowSelection={dontAllowSelection}
           />

               if (seatGrid[item.seat_row_number]) {
                   seatGrid[item.seat_row_number][item.seat_column_number] = seat
               } else {
                   while (seatGrid[item.seat_row_number] === undefined) {
                       seatGrid.push([]);
                   }
                   seatGrid[item.seat_row_number][item.seat_column_number] = seat
               }

    })
    //    col_number=col_number_array[col_number_array.length-1]
       available_seats = lowerDeckSeatCount+upperDeckSeatCount
   }
   return {
    seatGridLayout:{
        lowerSeatGrid:lowerSeatGrid,
        upperSeatGrid:upperSeatGrid,
        row_count:maximumRowNumber,
        col_count:maximumColumnNumber,
        available_seats:available_seats,
        available_windowSeats:available_windowSeats,
        lower_seatCount:lowerDeckSeatCount,
        upper_seatCount:upperDeckSeatCount,
        maximumRowNumberByColumnForLower:maximumRowNumberByColumnForLower,
        maximumRowNumberByColumnForUpper:maximumRowNumberByColumnForUpper
       },
       lowestFare
   }
}
