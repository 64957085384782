/**
 * @author Rejo Varghese
 * @description Ticket search v2
 */
import React, { useState } from "react";
import { Button, Input, Row, Col ,Table, Select, DatePicker, message, Tooltip } from 'antd';
import { fetchTicketSearchV2 } from '../../actions/reports.action';
import { isValid, getValuesOfJSonObject, isValidArrayWithValues, isValidString } from '../../utils/utilities';
import { loadingAction } from '../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { getUIDateFormat, getAPIDateFormat, getDateTimeFormat, getLocalDateAndTime} from '../../utils/date.utils';
// import { Printer } from '../../components/custom-components/custom-component';
import { getPaginationDetails, getDefaultPageSize, getRangeOptions } from './reports.module';
import { TicketSearchRowExpand } from "../../components/ticket-search-component/ticket-search-component";
import { fetchTicketDetailsByPNR } from "../../actions/ticket.action";


const formatPNRTicketData = (tktData) => {
    let travellers = tktData.traveller;
    let ticket = tktData.ticket, formattedData;
    travellers.map((traveller, ind) => {
        let isPartial = ticket.ticket_status!=traveller.traveller_status?true:false;
        let pnrTravellerData = {
            "pnr": ticket.pnr,
            "doj": tktData.trip.start_date,
            "origin": tktData.route.origin,
            "destination": tktData.route.destination,
            "full_name": traveller.full_name,
            "age": traveller.age,
            "gender": traveller.gender,
            "discounted_fare": traveller.discounted_fare,
            "fare": traveller.fare,
            "total_gst": traveller.total_gst,
            "net_commission": traveller.net_commission,
            "traveller_status": traveller.traveller_status,
            "cancellation_time": traveller.cancellation_time,
            "cancellation_penalty": traveller.cancellation_penalty,
            "phone_number": ticket.phone_number,
            "email_id": ticket.email_id,
            "seat": traveller.seat_number,
            "agent_name": tktData.agent.name,
            "booking_time": ticket.booking_time,
            "ticket_status": ticket.ticket_status,
            "amount_paid": ticket.amount_paid,
            "description": tktData.schedule.description,
            "order_no": isValid(tktData.transactionDetails.order_no)?tktData.transactionDetails.order_no:''
        }
        if (ind==0) {
            formattedData = {...pnrTravellerData};
            formattedData["doj"] = getUIDateFormat(formattedData["doj"]);
            formattedData["booking_time"] = getLocalDateAndTime(formattedData["booking_time"]);
            formattedData["travellerData"] = [{...pnrTravellerData}];
        }
        else {
            formattedData["travellerData"].push({...pnrTravellerData})
        }
        if (isPartial) {
            formattedData["isPartial"] = isPartial;
        }
    })
    return [formattedData];
}

const formatTicketData = (tickets) => {
    let ticketData = {}, transctionList = [], index = 0, orderList = {};
    tickets.map((ticket) => {
        let pnr = ticket.pnr;
        if(isValid(ticketData[pnr])) {
            let seat = ticketData[pnr]['seat'];
            seat+=","+ticket.seat_number
            ticketData[pnr]['seat'] = seat;
            ticketData[pnr]["travellerData"].push(ticket);
            let recordedStatus = ticketData[pnr]['ticket_status'];
            if (recordedStatus!=ticket.traveller_status) {
                ticketData[pnr]['isPartial'] = true;
            }
        }
        else {
            index++;
            let isPartial = ticket.ticket_status!=ticket.traveller_status?true:false;
            let formattedData = {
                "key": index,
                "pnr": ticket.pnr,
                "doj": getUIDateFormat(ticket.start_date),
                "origin": ticket.origin,
                "destination": ticket.destination,
                "full_name": ticket.full_name,
                "phone_number": ticket.phone_number,
                "seat": ticket.seat_number,
                "agent_name": ticket.agent_name,
                "booking_time": getLocalDateAndTime(ticket.booking_time),
                "ticket_status": ticket.ticket_status,
                "amount_paid": ticket.amount_paid,
                "travellerData": [ticket],
                "isPartial": isPartial
            }
            ticketData[pnr] = formattedData;
        }
    });
    return getValuesOfJSonObject(ticketData);
}

const RenderReport =(props)=>{

    const {
        tickets,
        pagination,
        setLoading,
        pnrFetchedData,
        setPnrFetchedData
    } = props;

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const assignStatusColor = (record) => {
        let statusColor;
        if (record.ticket_status== "Confirmed") {
            statusColor= "#2BB193";
        }
        else if (record.ticket_status == "Blocked") {
            statusColor = "#FFA000";
        }
        else {
            statusColor = "#ED716C";
        }
        return (
            <div>
                <Row>
                    <span style={{color: statusColor, fontWeight: 'bold'}}>{record.ticket_status}</span>
                </Row>
                {record.isPartial&&record.ticket_status== "Confirmed"&&
                    <Row>
                        <span style={{color: "#EA4025", fontWeight: 'bold', marginLeft: '2em'}}>P</span>
                    </Row>
                }
            </div>
        )
    }

    

    const columns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title: () => <Tooltip title="Date of Journey">DOJ</Tooltip>,
            key:'doj',
            dataIndex : 'doj'
        },
        {
            title:'Origin',
            key:'origin',
            dataIndex : 'origin'
        },
        {
            title:'Destination',
            key:'destination',
            dataIndex : 'destination'
        },
        {
            title:'Name',
            key:'full_name',
            dataIndex : 'full_name'
        },
        {
            title:'Phone',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Seats',
            key:'seat',
            dataIndex : 'seat'
        },
        {
            title:'Agent',
            key:'agent_name',
            dataIndex : 'agent_name'
        },
        {
            title: () => <Tooltip title="Boooking Date & Time">Book DT</Tooltip>,
            key:'booking_time',
            dataIndex : 'booking_time'
        },
        {
            title:'Status',
            key:'ticket_status',
            dataIndex : 'ticket_status',
            render : (text, record, index) => {
                return assignStatusColor(record);
            }
        },
        {
            title: () => <Tooltip title="Amount Paid">Amount</Tooltip>,
            key:'amount_paid',
            dataIndex : 'amount_paid'
        }
    ];

    return(
        <div className = "ticket-search-table">
                <Table
                    dataSource={tickets}
                    columns={columns}
                    bordered= {false}
                    expandable={{
                        expandedRowRender: (record) => {
                            return <div className="expand-ticket-search"><TicketSearchRowExpand
                                        TicketData = {record}
                                        pnrFetchedData = {pnrFetchedData}
                                        setPnrFetchedData = {setPnrFetchedData}
                                        selectedPNR = {record.pnr}
                                        assignStatusColor = {assignStatusColor}
                                        setLoading = {setLoading}
                                /></div>
                        }
                    }}
                    rowClassName="ticket-search-table-row"
                    expandRowByClick
                    pagination={getPaginationDetails(pagination, setPage, paginationSize, setPaginationSize)}
                    className="bordered-table"
                />
        </div>
    )
}

export const TicketSearchReportV2 = () => {

    const { RangePicker } = DatePicker;
    let allowedMaxDaysRange=31;

    const [tickets,setTickets]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    const [selectedTicketValue, setSelectedTicketValue] = useState('Mobile_Number');
    const [selectedDateValue, setSelectedDateValue] = useState('travel_date');
    const [dateInput, setDateInput] = useState(null);
    const [ticketInput, setTicketInput] = useState('');
    const [pnrFetchedData, setPnrFetchedData] = useState({});

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        setShowReport(true);
        setLoading(false);

        setTickets(formatTicketData(data));
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const handleTicketChange = (value) => {
        setSelectedTicketValue(value);
    };

    const handleDateChange = (value) => {
        setSelectedDateValue(value);
    };

    const handleSubmit = () => {
        if(isValidString(ticketInput) || isValidArrayWithValues(dateInput)) {
            if (!isValidString(ticketInput)&&isValidArrayWithValues(dateInput)&&dateInput[1].diff(dateInput[0],"days")>=allowedMaxDaysRange) {
                message.error(`Please select a date range with in ${allowedMaxDaysRange} days`);
            }
            else {
                if (['Mobile_Number', 'email_id', 'Passenger_name'].includes(selectedTicketValue)&&!isValidArrayWithValues(dateInput)) {
                    message.error("Please select date range to complete the search");
                }
                else {
                    setLoading(true);
                    setShowReport(false);
                    let isBooking = selectedDateValue=='booking_date'?true:false;
                    let dateRange = {}
                    dateRange['fromDate'] = isValidArrayWithValues(dateInput)?getAPIDateFormat(dateInput[0]): null;
                    dateRange['toDate'] = isValidArrayWithValues(dateInput)?getAPIDateFormat(dateInput[1]): null;
                    setPnrFetchedData({});
                    if (selectedTicketValue == "PNR") {
                        fetchTicketDetailsByPNR(ticketInput, (tktData) => {
                            setShowReport(true);
                            setLoading(false);
                            setTickets(formatPNRTicketData(tktData));
                            let tmpPnrData = pnrFetchedData;
                            let formattedTicketLogs = {}
                            formattedTicketLogs["travellerLogs"] = tktData["logs"]["travellerLogs"];
                            tmpPnrData[ticketInput] = tktData;
                            tmpPnrData[ticketInput]['pnrLogs'] = formattedTicketLogs;
                            setPnrFetchedData(tmpPnrData)
                        }, ()=> {
                            setLoading(false);
                        })
                    }
                    else {
                        fetchTicketSearchV2(selectedTicketValue, ticketInput,isBooking, dateRange, onSuccess,onFailure);
                    }
                }
            }
        }
        else {
            message.error("Please enter a valid input");
        }
    }

    const selectTicketOptions = [
        {
            value: 'Mobile_Number',
            label: 'Phone',
        },
        {
            value: 'PNR',
            label: 'PNR',
        },
        {
            value: 'email_id',
            label: 'Email id',
        },
        {
            value: 'Passenger_name',
            label: 'Passenger Name',
        },
        {
            value:"order_no",
            label:"Transaction ID"
        }
    ]

    const selectDateOptions = [
        {
            value: 'travel_date',
            label: 'Travel Date',
        },
        {
            value: 'booking_date',
            label: 'Booking Date',
        }
    ]

    return (
            <div className="ticket-search-inner-div">
                <div className = "ticket-search-select">
                    <Row justify="space-around" align="middle" gutter={[16,16]}>
                    <Input.Group compact >
                        <Col>
                        <Select
                            defaultValue={selectedDateValue}
                            style={{
                                width: 150,
                                height: 40,
                            }}
                            onChange={handleDateChange}
                            bordered= {true}
                            options={selectDateOptions}
                        />
                        <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)}
                                        onChange = {setDateInput}
                                        size ={"large"}
                                    />
                        </Col>
                        <Col>
                        <Select
                            defaultValue={selectedTicketValue}
                            style={{
                                width: 160,
                                height: 40,
                            }}
                            onChange={handleTicketChange}
                            bordered= {true}
                            options={selectTicketOptions}
                        />
                        <Input
                            style={{width: 200,height: 40}}
                            onChange={(e) => {setTicketInput(e.target.value)}}
                        />
                        </Col>
                        <Col>
                        <Button onClick={()=>{
                            handleSubmit();
                            }}
                        >Search</Button>
                        </Col>
                    </Input.Group>
                    </Row>
                </div>
                {showReport&&
                    <div>
                        {/* <Row gutter={[16,16]}>
                            <Col>
                                <Printer 
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                            <RenderReport 
                                                tickets={tickets}
                                                pagination={false}
                                            />
                                        )
                                    }} />
                            </Col>
                        </Row> */}
                        <Row className="marginTop" gutter={[16,16]}>
                            <Col>
                                <RenderReport 
                                    tickets={tickets}
                                    pagination={true}
                                    setLoading = {setLoading}
                                    pnrFetchedData = {pnrFetchedData}
                                    setPnrFetchedData = {setPnrFetchedData}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
    )
}