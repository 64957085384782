import React, {useState , useEffect, useContext , useRef} from "react";
import '../components/trip-create/trip-create.style.scss';
import { TripCreateRoute } from "../components/trip-create/trip-create-root";
import { useDispatch , useSelector} from 'react-redux';
import { tripCreateReducerSelector } from "../selector/trip-create.selector";
import { fetchMaterCitiesAction, getBusDetailsAction, resetAddressFromCache, setOnMarkerDragEnd, setSelectedCities } from "../actions/trip-create.actions";
import { isValidArray } from "../utils/utilities";
import { TripBottomRibbon } from "../components/trip-create/trip-bottom-ribbon";
import {VerifyTrip} from './../components/trip-create/verify-trip/verify-trip.component';
import { message , Steps as WebSteps } from "antd";
import { Steps as MobileSteps} from 'antd-mobile';
import MapComponent from "../components/map-component/map.component";
import { DeviceContext } from "../components/device-provider/device-provider";
import { setMapDirections } from "../actions/map-directions.action";
import { getWayPointsCities } from './../components/trip-create/trip-create.module'; 



export const TripCreatePage = (props) => {

  const [addStop, setAddStop] = useState(false);
  const [showRibbon, setShowRibbon] = useState(false);
  const [showVerifyPage, setShowVerifyPage] = useState(false);
  const [stepDataVal, setStepDataVal] = useState({})
  const [headerStep, setHeaderStep] = useState(0);
  const [selectedSeatNumbers, setSelectedSeatNumbers] = useState([]);
  const [showSelectCity, setShowSelectCity] = useState(false);
  const [distance, setDistance] = useState(0);
  const [duration, setDuration] = useState(0);
  const [enableLocationPick, setEnableLocationPick] = useState(false);
  const childRef = useRef();

  let customClassName = '';

  if(enableLocationPick){
    customClassName = 'disabledDiv';
  }else{
    customClassName = '';
  }

  const {
    isMobile
  } = useContext(DeviceContext);

  const dispatch = useDispatch();
  const tripCreateReducer = useSelector(tripCreateReducerSelector);

  let {masterCities, busesData, isTripCityCreated, tripScheduleId, selectedCities } = tripCreateReducer

  useEffect(()=>{
    dispatch(fetchMaterCitiesAction());
    dispatch(getBusDetailsAction());
  },[]);

  const resetAllData=()=>{
    setSelectedMasterCities([]);
    setAddStop(false);
    setShowRibbon(false);
    setShowVerifyPage(false);
    setStepDataVal({});
    setHeaderStep(0);
    setSelectedSeatNumbers([]);
    setShowSelectCity(false);
    setDistance(0);
    setDuration(0);
  }

  useEffect (() => {
    if (isTripCityCreated){
      setShowRibbon(true);
    }
  },[isTripCityCreated]);

  const resetPages = () => {
    setAddStop(false);
    setShowVerifyPage(false);
    setShowSelectCity(false);
  }


  const onCallBackSetDirections=(data)=>{
    setSelectedMasterCities(data);
    let newOptions = getWayPointsCities(data);
    dispatch(setMapDirections(newOptions,false));
  }

  const setSelectedMasterCities=(data)=>{
    dispatch(setSelectedCities(data));
  }

  const changeSteps = (value) => {
    resetPages();
    let isHeaderStep = false;
    switch(value){
      case 0:{
        isHeaderStep = true;
        setStepDataVal({'headerFormPos': 0})
        onCallBackSetDirections(selectedCities);
        break;
      }
      case 1: {
        isHeaderStep = true;
        setStepDataVal({'headerFormPos': 1});
        onCallBackSetDirections(selectedCities);
        break;
      }
      case 2: {
        if (isTripCityCreated){
          isHeaderStep = true;
          setAddStop(true);
        }
        else{
          isHeaderStep = false;
          message.error("Cannot add stops before creating selecting cities")
        }
        break;
      }
      case 3: {
        if (isTripCityCreated){
          isHeaderStep = true;
          setShowVerifyPage(true);
        }
        else{
          isHeaderStep = false;
          message.error("Cannot verify trips before creating adding stops")
        }
        break;
      }
      default: {
        isHeaderStep = false;
      }
    }
    if (isHeaderStep) {
      setHeaderStep(value)
    }
  }

  const RenderSteps = ({customClassName}) => {
    if(isMobile){
      return (
        <MobileSteps 
        current={headerStep} 
        size="small" 
        labelPlacement= "vertical" 
        onChange = {changeSteps} 
        className={`${customClassName}`}>
          <MobileSteps.Step title= "Select Bus"/>
          <MobileSteps.Step title= "Select Cities"/>
          <MobileSteps.Step title= "Add Stops"/>
          <MobileSteps.Step title= "Verify"/>
        </MobileSteps>
      )
    }else{
      return (
        <WebSteps 
        className={`${customClassName}`}
        current={headerStep} size="small" labelPlacement= "vertical" onChange = {changeSteps}>
          <WebSteps.Step title= "Select Bus"/>
          <WebSteps.Step title= "Select Cities"/>
          <WebSteps.Step title= "Add Stops"/>
          <WebSteps.Step title= "Verify"/>
        </WebSteps>
      )
    }
}

    return (
        <div className={`trip-create-root`}>
            <div className="trip-create-inner-div">
               <RenderSteps customClassName={customClassName} />
               <div className= "trip-root-form">
                {!showVerifyPage?
                  <div className="">
                    {isValidArray(masterCities)&&
                      <TripCreateRoute
                          onCallBackSetDirections={onCallBackSetDirections}
                          masterCities= {masterCities}
                          busesData = {busesData}
                          isTripCityCreated = {isTripCityCreated}
                          addStop = {addStop}
                          tripScheduleId = {tripScheduleId}
                          setShowVerifyPage = {setShowVerifyPage}
                          setHeaderStep = {setHeaderStep}
                          stepDataVal = {stepDataVal}
                          selectedSeatNumbers={selectedSeatNumbers}
                          setSelectedSeatNumbers={setSelectedSeatNumbers}
                          setShowSelectCity = {setShowSelectCity}
                          showSelectCity = {showSelectCity}
                          setDistance={setDistance}
                          setDuration={setDuration}
                          addStopComponent={<TripBottomRibbon
                                  distance = {distance}
                                  time = {duration}
                                  setAddStop = {setAddStop}
                                  setShowRibbon = {setShowRibbon}
                                  setHeaderStep = {setHeaderStep}
                                  showRibbon= {showRibbon}
                                />
                          }
                          setEnableLocationPick={setEnableLocationPick}
                          customClassName={customClassName}
                          childRef={childRef}
                        />
                    }
                  </div>:
                  <div  className="side-verify-nav">
                    <VerifyTrip 
                      resetAllData={resetAllData}
                      scheduleId={tripScheduleId}
                    />
                  </div>
                  }
                  <div className= "google-maps-root" id="google-maps-size">
                      <MapComponent 
                        onMarkerDragEnd={(lat,lng,index)=>{
                          dispatch(setOnMarkerDragEnd(lat,lng,index));
                        }}
                        isStopsPage = {addStop}
                        enableLocationPick = {enableLocationPick}
                        setEnableLocationPick = {setEnableLocationPick}
                        onClickMap={(lat,lng)=>{
                          childRef.current(lat,lng);
                        }}
                        onCancel={()=>{
                          dispatch(resetAddressFromCache())
                        }}
                      />
                  </div>
                </div>
            </div>
        </div>
    )
}