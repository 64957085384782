import React from 'react'
import { StyleSheet, Link , Text} from '@react-pdf/renderer'
import { config } from './config'
import SubTextBold from './SubTextBold';

const styles = StyleSheet.create({
  subtext: {
    fontFamily: 'Lato',
    color: config.grayColor,
    fontSize: config.normalFontSize + 1,
    fontWeight:'bold'
  }
})

const MobileCall = ({ style , txt }) => (
  <Link href={`tel:+91-${txt}`} style={[styles.subtext, style]}>
    <Text style={[styles.subtext, style]} >{txt}</Text>
  </Link>
)

export default MobileCall
