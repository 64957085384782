import React , { useState } from "react";
import './../../styles/report.style.scss';
import { SearchScheduleComponent } from './../../components/search-components/search-schedule-component/search-schedule.component';
import { Form , Button , Row , Col , Typography , Table } from 'antd';
import { isValid, isValidArray } from "./../../utils/utilities";
import { ScheduleSelect} from './../../components/search-components/schedule-select/schedule-select.component';
import { 
    getLocalDate,
    convertDateStringToDate,
    getLocalTime,
    convertTimeStringToDate,
    getLocalDateAndTime
} from './../../utils/date.utils';
import { fetchMasterTripSheet } from './../../actions/reports.action';
import { useDispatch } from "react-redux";
import { loadingAction } from './../../actions/loading-actions';
import { Printer } from './../../components/custom-components/custom-component';

//Syntax trip sheet report.
// let {
//     agentsTripSheet, as array
//     totalTripSheet, as json
// }=data;

const { 
    Text
} = Typography;

let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const agentTripSheetColumns=[
    {
        title : 'Agent Name',
        key : 'agent_name',
        dataIndex : 'agent_name',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{text}</Text>;
            }else{
                return text;
            }
        }
    },
    {
        title : 'Total seats',
        key : 'totalConfirmedTickets',
        dataIndex : 'totalConfirmedTickets',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title : 'Total ticket cost',
        key : 'totalConfirmedTicketCost',
        dataIndex : 'totalConfirmedTicketCost',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }    
    },
    {
        title : 'Commission',
        key : 'totalCommission',
        dataIndex : 'totalCommission',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title : 'Commission tds',
        key : 'totalTDS',
        dataIndex : 'totalTDS',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title : 'Net commission',
        key : 'totalNetCommission',
        dataIndex : 'totalNetCommission',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title:'Cancelled seats',
        key : 'totalCancelledTickets',
        dataIndex : 'totalCancelledTickets',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title:'Cancelled penality',
        key : 'totalCancellationPenalty',
        dataIndex : 'totalCancellationPenalty',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    },
    {
        title:'Net cash',
        key : 'netAmount',
        dataIndex : 'netAmount',
        render : (text,record,index) =>{
            if(record.agent_name === "Total"){
                return <Text strong>{isValid(text)?text:0}</Text>;
            }else{
                return isValid(text)?text:0;
            }
        }
    }
];

const getSchedule=(schedules,tripId)=>{
    let selectedSchedule=null;
    if(isValidArray(schedules)&&schedules.length>0){
        schedules.forEach(element => {
            if(element.trip_id===tripId){
                selectedSchedule=element;
            }
        });
    }
    return selectedSchedule
}

const RenderReport=({selectedSchedule,agentsTripSheet})=>{
    if(isValidArray(selectedSchedule)&&isValid(selectedSchedule.id)&&isValidArray(agentsTripSheet)){

        return <Printer 
        showComponent={true}
        zoomValue={1}
        renderComponent={()=>(
            <div>
            <Row gutter={[16,0]} justify="center">
                <Col>
                    <Text strong>
                    Master Trip Sheet - {global.userName}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Trip Name : </Text>{selectedSchedule.description}
                    </Text> 
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Total Tickets: </Text>{1}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From : </Text>{selectedSchedule.origin}
                    </Text> 
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Travel Date : </Text>{getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split('T')[0]))}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To : </Text>{selectedSchedule.destination}
                    </Text> 
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Dep Time : </Text>{getLocalTime(convertTimeStringToDate(selectedSchedule.start_time))}
                    </Text> 
                </Col>
            </Row>
            {/* <Row gutter={[16,0]} justify="space-between">
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Vehicle Number : </Text>{vechicleInfo.vechicle_no}
                    </Text> 
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Driver phone number : </Text>{vechicleInfo.phone_no}
                    </Text> 
                </Col>
            </Row> */}
            <Row gutter={[16,0]}>
                <Col>
                    <Text>
                        <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text> 
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table
                        className="bordered-table"
                        columns ={agentTripSheetColumns}
                        dataSource ={agentsTripSheet}
                        pagination={false}
                    />
                </Col>
            </Row>
        </div>
        )}
    />
    }else{
        return null
    }
  
}

export const MasterTripSheetReport = () => {

    const [schedules,setSchedules]=useState(undefined);
    const [selectedSchedule,setSelectedSchedule]=useState(undefined);
    const [agentsTripSheet,setAgentsTripSheet]=useState(undefined);

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        setLoading(false);
        setAgentsTripSheet(data.agentsTripSheet);
    }

    const onSearch=(data)=>{
        form.setFieldsValue({'returnId':null});
        setSelectedSchedule(null);
        setAgentsTripSheet(null);
        setSchedules(data);
    }

    return (
            <div className="report-inner-div">
                <SearchScheduleComponent 
                    onSearch={onSearch}
                />
                {isValidArray(schedules)&&schedules.length>0&&
                 <Form 
                 form={form}
                 onFinish={(data)=>{
                     let {
                        returnId
                     }=data;
                     setLoading(true);
                     setAgentsTripSheet(undefined);
                     setSelectedSchedule(getSchedule(schedules,returnId));
                     fetchMasterTripSheet(returnId,onSuccess);
                 }}
                 >
                    <Row gutter={16}>
                        <Col>
                            <ScheduleSelect 
                               schedules={schedules}
                               optionKey="trip_id"
                            />
                        </Col>
                        <Col>
                            <Form.Item>
                                    <Button type="primary" htmlType="submit">Get</Button>
                            </Form.Item>  
                        </Col> 
                    </Row>
                 </Form>
                }
               <RenderReport 
               selectedSchedule={selectedSchedule}
               agentsTripSheet={agentsTripSheet}
               />
            </div>
    )
}