import React, {useState, useEffect, Fragment} from "react";
import './../trip-create.style.scss';
import {CitySelect} from '../city-select/city-select'
import { getArrayfromString, isValid, isValidArray, isValidNumber ,arrayToString, getCloneArray} from "../../../utils/utilities";
import { useDispatch, useSelector } from 'react-redux';
import { createTripAction , updateTripAction, setTripData} from "../../../actions/trip-create.actions";
import { Form ,TimePicker, Row, Button, Input, Select, message , Col , InputNumber, Divider} from 'antd';
import moment from 'moment';
import { ShowCity } from "./show-city";
import { EditBlockedSeats } from "../edit-block-seats";
import { getDistanceDataBetweenCities, getScheduleTripCityAction } from './../../../actions/admin.action';
import { addMin, getMinsFromSecs ,getMinsBetweenTwoTimes, isMomentDate } from "../../../utils/date.utils";
import { loadingAction } from './../../../actions/loading-actions';
import { convertMetersToKm } from "../../../utils/math.utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { scheduleSelector, selectedCitiesSelector } from "../../../selector/trip-create.selector";
import { userPartnerSelectors } from "../../../selector/user.selector";

const getDayAndTime = (startTime,duration)=>{
    let mins = getMinsFromSecs(duration);
    var endTime = addMin(startTime,mins).format("HH:mm:ss");
    let time1 = startTime.format("HH:mm:ss");
    var minsFromStartDay = getMinsBetweenTwoTimes('00:00:00',time1);
    let secsFromStartDay = minsFromStartDay*60;
    let totalSecs = duration+secsFromStartDay;
    let day = Math.floor(totalSecs/86400);
    return{
      endTime,
      day
    }
  }


const isFareAvailable = (values,busSelected)=>{
    let fareAvailable = false ; 
    if(isValid(values['fare'])){
        fareAvailable = true;
    }else{
        if(isValidArray(busSelected)){
            let seatGroupIds=getArrayfromString(busSelected.seat_group_ids,',');
            seatGroupIds.forEach(element => {
              if(isValid(values[element])){
                fareAvailable = true;
              }
            });
        }
    }
    return fareAvailable;
}

const getFareJson=(busSelected,values)=>{
    let fare = values['fare'];
    if(isValidArray(busSelected)){
        let fareJson = {} , lowestFare = null;
        let seatGroupIds=getArrayfromString(busSelected.seat_group_ids,',');
        seatGroupIds.forEach(element => {
            fareJson[element]=values[element];
            if(!isValid(lowestFare) || lowestFare>values[element]){
                lowestFare = values[element];
            }
        });
        if(!isValid(fare)){
            fare = lowestFare;
        }
        return {
            fareJson : JSON.stringify(fareJson),
            fare : fare
        };
    }else{
        return {
            fareJson : null,
            fare,
        };
    }

}

const renderPossibleFares=(busSelected)=>{
    if(isValidArray(busSelected)){
        let fareComponents=[];
        let isSeatGroupIdsExits = isValidArray(busSelected)&&isValid(busSelected.seat_group_ids)?true:false;
        if(busSelected.supported_fare){
            fareComponents.push(
                        <Col>
                                <Form.Item
                                    // label="Fare"
                                    name="fare"
                                    tooltip="without gst"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <InputNumber
                                        placeholder="Fare"
                                        style={{width:'150px'}}         
                                    />                                
                                </Form.Item>
                        </Col>);
        }
        if(isSeatGroupIdsExits){
          let seatGroupIds=getArrayfromString(busSelected.seat_group_ids,',');
          seatGroupIds.forEach(element => {
            fareComponents.push(<Col>
                                  <Form.Item
                                    //   label={element}
                                      name={element}
                                      tooltip="without gst"
                                      rules={[{ required: true, message: '' }]}
                                      >
                                        <InputNumber
                                              placeholder={element}
                                              disabled={isValid(busSelected)?false:true}        
                                              style={{width:'150px'}}         
                                        />                                
                                  </Form.Item>
                                </Col>);
          });
        }
        return (
              <Row gutter={[16,16]}>
                {fareComponents}
              </Row>
              );   
    }else{
        return null;
    }
  }


export const CreateTripForm = (props) => {
    const {
        masterCities,
        busesData,
        selectedSeatNumbers, 
        setSelectedSeatNumbers,
        setHeaderStep,
        headerFormPos,
        setShowSelectCity,
        showSelectCity,
        setDistance,
        setDuration,
        addStopComponent,
        onCallBackSetDirections
    }= props;

    const { Option } = Select;


    const [tripCityHeader, setTripCityHeader] = useState("Create Trip");
    const [busSelected,setBusSelected] = useState(null);

    const [partnerSelected,setSelectedPartner] = useState(null);
    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const selectedCities = useSelector(selectedCitiesSelector);

    const partnerDetails = useSelector(userPartnerSelectors);
    
    const updateSelectedCity=(cityIndex,info)=>{
        let newArray = getCloneArray(selectedCities);
        if(isValid(info)){
            if(isValid(newArray[cityIndex])){
                newArray[cityIndex] = info.data;
            }else{
                newArray.push(info.data);
            }
        }else{
            newArray.splice(cityIndex,1);
        }
        onCallBackSetDirections([...newArray]);
    }

    const multicities = () => {
        var citiesDiv = [];   
        let cityNum = 2;
        if(cityNum<=selectedCities.length){
            cityNum =  selectedCities.length + 1;    
        }

        for (let index = 0; index < cityNum; index++) {
            let disable;
            let defaultValue = null;
            if (index==0){
                disable= false;
            }
            else {
                disable = selectedCities.length==0?true:false;
            }
            if (isValid(selectedCities[index])){
                defaultValue = selectedCities[index].city_name;
            }
            citiesDiv.push(
                <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index} type="TASK">
                {(provided,snapshot) => (
                    <div
                    className="marginBottom paddingRight"
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    >
                        <CitySelect
                            masterCities= {masterCities}
                            cityIndex={index}
                            updateSelectedCity= {updateSelectedCity}
                            disabled= {disable}
                            defaultCity = {defaultValue}
                            selectedCities = {selectedCities}
                        />
                    </div>  
                )}
                </Draggable>
            )
        }
        return citiesDiv;
    }

    const printBusesOption = (option) => {
        return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
    };

    const printPartnerOption = (option) => {
        return <Option  value={option.id} data={option} key={option.partner_code}>{option.partner_name}</Option>
    };

    const updateStartTime = (time) => {
        form.setFieldsValue({
            start_time:time
        });
    }

    const [form] = Form.useForm();
    const [current, updateCurrent] = useState(0);
    let endReached = current == 1 ? true : false;
    const [formeDetails, updateForm] = useState({});
    const schedule = useSelector(scheduleSelector);

    useEffect(() => {
        if (isValidArray(schedule)) {
            let intialFareJson = {};
            if(isValid(schedule.fareJson)){
                intialFareJson = JSON.parse(schedule.fareJson);
            }
            let startTime = null
            if(isValid(schedule.startTime)){
                startTime = moment(schedule.startTime, timeFormat);
            }
            let formObject = {
                start_time: startTime,
                bus_id: schedule.busId,
                trip_identifier: schedule.name,
                fare: schedule.fare,
                ...intialFareJson
            };
            updateForm(formObject);
            form.setFieldsValue(formObject);
            let seatNos = getArrayfromString(schedule.blockedSeatNumbers);
            setSelectedSeatNumbers(seatNos);
            setBusSelected(schedule.busSelected);
        }else{
            form.resetFields();
            updateForm({});
        }
    }, [schedule]);

    useEffect(() => {
        if (isValidNumber(headerFormPos)){
            updateCurrent(headerFormPos)
        }
    },[headerFormPos]);

    useEffect(() => {
        setHeaderStep(current);
    },[current]);

    const onSuccessTripCity = (data) =>{
        if(data.tripCities.length>0){
            dispatch(setTripData(data));
        }
    }

    const onSuccess=(distanceInMeters,durationInSec)=>{
        setDistance(distanceInMeters);
        setDuration(durationInSec);
        let masterCityLength = selectedCities.length;
        let origin = selectedCities[0]['city_name'];
        let destination = selectedCities[masterCityLength-1]['city_name'];
        let startTime = formeDetails.start_time;
        if (!isMomentDate(startTime)) {
            startTime = moment(startTime, timeFormat);
        }
        let {endTime,day} = getDayAndTime(startTime,durationInSec);
        
        let {
            fareJson,
            fare
        } = getFareJson(busSelected,formeDetails);

        let blockedSeatNum = arrayToString(selectedSeatNumbers);
        formeDetails.start_time = startTime.format("HH:mm:ss");
        formeDetails.end_time = endTime;
        formeDetails.selectedCities = selectedCities;
        formeDetails['description'] = origin + " " + destination + " " + startTime.format("hh:mm A") +" " + busSelected.description + " " + formeDetails.trip_identifier;
        formeDetails['origin'] = origin;
        formeDetails['origin_latitude'] = selectedCities[0].latitude;
        formeDetails['origin_longitude'] = selectedCities[0].longitude;
        formeDetails['destination'] = destination;
        formeDetails['destination_latitude'] = selectedCities[masterCityLength-1].latitude;
        formeDetails['destination_longitude'] = selectedCities[masterCityLength-1].longitude;
        formeDetails['destination_day'] = day;
        formeDetails['distance'] = convertMetersToKm(distanceInMeters);
        formeDetails['travels_id'] = global.travelsId;
        formeDetails['operator_name'] = global.operatorName;
        formeDetails['bus_id'] = busSelected.id;
        if(isValidArray(partnerSelected)){
            formeDetails['partner_code'] = partnerSelected.partner_code;
        }
        formeDetails.fare_json = fareJson;
        formeDetails.fare = fare;
        formeDetails.blocked_seat_numbers = isValid(blockedSeatNum)?blockedSeatNum:'';
        let scheduleId = schedule.id;
        if (isValid(scheduleId)){
            dispatch(updateTripAction(scheduleId,formeDetails,()=>{
                setLoading(false);
                setShowSelectCity(true);
            },()=>{
                setLoading(false);
            }));
        }
        else {
            dispatch(createTripAction(formeDetails,(newScheduleId)=>{
                setLoading(false);
                setShowSelectCity(true);
                if (isValid(newScheduleId)) {
                    getScheduleTripCityAction(newScheduleId,onSuccessTripCity,()=> {})
                }
            },()=>{
                setLoading(false);
            }));
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        form.validateFields()
            .then((values) => {
                updateForm(Object.assign(formeDetails, values));
                if (endReached) {
                    let masterCityLength = selectedCities.length;
                    if (masterCityLength>=2){
                        let origin = selectedCities[0]['city_name'];
                        let destination = selectedCities[masterCityLength-1]['city_name'];
                        var cityList = [];
                        selectedCities.map((city) => {
                            cityList.push(city.city_name);
                        })
                        setLoading(true);
                        getDistanceDataBetweenCities(
                            cityList,
                            onSuccess,
                            ()=>{
                                message.error(`No route found between ${origin} and ${destination}.`);
                                setLoading(false);
                            });
                    }
                    else {
                        message.error("Please enter minimum of 2 cities")
                    }
                }
                else{
                    let fareAvailable = isFareAvailable(values,busSelected);
                    if(fareAvailable){
                        updateCurrent(current + 1);
                    }else{
                        message.error("Fare unavailable. Enable default fare / add seat group ids for bus.")
                    }
                }
            });
    }

    const layout = {
        labelCol: { span: 8},
        wrapperCol: { span: 14}
    };

    const timeFormat = 'HH:mm';

    let isBusSelected = isValidArray(busSelected)&&isValid(busSelected.id)?true:false;

    const selectSeat =(seat)=>{
        let index = selectedSeatNumbers.indexOf(seat.seat_number);
        if(index!==-1){
            selectedSeatNumbers.splice(index, 1);
        }else{
            selectedSeatNumbers.push(seat.seat_number);
        }
        setSelectedSeatNumbers([...selectedSeatNumbers]);
    }

    const onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
          return;
        }
    
        const items = reorder(
          selectedCities,
          result.source.index,
          result.destination.index
        );
        onCallBackSetDirections([...items]); 
      }

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        let [removed] = result.splice(startIndex, 1);
        if(!isValid(removed)){
            removed = {};
        }
        result.splice(endIndex, 0, removed);
        return result;
      };

    return (
        <div className="create-trip-root">
                <div>
                    <h3>
                        {tripCityHeader}
                    </h3>
                </div>
                <div className="create-trip-underline"></div>
                {!showSelectCity?<Form form={form} name="basic" initialValues={{trip_identifier: ''}} {...layout}>
                    {current==0&& (
                        <Fragment>
                            <Form.Item label= "Start Time:" name = "start_time" rules={[{ required: true, message: "Please input Start Time!" }]}>
                                <TimePicker 
                                    className='remove-clear-icon'
                                    showSearch
                                    popupClassName={"time-picker-to-not-display-footer"}
                                    onChange={updateStartTime} 
                                    onSelect={updateStartTime}
                                    defaultOpenValue={moment('00:00', timeFormat)} 
                                    minuteStep={5}  format={'hh:mm a'} 
                                />
                            </Form.Item>
                            <Form.Item
                                label="Select bus"
                                name="bus_id"
                                rules={[{ required: true, message: 'Please input select bus!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Select bus"
                                    onChange={(value , componentData)=>{
                                        setBusSelected(componentData.props.data);
                                    }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {busesData.map(printBusesOption)}
                                </Select>
                            </Form.Item>
                            {isBusSelected&&
                                <EditBlockedSeats 
                                    busId={busSelected.id}
                                    blockedSeats={''}
                                    selectSeat={selectSeat}
                                    selectedSeatNumbers={selectedSeatNumbers}
                                    justShowSeatLayout={true}
                                />
                            }
                            <Form.Item label="Trip Identifier: " name="trip_identifier" 
                            rules={[{ required: false, message: "Please input your trip identifier!" }]}>
                                <Input />
                            </Form.Item>
                            {isValidArray(partnerDetails)&&partnerDetails.length>0&&
                                <Form.Item label="Partner Code: " name="partner_code" 
                                    rules={[{ required: false, message: "Please input your partner code!"}]}>
                                        <Select
                                            showSearch
                                            placeholder="Select partner"
                                            onChange={(value , componentData)=>{
                                                setSelectedPartner(componentData.props.data);
                                            }}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {partnerDetails.map(printPartnerOption)}
                                        </Select>
                                </Form.Item>
                            }
                            <div>
                                <h3>Default Fare</h3>
                            </div>
                            {renderPossibleFares(busSelected)}
                            {isBusSelected&&
                                <EditBlockedSeats 
                                    busId={busSelected.id}
                                    blockedSeats={''}
                                    selectSeat={selectSeat}
                                    selectedSeatNumbers={selectedSeatNumbers}
                                />
                            }
                        </Fragment>
                    )}
                    {endReached&&
                        <DragDropContext 
                            onDragEnd={onDragEnd}
                        >
                            <Droppable droppableId="droppable">
                                {(provided,snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    className={snapshot.isDragging?"":"draggable-root"}
                                    >
                                        {multicities()}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    }
                    <Divider className="trip-divider"/>
                    <Row justify="space-around">
                        {current !== 0 &&
                        <Button disabled={current == 0 ? true : false} type="primary" onClick={() => updateCurrent(current - 1)}>
                                PREV
                        </Button>
                        }
                        <Button
                            type="primary"
                            onClick={(e) => {
                                handleSubmit(e, "add");
                            }}
                        >
                            {endReached ? "Continue" : "Continue"}
                        </Button>
                    </Row>
                </Form>:
                isValid(selectedCities)&&<ShowCity
                    selectedCities = {selectedCities}
                    setShowSelectCity = {setShowSelectCity}
                    updateCurrentPosition = {updateCurrent}
                    setTripCityHeader = {setTripCityHeader}
                    addStopComponent={addStopComponent}
                />
                }
        </div>
    )
}