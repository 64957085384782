import { Table , Typography } from "antd";
import React, { useEffect, useState } from "react";
import { fetchCancelledTickets } from "../../actions/reports.action";
import { getLocalDateAndTime } from "../../utils/date.utils";
import { isValid, isValidArray } from "../../utils/utilities";

const { Text } = Typography;

export const CancelledTripSheet = (props) => {

    const {
        tripId
    } = props;

    const [ cancelledReportData , setCancelledReportData] = useState([]);
    const [ oldTripId , setOldTripId] = useState(null);

    const onSuccess =(data)=>{
        setCancelledReportData(data);
    }

    const onFailure =(errorMessage)=>{
        // message.error(errorMessage);
    }

    useEffect(()=>{
        if(isValid(tripId)&&tripId!=oldTripId){
            setOldTripId(tripId);
            fetchCancelledTickets(tripId,onSuccess,onFailure)
        }
    },[tripId])

    const columns = [
        {
          title: 'Seat No',
          dataIndex: 'seat_number',
          key: 'seat_number',
          className:"control-margin"
        },
        {
          title: 'Ticket No',
          dataIndex: 'pnr',
          key: 'pnr',
          className:"control-margin"
        },
        {
          title: 'Name',
          dataIndex: 'full_name',
          key: 'full_name',
          className:"control-margin"
        },
        {
          title: 'Boarding',
          dataIndex: 'pick_up_location',
          key: 'pick_up_location',
          className:"control-margin"
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'destination',
          className:"control-margin"
        },
        {
          title: 'Drop Off',
          dataIndex: 'drop_location',
          key: 'drop_location',
          className:"control-margin"
        },
        {
            title: 'Cancellation Charges',
            dataIndex: 'cancellation_penalty',
            key: 'cancellation_penalty',
            className:"control-margin" 
        },
        {
            title: 'Cancelled By',
            dataIndex: 'cancelled_by',
            key: 'cancelled_by',
            className:"control-margin"
        },
        {
          title:'Cancellation time',
          dataIndex: 'cancellation_time',
          key: 'cancellation_time',
          className:"control-margin",
          render : (text) => {
            if(isValid(text)){
                return getLocalDateAndTime(text)
            }else{
                return ''
            }
        }
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            className:"control-margin"
        }
      ];

      if(isValidArray(cancelledReportData)&&cancelledReportData.length>0){
        return (
            <div>
                <Text strong className="tripSheetSplFontSize">Cancellation details</Text>
                <Table 
                    dataSource={cancelledReportData}
                    columns={columns}
                    pagination={false}
                    className={`bordered-table ${global.tripSheetBold}`}
                />
            </div>
        )
      }else{
        return null
      }
   
}