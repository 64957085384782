/**
 * @author Rejo Varghese
 * @description Amenities related tab
 */
import React, { useEffect, useState } from "react";
import { Button, Row, Col, Divider, Typography, Checkbox} from "antd";
import './render-tabs.style.scss';
import { isValidArray } from "../../../utils/utilities";
import { getAmenitiesForScheduleAction, addAmenitiesToScheduleAction } from "../../../actions/admin.action";

const { Text } = Typography;

const CheckboxGroup = Checkbox.Group;

export const ScheduleAmenities = (props) => {

    const{
        scheduleData,
        isAmenitiesOpened,
        amenities,
        fetchAmenities
    } = props;

    const [scheduleAmenities, setScheduleAmenities] = useState([]);
    const [amenityOption, setAmenityOption] = useState([]);
    const [amenityList, setAmenityList] = useState([]);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false);

    useEffect(()=>{
        if(isAmenitiesOpened && isValidArray(scheduleData)){
            fetchAmenitiesForSchedule();
            if (!isValidArray(amenities)) {
                fetchAmenities();
            }
        }
    },[isAmenitiesOpened,scheduleData]);

    const fetchAmenitiesForSchedule = () => {
        getAmenitiesForScheduleAction(scheduleData.bus_id, scheduleData.id, setScheduleAmenities )
    }
    
    const onChange = (list) => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < amenityOption.length);
        setCheckAll(list.length === amenityOption.length);
    };
    
    const onCheckAllChange = (e) => {
        setCheckedList(e.target.checked ? amenityList : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect (() => {
        if (isValidArray(amenities)) {
            let tempOption = [], tempAmenity = [];
            amenities.map((data) => {
                tempOption.push(<Col span = {12}><Checkbox value={data.amenity}>{data.amenity}</Checkbox></Col>);
                tempAmenity.push(data.amenity);
            })
            setAmenityOption(tempOption);
            setAmenityList(tempAmenity);
        }
    },[amenities])
    
    useEffect(() => {
        if (isValidArray(scheduleAmenities)) {
            let tempBusAmenity = [];
            scheduleAmenities.map((data) => {
                tempBusAmenity.push(data.amenity_name);
            });
            setCheckedList(tempBusAmenity);
        }
    },[scheduleAmenities])


        return(
            <div className="extend-block-root">
                <Row>
                    <Col offset= {3}>
                <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                       <Text strong style= {{color: '#1057B9'}}> Select All</Text>
                    </Checkbox>
                    </Col>
                </Row>
                <Divider />
                <Row justify="center" align="middle">
                    <CheckboxGroup value={checkedList} onChange={onChange} style={{ width: '80%' }}>
                        <Row gutter={[16,16]} justify="center" align="middle">
                            {amenityOption}
                        </Row>
                    </CheckboxGroup>
                </Row>
                <Divider />
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <Button
                            onClick={()=> {addAmenitiesToScheduleAction(scheduleData.bus_id, scheduleData.id,checkedList);}}
                            style={{margin:'5px' , background:'#FF6767' , borderColor:'#FF6767' ,color:'#FFFFFF', borderRadius: 5}}
                        >
                            Update
                        </Button>
                    </Col>
                </Row>
            </div>
        )
}
