/**
 * Loading related actions can be created here
 */

import {
    LOADING_ACTION
   } from "./action-types";

export const loadingAction = (loadingValue)=>{
    return{
        type:LOADING_ACTION,
        payload:loadingValue
    }
}