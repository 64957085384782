import React from "react";
// Note : check details in https://www.npmjs.com/package/react-device-detect
import {
    isMobile,
    isMobileOnly,
    isTablet,
    isDesktop,
    isSmartTV,
    isWearable,
    isAndroid,
    isWinPhone,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isEdge,
    isYandex,
    isChromium,
    isMobileSafari,
    isSamsungBrowser,
    osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
    getUA,
    deviceType,
    isIOS13,
    isIPhone13,
    isIPad13,
    isEdgeChromium,
    isElectron,
    isLegacyEdge,
    isWindows,
    isMacOs,
    deviceDetect,
    OsTypes,
    BrowserTypes
  } from "react-device-detect";

  const deviceEnvironment = {
    isMobile,//return true for mobile and tablet
    isMobileOnly,
    isTablet,
    isDesktop,
    isSmartTV,
    isWearable,
    isAndroid,
    isWinPhone,
    isIOS,
    isChrome,
    isFirefox,
    isSafari,
    isOpera,
    isEdge,
    isYandex,
    isChromium,
    isMobileSafari,
    isSamsungBrowser,
    osVersion,
    osName,
    fullBrowserVersion,
    browserVersion,
    browserName,
    mobileVendor,
    mobileModel,
    engineName,
    engineVersion,
    getUA,
    deviceType,
    isIOS13,
    isIPhone13,
    isIPad13,
    isEdgeChromium,
    isElectron,
    isLegacyEdge,
    isWindows,
    isMacOs,
    deviceDetect,
    OsTypes,
    BrowserTypes
};

export const DeviceContext = React.createContext(deviceEnvironment);

export const DeviceProvider = (props) => {

    return (
        <DeviceContext.Provider value={deviceEnvironment}>
            {props.children}
        </DeviceContext.Provider>
    )
};
