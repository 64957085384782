/**
 * @author Rejo Varghese
 * @description Seat click ticket module
 */

import { GET_DROP_LOCATION_BY_TRIP_ID_URL, GET_PICKUP_LOCATION_BY_TRIP_ID_URL, SET_EXCEPTIONAL_REFUND_BY_TICKET_URL } from "./../../utils/api-url";
import { SUCCESS_STATUS } from "../../utils/constant";


export const getPickupLocationByTripId=(scheduleId, date, tripId, onSuccess=()=>{},onFailure=()=>{})=>{

fetch(GET_PICKUP_LOCATION_BY_TRIP_ID_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
        "scheduleId": scheduleId,
        "date": date,
        "tripId": tripId,
        "operatorCode" : global.operatorCode,
        "travelsId" : global.travelsId  
    })
    })
    .then(response => response.json())
    .then(data =>{
        onSuccess(data.Payload);
    }).catch((err)=>{
        console.log({err});
        onFailure("Failed to get ticket info!");
    })
}

export const getDropLocationByTripId=(scheduleId, date, tripId, onSuccess=()=>{},onFailure=()=>{})=>{

    fetch(GET_DROP_LOCATION_BY_TRIP_ID_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "scheduleId": scheduleId,
            "date": date,
            "tripId": tripId,
            "operatorCode" : global.operatorCode,
            "travelsId" : global.travelsId  
        })
        })
        .then(response => response.json())
        .then(data =>{
            onSuccess(data.Payload);
        }).catch((err)=>{
            console.log({err});
            onFailure("Failed to get ticket info!");
        })
    }

export const setExceptionalRefundByTicketId = (ticketId, refundAmount, onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(SET_EXCEPTIONAL_REFUND_BY_TICKET_URL, {
        method: "POST",
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
            "ticketId": ticketId,
            "refundAmount": refundAmount,
            "operatorCode" : global.operatorCode,
            "travelsId" : global.travelsId,
            "updatedBy":global.userName
        })
    }).then(response => response.json())
    .then(data => {
        if(data.Status==SUCCESS_STATUS) {
            onSuccess(data.Payload);
        }
        else {
            onFailure(data.Message)
        }
    }).catch((err) =>  {
        console.log({err});
        onFailure("Failed to set exceptional refund");
    })
}