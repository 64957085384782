import { ISSUE_AUTOMATIC_REUND_BY_TRAVELLER_URL } from "../../../utils/api-url";
import { getAPIDateFormat } from "../../../utils/date.utils";
import { postCall } from "../../../utils/network.util";

export const issueAutomaticRefundByTraveller=(traveller,onSuccess,onFailure)=>{
    return postCall(ISSUE_AUTOMATIC_REUND_BY_TRAVELLER_URL, {
        traveller:{
            email: traveller.email_id,
            pnr: traveller.pnr,
            operatorId: traveller.travels_name,
            operatorCode: traveller.operator_code,
            doj: getAPIDateFormat(traveller.start_date),
            sourceId: traveller.origin_id,
            destinationId: traveller.destination_id,
            phone_number: traveller.phone_number,
            seat_number: traveller.seat_number,
            traveller_id: traveller.id 
        },
        agentName:global.userName
      }).then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                return onSuccess(clientPayload.Payload);
            }else{
                return onFailure(clientPayload.Message);
            }
        }else{
            return onFailure("Failed to issue refund!");
        }
      })
      .catch((reason)=> {
        console.log({reason});
        return onFailure("Failed to isse refund!");
      })
}