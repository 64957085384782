import { Modal,Row,Col,Typography , Divider, Input ,Select, Button, message, Table,Checkbox} from 'antd';
import React, { useState } from 'react';
import { checkActionAllowed, isValid, isValidArray, getCloneArray, isValidArrayWithValues, removeArrayIndexValue, deleteIndexValue } from '../../utils/utilities';
import { getTicketById , updateTransactionDetails } from './../../actions/ticket.action';
import { LoadingSpinner } from './../custom-components/custom-component';
import { getLocalDateAndTime, getUIDateFormat, getTimeStampInReadableFormat, getCurrentDateInIST, getAPIDateFormat } from './../../utils/date.utils';
import {
    ACTION_SEAT_NUMBER_UPDATE_ALLOWED,
    ACTION_SEAT_GENDER_UPDATE_ALLOWED,
    ACTION_CANCEL_TICKET_ALLOWED,
    ACTION_CANCEL_BKBS_TICKET_ALLOWED,
    ACTION_TO_VIEW_SEAT_DOUBLE_CLICK,
    ACTION_EXCEPTIONAL_REFUND_ALLOWED,
    getSameUser
} from './../../utils/constant';
import {
    selectedScheduleSeatsSelector,
    selectedScheduleSelector
} from './../../selector/home.selector';
import { 
    useDispatch,
    useSelector
} from 'react-redux';
import { Printer } from './../custom-components/custom-component'
import { TicketSummary } from './ticket-summary.component';
import { fetchSeatLayout , setDropPointDetailsForSelectedSchedule, setPickupPointDetailsForSelectedSchedule, setSelectedScheduleSeats} from './../../actions/home.action';
import { loadingAction } from './../../actions/loading-actions';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import { checkUpdatedValueFromObjects } from './../../utils/utilities'
import { isMobile } from 'react-device-detect';
import { getDropLocationByTripId, getPickupLocationByTripId } from './seat-click-ticket.module';
import { NotifyButton, NotifyComponent } from './notify.component';
import { TicketPdf } from '../../pages/pdfs/ticket.pdf';
import { RefundComponent } from './refund.component';

const {
    Option,
    OptGroup 
} = Select;

const handleChange=(type,payload,value,transactionDetails)=>{
    let travellerIndex;
    let newTransactionDetails = Object.assign({},transactionDetails);
    travellerIndex = transactionDetails.traveller.findIndex((traveller)=>{
      return traveller.id === payload.id;
    });
    switch (type){
      case "SEX":
        newTransactionDetails.traveller[travellerIndex].gender = value;
        break;
      case "PICKUP_POINT":
        newTransactionDetails.traveller[travellerIndex].pickup_location.id = value;
        break;
      case "DROP_POINT":
        newTransactionDetails.dropDetails = value;
        newTransactionDetails.ticket.drop_location = value.id;
        break;
      case "SEATNUMBER":
        newTransactionDetails.traveller[travellerIndex].seat_number = value;
        break;
      case "PHONENUMBER":
        newTransactionDetails.ticket.phone_number = value;
        break;
      case "EMAILID":
        newTransactionDetails.ticket.email_id = value;
        break;
      case "REMARKS":
        newTransactionDetails.ticket.remarks = value;
        if(value.length > 10){
            newTransactionDetails.ticket.remarksError = "Remarks cannot be greater than 10 characters.";
        }else{
            newTransactionDetails.ticket.remarksError = null;
        }
        break;
      case "PASSENGERNAME":
      newTransactionDetails.traveller[travellerIndex].full_name= value;
        break;
      default:
        break;
    }
    return newTransactionDetails;
  }

const { Text } = Typography;

let xs=24, sm=24, md=8 , lg=8 , xl=8 , xxl=8;


const renderBoardingPoints=(availableBoardings)=>{

    let availableBoardingsComponents=[];
    if(isValidArray(availableBoardings)){
        for (const key in availableBoardings) {
            if (Object.hasOwnProperty.call(availableBoardings, key)) {
                const boardingPoints = availableBoardings[key];
                let optionBoardingPoints=[];
                if(isValidArray(boardingPoints)&&boardingPoints.length>0){
                    boardingPoints.forEach(element => {
                        optionBoardingPoints.push(<Option value={element.id}>{element.location}</Option>)
                    });
                }
                availableBoardingsComponents.push(<OptGroup label={key}>{optionBoardingPoints}</OptGroup>);
            }
        }
    }
    return availableBoardingsComponents;
}

const renderDropPoints=(availableDrops)=>{
    let availableDropComponents=[];
    if(isValidArray(availableDrops)){
        for (const key in availableDrops) {
            if (Object.hasOwnProperty.call(availableDrops, key)) {
                const dropPoints = availableDrops[key];
                let optionDropPoints=[];
                if(isValidArray(dropPoints)&&dropPoints.length>0){
                    dropPoints.forEach(element => {
                        optionDropPoints.push(<Option value={element.id} data={element}>{element.location}</Option>)
                    });
                }
                availableDropComponents.push(<OptGroup label={key}>{optionDropPoints}</OptGroup>);
            }
        }
    }
    return availableDropComponents;
}


const renderPassengers=({travellers,trip,renderAvailableSeats , pickupTimings , onFiledChange})=>{
    let currentDate = getAPIDateFormat(getCurrentDateInIST());
    let isNotTripCompleted= currentDate<=getAPIDateFormat(trip.start_date);
    let isSeatNumberUpdateAllowed=isValid(global.allowedActions[ACTION_SEAT_NUMBER_UPDATE_ALLOWED])&&isNotTripCompleted?true:false;
    let isGenderUpdateAllowed=isValid(global.allowedActions[ACTION_SEAT_GENDER_UPDATE_ALLOWED])?true:false;

    if(isValidArray(travellers)&&travellers.length>0){
        if(isMobile){
            let passengersComponent=[];
            travellers.forEach(element => {
              passengersComponent.push(<Row gutter={[16,16]} align="middle" className="ticket-passenger-block">
                    <Col>
                        {isSeatNumberUpdateAllowed?
                        <Row align="middle" gutter={[16,0]}>
                            <Col>
                                <Text strong>Seat # : </Text> 
                            </Col>
                            <Col>
                                <Select value={element.seat_number} className="seat-number-select"
                                onChange={(value)=>{
                                    onFiledChange(value,"SEATNUMBER",element)
                                }}
                                >
                                {renderAvailableSeats(element.gender , element)}
                                </Select>
                            </Col>
                        </Row>
                        :
                        <Text>
                            <Text strong>Seat Number : </Text>{element.seat_number}
                        </Text> 
                        }
                       
                    </Col>
                    <Col>
                        {isGenderUpdateAllowed?
                            <Row align="middle" gutter={[16,4]}>
                                <Col>
                                    <Text strong>Sex : </Text> 
                                </Col>
                                <Col>
                                    <Select className="seat-gender-select" value={element.gender}
                                     onChange={(value)=>{
                                        onFiledChange(value,"SEX",element)
                                    }}
                                    >
                                        <Option value='M'>M</Option>
                                        <Option value='F'>F</Option>
                                    </Select>
                                </Col>
                            </Row>
                            :
                            <Text>
                                <Text strong>Sex : </Text>{element.gender}
                            </Text>
                        }
                    </Col>
                    <Col>
                        <Text>
                            <Text strong>Age : </Text>{element.age}
                        </Text> 
                    </Col>
                    <Col>
                        <Row align="middle" gutter={[16,4]}>
                            <Col>
                                <Text strong>Passenger Name : </Text> 
                            </Col>
                            <Col>
                                <Input 
                                value={element.full_name}
                                 onChange={(e)=>{
                                    onFiledChange(e.target.value,"PASSENGERNAME",element)
                                }}
                                className=" passenger-input"
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Row align="middle" gutter={[16,4]}>
                                <Col>
                                    <Text strong>Boarding Point : </Text> 
                                </Col>
                                <Col>
                                    <Select className="boarding-point-select"
                                     value={element.pickup_location.id}
                                    //  value={isValid(pickupTimings[element.pickup_location.city])&&pickupTimings[element.pickup_location.city].find((item) => item.id == element.pickup_location.id)?element.pickup_location.id:element.pickup_location.location}
                                     onChange={(value)=>{
                                        onFiledChange(value,"PICKUP_POINT",element)
                                    }}
                                    >
                                        {renderBoardingPoints(pickupTimings,element,trip)}
                                    </Select>
                                </Col>
                        </Row>
                    </Col>
                    <Col>
                        <Text>
                            <Text strong>Amount Paid : </Text>{element.amount_paid}
                        </Text> 
                    </Col>
                    <Col>
                        <Text>
                            <Text strong>Status: </Text>
                            <span className={element.traveller_status =='Confirmed'?'confirm-status-txt':'cancel-status-txt'}>
                            {element.traveller_status}
                            </span>
                        </Text> 
                    </Col>
                </Row>);
            });
    
            return passengersComponent; 
        }else{
            return (
                <Table 
                    columns={
                        [
                            {
                                title:"Seat no",
                                dataIndex:"seat_number",
                                key:"seat_number",
                                render:(text,element)=>{
                                    return(
                                        <div>
                                             {isSeatNumberUpdateAllowed?
                                                <Row align="middle" gutter={[16,0]}>
                                                    <Col>
                                                        <Select value={element.seat_number} className="seat-number-select"
                                                        onChange={(value)=>{
                                                            onFiledChange(value,"SEATNUMBER",element)
                                                        }}
                                                        >
                                                        {renderAvailableSeats(element.gender , element)}
                                                        </Select>
                                                    </Col>
                                                </Row>
                                                :
                                                <Text>
                                                    {text}
                                                </Text> 
                                                }                     
                                        </div>
                                    )}
                            },
                            {
                                title:"Name",
                                dataIndex:'name',
                                key:'name',
                                render:(text,element)=>{
                                    return(
                                        <div>
                                           <Input 
                                                value={element.full_name}
                                                onChange={(e)=>{
                                                    onFiledChange(e.target.value,"PASSENGERNAME",element)
                                                }}
                                                className="defalut-input-style"
                                            />
                                        </div>
                                    )
                                }
                            },
                            {
                                title:"Age",
                                dataIndex:'age',
                                key:'age'
                            },
                            {
                                title:"Sex",
                                dataIndex:"sex",
                                key:"sex",
                                render:(text,element)=>{
                                    return(<div>
                                         {isGenderUpdateAllowed?
                                            <Select className="seat-gender-select" value={element.gender}
                                                onChange={(value)=>{
                                                onFiledChange(value,"SEX",element)
                                            }}
                                            >
                                                <Option value='M'>M</Option>
                                                <Option value='F'>F</Option>
                                            </Select>
                                            :
                                            <div>{text}</div>
                                        }
                                    </div>)
                                }
                            },
                            {
                                title:"Amount Paid",
                                dataIndex:'amount_paid',
                                key:'amount_paid'
                            },
                            {
                                title:"Boarding",
                                dataIndex:'pickup_location',
                                key:'pickup_location',
                                render:(text,element)=>{
                                    if(isValid(element.pickup_location)){
                                        let defaultValue;
                                        let city = element.pickup_location.city;
                                        if (isValid(pickupTimings[city])&& pickupTimings[city].find((item) => item.id == element.pickup_location.id)) {
                                            defaultValue = element.pickup_location.id;
                                        }
                                        else {
                                            defaultValue = element.pickup_location.location
                                        }
                                        return(
                                            <Select className="boarding-point-select" value={defaultValue}
                                            onChange={(value)=>{
                                                onFiledChange(value,"PICKUP_POINT",element)
                                            }}
                                            >
                                                {renderBoardingPoints(pickupTimings,element)}
                                            </Select>
                                        )
                                    }else{
                                        return null;
                                    }
                                }
                            },
                            {
                                title:"Status",
                                dataIndex:'traveller_status',
                                key:"traveller_status",
                                render:(text,element)=>{
                                    return (<div>{text}</div>)
                                }
                            }
                        ]
                    }
                    dataSource={travellers}
                    pagination={false}
                    className="bordered-table"
                />
          );
        }
    }else{
        return null
    }
}

const getRemarkError=(remarksError)=>{
    if(isValid(remarksError)&&remarksError.length>10){
        return "ant-input-status-error";
    }else{
        return "";
    }
}

const ModalBody=({
    setSelectedNotifyTickets,
    selectedNotifyTickets,
    index,showTicketNo,ticketInfo,oldTicketInfo,loading,seats,selectedSchedule,pickupTimingsByTripCity,dropTimingsByTripCity,setTicketInfo,handleCancel,feacthSeats,loadingFunc})=>{

    let history = useHistory();

    if(isValidArray(ticketInfo)&&isValidArray(ticketInfo.ticket)&&isValid(ticketInfo.ticket.id)){
        let {
            ticket,
            agent,
            traveller,
            route,
            pickupDetails,
            dropDetails,
            schedule,
            trip
        } = ticketInfo;

        let pickupTimings= {} , dropTimings = {};

        if(isValidArray(selectedSchedule)){
            pickupTimings = {};
            if(trip.cut_route_enabled == 0){
                pickupTimings[selectedSchedule.origin] = selectedSchedule.pickupTimings;
            }
            if(isValidArray(pickupTimingsByTripCity)){
                if (route.origin in pickupTimingsByTripCity) {
                    pickupTimings[route.origin] = pickupTimingsByTripCity[route.origin];
                }
            }

            dropTimings = {};
            if(trip.cut_route_enabled == 0){
                dropTimings[selectedSchedule.destination] = selectedSchedule.dropTimings;
            }
            
            if(isValidArray(dropTimingsByTripCity)){
                if (route.destination in dropTimingsByTripCity) {
                    dropTimings[route.destination] = dropTimingsByTripCity[route.destination];
                }
            } 
        }


        const renderAvailableSeats=(gender,bookedSeat)=>{
            let seatAvailableOptions=[];
            seatAvailableOptions.push(<Option key={bookedSeat.id} value={bookedSeat.seat_number}>{bookedSeat.seat_number}</Option>);
            if(isValidArray(seats)&&seats.length>0){
                seats.forEach(seat => {
                    if(seat.seat_number!=="Empty"&&(!isValid(seat.seat_status)||seat.seat_status === "")){
                        if(gender==='F'){
                            if(seat.ladies_seat===1||seat.gents_seat===0){
                                seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                            }
                        }else{
                            if(!(seat.ladies_seat===1)){
                                seatAvailableOptions.push(<Option key={seat.id} value={seat.seat_number}>{seat.seat_number}</Option>);
                            }
                        }
                    }
                });
            }
            return seatAvailableOptions;
        }

        const onFiledChange=(value,type,traveller)=>{
            setTicketInfo(handleChange(type,traveller,value,ticketInfo));
        }

        let isCancelTicketAllowed=isValid(global.allowedActions[ACTION_CANCEL_TICKET_ALLOWED])|| (isValid(global.allowedActions[ACTION_CANCEL_BKBS_TICKET_ALLOWED]) && getSameUser(ticket.agent_id) )?true:false;
        
        let isExceptionalRefundAllowed=isValid(global.allowedActions[ACTION_EXCEPTIONAL_REFUND_ALLOWED])?true:false;

        const closeAndMoveToViewCancel=()=>{
            history.push('/view-cancel?pnr='+ticket.pnr);
            handleCancel()
        }

        // let bookingTime = `${getUIDateFormat(ticket.booking_time)},\xa0\xa0${getLocalTime(ticket.booking_time)}`;

        let isRemarkError = getRemarkError(ticket.remarksError);

        return(
            <div>
                {showTicketNo&&
                    <Row>
                        <Col>
                            <Text>Update Ticket- Passenger {index+1}</Text>
                             <div className='update-ticket-underline'></div>
                        </Col>
                    </Row>
                }
                <Row justify='center' align='middle'>
                    <Col>
                        <Text>
                            {schedule.description}
                        </Text> 
                    </Col>
                </Row>
                <Row justify='center' align='middle'>
                    <Col>
                        <Text strong>PNR : </Text>{ticket.pnr}
                    </Col>
                </Row>
                <Row justify='center' align='middle'>
                    <Col>
                        <Text strong>{`${route.origin} to ${route.destination}`}</Text>
                    </Col>
                </Row>
                <Row flex={1} gutter={[16,]} align="middle" justify="space-between">
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        {isValidArray(pickupDetails)&&isValid(pickupDetails.timing)?
                        <Row>
                            <Text>
                                <Text strong>Boarding Time: </Text>
                                {getUIDateFormat(pickupDetails.travel_date) + "," + getTimeStampInReadableFormat(pickupDetails.timing)}
                            </Text> 
                        </Row>
                        :null
                        }
                        <Row>
                            <Text>
                                <Text strong>Booking Time: </Text>
                                {`${getLocalDateAndTime(ticket.booking_time)}`}
                            </Text> 
                        </Row>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Row justify='end'>
                            <Col>
                                <Text strong>Agent Name: </Text>{agent.name}
                            </Col>
                        </Row>
                        {isValidArray(dropDetails)&&isValid(dropDetails.city)?
                            <Row justify='end'>
                                    {/* <Col>
                                        <Text strong>Drop: </Text>{dropDetails.location}
                                    </Col> */}
                                        <Col>
                                            <Text strong>Drop Point : </Text> 
                                        </Col>
                                        <Col>
                                            <Select 
                                            className="boarding-point-select" 
                                            value={isValid(dropTimings[dropDetails.city])&&dropTimings[dropDetails.city].find((item) => item.id == ticket.drop_location)?ticket.drop_location:dropDetails.location}
                                                onChange={(dropPointId,{data})=>{
                                                onFiledChange(data,"DROP_POINT",{})
                                            }}
                                            >
                                                {renderDropPoints(dropTimings)}
                                            </Select>
                                        </Col>
                            </Row>
                            :null
                        }
                    </Col>
                </Row>
                <Row flex={1} gutter={[16,4]} justify="space-between" align="middle">
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        {ticket.is_reschedule_allowed === 0?
                            <div>
                                <Text strong>Ticket type : </Text>
                                Rescheduled 
                            </div>
                            :null
                        }
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        {global.useRemarks === 1 &&
                            <Row align="middle" gutter={[16,4]}>
                                <Col>
                                    <Text strong>Remarks : </Text> 
                                </Col>
                                <Col>
                                    <Row>
                                        <Input 
                                            placeholder="Remarks" 
                                            className={"defalut-input-style " + isRemarkError}
                                            value={ticket.remarks}
                                            onChange={(e)=>{
                                                onFiledChange(e.target.value,"REMARKS",{});
                                            }}
                                            style={{maxWidth:'250px'}}
                                        />
                                    </Row>
                                    <Row>
                                        <Text type="danger">{ticket.remarksError}</Text>
                                    </Row>
                                </Col>
                            </Row>
                        }
                    </Col>
                </Row>
                <Row flex={1} gutter={[16,4]} justify="space-between" align="middle">
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Text>
                            <Text strong>Agent phone number : </Text>{agent.phone_number}
                        </Text> 
                    </Col>
                </Row>
                <Row flex={1} gutter={[16,4]} justify="space-between" align="middle">
                    {isValid(ticket.payment_type)&&
                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                          <Text>
                              <Text strong>Payment type : </Text>{ticket.payment_type}
                          </Text> 
                        </Col>
                    }
                </Row>
                <Row flex={1} gutter={[16,4]} justify="space-between" align="middle">
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                        <Row align="middle" gutter={[16,4]}>
                                <Col>
                                    <Text strong>Phone number : </Text> 
                                </Col>
                                <Col>
                                    <Input value={ticket.phone_number}
                                    onChange={(e)=>{
                                        onFiledChange(e.target.value,"PHONENUMBER",{});
                                    }}
                                    className="phone-no-input-style"
                                    />
                                </Col>
                        </Row>
                    </Col>
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Row align="middle" gutter={[16,4]}>
                                <Col>
                                    <Text strong>Email Id : </Text> 
                                </Col>
                                <Col>
                                    <Input value={ticket.email_id} onChange={(e)=>{
                                        onFiledChange(e.target.value,"EMAILID",{});
                                        }}
                                    className="email-id-input-style"
                                    />
                                </Col>
                            </Row>
                    </Col>
                </Row>
                <Divider />
                {renderPassengers({travellers:traveller,trip:trip,renderAvailableSeats,pickupTimings,onFiledChange})}
                <div className="marginTop">
                    <Row gutter={[16,16]} justify="space-around">
                        <Col>
                                <Printer 
                                printerClassName="print-ticket-popup-btn"
                                renderComponent={()=>(
                                    <TicketSummary 
                                        ticketDetails={ticketInfo}
                                        isPrinter={true}
                                    />
                                    )}
                                    zoomValue={1}
                                />
                        </Col>
                        <Col>
                            <TicketPdf 
                                ticketDetails={ticketInfo}
                            />
                        </Col>
                        {isCancelTicketAllowed&&
                            <Col>
                                <Button onClick={closeAndMoveToViewCancel} className="modify-cancel-btn">Modify/{isMobile?<br />:null}Cancel</Button>
                            </Col>
                        }       
                        <Col>
                                <Button 
                                className='update-ticket-popup-btn'
                                onClick={()=>{
                                    if(!isRemarkError){
                                        loadingFunc(true);
                                        let oldTraveller = oldTicketInfo.traveller
                                        if (isValidArrayWithValues(oldTraveller)) {
                                            let isUpdatedValues = false;
                                            oldTraveller.map((tktVal,ind) => {
                                                if (checkUpdatedValueFromObjects(tktVal,traveller[ind])) {
                                                    isUpdatedValues = true
                                                }
                                                if (checkUpdatedValueFromObjects(tktVal.pickup_location,traveller[ind].pickup_location)) {
                                                    isUpdatedValues = true
                                                }
                                            })
                                            if (isUpdatedValues) {
                                                updateTransactionDetails(ticketInfo,
                                                    ()=>{
                                                        loadingFunc(false);
                                                        feacthSeats();
                                                    },()=>{
                                                        loadingFunc(false);
                                                    }
                                                );
                                            }
                                            else {
                                                if (checkUpdatedValueFromObjects(oldTicketInfo.ticket,ticket)){
                                                    updateTransactionDetails(ticketInfo,()=>{
                                                        loadingFunc(false);
                                                        feacthSeats();
                                                    },()=>{
                                                        loadingFunc(false);
                                                    });
                                                }
                                                else {
                                                    message.warn("No Value to Update");
                                                    loadingFunc(false);
                                                }
                                            }
                                        }
                                        else {
                                            message.warn("No Value to Update");
                                            loadingFunc(false);
                                        }
                                    }
                                }}>Update</Button>
                        </Col>
                        <Col>
                            <NotifyButton 
                                onClick={()=>{
                                    let indexTicket = selectedNotifyTickets.indexOf(ticketInfo.ticket.id);
                                    if(selectedNotifyTickets.indexOf(ticketInfo.ticket.id) == -1){
                                        selectedNotifyTickets.push(ticketInfo.ticket.id);
                                        setSelectedNotifyTickets([...selectedNotifyTickets]);
                                    }else{
                                        setSelectedNotifyTickets([...deleteIndexValue(selectedNotifyTickets,indexTicket)]);
                                    }
                                }}
                            />
                        </Col>
                        {isExceptionalRefundAllowed&&
                            <Col>
                                <RefundComponent ticketInfo={ticketInfo} />
                            </Col>
                        }
                    </Row>
                    <NotifyComponent ticketInfo={ticketInfo} selectedNotifyTickets={selectedNotifyTickets}/>
                </div>
               
            </div>
        )
    }else if(loading){
            return (
            <div>
                <LoadingSpinner />
            </div>)
    }else{
        return (
            <div>
                Ticket details not found.
            </div>
        )
    }

   
}

export const SeatClickTicketPopUp=({ticketId,ticketDetails,children,agentId,isOnClickExits=false})=>{

    const [ticketInfo,setTicketInfo]=useState([]);
    const [oldTicketInfo,setOldTicketInfo]=useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedNotifyTickets,setSelectedNotifyTickets] = useState([]);

    const seats = useSelector(selectedScheduleSeatsSelector);
    const selectedSchedule=useSelector(selectedScheduleSelector);
    const dispatch = useDispatch();

    const setSeats=(data)=>{
        dispatch(setSelectedScheduleSeats(data));
    }

    useEffect(()=>{
        if(!isValidArray(seats)){
            feacthSeats();
        }
    },[selectedSchedule]);

    const feacthSeats=()=>{
        if(isValidArray(selectedSchedule)){
            dispatch(fetchSeatLayout(selectedSchedule.trip_route_id,(seatsData)=>{
                let fetchedSeats = seatsData.seats;
                setSeats(fetchedSeats);
                handleCancel();
            },(errmsg)=>{
                message.error(errmsg);
                setLoading(false);
            }));
        }
    }

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };
    
      const getTicketDetails=()=>{
        if(isValidArray(ticketDetails)&&isValidArray(ticketDetails.ticket)&&isValid(ticketDetails.ticket.id)){
            setTicketInfo([ticketDetails]);
            setOldTicketInfo([getCloneArray(ticketDetails)]);
        }else{
            if(isValidArray(ticketId)&&ticketId.length>0){
                ticketId.forEach((tId,index) => {
                    setLoading(true);
                    getTicketById(tId,(data)=>{
                        ticketInfo[index] = data;
                        setTicketInfo([...ticketInfo]);
                        // oldTicketInfo[index] = {...data};
                        setOldTicketInfo(getCloneArray(ticketInfo));
                        setLoading(false);
                    },()=>{
                        setLoading(false);
                    });
                });
            }else{
                setLoading(true);
                getTicketById(ticketId,(data)=>{
                    setTicketInfo([data]);
                    setOldTicketInfo([getCloneArray(data)]);
                    setLoading(false);
                },()=>{
                    setLoading(false);
                });
            }
        }
      }

      const loadingFunc=(bool)=>{
        dispatch(loadingAction(bool));
      }

      const setPickupTimingsByTripCity =(info)=>{
        dispatch(setPickupPointDetailsForSelectedSchedule(info));
      }

      const setDropTimingsByTripCity =(info)=>{
        dispatch(setDropPointDetailsForSelectedSchedule(info));
      }

      const onBtnAction=()=>{
        getTicketDetails();
        showModal();
        if (!isValid(selectedSchedule.pickupTimingsByTripCity)) {
            getPickupLocationByTripId(selectedSchedule.schedule_id,selectedSchedule.start_date,selectedSchedule.trip_id, setPickupTimingsByTripCity)
        }

        if (!isValid(selectedSchedule.dropTimingsByTripCity)) {
            getDropLocationByTripId(selectedSchedule.schedule_id,selectedSchedule.start_date,selectedSchedule.trip_id, setDropTimingsByTripCity)
        }
      }


    return(
        <div>
            <div
                onClick={()=>{
                    if(isOnClickExits && (checkActionAllowed(ACTION_TO_VIEW_SEAT_DOUBLE_CLICK) || getSameUser(agentId))){
                        onBtnAction();
                    }
                }}
                onDoubleClick={()=>{
                    if(checkActionAllowed(ACTION_TO_VIEW_SEAT_DOUBLE_CLICK) || getSameUser(agentId)){
                        onBtnAction();
                    }
                }}
            >
                {children}
            </div>
            <Modal 
            visible={isModalVisible} 
            onOk={handleOk} 
            onCancel={handleCancel} 
            width="80vw"
            footer={null}
            >
            {ticketInfo.map((ticketData,index) => (
                <ModalBody 
                ticketInfo={ticketData} 
                oldTicketInfo = {oldTicketInfo[index]}
                loading={loading} 
                seats={seats} 
                selectedSchedule={selectedSchedule}
                pickupTimingsByTripCity = {selectedSchedule.pickupTimingsByTripCity}
                dropTimingsByTripCity = {selectedSchedule.dropTimingsByTripCity}
                setTicketInfo={(info)=>{
                    ticketInfo[index]=info;
                    setTicketInfo([...ticketInfo]);
                }} 
                handleCancel={handleCancel}
                feacthSeats={feacthSeats}
                loadingFunc={loadingFunc}
                showTicketNo={ticketInfo.length>1?true:false}
                index={index}
                setSelectedNotifyTickets={setSelectedNotifyTickets}
                selectedNotifyTickets={selectedNotifyTickets}
                />      
            ))}
            </Modal>
        </div>
    )
}