import { GET_ROUTES_BY_SCHEDULE_ID_URL } from './../../utils/api-url';
import {  postCall } from '../../utils/network.util';
import { message } from 'antd';

  export const getViaCities=(scheduleId,onSuccess,onFailure)=>{
      postCall(GET_ROUTES_BY_SCHEDULE_ID_URL,{"scheduleId" :scheduleId})
      .then((findresponce) =>
        {
          if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
             onSuccess(findresponce.payload.Payload.routes);
           }else{
            onFailure();
             if(findresponce.status === "SUCCESS"){
              message.error(findresponce.payload.Message);
             }else{
              message.error("Failed to get cities!");
             }
           }
        })
  }