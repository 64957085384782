import React, { useEffect , useContext , useRef} from "react";
import { Row , Select , Col , DatePicker , Button , Form} from 'antd';
import { getOriginCities , getDestinationCities , fetchSchedules } from './../../../actions/home.action';
import { originsSelector,originSupportedDestinationsSelector } from './../../../selector/home.selector';
import { isValid, isValidArray } from './../../../utils/utilities';
import {  getAPIDateFormat } from './../../../utils/date.utils';
import { useDispatch,useSelector } from "react-redux";
import {
    SwapOutlined
  } from '@ant-design/icons';
import { DeviceContext } from './../../device-provider/device-provider';
import moment from "moment";
import { loadingAction } from './../../../actions/loading-actions';


const { Option } = Select;

const renderOrigins=(origins)=>
{
    let originsArray=[];
    if(isValidArray(origins)&&origins.length>0){
        origins.forEach(element => {
            if(isValid(element)){
                originsArray.push(<Option value={element}>{element}</Option>)
            }        
        });
    }
    return originsArray;
}

const renderDestinations=(destinations)=>
{
    let destiArray=[];
    if(isValidArray(destinations)&&destinations.length>0){
        destinations.forEach(element => {
            if(isValid(element)){
                destiArray.push(<Option value={element}>{element}</Option>)
            }        
        });
    }
    return destiArray;
}

export const SearchScheduleComponent = ({
    origin=null,
    destination=null,
    date=null,
    isMobileFriendly=false,
    isSearchClickTigger=false,
    isGetSeatAvailibity=false,
    onSearch=()=>{

    }}) => {

    const {
        isMobile
    }=useContext(DeviceContext);

    const origins = useSelector(originsSelector);
    const destinations = useSelector(originSupportedDestinationsSelector);
    const dispatch=useDispatch();
    const [form] = Form.useForm();
    const searchButtonRef = useRef(null);

    
    useEffect(()=>{
        function call() {
            dispatch(getOriginCities());
          }
          call();
    },[]);

    useEffect(()=>{
        if(isValid(origin)){
            dispatch(getDestinationCities(origin));
        }
    },[])

    useEffect(()=>{
        if(isSearchClickTigger){
            searchButtonRef.current.click();   
        }
    },[]);

    const onOriginChange=(value)=>{
        dispatch(getDestinationCities(value));
    }

    const setLoading =(value)=>{
        dispatch(loadingAction(value));
    }
    const onFinish=(values)=>{
        setLoading(true);
        let origin=values.origin;
        let destination=values.destination;
        let selectedDate=getAPIDateFormat(values.date);
        dispatch(fetchSchedules(origin,destination,selectedDate,isGetSeatAvailibity,true,(fetchedSchedules)=>{
            onSearch(fetchedSchedules,selectedDate,origin,destination);
            setLoading(false);
        }));
    }

    let ValidateToApplyMobileStyles=isMobile&&isMobileFriendly?true:false;

    if(isValid(date)){
        date = moment.parseZone(date);
    }
    return (
        <Row>
            <Form
            onFinish={onFinish}
            form={form}
            initialValues={{origin,destination,date}}
            >
            <Row gutter={16} justify="center" align="middle">
            <Col xs={ValidateToApplyMobileStyles?24:null} sm={ValidateToApplyMobileStyles?24:null}>
                    <Form.Item
                                name="origin"
                                rules={[{ required: true, message: 'Please input your origin!' }]}
                                style={ValidateToApplyMobileStyles?{ marginBottom: "0px" }:{}}

                        >
                            <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select a origin"
                                    optionFilterProp="children"
                                    onChange={onOriginChange}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    bordered={true}
                                    className="defalut-select-style"
                                >
                                {renderOrigins(origins)}
                                </Select>
                        </Form.Item>
                </Col>
                <Col xs={ValidateToApplyMobileStyles?24:null} sm={ValidateToApplyMobileStyles?24:null}>
                                <Row justify="center">
                                    <Form.Item
                                        style={ValidateToApplyMobileStyles?{ margin: "0px" }:{}}
                                    >
                                        <Button
                                        className="transparentButton"
                                        type="text"
                                        icon={<SwapOutlined className={ValidateToApplyMobileStyles?"transform90Degrees":""}
                                        onClick={()=>{
                                           let {
                                               origin,
                                               destination
                                           } =form.getFieldsValue();
                                           form.setFieldsValue({
                                               origin:destination,
                                               destination:origin
                                           })
                                        }}
                                        />}
                                        >
                                        </Button>
                                    </Form.Item>
                                </Row> 
                                </Col>
                <Col xs={ValidateToApplyMobileStyles?24:null} sm={ValidateToApplyMobileStyles?24:null}>
                        <Form.Item
                                name="destination"
                                rules={[{ required: true, message: 'Please input your destination!' }]}
                        >
                                <Select
                                    size="large"
                                    showSearch
                                    placeholder="Select a destination"
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    bordered={true}
                                    className="defalut-select-style"
                                >
                                {renderDestinations(destinations)}
                                </Select>
                        </Form.Item>
                </Col>
                <Col xs={ValidateToApplyMobileStyles?24:null} sm={ValidateToApplyMobileStyles?24:null}>
                        <Form.Item
                                name="date"
                                rules={[{ required: true, message: 'Please input your date!' }]}
                        >
                                <DatePicker className="defalut-picker-style"/>
                        </Form.Item>
                </Col>
                <Col xs={ValidateToApplyMobileStyles?24:null} sm={ValidateToApplyMobileStyles?24:null}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" ref={searchButtonRef}>Search</Button>
                        </Form.Item>
                </Col>
            </Row>
        </Form>
    </Row>
    )
}