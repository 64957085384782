import { Button, message } from "antd";
import React from "react";
import { issueAutomaticRefundByTraveller } from "./trip-tickets.module";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../../actions/loading-actions";

export const OTARefund = (props) => {

    let {
        travellerStatus,
        busCancellation,
        agentPrvilleage,
        automaticRefund,
        traveller,
        reload
    } = props;

    const dispatch = useDispatch();
    
    const onSuccess=()=>{
        message.success("Successfully informed about refund.");
        setLoading(false);
        reload();
    }
    const onFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    if(automaticRefund == false && travellerStatus == "Cancelled" && busCancellation == 1 && agentPrvilleage == "OTA"){
        return (
            <div>
                <Button className="outline-button" 
                onClick={()=>{
                    issueAutomaticRefundByTraveller(traveller,onSuccess,onFailure);
                }}
                >Refund</Button>
            </div>
        );
    }else{
        return null;
    }
}