import { Form, Input, Button, Checkbox } from 'antd';
import React, {useState, useEffect} from "react";
import { useDispatch , useSelector} from 'react-redux';
import { values } from 'underscore';
import { createTripExpense, getTripExpenseDetails, updateTripExpense } from '../../../actions/trip-expense.actions';
import { tripExpenseReducerSelector } from '../../../selector/trip-expense.selector';
import { isValidArray } from '../../../utils/utilities';

let addButton = 'Create', updateButton = "Update";

export const TripExpenseTable = (props) => {

  const [ form ] =Form.useForm();
  const [buttonName, setbuttonName] = useState(addButton)
  const dispatch = useDispatch();
  const tripExpenseReducer = useSelector(tripExpenseReducerSelector)
  const {
    tripId
  }=props

  useEffect(()=>{
    dispatch(getTripExpenseDetails(tripId));
  },[]);

  const onFinish = (values) => {
    if(buttonName==addButton){
      dispatch(createTripExpense({...values}))
    }
    else{
      dispatch(updateTripExpense({...values}));
    }
  };

  useEffect(()=>{
    fillForm()
  },[tripExpenseReducer]);

  const fillForm=()=>{
    if(isValidArray(tripExpenseReducer)&& isValidArray(tripExpenseReducer.tripExpense)){
      setbuttonName(updateButton)
      let{
        trip_id,
        vehicle_emi,
        permits
    } = tripExpenseReducer.tripExpense;

    form.setFieldsValue({
      tripId:trip_id,
      vehicleEmi:vehicle_emi,
      permits:permits
    })    
    } 
}

  return (
    <Form
      form={form}
      onFinish={onFinish}
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      initialValues={{ tripId: tripId }}
      autoComplete="off"
    >
      <Form.Item
        label="Trip Id"
        name="tripId"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
         disabled={true} 
        />
      </Form.Item>

      <Form.Item
        label="Vehicle EMI"
        name="vehicleEmi"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Permits"
        name="permits"
        rules={[
          {
            required: true,
            message: 'Please input your password!',
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Button type="primary" htmlType="submit">
          {buttonName}
        </Button>
      </Form.Item>
    </Form>
  );
};