import React , { useState } from 'react';
import { Button , Modal } from 'antd';
import {
    InfoCircleOutlined
  } from '@ant-design/icons';

export const SeatLegend = props => {

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    return (
        <div className='seat-legend-root'>
                <Button onClick={showModal} className="outline-button">
                    <div className="indicator-container">
                        <div className="indicator-description">
                                <span className='seat-colors-txt'>
                                    Seat Colors
                                </span>
                                <InfoCircleOutlined />
                        </div>
                    </div>
                </Button>
                <Modal title="Legends" 
                 maskClosable={false}
                 visible={isModalVisible} 
                 onOk={handleOk} 
                 onCancel={handleCancel}
                 footer={null}
                 >
                            <div className="seat-indicator-row">
                                <div className="indicator-container">
                                    <div className="ladies-seat-legend">
                                        {/* <div className="seat-line ladies-indicator"/> */}
                                    </div>
                                    <div className="indicator-description">
                                        Ladies Seat
                                    </div>
                                </div>
                                <div className="indicator-container">
                                    <div className="gents-seat-legend">
                                        {/* <div className="seat-line gents-indicator"/> */}
                                    </div>
                                    <div className="indicator-description">
                                        Gents Seat
                                    </div>
                                </div>
                                <div className="indicator-container">
                                    <div className="self-blocked-legend"/>
                                    <div className="indicator-description">
                                        Blocked-self
                                    </div>
                                </div>
                                <div className="indicator-container">
                                    <div className="blocked-others-legend"/>
                                    <div className="indicator-description">
                                        Blocked-others
                                    </div>
                                </div>
                                <div className="indicator-container">
                                    <div className="ota-legend"/>
                                    <div className="indicator-description">
                                        OTA blocked
                                    </div>
                                </div>
                                <div className="indicator-container">
                                    <div className="phone-blocked-legend"/>
                                    <div className="indicator-description">
                                        Phone blocked
                                    </div>
                                </div>
                            </div>
                </Modal>
        </div>
    );
};

SeatLegend.defaultProps = {

};

SeatLegend.propTypes = {

};
