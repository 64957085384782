/**
 * @author Manne Hareesh
 * @description Reducer for the components in home page
 */
import {
    SET_DESTINATION_CITIES,
    SET_ORIGIN_CITIES,
    SET_SELECTED_SCHEDULE_SEATS,
    SET_SELECTED_SCHEDULE,
    LOG_OUT_ACTION,
    SET_BOOKED_TICKET,
    SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE,
    SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE
 } from '../actions/action-types';
  
  const initialState = {
      origins: undefined,
      originSupportedDestinations:undefined,
      selectedScheduleSeats:undefined,
      selectedSchedule:undefined,
      bookedTicketObject:undefined,
      dropTimingsByTripCity:undefined
  }
  
  export const homeReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
          case SET_ORIGIN_CITIES :
                newState["origins"] = action.payload;
                return newState;
            
          case SET_DESTINATION_CITIES :
                newState["originSupportedDestinations"] = action.payload;
                return newState;
          
          case SET_SELECTED_SCHEDULE_SEATS : 
                newState["selectedScheduleSeats"] = action.payload;
                return newState;

          case SET_SELECTED_SCHEDULE : 
                newState["selectedSchedule"] = action.payload;
                return newState;

         case SET_BOOKED_TICKET:
            newState["bookedTicketObject"] = action.payload;
            return newState;

        case SET_PICK_UP_POINTS_DETAILS_SELECTED_SCHEDULE:
            newState["selectedSchedule"]["pickupTimingsByTripCity"]= action.payload;
            return newState;

        case SET_DROP_UP_POINTS_DETAILS_SELECTED_SCHEDULE:
            newState["selectedSchedule"]["dropTimingsByTripCity"]= action.payload;
            return newState;

          case LOG_OUT_ACTION :
            return initialState;

          default:
              return state;
      }
  }
  