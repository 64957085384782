import React, { useState } from "react";
import { Form , Button , Radio ,Row ,Col , DatePicker , Typography , Table} from 'antd';
import { getRangeOptions , getPaginationDetails, getDefaultPageSize} from './reports.module';
import { fetchWebTicketSalesSheet} from './../../actions/reports.action';
import { getAPIDateFormat , convertDateStringToDate ,getLocalDate , getLocalDateAndTime, getDateTimeFormat} from './../../utils/date.utils';
import { isValid, isValidArray, isValidNumber } from "../../utils/utilities";
import { roundNumber } from './../../utils/math.utils';
import { Printer } from './../../components/custom-components/custom-component';
import ReactExport from "react-export-excel";
import { dafidyUserCheck } from "../../utils/constant";
import { loadingAction } from "../../actions/loading-actions";
import { useDispatch } from "react-redux";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const {Text} =Typography;

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const getFilteredOptions=(confirmedTickets,cancelledTickets)=>{
    let confirmedTicketOptions = {
        operatorOptions:[],
        addedOperators:[],
        scheduleOptions:[],
        addedSchedules:[]
    } , cancelledTicketOptions = {
        operatorOptions:[],
        addedOperators:[],
        scheduleOptions:[],
        addedSchedules:[]
    };
    if(isValidArray(confirmedTickets)&&confirmedTickets.length>0){
        confirmedTickets.forEach((element)=>{
            if(confirmedTicketOptions.addedOperators.indexOf(element.operator_name) === -1){
                confirmedTicketOptions.addedOperators.push(element.operator_name);
                confirmedTicketOptions.operatorOptions.push({
                    text:element.operator_name,
                    value:element.operator_name
                }); 
            }

            if(confirmedTicketOptions.addedSchedules.indexOf(element.description) === -1){
                confirmedTicketOptions.addedSchedules.push(element.description);
                confirmedTicketOptions.scheduleOptions.push({
                    text:element.description,
                    value:element.description
                }); 
            }
        })
    }

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach((element)=>{
            if(cancelledTicketOptions.addedOperators.indexOf(element.operator_name) === -1){
                cancelledTicketOptions.addedOperators.push(element.operator_name);
                cancelledTicketOptions.operatorOptions.push({
                    text:element.operator_name,
                    value:element.operator_name
                }); 
            }

            if(cancelledTicketOptions.addedSchedules.indexOf(element.description) === -1){
                cancelledTicketOptions.addedSchedules.push(element.description);
                cancelledTicketOptions.scheduleOptions.push({
                    text:element.description,
                    value:element.description
                }); 
            }
        });
    }

    return {
        confirmedTicketOptions,
        cancelledTicketOptions
    }
}


const generateJsonToExport=(filters,confirmedTickets,cancelledTickets,transactionDetailsByTicketId,extraTransactions)=>{

    let reportsConfirmedXmlData=[];
    let reportsCancelledXmlData=[];
    let reportsExtraPayments=[];

    if(isValidArray(confirmedTickets)&&confirmedTickets.length>0){
        confirmedTickets.forEach(element => {
            let bookingTIme=getLocalDateAndTime(element.booking_time);
            let startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]));
            let amountPaid =roundNumber(isValidNumber(element.amount_paid)?element.amount_paid : element.total_ticket_cost);
            let orderNo = '';
            if(isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[element.ticket_id])){
                orderNo = transactionDetailsByTicketId[element.ticket_id].order_no
            }
            reportsConfirmedXmlData.push([
                element.pnr,element.seat_count,
                element.seat_number,
                element.total_ticket_cost,element.direct_discount,element.commission,amountPaid,
                bookingTIme,startDate,element.description,orderNo
            ])
        });
    }

    if(isValidArray(cancelledTickets)&&cancelledTickets.length>0){
        cancelledTickets.forEach(element => {
            let bookingTIme=getLocalDateAndTime(element.booking_time);
            let startDate =getLocalDate(convertDateStringToDate(element.start_date.split('T')[0]));
            let refund = roundNumber(element.amountPaidWithMiles-element.commission-element.cancellation_penalty);
            let amountPaid =roundNumber(isValidNumber(element.amount_paid)?element.amount_paid:element.total_ticket_cost);
            let orderNo = '';
            if(isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[element.ticket_id])){
                orderNo = transactionDetailsByTicketId[element.ticket_id].order_no
            }
            reportsCancelledXmlData.push([
                element.pnr,element.seat_count,
                element.seat_number,
                element.total_ticket_cost,element.direct_discount,element.commission,amountPaid,element.cancellation_penalty,
                refund,bookingTIme,startDate,element.description,orderNo
            ])
        });
    }

    if(isValidArray(extraTransactions)&&extraTransactions.length>0){
        extraTransactions.forEach((element)=>{
            let paymentTime=getDateTimeFormat(element.payment_time);
            reportsExtraPayments.push([element.order_no,paymentTime,element.payment_amount]);
        })
    }

    // let agentName=filters.isSelectAllAgents?'all':filters.agentName;
    let dateType =filters.isBookingDate?"Booking":"Travel";
    let generatedTime = getLocalDateAndTime(new Date());
    const multiDataSet = [
        {
            columns: ["Report","From Date", "To Date","Type of date" , "Generated day/time"],
            data: [[
                "Web ticket sales report",filters.fromDate,filters.toDate,dateType,generatedTime
            ]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "PNR" , "Seat count" ,
                "Seat number" ,
                "Total amount","Discount","Commission","Amount paid",
                "Booking time","Travel date","Trip description","Transaction details"],
            data: reportsConfirmedXmlData
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "Order No" , "Payment Time" ,
                "Payment Amount"],
            data: reportsExtraPayments
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "PNR" , "Seat count" ,
                "Seat number" ,
                "Total amount","Discount","Commission" ,"Cancelled amount","Cancellation penality" ,
                "Refund","Booking time","Travel date","Trip description","Cancelled date","Transaction details"],
            data: reportsCancelledXmlData
        }
    ];


    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"web-ticket-sales-report"}>
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}


const getTicketsByAgentName=(tickets,agentName,isSelectAllAgents)=>{
    let formatedTickets=[];
    let seatCount = 0,
    totalTicketCost = 0,
    directDiscount = 0,
    totalCommission = 0,
    amountPaid = 0,
    cancellationPenalty = 0;

    tickets.forEach(element => {
        if(isSelectAllAgents||(isValid(agentName)&&element.name===agentName)){
            seatCount=seatCount+element.seat_count;
            totalTicketCost = totalTicketCost + element.total_ticket_cost;
            directDiscount = directDiscount + element.direct_discount;
            cancellationPenalty = cancellationPenalty +element.cancellation_penalty;

            amountPaid = amountPaid + roundNumber(isValidNumber(element.amount_paid)?element.amount_paid:element.total_ticket_cost);
            totalCommission = element.commission + totalCommission;

            formatedTickets.push(element);
        }
    });
    return {dataSource:formatedTickets,total:{
        seatCount,
        totalTicketCost,
        directDiscount,
        totalCommission,
        amountPaid,
        cancellationPenalty,
        refundAmount:amountPaid-cancellationPenalty
    }};
}

const RenderReport=({fromDate,toDate,isBookingDate,agentName,confirmedTickets,cancelledTickets,extraTransactions,pagination,isSelectAllAgents,transactionDetailsByTicketId})=>{

    let {
        confirmedTicketOptions,
        cancelledTicketOptions
    } = getFilteredOptions(confirmedTickets,cancelledTickets);


    const [confirmPage, setConfirmPage] = useState(1);
    const [cancelPage, setCancelPage] = useState(1);
    const [confirmPaginationSize, setConfirmPaginationSize] = useState(getDefaultPageSize);
    const [cancelPaginationSize, setCancelPaginationSize] = useState(getDefaultPageSize);

    const confirmTicketsColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (confirmPage - 1) * confirmPaginationSize + index+1
        },
        {
            title:'Agent Name',
            key:'name',
            dataIndex:'name'
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Seat count',
            key:'seat_count',
            dataIndex : 'seat_count'
        },
        {
            title:'Seat number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Total amount',
            key:'total_ticket_cost',
            dataIndex : 'total_ticket_cost'
        },
        {
            title:'Discount',
            key:'direct_discount',
            dataIndex : 'direct_discount'
        },
        {
            title:'Commission',
            key:'commission',
            dataIndex : 'commission'
        },
        {
            title:'Amount paid',
            key:'amountPaid',
            render : (text, record, index) => roundNumber(isValidNumber(record.amount_paid)?record.amount_paid:record.total_ticket_cost)
        },
        {
            title:'Booking time',
            key:'booking_time',
            render : (text, record, index) => getLocalDateAndTime(record.booking_time)
        },
        {
            title:'Travel date',
            key:'pnr',
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description',
            filters:confirmedTicketOptions.scheduleOptions,
            onFilter: (value, record) => record.description.indexOf(value) === 0
        },
        {
            title:"Transaction details",
            key:'ticket_id',
            dataIndex : 'ticket_id',
            render : (text, record, index) => {
                if(isValid(text)&&isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[text])){
                    return transactionDetailsByTicketId[text].order_no
                }else{
                    return ''
                }
            }
        }
    ];

    if(dafidyUserCheck()){
        confirmTicketsColumns.push({
            title : "Operator name",
            key : "operator_name",
            dataIndex : "operator_name",
            filters:confirmedTicketOptions.operatorOptions,
            onFilter: (value, record) => record.operator_name.indexOf(value) === 0,
        })
    }

    const cancelTicketsColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (cancelPage - 1) * cancelPaginationSize + index+1
        },
        {
            title:'Agent Name',
            key:'name',
            dataIndex:'name'
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr'
        },
        {
            title:'Seat count',
            key:'seat_count',
            dataIndex : 'seat_count'
        },
        {
            title:'Seat number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Total amount',
            key:'total_ticket_cost',
            dataIndex : 'total_ticket_cost'
        },
        {
            title:'Discount',
            key:'direct_discount',
            dataIndex : 'direct_discount'
        },
        {
            title:'Commission',
            key:'commission',
            dataIndex : 'commission'
        },
        {
            title:'Cancelled amount',
            key:'amountPaid',
            render : (text, record, index) => roundNumber(isValidNumber(record.amount_paid)?record.amount_paid:record.total_ticket_cost)
        },
        {
            title:'Penality',
            key:'cancellation_penalty',
            dataIndex : 'cancellation_penalty'
        },
        {
            title:'Refund amount',
            key:'net_amount',
            render : (text, record, index) =>{
                let amountPaid =isValidNumber(record.amount_paid)?record.amount_paid:record.total_ticket_cost;
                return roundNumber(amountPaid-record.cancellation_penalty)
                }
        },
        {
            title:'Booking time',
            key:'booking_time',
            render : (text, record, index) => getLocalDateAndTime(record.booking_time)
        },
        {
            title:'Travel date',
            key:'pnr',
            render : (text, record, index) => {
                if(isValid(record.start_date)){
                    return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Trip description',
            key:'description',
            dataIndex : 'description',
            filters:cancelledTicketOptions.scheduleOptions,
            onFilter: (value, record) => record.description.indexOf(value) === 0
        },
        {
            title:'Cancelled date',
            key:'cancellation_time',
            render : (text, record, index) => {
                if(isValid(record.cancellation_time)){
                    return getLocalDate(convertDateStringToDate(record.cancellation_time.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:"Transaction details",
            key:'ticket_id',
            dataIndex : 'ticket_id',
            render : (text, record, index) => {
                if(isValid(text)&&isValid(transactionDetailsByTicketId)&&isValid(transactionDetailsByTicketId[text])){
                    return transactionDetailsByTicketId[text].order_no
                }else{
                    return ''
                }
            }
        },
        {
            title:"Automatic refund",
            key:'automatic_refund',
            dataIndex : 'automatic_refund',
            render : (text, record, index) => {
                return record.automatic_refund === 1? 'Yes': 'No'
            }
        }
    ];

    if(dafidyUserCheck()){
        cancelTicketsColumns.push({
            title : "Operator name",
            key : "operator_name",
            dataIndex : "operator_name",
            filters:cancelledTicketOptions.operatorOptions,
            onFilter: (value, record) => record.operator_name.indexOf(value) === 0
        })
    }
    let dataSource = {
        confirmed:{
            ...getTicketsByAgentName(confirmedTickets,agentName,isSelectAllAgents)
        },
        cancelled:{
            ...getTicketsByAgentName(cancelledTickets,agentName,isSelectAllAgents)
        }
    }

    return(
        <div>
            <Row justify="center" gutter={[16,16]}>
                <Col>
                    <Text strong>Web ticket sales report</Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>From date : </Text>{fromDate}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>To date : </Text>{toDate}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]} justify={"space-between"}>
                <Col  xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                        <Text strong>Type of date : </Text>{isBookingDate?"Booking Date":"Travel Date"}
                    </Text>
                </Col>
                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                    <Text>
                            <Text strong>Generated day/time : </Text>{getLocalDateAndTime(new Date())}
                    </Text>
                </Col>
            </Row>
            <Row gutter={[16,0]}>
                <Col>
                    <Text strong>Agent : {isSelectAllAgents?"All agents":agentName}</Text>
                </Col>
            </Row>
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table  
                        columns={confirmTicketsColumns}
                        dataSource={dataSource.confirmed.dataSource}
                        pagination={getPaginationDetails(pagination,setConfirmPage, confirmPaginationSize, setConfirmPaginationSize)}
                        className="bordered-table"
                        summary={(pageData ) => {
                            if(isValidArray(dataSource.confirmed.total)){
                                return (
                                    <>
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{dataSource.confirmed.total.seatCount}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.confirmed.total.totalTicketCost)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.confirmed.total.directDiscount)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.confirmed.total.totalCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell className="fontWeightBold">{roundNumber(dataSource.confirmed.total.amountPaid)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        {dafidyUserCheck()&&
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        }
                                      </Table.Summary.Row>
                                    </>
                                  );
                            }else{
                                return null;
                            }
                        }}
                    />
               </Col>
           </Row>
           {isValidArray(extraTransactions)&&extraTransactions.length>0&&
           <div>
            <Row gutter={[16,16]}>
                <Col>
                    <Text strong>Additional payments for rescheduling</Text>
                </Col>
            </Row>
            <Row gutter={[16,16]} className="marginTop">
                <Col>
                    <Table 
                        columns={[
                            {
                                title:'Order no',
                                key:'order_no',
                                dataIndex:'order_no'
                            },
                            {
                                title:'Payment Time',
                                key:'payment_time',
                                dataIndex:'payment_time',
                                render : (text, record, index) => getDateTimeFormat(record.payment_time)
                            },
                            {
                                title:'Payment Amount',
                                key:'payment_amount',
                                dataIndex:'payment_amount'
                            }, 
                        ]}
                        dataSource={extraTransactions}
                        pagination={false}
                        className="bordered-table"
                        summary={(pageData ) => {
                            let summaryTotalFare = 0;
                            pageData.forEach(({ payment_amount}) => {
                                if(isValidNumber(payment_amount)){
                                summaryTotalFare = summaryTotalFare + payment_amount;
                                }
                            });
                            return (
                                <>
                                <Table.Summary.Row>
                                    <Table.Summary.Cell></Table.Summary.Cell>
                                    <Table.Summary.Cell>Total</Table.Summary.Cell>
                                    <Table.Summary.Cell>{summaryTotalFare}</Table.Summary.Cell>
                                </Table.Summary.Row>
                                </>
                            );
                        }}
                        />
                    </Col>
                </Row>
            </div>
           }
           <Row gutter={[16,16]} className="marginTop">
               <Col>
                <Table  
                        columns={cancelTicketsColumns}
                        dataSource={dataSource.cancelled.dataSource}
                        pagination={getPaginationDetails(pagination,setCancelPage, cancelPaginationSize, setCancelPaginationSize)}
                        className="bordered-table"
                        summary={(pageData ) => {
                            if(isValidArray(dataSource.confirmed.total)){
                                return (
                                    <>
                                      <Table.Summary.Row>
                                        <Table.Summary.Cell>Total</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell>{dataSource.cancelled.total.seatCount}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.cancelled.total.totalTicketCost)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.cancelled.total.directDiscount)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.cancelled.total.totalCommission)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.cancelled.total.amountPaid)}</Table.Summary.Cell>
                                        <Table.Summary.Cell>{roundNumber(dataSource.cancelled.total.cancellationPenalty)}</Table.Summary.Cell>
                                        <Table.Summary.Cell className="fontWeightBold">{roundNumber(dataSource.cancelled.total.refundAmount)}</Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        {dafidyUserCheck()&&
                                        <Table.Summary.Cell></Table.Summary.Cell>
                                        }
                                      </Table.Summary.Row>
                                    </>
                                  );
                            }else{
                                return null;
                            }
                        }}
                    />
               </Col>
           </Row>
        </div>
    )
}

export const WebSalesTicketReport = () => {

    const [form] = Form.useForm();

    const [showReport,setShowReport]=useState(false);

    const dispatch = useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
        isSelectAllAgents:true,
        isSelectAllSchedules:false,
        agentNames:[],
        schedules:[],
        operators:[]
    });

    const [cancelledTickets,setCancelledTickets]=useState(undefined);
    const [confirmedTickets,setConfirmedTickets]=useState(undefined);
    const [extraTransactions,setExtraTransactions]=useState(undefined);

    const [transactionDetailsByTicketId,setTransactionDetailsByTicketId]=useState(undefined);

    const onFinish=(values)=>{
        setLoading(true);
        let {
            dateRange,
            isBooking
        }=values;
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setShowReport(false);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        filters.isBookingDate=isBooking;
        setFilters({...filters});
        fetchWebTicketSalesSheet(null,fromDate,toDate,isBooking,(data,)=>{
        setCancelledTickets(data.cancelledTransactions);
        setConfirmedTickets(data.confirmedTransactions);
        setExtraTransactions(data.extraTransactions);
        setTransactionDetailsByTicketId(data.transactionDetailsByTicketId);
        setShowReport(true);
        setLoading(false);
        },()=>{
            setLoading(false);
        });
    }


    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range"
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker
                                        ranges={getRangeOptions(allowedMaxDaysRange)}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                        <Form.Item name="isBooking" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                            <Radio.Group>
                                <Radio value={true}>Booking Date</Radio>
                                <Radio value={false}>Travel Date</Radio>
                            </Radio.Group>
                        </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&
                <div>
                    <Row gutter={16}>
                        <Col>
                        {
                            generateJsonToExport(filters,confirmedTickets,cancelledTickets,transactionDetailsByTicketId,extraTransactions)
                        }
                        </Col>
                        <Col>
                                <Printer
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                        <RenderReport
                                            fromDate={filters.fromDate}
                                            toDate={filters.toDate}
                                            isBookingDate={filters.isBookingDate}
                                            agentName={filters.agentName}
                                            isSelectAllAgents={filters.isSelectAllAgents}
                                            confirmedTickets={confirmedTickets}
                                            transactionDetailsByTicketId={transactionDetailsByTicketId}
                                            cancelledTickets={cancelledTickets}
                                            extraTransactions={extraTransactions}
                                            pagination={false}
                                        />
                                )
                            }} />
                        </Col>
                    </Row>
                    <RenderReport
                        fromDate={filters.fromDate}
                        toDate={filters.toDate}
                        isBookingDate={filters.isBookingDate}
                        agentName={filters.agentName}
                        isSelectAllAgents={filters.isSelectAllAgents}
                        confirmedTickets={confirmedTickets}
                        transactionDetailsByTicketId={transactionDetailsByTicketId}
                        cancelledTickets={cancelledTickets}
                        extraTransactions={extraTransactions}
                        pagination={true}
                    />
                </div>
                }
            </div>
    )
}
