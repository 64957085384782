import React from "react";
import { Button, message } from "antd";
import { showConfirm } from './../custom-components/custom-component';
import { handleReleaseAllSeats } from './release-all-seats.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_BLOCK_ALL_TICKETS_ALLOWED } from './../../utils/constant';
import './release-all-seats.styles.scss';

export const ReleaseAllSeatsComponent = (props) => {

    const{
        selectedSchedule,
        fetchSeats,
        setSelectedTripRoute
    }=props;

    const onSuccess=()=>{
        setSelectedTripRoute(selectedSchedule.trip_route_id);
        fetchSeats(selectedSchedule.trip_route_id);
    }

    const onFailure=()=>{
        message.error("Failed to release seats!")
    }

    const handleClick=()=>{
        showConfirm(()=>{
            if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
                let tripId=selectedSchedule.trip.id;
                handleReleaseAllSeats(tripId,selectedSchedule.trip_route_id,onSuccess,onFailure);
            }else{
                message.error("Trip not selected!")
            }
        },"Release all seats","Are you sure to release all blocked seats");
    }
    if(checkActionAllowed(ACTION_BLOCK_ALL_TICKETS_ALLOWED)){
        return (
            <div className="marginTop marginBottom">
                <Button onClick={handleClick} className="release-all-seats-btn">
                    Release all blocked seats
                </Button>
            </div>
        )
    }else{
        return null
    }
}
