import { Form , Row , Col , DatePicker , Button , Table} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRangeOptions } from './reports.module';
import { getTrackingReportDataAction } from '../../actions/reports.action';
import { getAPIDateFormat } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";
import { isValidNumber } from "../../utils/utilities";

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;

export const TrackingSummaryReport = (props) => {

    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const [trackingSummary,setTrackingSummary] = useState([]);


    const onSuccess=(data)=>{
        setTrackingSummary(data);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);  
        getTrackingReportDataAction(fromDate,toDate,onSuccess,onFailure);
    }

    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    // {
    //     "tripCount": 4,
    //     "gpsVendor": "Sensel",
    //     "operatorName": "The King Travels",
    //     "vehicleAssigned": 4,
    //     "trackingCount": 4
    // }

    const columns = [
        {
            title:"Operator Name",
            dataIndex:"operatorName",
            key:"operatorName"
        },
        {
            title: "GPS Vendor",
            dataIndex: "gpsVendor",
            key: "gpsVendor"
        },
        {
            title:"Trip Count",
            dataIndex:"tripCount",
            key:"tripCount"
        },
        {
            title:"Vehicle Assigned",
            dataIndex:"vehicleAssigned",
            key:"vehicleAssigned"
        },
        {
            title: "Tracking Count",
            dataIndex: "trackingCount",
            key: "trackingCount"
        }
    ];

  
      return (
          <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Get</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                            <Table 
                                columns={columns}
                                dataSource={trackingSummary}
                                pagination={false}
                                className={"bordered-table"}
                                summary={(pageData ) => {
                                    let summaryTripCount = 0;
                                    let summaryVehicleAssigned = 0;
                                    let summaryTrackingCount = 0;
                                    let summaryFuture=0;
                                    let summaryAmount = 0;
                                    pageData.forEach(({ tripCount, vehicleAssigned, trackingCount}) => {
                                      if(isValidNumber(tripCount)){
                                        summaryTripCount = summaryTripCount + tripCount;
                                      }
                                      if(isValidNumber(vehicleAssigned)){
                                        summaryVehicleAssigned = summaryVehicleAssigned + vehicleAssigned;
                                      }
                                      if(isValidNumber(trackingCount)){
                                        summaryTrackingCount = summaryTrackingCount + trackingCount;
                                      }
                                    });

                                    return(
                                        <Table.Summary fixed>
                                        <Table.Summary.Row  style= {{fontWeight: 'bold'}}>
                                          <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                          <Table.Summary.Cell index={1}></Table.Summary.Cell>
                                          <Table.Summary.Cell index={2}>{summaryTripCount}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={3}>{summaryVehicleAssigned}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={4}>{summaryTrackingCount}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    )
                            }}
                            />
                    </Col>
                </Row>
          </div>
      )
  }
            

      
