import React from "react";
import { Route , Redirect , useLocation} from 'react-router-dom';
import { useSelector } from "react-redux";
import { userLoginSelector } from '../selector/user.selector';

const PrivateRoute = ({ component: Component, ...rest }) => {

    const isLoggedIn=useSelector(userLoginSelector);
    const location = useLocation();
    const pathName = location.pathname;
    global.loginPathName=pathName;//setting last path name on refresh to work
    global.loginSearch=location.search;
    return (
      <Route
        {...rest}
        render={props =>
          isLoggedIn ? (
            <Component {...props}/>
          ) : (
            <Redirect to={{ pathname: '/login' }} state={{pathName}} />
          )
        }
      />
    )
  }

  export default PrivateRoute;
