import React ,{useContext} from 'react'
import './map.component.style.scss';
import { DeviceContext } from './../../../components/device-provider/device-provider';

function BusLocationMapComponent(props) {

  const {
    isMobile
  }=useContext(DeviceContext);

  let containerStyle = {
    width: '100%',
    height: '400'
  };

  if(isMobile){
    containerStyle['width']='300';
    containerStyle['height']='200';
  }

    const {
        latitude,longitude
    } = props;

    const mapsApiKey = global.googleMapsApi;

  return(
    <div>
        <iframe
                seamless
                defer 
                width={containerStyle.width}
                height={containerStyle.height}
                style={{border:0}}
                frameborder="0"
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${mapsApiKey}&q=${latitude},${longitude}`}>
        </iframe>
    </div>
  ) 
}

export default BusLocationMapComponent;

//Note refs: https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself
// https://codesandbox.io/s/eloquent-hopper-16k2e?file=/src/App.js:288-431
// https://stackoverflow.com/questions/64165413/react-google-maps-api-directionsservice-keeps-rerendering-itself