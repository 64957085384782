import React, { useEffect, useState } from "react";
import '../styles/login-page.style.scss';
import { Form, Input , Button} from 'antd';
import { userLogin ,setRunSplashScreen, setLoginUserDetails , verifyOtp} from '../actions/user.action';
import { useDispatch , useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { runSplashScreenSelector } from './../selector/user.selector';
import { LoadingSpinner } from './../components/custom-components/custom-component';
import { LOGIN_ACCESS_TOKEN_KEY , AGENT_COLORS_ACCESS_TOKEN_KEY, DAFIDY_USER } from './../utils/constant';
import { getLocalStorage, isValid ,setAgentPortalColors,setLocalStorage} from './../utils/utilities';

export const LoginPage = () => {

    const dispatch=useDispatch();
    const history=useHistory();
    const runSplashScreen = useSelector(runSplashScreenSelector);
    const [showOtpContent , setShowOtpContent] = useState(false);
    const [apiResult , setApiResult] = useState({});

    const onFinish = (values) => {
        let {
            username,
            password
        }=values;
       loginDispatch(username,password,null);
      };

      const loginDispatch=(username,password,access_code)=>{
        dispatch(userLogin(username,password,access_code,(clientPayload)=>{
            if(clientPayload.verify_otp_for_login === 1){
                setShowOtpContent(true);
                setApiResult(clientPayload);
            }else{
                onLogin(clientPayload);
            }
        }));
      }

      const onLogin=(clientPayload)=>{
        setLocalStorage(LOGIN_ACCESS_TOKEN_KEY,clientPayload.access_code);
        dispatch(setLoginUserDetails(clientPayload));
        let pathName = 'home';
        if(clientPayload.privilege === DAFIDY_USER){
            pathName = 'report/webticketsalesreport';
        }else if(clientPayload.privilege == 'Prepaid-OTA'){
            pathName= 'prepaid-ota';
        }
        if(isValid(global.loginPathName)&&global.loginPathName !== '/'){
            pathName = global.loginPathName;
            if(isValid(global.loginSearch)){
                pathName = pathName + global.loginSearch;
            }
        }
        history.push(pathName);
      }

      useEffect(()=>{
        let accessCode = getLocalStorage(LOGIN_ACCESS_TOKEN_KEY);
        let agentPortalColors = getLocalStorage(AGENT_COLORS_ACCESS_TOKEN_KEY);
        if(isValid(agentPortalColors)){
            agentPortalColors=JSON.parse(agentPortalColors);
            setAgentPortalColors(agentPortalColors);
        }

        if(isValid(accessCode)){
            loginDispatch(null,null,accessCode);
        }else{
            dispatch(setRunSplashScreen(false));
        }
      },[]);

      const onFinishVerifyOtp=(values)=>{
          let {
            otp
          } = values;
        dispatch(verifyOtp(otp,apiResult.phone_number,()=>{
            onLogin(apiResult);
            setShowOtpContent(false);
        }));
      }

    if(runSplashScreen){
        return <div className="login-form-root">
                    <LoadingSpinner />
                </div>
    }
    if(showOtpContent){
        return (
                <div className="login-form-root">
                    <div className='login-form-secondary'>
                        <p>{`Verification code is sent to your phone number , ${apiResult.phone_number}`}</p>
                        <p>Please enter the verification code.</p>
                        <Form
                        onFinish={onFinishVerifyOtp}
                        >
                                <Form.Item
                                name={'otp'}
                                >
                                    <Input placeholder="Enter your OTP!" />
                                </Form.Item>
                                <Form.Item>
                                    <Button block type="primary" htmlType="submit" className="btn-lg">
                                        Verify
                                    </Button>
                                </Form.Item>
                        </Form>
                    </div>
            </div>
        )
    }
    return (
        <div className="login-form-root">
               <div className='login-form-secondary'>
                   <h3 className="heading-div">Welcome to the Agent Booking Portal</h3>
                   <div>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            >
                                <Form.Item
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your Username!' }]}
                                >
                                    <Input
                                    // prefix={<UserOutlined className="site-form-item-icon" />}
                                    placeholder="Enter Username"
                                    className="defalut-input-style"
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your Password!' }]}
                                >
                                    <Input
                                    // prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Enter Password"
                                    className="defalut-input-style"
                                    />
                                </Form.Item>
                                <div className="login-form-button">
                                    <Form.Item >
                                        <Button type="primary" htmlType="submit">
                                            Sign in
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                   </div>
               </div>
        </div>
    );
}
