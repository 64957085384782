import { RELEASE_ALL_BLOCKED_SEATS_URL } from './../../utils/api-url';
import { postCall } from "./../../utils/network.util";

 export const handleReleaseAllSeats=(tripId,tripRouteId,onSuccess=()=>{},onFailure=()=>{})=>{
      var released = postCall(RELEASE_ALL_BLOCKED_SEATS_URL, {
        tripId: tripId,
        tripRouteId: tripRouteId,
        updatedBy : global.userName,
        isManualReleased : true
      });
      released.then((data) => {
        if(data){
          onSuccess();
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }