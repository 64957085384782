import React, {useState} from "react";
import '../styles/notification.style.scss';
import { SearchScheduleComponent } from './../components/search-components/search-schedule-component/search-schedule.component';
import { Form , Button , Row , Col, Input, message } from 'antd';
import { isValid, isValidArray } from "../utils/utilities";
import { ScheduleSelect} from './../components/search-components/schedule-select/schedule-select.component';
import { CustomModal } from './../components/custom-components/custom-component';
import { NOTIFICATION_DELAY_BUS_URL,SEND_CANCELLATION_NOTIFICATION_URL } from './../utils/api-url';
import { loadingAction } from './../actions/loading-actions';
import { useDispatch } from "react-redux";

const { TextArea }= Input ;

const DrawLine =()=>{
    return(
        <div className="notification-underline">

        </div>
    )
}

const getScheduleDetails=(tripId,schedules)=>{
    let schedule=null;
    if(isValidArray(schedules)&&schedules.length>0){
         schedules.forEach((element)=>{
                if(isValid(element.trip_id)&&tripId===element.trip_id){
                    schedule = element;
                }
            })
    }
    return schedule;
}

const sendDelayNotification=(delayMessage,delaytime,travelDate,tripId,setLoading)=>{
    setLoading(true); 
    return fetch(NOTIFICATION_DELAY_BUS_URL, {
      method: "POST",
      headers:
      {'Content-Type':'application/json'}
      ,
      body: JSON.stringify(
        { "delayInfo": {
            "tripId" : tripId,
            delayTime:delaytime,
            delayMessage,
            travelDate:travelDate,
            operatorCode:global.operatorCode,
            operatorName:global.operatorName
        }
        })
      }).then(response => response.json())
      .then(data => {
        setLoading(false);
          if (data === false){
            message.error("Notification could not be sent due to unexpected error");
              return false;
          }
          else{
              message.success("Notification sent successfully");
              return true;
          }
      });
  }

 const sendCancelNotification=(cancelMessage,travelDate,tripId,setLoading)=>{
    setLoading(true);
    return fetch(SEND_CANCELLATION_NOTIFICATION_URL, {
      method: "POST",
      headers:
      {'Content-Type':'application/json'}
      ,
      body: JSON.stringify(
      { "cancelInfo": {"tripId" : tripId,
                      "cancelMessage": cancelMessage,
                      travelDate:travelDate,
                      operatorCode:global.operatorCode,
                      operatorName:global.operatorName
                      }}
      )
      }).then(response => response.json())
      .then(data => {
          setLoading(false);
          if (data === false){
            message.error("Notification could not be sent due to unexpected error");
            return false;
          }
          else{
            message.success("Notification sent successfully");
            return true;
          }
      });
  }

const DelayBody=({delayForm,travelDate,origin,destination})=>{

    let defaultDelayEmailMsg=`Dear Customer, Thank you so much for choosing ${global.operatorName}. Due to some unforseen reasons, the bus that you were scheduled to travel on ${travelDate} from ${origin} to ${destination} has been delayed by minvariable mins. We sincerely apologize for the inconvenience. We will try our very best to avoid such misfortunes in the future.Thank you so much for your understanding,- ${global.operatorName}`;
    // let defaultDelaySmsMsg=`Dear Customer, Due to unforeseen reasons, your bus on 2021-09-02 has been delayed by minvariable mins. We apologize for the inconvenience-${global.operatorName}`;

    const setDefaultValues=()=>{
        let delaytime=delayForm.getFieldValue('delaytime');
        if(isValid(delaytime)){
            delayForm.setFieldsValue({
                emailMsg:defaultDelayEmailMsg.replace('minvariable',delaytime)
                // smsMsg:defaultDelaySmsMsg.replace('minvariable',delaytime)
            })
        }else{
            message.error("Please input time in mins!")
        }
    }


    return(
        <div>
            <Form 
               form={delayForm}
            >
                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="delaytime"
                        label="Delay Time:"
                        rules={[{ required: true, message: 'Please input your Delay time!' }]}
                        >
                            <Input className="defalut-input-style" placeholder={"Delay time"}/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={setDefaultValues}>
                                SET
                        </Button>
                    </Col>
                </Row>

                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="emailMsg"
                        label="Email Message:"
                        rules={[{ required: true, message: 'Please input your email message!' }]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="smsMsg"
                        label="SMS Message:"
                        rules={[{ required: true, message: 'Please input your sms message!' }]}
                        >
                            <TextArea maxLength={158} showCount={true}/>
                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>
        </div>
    )
}


const CancelBody=({cancelForm,travelDate,origin,destination})=>{

    let defaultCancelEmailMsg=`Dear Customer,Thank you so much for choosing ${global.operatorName}. Due to some unforseen reasons, the bus that you were scheduled to travel on ${travelDate} from ${origin} to ${destination} has been cancelled. We sincerely apologize for the inconvenience. We will try our very best to avoid such misfortunes in the future.Thank you so much for your understanding,- ${global.operatorName}`;

    // let defaultCancelSmsMsg=`Dear Customer,Due to unforeseen reasons,your bus on 2021-09-02 has been cancelled.We apologize for the inconvenience-${global.operatorName}`;


    return(
        <div>
             <Form 
               initialValues={{emailMsg:defaultCancelEmailMsg}}
               form={cancelForm}
            >
                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="emailMsg"
                        label="Email Message:"
                        rules={[{ required: true, message: 'Please input your email message!' }]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="smsMsg"
                        label="SMS Message:"
                        rules={[{ required: true, message: 'Please input your sms message!' }]}
                        >
                            <TextArea maxLength={158} showCount={true}/>
                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>
        </div>
    )
}

export const NotificationPage = () => {
    const [schedules,setSchedules]=useState(undefined);
    const [selectedSchedule,setSelectedSchedule]=useState(undefined);
    const [showNotifyButtons,setNotifyButtons]=useState(false);
    const [filters,setFilters]=useState({});

    const [form] = Form.useForm();
    const [delayForm]=Form.useForm();
    const [cancelForm]=Form.useForm();

    const dispatch=useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
        return form.validateFields()
        .then(values => {
            let {
                returnId
            }=values;
            setSelectedSchedule(getScheduleDetails(returnId,schedules));
            setNotifyButtons(true);
           return true;
        }).catch((err)=>{
          console.log({err});
          return false
        })
    }

    return (
        <div className="notification-root">
            <div className="notification-inner-div">
                <div>
                    <h3>
                    Notification
                    </h3>            
                    <DrawLine />
                </div>
               <SearchScheduleComponent 
                    isMobileFriendly={true}
                    onSearch={(data,date,origin,destination)=>{
                        setFilters({travelDate:date,origin:origin,destination:destination});
                        setSchedules(data);
                        setNotifyButtons(false);
                        form.resetFields();
                    }}
                />
                {isValidArray(schedules)&&schedules.length>0&&
                 <Form 
                 form={form}
                 >
                    <Row gutter={16}>
                        <Col>
                            <ScheduleSelect 
                               isFixedWidth={true}
                               schedules={schedules}
                               optionKey="trip_id"
                            />
                        </Col>
                        <Col>
                            <Form.Item>
                                    <Button type="primary" onClick={handleSubmit}>Get</Button>
                            </Form.Item>  
                        </Col> 
                    </Row>
                 </Form>
                }
                {showNotifyButtons&&
                <div>
                    <Row>
                        Click on any option!
                    </Row>
                    <Row gutter={[16,16]}>
                        <Col>
                            <CustomModal 
                                buttonName={"Delay Bus"}
                                buttonClassName={""}
                                onButtonClick={handleSubmit}
                                children={<DelayBody delayForm={delayForm} {...filters}/>}
                                onOk={async ()=>{
                                    return delayForm.validateFields()
                                    .then(async (values) => {
                                    let {
                                        delaytime,
                                        emailMsg
                                    }=values;
                                     return await sendDelayNotification(emailMsg,delaytime,filters.travelDate,selectedSchedule.trip_id,setLoading);
                                    }).catch((err)=>{
                                     console.log({err});
                                     return false
                                    })
                                }}
                                okButtonName={"Send Notification"}
                                modalTitle="Delay Notification"
                            />
                        </Col>
                        <Col>
                        <CustomModal 
                                buttonName={"Cancel Bus"}
                                buttonClassName={""}
                                onButtonClick={handleSubmit}
                                children={<CancelBody cancelForm={cancelForm} {...filters}/>}
                                onOk={async ()=>{
                                    return cancelForm.validateFields()
                                    .then(async (values) => {
                                    let {
                                        emailMsg
                                    }=values;
                                     return await sendCancelNotification(emailMsg,filters.travelDate,selectedSchedule.trip_id,setLoading);
                                    }).catch((err)=>{
                                     console.log({err});
                                     return false
                                    })
                                }}
                                okButtonName={"Send Notification"}
                                modalTitle="Cancel Notification"
                            />
                        </Col>
                    </Row>
                </div>
                }
            </div>
             
        </div>
    )
}