import React, { useEffect, useState } from "react";
import { fetchCancelledTickets } from "../../actions/reports.action";
import { getLocalDateAndTime } from "../../utils/date.utils";
import { isValid, isValidArray, removeArrayIndexValue } from "../../utils/utilities";
import { View , StyleSheet} from '@react-pdf/renderer';
import Table from "../../pages/pdfs/common/Table";
import Row from "../../pages/pdfs/common/Row";
import SubTextBold from "../../pages/pdfs/common/SubTextBold";

const styles = StyleSheet.create({
    cancellationDetailsBlock:{
        justifyContent:'center',
        paddingTop:10
      },
      cancellationDetailsTxt:{
        textAlign:'center'
      }
});

export const CancelledTripSheetPdfComponent = (props) => {

    const {
        tripId,
        showFareDetails=true
    } = props;

    const [ cancelledReportData , setCancelledReportData] = useState([]);
    const [ oldTripId , setOldTripId] = useState(null);

    const onSuccess =(data)=>{
        setCancelledReportData(data);
    }

    const onFailure =(errorMessage)=>{
        // message.error(errorMessage);
    }

    useEffect(()=>{
        if(isValid(tripId)&&tripId!=oldTripId){
            setOldTripId(tripId);
            fetchCancelledTickets(tripId,onSuccess,onFailure)
        }
    },[tripId]);

    
    const columns = [
        {
          title: 'Seat No',
          dataIndex: 'seat_number',
          key: 'seat_number',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
          title: 'Ticket No',
          dataIndex: 'pnr',
          key: 'pnr',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
          title: 'Name',
          dataIndex: 'full_name',
          key: 'full_name',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
          title: 'Boarding',
          dataIndex: 'pick_up_location',
          key: 'pick_up_location',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
          title: 'Destination',
          dataIndex: 'destination',
          key: 'destination',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
          title: 'Drop Off',
          dataIndex: 'drop_location',
          key: 'drop_location',
          className:"control-margin",
          pdfRender: ({index,text,record}) => text
        },
        {
            title: 'Cancellation Charges',
            dataIndex: 'cancellation_penalty',
            key: 'cancellation_penalty',
            className:"control-margin" ,
            pdfRender: ({index,text,record}) => text

        },
        {
            title: 'Cancelled By',
            dataIndex: 'cancelled_by',
            key: 'cancelled_by',
            className:"control-margin",
            pdfRender: ({index,text,record}) => text
        },
        {
          title:'Cancellation time',
          dataIndex: 'cancellation_time',
          key: 'cancellation_time',
          className:"control-margin",
          render : (text) => {
                if(isValid(text)){
                    return getLocalDateAndTime(text)
                }else{
                    return ''
                }
            },
         pdfRender: ({index,text,record}) => getLocalDateAndTime(text)
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
            className:"control-margin",
            pdfRender: ({index,text,record}) => text
        }
      ];

      if(!showFareDetails){
        columns.splice(6, 1);
      }

   const renderCancelledSeats=()=>{
    let cancellationSeats=[];
    cancelledReportData.forEach((row,index) => {
        let cancellationCells=[];
        columns.forEach((column)=>{
            cancellationCells.push(
                <Table.Cell>
                    {column.pdfRender({text:row[column.dataIndex],record:row,index})}
                </Table.Cell> 
            );
        });
        cancellationSeats.push(
        <Table.Row key={row.id}>
            {cancellationCells}
        </Table.Row>
        );
    });
    return cancellationSeats;
   }


      if(isValidArray(cancelledReportData)&&cancelledReportData.length>0){
        return (
            <View>
                <Row  style={styles.cancellationDetailsBlock}>
                  <SubTextBold style={styles.cancellationDetailsTxt}>Cancellation details</SubTextBold>
                </Row>
                <Row>
                    <Table>
                        <Table.Header>
                            {columns.map(column => (
                            <Table.HeaderCell >{column.title}</Table.HeaderCell>
                            ))}
                        </Table.Header>
                        <Table.Body>
                            {renderCancelledSeats()}
                        </Table.Body>
                    </Table>
                </Row>
            </View>
        )
      }else{
        return null;
      }
}