import React, { useState } from "react";
import { Button, Form, DatePicker, Row , Col, message ,Table} from 'antd';
import { fetchRescheduledTicketReport } from './../../actions/reports.action';
import { isValid } from './../../utils/utilities';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { getAPIDateFormat} from './../../utils/date.utils';
import { Printer } from './../../components/custom-components/custom-component';
import { getRangeOptions, getPaginationDetails, getDefaultPageSize } from './reports.module';

const { RangePicker } = DatePicker;

const RenderReport =({tickets,pagination})=>{

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const columns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title: "PNRs",
            children:
            [
                {
                    title:'PNR',
                    key:'pnr',
                    dataIndex : 'pnr',
                    render :(text) =>{
                        return(
                            <a href= {'/view-cancel?pnr='+text}
                            type="link" target="_blank" className="outline-button">{text}</a>
                        )                 
                    }
                },
                {
                    title:'Old PNR',
                    key:'old_pnr',
                    dataIndex : 'old_pnr',
                    render :(text) =>{
                        return(
                            <a href= {'/view-cancel?pnr='+text}
                            type="link" target="_blank" className="outline-button">{text}</a>
                        )                 
                    }
                }
            ]
        },
        {
            title:'Mobile number',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Email id',
            key:'email_id',
            dataIndex : 'email_id'
        },
        {
            title:'Ticket Status',
            key:'ticket_status',
            dataIndex : 'ticket_status'
        },
        {
            title:'Amount Paid',
            key:'amount_paid',
            dataIndex : 'amount_paid'
        },
        {
            title:'Transaction References',
            children: [
                {
                    title:'Reference 1',
                    key:'reference1',
                    dataIndex : 'reference1'
                },
                {
                    title:'Reference 2',
                    key:'reference2',
                    dataIndex : 'reference2'
                }
            ]
        }
    ];

    return(
        <Row>
            <Col>
                <Table
                    dataSource={tickets}
                    columns={columns}
                    pagination={getPaginationDetails(pagination, setPage, paginationSize, setPaginationSize)}
                    className="bordered-table"
                />
            </Col>
        </Row>
    )
}

export const RescheduledTicketReport = () => {

    const [form] = Form.useForm();
    const [tickets,setTickets]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    let allowedMaxDaysRange=31;

    const dispatch=useDispatch();


    const onSuccess=(data)=>{
        setShowReport(true);
        setLoading(false);
        setTickets(data);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    const onFinish=(values)=>{
        let {
            dateRange
        }=values
        if(isValid(dateRange)){
            const fromDate = getAPIDateFormat(dateRange[0]);
            const toDate = getAPIDateFormat(dateRange[1]);
            setLoading(true);
            setShowReport(false);
            fetchRescheduledTicketReport(fromDate,toDate,onSuccess,onFailure);
        }else{
            message.error("Please input atleat one filed!")
        }
    }

    return (
            <div className="report-inner-div">
                <Form 
                form={form} 
                onFinish={onFinish}
                >
                    <Row gutter={[16,0]} className="ticket-search-form-block">
                        
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                                rules={[
                                    { required: true , message: 'Please input your date range!'},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                        },
                                    })
                                ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="center"> 
                        <Col>
                            <Form.Item>
                                <Button htmlType={"submit"}>Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&
                    <div>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Printer 
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                            <RenderReport 
                                                tickets={tickets}
                                                pagination={false}
                                            />
                                        )
                                    }} />
                            </Col>
                        </Row>
                        <Row className="marginTop" gutter={[16,16]}>
                            <Col>
                                <RenderReport 
                                    tickets={tickets}
                                    pagination={true}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
    )
}