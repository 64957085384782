import packageJson from '../../package.json';


export const DEV_ENV = 'dev';
export const TEST_ENV = 'test';
export const B2B_PROD_ENV = 'b2b_prod';
export const PROD_ENV = 'prod';

export const APP_ENV = B2B_PROD_ENV;

export const API_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:4000" :
    (APP_ENV === TEST_ENV) ? "https://staging-agent-server.appspot.com" :
        (APP_ENV === PROD_ENV) ? "https://customer-website-server.appspot.com"
        :"https://b2b-agent-server.appspot.com";

export const ADMIN_CLIENT_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:3000" :
    (APP_ENV === TEST_ENV) ? "https://staging-admin-client.appspot.com" :
    (APP_ENV === PROD_ENV) ? "https://admin-manager-client.appspot.com"
        :"https://b2b-travels-admin-client.appspot.com";

export const API_ADMIN_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:5000" :
    (APP_ENV === TEST_ENV) ? "https://staging-admin-server.appspot.com" :
    (APP_ENV === PROD_ENV) ? "https://admin-manager-server.appspot.com"
        :"https://b2b-travels-admin.appspot.com";

export const API_B2B_ADMIN_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:5000" :
    (APP_ENV === TEST_ENV) ? "https://staging-admin-server.appspot.com" :
        "https://b2b-travels-admin.appspot.com";

export const API_SRMS_ADMIN_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:5000" :
    (APP_ENV === TEST_ENV) ? "https://staging-admin-server.appspot.com" :
        "https://admin-manager-server.appspot.com";

export const API_SRMS_BASE_URL = (APP_ENV === DEV_ENV) ? "http://localhost:4000" :
    (APP_ENV === TEST_ENV) ? "https://staging-agent-server.appspot.com" :
        "https://customer-website-server.appspot.com";

export const BUS_CANCELLATION_EMAIL_ADDRESS="raghu@dafidy.com";

export const WEB_RELEASE_VERSION = packageJson.version;


export const getSrmsMavenAgentId =()=>{
    if(APP_ENV == B2B_PROD_ENV){
        return 105;
    }else{
        return global.agentId;
    }
} 

export const getSrmsMavenTravelsId =()=>{
    if(APP_ENV == B2B_PROD_ENV){
        return 3;
    }else{
        return global.travelsId;
    }
} 
