import { Button, Col, Form , Input , Row, Select , DatePicker, message} from "antd";
import React, { useEffect } from "react";
import './transaction-form.style.scss';
import { PlusOutlined } from '@ant-design/icons';
import { AgentsSelect } from './../search-components/agents-select/agents-select.component';
import { useDispatch } from "react-redux";
import { createAgentTransaction } from "../../actions/agents.actions";
import { isValidNumber } from "../../utils/utilities";
import moment from "moment";

const { Option } = Select;

export const TransactonsFormPage = (props) => {

    let {
        showTransactionForm,
        setShowTransactionForm,
        agents,
        selectedAgentId
    } = props;

    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const onClickPlus = ()=>{
        setShowTransactionForm(true);
    }

    const createAgentTransactionDispatch=(values)=>{
        dispatch(createAgentTransaction({...values},()=>{
            resetForm();
            message.success("Transaction created successfully!")
        },()=>{}))
    }

    useEffect(()=>{
        if(isValidNumber(selectedAgentId)){
            setShowTransactionForm(true);
        }
    },[selectedAgentId]);

    const resetForm=()=>{
        form.resetFields();
    }

    const onFinish = (values) =>{
        values.agentId = values.agentName;
        createAgentTransactionDispatch(values);
    }

    if(showTransactionForm){
        return (
            <div className="agent-form-root">
                <div className="fontWeightBold new-transactions-text">
                    New Transaction 
                </div>
                <div className="agent-form-inside">
                    <Form
                        form={form}
                        initialValues={{ date : moment().utcOffset("+05:30"), agentName:selectedAgentId}}
                        className = "agent-form"
                        onFinish={onFinish}
                    >
                        <AgentsSelect
                            agents={agents}
                            showLabel={true}
                            isManditory={true}
                            showHeadOffice={false}
                            showDriver={false}
                            selectedAgentId={selectedAgentId}
                        />
                        <Row gutter={[16.16]}>
                            <Col>
                                <Form.Item
                                label="Date"
                                rules={[{ required: true, message: 'Please input date' }]}
                                name="date"
                                >
                                        <DatePicker  />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                label="Amount (₹)"
                                rules={[{ required: true, message: 'Please input amount' }]}
                                name="amount"
                                >
                                    <Input placeholder="Amount"  />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                                label="Description"
                                rules={[{ required: true, message: 'Please input description' }]}
                                name="description"
                                >
                                <Input placeholder="Description"  />
                        </Form.Item>
                        <Row  gutter={[16.16]}>
                            <Col>
                                <Form.Item
                                    label="Payment Type"
                                    rules={[{ required: true, message: 'Please select payment type' }]}
                                    name="paymentType"
                                    >
                                         <Select
                                            size="large"
                                            showSearch
                                            placeholder="Select a option"
                                            optionFilterProp="childrens"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            bordered={true}
                                            className="defalut-select-style"
                                        >
                                            <Option value={'Cash'}>Cash</Option>
                                            <Option value={'Online'}>Online</Option>
                                        </Select>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label="Status"
                                    rules={[{ required: true, message: 'Please select option' }]}
                                    name="status"
                                    >
                                      <Select
                                            size="large"
                                            showSearch
                                            placeholder="Select a option"
                                            optionFilterProp="childrens"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                            bordered={true}
                                            className="defalut-select-style"
                                        >
                                            <Option value={'Pending'}>Pending</Option>
                                            <Option value={'Completed'}>Completed</Option>
                                            <Option value={'Failed'}>Failed</Option>
                                        </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                                label="Ref Id"
                                rules={[{ required: false, message: 'Please input amount' }]}
                                name="refId"
                                >
                                    <Input placeholder="Ref Id"  />
                        </Form.Item>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button className="cancel-button">Cancel</Button>
                            </Col>
                            <Col>
                                <Button htmlType="submit">Create</Button>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        );
    }else{
        return (
            <div className="new-transactions-button">
                <div className="fontWeightBold new-transactions-text">
                    New Transaction 
                </div>
                <div>
                    <Button
                        className="plus-button"
                        onClick={onClickPlus}
                        icon={<PlusOutlined />}
                    />
                </div>
            </div>
        );
    }
}