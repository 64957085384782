/**
 * @author Rejo Varghese
 * @description Logs details pop up
 */
import React, { useState, useEffect } from 'react';
import { Modal, Typography, Row, Divider } from 'antd';
import { getReadableDate, getTimeStampInReadableFormat } from '../../../utils/date.utils';
import { isValid } from '../../../utils/utilities';
import { LogsModalBody } from './logs-modal.body';

const {Text} = Typography;

export const LogsModalRoot = (props) => {
    const {
        showModal,
        setShowModal,
        selectedLog,
        scheduleDescription,
        travelDate,
        startTime,
        seatGroupIds,
        supportedFare
    } = props;

    const [action, setAction] = useState(null);

    useEffect(() => {
        if (isValid(selectedLog)&&isValid(selectedLog.action)) {
            setAction(selectedLog.action);
        }
        else {
            setAction(null);
        }
    },[selectedLog]);

    const handleOk = () => {
        setShowModal(false);
    }



    return (
        <div>
            {isValid(action)&&
            <Modal 
                title={"Logs"}
                onOk={handleOk}
                onCancel={handleOk}
                visible={showModal}
                width={"90vw"}
                footer={null}
            >
            <div>
                <Text style= {{fontWeight: 'bold'}}>{scheduleDescription}</Text>
                <br/>
                <Text style= {{fontWeight: 'bold'}}>{isValid(travelDate)? getReadableDate(travelDate):''} {isValid(startTime)?getTimeStampInReadableFormat(startTime):''}</Text>
                <Row align= "middle" justify ="center">
                    <Text style= {{fontWeight: 'bold', textDecorationLine: 'underline'}}>{action}</Text>
                    <Divider />
                </Row>
                <LogsModalBody 
                    selectedLog = {selectedLog} 
                    seatGroupIds = {seatGroupIds}
                    supportedFare = {supportedFare}
                />
            </div>
            </Modal>}
        </div>
    )
}