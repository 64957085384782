import React from 'react';
import {Calendar} from "antd";
import './render-calender.style.scss';
import { getDate, getMonth } from '../../../utils/date.utils';
import { useEffect } from 'react';
import { useState } from 'react';
import { formateDataForDayWise, getDayStatus } from './render-calender.module';

export const ScheduleListCalender = (props) => {

    const {
        selectedDate,
        scheduleData
    } = props

    let currentMonth = getMonth(selectedDate);

    const [dayWiseData,setDayWiseData] = useState({});
    
    useEffect(()=>{
        setDayWiseData(formateDataForDayWise(scheduleData.trips));
    },[scheduleData]);

    return(
        <div className="site-calendar-customize-header-wrapper">
            <Calendar 
                fullscreen={false}
                value={selectedDate}
                headerRender={()=>{
                    return null              
                }}
                dateFullCellRender={(date)=>{
                    let day = getDate(date);
                    let month = getMonth(date);
                    if(month===currentMonth){
                        let statusClassName = getDayStatus(date,dayWiseData);
                        return <div className={`current-date`}>
                            {day}
                            <div className={statusClassName}>

                            </div>
                        </div>
                    }else{
                        return null
                    }
                }}
                monthFullCellRender={(date)=>{
                    return null
                }}
                mode="month"
            />
        </div>
    );
};

ScheduleListCalender.defaultProps = {

};

ScheduleListCalender.propTypes = {

};
