import React, { useEffect, useState } from "react";
import { Button ,Row,Col, Select, Calendar , Typography, message} from "antd";
import './render-tabs.style.scss';
import { addMonth, getAPIDateFormat, getDate, getDateMonthInReadable , getMonth, subtractMonth } from "../../../utils/date.utils";
import { getActiveStatus, getDates, informRedBusAboutDeRegister, informRedBusAboutRegister } from "./render-tabs.module";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { CalenderColorLegend } from "../calender-color-legend/calender-color-legend";
import { isValid } from "../../../utils/utilities";

const {Option} = Select;
const { Text } = Typography;

export const RedBusTabComponent = (props) => {

        const{
            selectedDate,
            isMobile,
            scheduleData
        } = props;

        const [currentDate , setCurrentDate] = useState(selectedDate);
        const [nextDate , setNextDate] = useState(selectedDate);
        const [currentSelectedDate , setCurrentSelectedDates] = useState(null);
        const [openCalenderData , setOpenCalenderData] = useState({});

        const dispatch = useDispatch();


        useEffect(()=>{
            setCurrentDate(selectedDate);
        },[selectedDate]);

        useEffect(()=>{
            let secondDate = addMonth(currentDate,1);
            setNextDate(secondDate);
        },[currentDate]);

        let currentMonth = getMonth(currentDate);
        let nextMonth = getMonth(nextDate);

        const onSuccess=(msg)=>{
            setLoading(false);
            setCurrentSelectedDates(null);
            message.success(msg);
        }

        const setLoading = (value)=>{
            dispatch(loadingAction(value));
        }

        const onFailure=(err)=>{
            setLoading(false);
            message.error(err)
        }


        const onPreviousClick =()=>{
            if(isMobile){
                setCurrentDate(subtractMonth(currentDate,1));
            }else{
                setCurrentDate(subtractMonth(currentDate,2));
            }
        }

        const onNextClick =()=>{
            if(isMobile){
                setCurrentDate(addMonth(currentDate,1));
            }else{
                setCurrentDate(addMonth(currentDate,2));
            }
        }


        return(
            <div className="extend-block-root">
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <div className="site-extended-calendar">
                            <Calendar 
                                value={currentDate}
                                fullscreen={false}
                                headerRender={()=>{
                                    return (
                                    <Row gutter={[16,16]} align="middle">
                                        <Col>
                                            <Button type="link" className="previous-calender-btn" onClick={onPreviousClick}>
                                                <Text className="normal-txt">Previous</Text>
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Text strong>{getDateMonthInReadable(currentDate)}</Text>
                                        </Col>  
                                        {isMobile&&
                                            <Col>
                                                <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                    <Text className="normal-txt">Next</Text>
                                                </Button>
                                            </Col>
                                        }      
                                    </Row>
                                    )              
                                }}
                                dateFullCellRender={(date)=>{
                                    let day = getDate(date);
                                    let month = getMonth(date);
                                    if(month===currentMonth){
                                        let apidate = getAPIDateFormat(date);
                                        let {
                                            className,
                                            styles
                                        } = getActiveStatus(openCalenderData,apidate);
                                        let index = [currentSelectedDate].indexOf(apidate);
                                        let selectedClassName = index!=-1?"schedule-date-selected":"";
                                        return (
                                            <div 
                                            className={`current-date ${className} edit-fare-date-block`} style={styles}>
                                                <div className={`${selectedClassName}`}>{day}</div>
                                            </div>
                                        )
                                    }else{
                                        return (
                                            <div className={`next-month-date`}>
                                                <div>{day}</div>
                                            </div>
                                        )
                                    }
                                }}
                                mode="month"
                                onSelect={(date)=>{
                                    let apidate = getAPIDateFormat(date);
                                    setCurrentSelectedDates(apidate);
                                }}
                            />
                        </div>
                    </Col>
                    {!isMobile&&
                    <Col>
                            <div className="site-extended-calendar">
                                <Calendar 
                                            value={nextDate}
                                            fullscreen={false}
                                            headerRender={()=>{
                                                return (
                                                <Row gutter={[16,16]} align="middle" justify="end">
                                                    <Col>
                                                        <Text strong>{getDateMonthInReadable(nextDate)}</Text>
                                                    </Col>
                                                    <Col>
                                                        <Button type="link" className="previous-calender-btn" onClick={onNextClick}>
                                                            <Text className="normal-txt">Next</Text>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                )              
                                            }}
                                            dateFullCellRender={(date)=>{
                                                let day = getDate(date);
                                                let month = getMonth(date);
                                                if(month===nextMonth){
                                                    let apidate = getAPIDateFormat(date);
                                                    let {
                                                        className,
                                                        styles
                                                    } = getActiveStatus(openCalenderData,apidate);
                                                    let index = [currentSelectedDate].indexOf(apidate);
                                                    let selectedClassName = index!=-1?"schedule-date-selected":"";
                                                    return (
                                                        <div 
                                                            className={`current-date ${className} edit-fare-date-block`} style={styles}>
                                                            <div className={`${selectedClassName}`}>{day}</div>
                                                        </div>
                                                    )
                                                }else{
                                                    return (
                                                        <div className={`next-month-date`}>
                                                            <div>{day}</div>
                                                        </div>
                                                    )
                                                }
                                            }}
                                            mode="month"
                                            onSelect={(date)=>{
                                                let apidate = getAPIDateFormat(date);
                                                setCurrentSelectedDates(apidate);
                                            }}
                                        />
                            </div>
                        </Col>
                    }
                </Row>
                <Row gutter={[16,16]} justify="center" align="middle">
                    <Col>
                        <Select
                        placeholder="Select Dates"
                        className="schedule-date-select"
                        onChange={(value)=>{
                                let dates = getDates(value);
                                setCurrentSelectedDates(dates[0]);
                        }}
                        >
                            <Option value={'today'}>Today</Option>
                            <Option value={'tomorrow'}>Tomorrow</Option>
                        </Select>
                    </Col>
                </Row>
                <Row gutter={[16,16]} align="center">
                    <Col>
                        <CalenderColorLegend />
                    </Col>
                    <Col>
                        <Button onClick={()=>{
                            if(isValid(currentSelectedDate)){
                                setLoading(true);
                                informRedBusAboutRegister(scheduleData.id,currentSelectedDate,onSuccess,onFailure);
                            }else{
                                message.error("Please select start date.");
                            }
                        }}>Register DP</Button>
                    </Col>
                    <Col>
                        <Button onClick={()=>{
                            setLoading(true);
                            informRedBusAboutDeRegister(scheduleData.id,onSuccess,onFailure);
                        }}>DeRegister DP</Button>
                    </Col>
                </Row>
            </div>
        )
}
