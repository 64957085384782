/**
 * @author Rejo Varghese
 * @description Component to show logs
 */
import React, { useEffect, useState, useContext } from 'react';
import { Typography, message, Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import { loadingAction } from '../../actions/loading-actions';
import { fetchLogsByTrip } from '../../actions/home.action';
import ZoomComponent from '../custom-components/zoom-component/zoom.component';
import { DeviceContext } from '../device-provider/device-provider';
import { LogsTable } from './logs-table/logs-table';

const {Text} = Typography;

const LogsComponent = (props) => {
    const{
        tripId,
        scheduleDescription,
        travelDate,
        startTime,
        scheduleId,
        supportedFare,
        seatGroupIds
    } = props;

    const dispatch = useDispatch();

    const [logsData, setLogsData] = useState([]);

    const {
        isMobile
    }=useContext(DeviceContext);

    useEffect(()=>{
        setLoading(true);
        fetchLogsByTrip(scheduleId,tripId,onSuccess,onFailure);
    },[tripId]);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        setLogsData(data);
        setLoading(false);
    }

    const onFailure=(msg)=>{
        message.error(msg);
        setLoading(false);
    }


    return (
        <ZoomComponent isZoomEnabled={isMobile}>
            <div className='tab-block'>
                {logsData.length>0&&
                    <Row gutter={[16,16]} justify="center" align="middle">
                        <Col>
                            <LogsTable
                                dataSource = {logsData}
                                scheduleDescription = {scheduleDescription}
                                travelDate={travelDate}
                                startTime = {startTime}
                                supportedFare ={supportedFare}
                                seatGroupIds={seatGroupIds}
                            />
                        </Col>
                    </Row>
                }
            </div>
        </ZoomComponent>
    )
}

export default LogsComponent