import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table, Select} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchTicketsBookedAfterStartTime } from './../../actions/reports.action';
import { isValidArray , isValidNumber , isValid } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime , getLocalDate , convertDateStringToDate} from './../../utils/date.utils';
import { getRangeOptions , getPaginationDetails} from './reports.module';
import { roundNumber } from './../../utils/math.utils';
const {
    Option
} = Select;

const confirmTicketsColumns=[
    {
        title:'SN',
        key:'index',
        render : (text, record, index) => index+1
    },
    {
        title:'PNR',
        key:'pnr',
        dataIndex : 'pnr'
    },
    {
        title:'Seat count',
        key:'seat_count',
        dataIndex : 'seat_count'
    },
    {
        title:'Seat number',
        key:'seat_number',
        dataIndex : 'seat_number'
    },
    {
        title:'Total amount',
        key:'total_ticket_cost',
        dataIndex : 'total_ticket_cost'
    },
    {
        title:'Discount',
        key:'direct_discount',
        dataIndex : 'direct_discount'
    },
    {
        title:'Amount paid',
        key:'amountPaid',
        render : (text, record, index) => roundNumber(isValidNumber(record.amount_paid)?record.total_ticket_cost:record.amount_paid)
    },
    {
        title:'Booking time',
        key:'booking_time',
        render : (text, record, index) => getLocalDateAndTime(record.booking_time)
    },
    {
        title:'Travel date',
        key:'pnr',
        render : (text, record, index) => {
            if(isValid(record.start_date)){
                return getLocalDate(convertDateStringToDate(record.start_date.split('T')[0]))
            }else{
                return ''
            }
        }
    },
    {
        title:'Trip description',
        key:'description',
        dataIndex : 'description'
    },
    {
        title:"Transaction details",
        key:'order_no',
        dataIndex : 'order_no'
    },
    {
        title:"Manually issued",
        key:'name',
        dataIndex : 'name',
        render:(text, record, index)=> record.name === 'web'? 'Yes': 'No'
    }
];


const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({ticketsData,formValues,pagination})=>{

    let {
        dateRange
    }=formValues;
    if(isValidArray(ticketsData)&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Tickets Booked After Departure</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table 
                                        className="bordered-table"
                                        columns={confirmTicketsColumns}
                                        dataSource={ticketsData}
                                        pagination={getPaginationDetails(pagination)}
                                    />
                                </Col>
                            </Row>
                        </div>
            )
    }else{
        return null
    }
}


export const TicketsBookedAfterTripStartTime = () => {

    const [showReport,setShowReport]=useState(false);
    const [ticketsData,setTicketsData]=useState(undefined);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        setLoading(false);
        setShowReport(true);
        setTicketsData(data);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowReport(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        fetchTicketsBookedAfterStartTime(fromDate,toDate,onSuccess,onFailure);
    }

    const [form] = Form.useForm();

    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&
                <RenderReport 
                    ticketsData={ticketsData}
                    formValues={form.getFieldsValue()}
                    pagination={false}
                    />
                }
            </div>
    )
}