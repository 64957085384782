import _ from 'underscore';

export const getUrlVars = (queryParamString) =>{
   let vars = {};
   queryParamString.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
       vars[key] = value;
   });
   return vars;
}

export const getValuesOfJSonObject=(jsonObject)=>{
  return [..._.values(jsonObject)];
}

export const unquiArray=(data)=>{
   return _.uniq(data);
}

export const getCloneArray = (data)=>{
   return JSON.parse(JSON.stringify(data));
}

export const uniqueMerge=(arr1,arr2)=>{
   return _.union(arr1,arr2);
}

export const getJsonKeys=(jsonObj)=>{
   if(isValidArray(jsonObj)){
      return _.keys(jsonObj);
   }else{
      return [];
   }
}

export const checkActionAllowed=(actionName)=>{
   if(isValid(global.allowedActions)&&isValid(global.allowedActions[actionName])){
      return true;
   }else{
      return false;
   }
}

export const setLocalStorage=(key,value)=>{
   localStorage.setItem(key , value);
}

export const getLocalStorage=(key)=>{
   return localStorage.getItem(key);
}

export const removeLocalStorage=(key)=>{
   return localStorage.removeItem(key);
}

export const insertAtPosition=(arr,position,obj)=>{
   if(arr.length>position){
      return arr.splice(position, 0, obj);
   }else{
      return arr.push(obj);
   }
}

export const removeArrayIndexValue=(arr,index)=>{
   return  arr.splice(index, 1);
}

export const deleteIndexValue = (arr,index) =>{
   delete arr[index];
   return arr;
 
 }
/**
 * utilities
 */
 export const isValid = (data) => (data !== undefined && data !== null && data !== 0);

 export const isValidIndex = (data) => (data !== undefined && data !== null);
 
 export const isValidNumber = (data) => (data !== undefined && data !== null);
 
 export const isValidString = (data) => (data !== undefined && data !== null && data.length !== 0);
 
 export const isValidArrayWithValues = (array) => (array !== undefined && array !== null
    && typeof(array) == "object" && array.length > 0);
 
 export const isValidArray = (array) => (array !== undefined && array !== null
    && typeof(array) == "object");
 

export const setAgentPortalColors=(agentPortalColors)=>{
   if(isValid(agentPortalColors.primary_color)){
      document.documentElement.style.setProperty('--primaryColor', agentPortalColors.primary_color);
   }
   if(isValid(agentPortalColors.secondary_color)){
         document.documentElement.style.setProperty('--secondaryColor', agentPortalColors.secondary_color);
   }
   if(isValid(agentPortalColors.default_button_bg_color)){
         document.documentElement.style.setProperty('--defaultButtonColor', agentPortalColors.default_button_bg_color);
   }
   if(isValid(agentPortalColors.default_button_txt_color)){
      document.documentElement.style.setProperty('--defaultButtonText', agentPortalColors.default_button_txt_color);
   }
   if(isValid(agentPortalColors.header_bg_color)){
      document.documentElement.style.setProperty('--headerBgColor', agentPortalColors.header_bg_color);
   }
   if(isValid(agentPortalColors.header_txt_color)){
      document.documentElement.style.setProperty('--headerTxtColor', agentPortalColors.header_txt_color);
   }
   if(isValid(agentPortalColors.drop_down_menu_bg_color)){
      document.documentElement.style.setProperty('--dropDownMenuBgColor', agentPortalColors.drop_down_menu_bg_color);
   }
   if(isValid(agentPortalColors.drop_down_menu_txt_color)){
      document.documentElement.style.setProperty('--dropDownMenuTxtColor', agentPortalColors.drop_down_menu_txt_color);
   }
   if(isValid(agentPortalColors.drop_down_selected_bg_color)){
      document.documentElement.style.setProperty('--dropDownSelectedMenuBgColor', agentPortalColors.drop_down_selected_bg_color);
   }
   if(isValid(agentPortalColors.drop_down_selected_txt_color)){
      document.documentElement.style.setProperty('--dropDownSelectedTxtColor', agentPortalColors.drop_down_selected_txt_color);
   }
}


export const getArrayfromString=(string,symbol=',')=>{
   if(isValidString(string)){
       return string.split(symbol);
   }else{
       return []
   }
}

export const phoneNumberValidation = (inputtxt)=>{
   var phoneno = /^\d{10}$/;
   return isValidNumber(inputtxt)&&inputtxt.match(phoneno);
}

export const sortJsonObject=(data,key,isNumber=true)=>{
   if(isValidArray(data)&&data.length>0){
     return data.sort(function(a,b){
         if(isNumber){
           return a[key] - b[key];
         }else{
               return a[key].toLowerCase().localeCompare(b[key].toLowerCase())
         }
       }
     );
   }else{
     return []
   }
 }

 export const sortArrayObject=(data,ascending=true)=>{
   if(isValidArray(data)&&data.length>0){
      if(ascending){
         return _.sortBy(data, function (name) {return name})
      }else{
         return _.sortBy(data, function (name) {return name}).reverse()
      }
   }else{
     return []
   }
 }

 export const arrayToString=(arr)=>{
    if(isValidArray(arr)&&arr.length>0){
      return arr.toString();
    }else{
       return null;
    }
 }

 export const checkUpdatedValueFromObjects = (object1, object2) => {
   let updatedData = {}, updateKeyLength = 0;
   for (const [key, value] of Object.entries(object1)) {
     if (!isValidArray(value)&&(value!== object2[key])){
       updatedData[key] = value;
       updateKeyLength++;
     }
   }
   if (updateKeyLength>0) {
       return true;
   }
   else {
       return false;
   }
}

export const trimString=(txt)=>{
   if(isValidString(txt)){
      return txt.trim();
   }else{
      return txt;
   }
}

export function isInArray(array, value) {
   return (array.find(item => {return item == value}) || []).length > 0;
 }

 export function isNumeric(num){
   return !isNaN(num)
 }

 export const findSubStringExits=(string,substring)=>{
   if(isValidString(string)&&isValidString(substring)){
      return string.includes(substring);
   }else{
      return false;
   }
 }