import React from "react";
import { Select , Row , Col , Button} from "antd";
import { isValid, isValidArray } from "./../../../utils/utilities";
import './origin-destination.style.scss';

const {
    Option
} = Select;
const renderOrigins=(origins)=>
{
    let originsArray=[];
    if(isValidArray(origins)&&origins.length>0){
        origins.forEach(element => {
            if(isValid(element)){
                originsArray.push(<Option value={element}>{element}</Option>)
            }        
        });
    }
    return originsArray;
}

const renderDestinations=(destinations)=>
{
    let destiArray=[];
        if(isValidArray(destinations)&&destinations.length>0){
            destinations.forEach(element => {
                if(isValid(element)){
                    destiArray.push(<Option value={element}>{element}</Option>)
                }        
            });
        }
    return destiArray;
}


export const OriginDestionationFilter=({
    origins=[],
    destinations=[],
    onOriginChange=()=>{},
    onDestionationChange =()=>{},
    origin=null,
    destination=null
})=>{

        return (
            <Row className="origin-destionation-edit-fare" gutter={[16,16]} >
                <Col className="origin-block">
                    <Select
                        value={origin}
                        mode="multiple"
                        size="large"
                        showSearch
                        placeholder="Select a origin"
                        optionFilterProp="children"
                        onChange={onOriginChange}
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        bordered={true}
                        className="defalut-multi-select-style"
                        // allowClear={true}
                        // maxTagCount={2}
                        listHeight={180}
                        >
                        {renderOrigins(origins)}
                    </Select>
                    <Button 
                        ghost 
                        onClick={()=>{
                            onOriginChange([]);
                        }} >
                            Clear
                    </Button>
                </Col>
                <Col>
                    <Select
                        value={destination}
                        size="large"
                        mode="multiple"
                        showSearch
                        placeholder="Select a destination"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onChange={onDestionationChange}
                        bordered={true}
                        className="defalut-multi-select-style"
                        listHeight={180}
                        // maxTagCount={2}
                    >
                            {renderDestinations(destinations)}
                    </Select>
                    <Button 
                        ghost 
                        onClick={()=>{
                            onDestionationChange([]);
                        }} >
                            Clear
                    </Button>
                </Col>
            </Row>  
        );
}