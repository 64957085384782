import { Button, Modal, Row , Col , Typography} from 'antd';
import React from 'react';
import { useState } from 'react';
import './calender-color-legend.style.scss';
import {
    InfoCircleOutlined
  } from '@ant-design/icons';

const {
    Text,
    Title 
} = Typography;

export const CalenderColorLegend = () => {

    const [visible,setVisible] = useState(false);

    const showModal=()=>{
        setVisible(true);
    }

    const hideModal=()=>{
        setVisible(false);
    }

    return(
        <div>
            <Button className='outline-button' onClick={showModal}>
                <div className="indicator-container">
                        <div className="indicator-description">
                                <span className='seat-colors-txt'>
                                    Calendar Colours
                                </span>
                                <InfoCircleOutlined />
                        </div>
                    </div>
            </Button>
            <Modal
                footer={null}
                visible={visible}
                onCancel={hideModal}
                onOk={hideModal}
                width={400}
            >
                <Row justify='center' align='middle'>
                    <Col>
                        <div>
                            <Title  strong className='calendar-color-txt' level={4}>Calendar Colours</Title>
                        </div>
                        <Row justify='space-between' align='middle' gutter={[16,16]}>
                            <Col>
                                <Text strong>Blocked</Text>
                            </Col>
                            <Col>
                                <div className='blocked-date-legend date-legend'></div>
                            </Col>
                        </Row>
                        <Row justify='space-between' align='middle' gutter={[16,16]}>
                            <Col>
                                <Text strong>Selected Days</Text>
                            </Col>
                            <Col>
                                <div className='selected-date-legend date-legend'></div>
                            </Col>
                        </Row>
                        <Row justify='space-between' align='middle' gutter={[16,16]}>
                            <Col>
                                <Text strong>High Demad Day</Text>
                            </Col>
                            <Col>
                                <div className='high-demand-date-legend date-legend'></div>
                            </Col>
                        </Row>
                        <Row justify='space-between' align='middle' gutter={[16,16]}>
                            <Col>
                                <Text strong>Seat Sharing</Text>
                            </Col>
                            <Col>
                                <div className='cut-route-enabled date-legend'></div>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]} justify="center" align='middle'>
                            <Col>
                                <div className='confirm-tickets-count-block'></div>
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col><Text strong>Empty</Text></Col>
                            <Col><Text strong>Full</Text></Col>
                        </Row>

                    </Col>
                </Row>
            </Modal>
        </div>
    );
};

CalenderColorLegend.defaultProps = {

};

CalenderColorLegend.propTypes = {

};
