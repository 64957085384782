import { message }  from 'antd';
import { getLocalDate } from '../../utils/date.utils';
import { isValid, isValidArray ,getValuesOfJSonObject} from '../../utils/utilities';
import { SEND_BUS_CANCELLATION_EMAIL_URL , BLOCK_ALL_AVAILABLE_SEATS_OF_TRIP } from './../../utils/api-url';
import { roundNumber } from './../../utils/math.utils';

export const defaultMsg="We had cancelled the following services. Please issue a full refund ONLY to the PNRs mentioned below. The other seats in the buses have been re-assigned to a different service after communicating with the customers:";


export const blockAllAvailableSeats=(tripId,setLoading)=>{
    fetch(BLOCK_ALL_AVAILABLE_SEATS_OF_TRIP, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            "tripId": tripId,
            "operatorCode" : global.operatorCode
        })
    })
    .then(response => response.json())
    .then(data => {
        setLoading(false);
        if(data.Status==="SUCCESS"){
            message.success(data.Message);
            return true;
        }else{
            message.error(data.Message);
            return false;
        }
    }).catch((reason)=> {
        setLoading(false);
        message.error(reason)
        return false;
    });
}

export const sendBusCancellationEmail=(
    passengars,
    agentName,
    tripId,
    travelDate,
    origin,
    destination,
    isNofityPassengers,
    setLoading,
    userName)=>{
    setLoading(true);
    let pnrsArray=[];
        passengars.forEach((ele)=>{
                if(pnrsArray.indexOf(ele.pnr)==-1){
                    pnrsArray.push(ele.pnr);
                }
        })

        return fetch(SEND_BUS_CANCELLATION_EMAIL_URL, {
            method: "POST",
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify({
                 "tripId": tripId,
                "agentName":agentName,
                "selectedPassengars" : pnrsArray,
                "operatorName" : global.operatorName,
                "operatorCode" : global.operatorCode,
                "travelDate":getLocalDate(travelDate),
                "origin":origin,
                "destination":destination,
                "isNofityPassengers":isNofityPassengers,
                "userName":userName,
            })
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false);
            if(data.Status===true){
                message.success(data.message);
                return true;
            }else{
                message.error(data.message);
                return false;
            }
        }).catch((reason)=> {
            setLoading(false);
            message.error(reason)
            return false;
        });
}

export const popUpColumns=[
    {
        title: 'PNR',
        dataIndex: 'pnr',
        key: 'pnr'
    },
    {
        title: 'Phone',
        dataIndex: 'phone_number',
        key: 'phone_number',
    },
    {
        title: 'Seat',
        dataIndex: 'seat_number',
        key: 'seat_number',
    }
];

const isValidTicketToShow=(record,agentName,othersValue,redbusValue,webSiteValue,abhiBusValue,paytmValue, qwikBusValue)=>{
    if((isValid(record.agent_name)&&record.agent_name.toLowerCase() === agentName.toLowerCase())){
        return true;
    }else if(
        agentName === othersValue &&
        record.agent_name.toLowerCase() !== webSiteValue.toLowerCase() &&
        record.agent_name.toLowerCase() !== redbusValue.toLowerCase() &&
        record.agent_name.toLowerCase() !== abhiBusValue.toLowerCase() &&
        record.agent_name.toLowerCase() !== paytmValue.toLowerCase() &&
        record.agent_name.toLowerCase() !== qwikBusValue.toLowerCase()
    ){
        return true;
    }else{
        return false;
    }

}

export const getTableDataFilterByAgent=(tickets,agentName,othersValue,redbusValue,webSiteValue,abhiBusValue,paytmValue,qwikBusValue)=>{
    let filteredTicketsObject = {};
    if(isValidArray(tickets)&&tickets.length>0){
        tickets.forEach(element => {
            if(isValidTicketToShow(element,agentName,othersValue,redbusValue,webSiteValue,abhiBusValue, paytmValue, qwikBusValue)){
                if(isValidArray(filteredTicketsObject[element.pnr])){
                    filteredTicketsObject[element.pnr].age=filteredTicketsObject[element.pnr].age+","+element.age;
                    filteredTicketsObject[element.pnr].seat_number= filteredTicketsObject[element.pnr].seat_number+","+element.seat_number;
                    filteredTicketsObject[element.pnr].full_name= filteredTicketsObject[element.pnr].full_name+","+element.full_name;
                    filteredTicketsObject[element.pnr].gender= filteredTicketsObject[element.pnr].gender+","+element.gender;
                    if(isValid(element.amount_paid)){
                        filteredTicketsObject[element.pnr].ticket_cost= roundNumber(filteredTicketsObject[element.pnr].ticket_cost+element.amount_paid);
                    }else{
                        filteredTicketsObject[element.pnr].ticket_cost= roundNumber(filteredTicketsObject[element.pnr].ticket_cost+element.ticket_cost);
                    }

                }else{
                    filteredTicketsObject[element.pnr]={...element};
                    if(isValid(element.amount_paid)){
                        filteredTicketsObject[element.pnr].ticket_cost=element.amount_paid;
                    }
                }
            }
        });
        return [...getValuesOfJSonObject(filteredTicketsObject)];
    }else{
        return [];
    }
}

export const getAgentNames=(tickets,othersValue,redbusValue,webSiteValue,abhiBusValue,paytmValue, qwikBusValue)=>{
    let agentNames=[];
    if(isValidArray(tickets)&&tickets.length>0){
        tickets.forEach((ticket)=>{
            if(ticket.agent_name === redbusValue || ticket.agent_name === webSiteValue || ticket.agent_name === abhiBusValue || ticket.agent_name === paytmValue || ticket.agent_name === qwikBusValue){
                if(agentNames.indexOf(ticket.agent_name)==-1){
                    agentNames.push(ticket.agent_name);
                }
            }else{
                if(agentNames.indexOf(othersValue)==-1){
                    agentNames.push(othersValue);
                }
            }
        })
    }
    return agentNames;
}
