import { Form , Input , Button , Select, message } from "antd";
import React, { useEffect, useState } from "react";
import MapComponent from "../components/standard-stops/map-component/map.component";
import '../styles/create-standard-stops.style.scss';
import { createAddressLocation, getStandardStops, updateAddressLocation } from "../actions/address.actions";
import { useDispatch, useSelector } from "react-redux";
import { tripCreateReducerSelector } from "../selector/trip-create.selector";
import { isValid, isValidArray } from "../utils/utilities";
import {fetchMaterCitiesAction} from './../actions/trip-create.actions';
import { getLatLngofAddress } from './../actions/admin.action';
import { useLocation } from "react-router-dom";
import { loadingAction } from "../actions/loading-actions";

const { Option } = Select;

const DrawLine =()=>{
    return(
        <div className="create-standard-stops-underline">

        </div>
    )
}

export const CreateStandardStopsPage = (props) => {

    const {
        fromModal,
        selectedCity,
        onSuccessRefresh=()=>{},
        operatorCode=null,
        onFailureRefresh=()=>{},
        isAddressBook=false,
        formDetailsFromprops=null
    } = props;

    const [form] = Form.useForm();

    const tripCreateReducer = useSelector(tripCreateReducerSelector);
    const [disableOk, setDisableOk] = useState(true);
    const [latLng, setLatLng] = useState();
    const [enableLocationPick,setEnableLocationPick] = useState(false);
    const [formDetails,setFormDetails] = useState(null);
    const [disAbleSetMapLocation,setDisableSetMapLocation] = useState(true);
    const [center,setCenter] = useState({lat:13.0694,lng:80.1948});

    const reSetLatLng=(lat,lng)=>{
        let data ={latitude:lat,longitude:lng};
        setLatLng({...data});
    }

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(fetchMaterCitiesAction());
    },[]);

    const location = useLocation();

    const setLoading =(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(() => {
       if(isValidArray(location.state)&&isValidArray(location.state.formdetails)){
        setFormDetails(location.state.formdetails);
        setCenter({lat:location.state.formdetails.latitude,lng:location.state.formdetails.longitude}); 
       }else if(isValidArray(formDetailsFromprops)){
        setFormDetails(formDetailsFromprops);
        setCenter({lat:formDetailsFromprops.latitude,lng:formDetailsFromprops.longitude}); 
       }else{
        setFormDetails(null);
       }
    }, [location,formDetailsFromprops]);

    useEffect(()=>{
        if(isValidArray(formDetails)){
            form.setFieldsValue({
                stopname:formDetails.name,
                address:formDetails.address,
                landmark:formDetails.landmark,
                city:formDetails.city,
                phoneNumber:formDetails.phone_number
            });
            reSetLatLng(formDetails.latitude,formDetails.longitude);
            setDisableSetMapLocation(false);
        }else{
            form.resetFields();
            reSetLatLng(null,null);
        }
        
    },[formDetails]);


    let { masterCities } = tripCreateReducer;

    const onSuccess=(id)=>{
        setLoading(false);
        form.resetFields();
        reSetLatLng(null,null);
        dispatch(getStandardStops());
        onSuccessRefresh(id);
    }

    const onFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
        onFailureRefresh(errMsg)
    }

    const createAddress=()=>{
        form.validateFields()
        .then(values => {
            let {
                stopname,
                address,
                city,
                landmark,
                phoneNumber
            } = values
            if(!isAddressBook){
                phoneNumber=null
            }
            let formatedData = {
                name:stopname,
                latitude:latLng.latitude, 
                longitude:latLng.longitude, 
                landmark:landmark, 
                address:address, 
                phone_number:phoneNumber, 
                landline_number:null, 
                city:city, 
                is_office:0, 
                agent_user_name:null, 
                precedence_number:null, 
                is_active:1, 
                operator_code:operatorCode
            };
            setLoading(true);
            if(isValid(formDetails)&&isValid(formDetails.id)){
                formatedData.id = formDetails.id;
                updateAddressLocation(formatedData,onSuccess,onFailure);
            }else{
                createAddressLocation(formatedData,onSuccess,onFailure);
            }
        });
    }

    let buttonName = isValidArray(formDetails)&&isValid(formDetails.id)?"Update":"Create";

    let customClassName ='';

    if(enableLocationPick){
        customClassName = 'disabledDiv';
      }else{
        customClassName = '';
      }

      useEffect(()=>{
        if(isValid(selectedCity)){
            form.setFieldsValue({
                city:selectedCity
            })
        }
      },[selectedCity])
    return (
        <div className={fromModal?"":"create-standard-stops-root"}>
            <div className={`create-standard-stops-inner-div`}>
                <div className={customClassName}>
                    <h3>
                        {buttonName} {isAddressBook?"Address":"Standard Stop"}
                    </h3>     
                    <DrawLine />
                </div>
                <div className={`create-standard-stops-form`}>
                    <div className={`create-form-div ${customClassName}`}>
                            <Form
                                initialValues={{
                                    city:selectedCity
                                }}
                                name="basic"
                                form={form}
                            >
                                <div className="create-form-inner-div">
                                    <Form.Item    
                                    name="stopname"
                                    rules={[{ required: true ,  message:"Please input stop name"}]}
                                    label="">
                                        <Input placeholder="Stop Name"/>
                                    </Form.Item>
                                    <Form.Item    
                                    name="city"
                                    rules={[{ required: true ,  message:"Please input city"}]}
                                    label="">
                                        <Select
                                        disabled={isValid(selectedCity)?true:false}
                                        showSearch
                                        size="large"
                                        style= {{backgroundColor: 'white',width: 200}}
                                        placeholder="Select a city"
                                        optionFilterProp="children"
                                        >
                                            {masterCities.map(item => (
                                                <Option key = {item.id} value= {item.city_name} data={item}>{item.city_name}{isValid(item.alias_city_name)?"("+item.alias_city_name+")":null}</Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                    {isAddressBook?
                                        <Form.Item
                                        name="phoneNumber"
                                        rules={[{ required: true ,  message:"Please input phone number"}]}
                                        label=""
                                        >
                                            <Input placeholder="Phone Number"/>
                                        </Form.Item>
                                        :null
                                    }
                                    <Form.Item    
                                    name="landmark"
                                    rules={[{ required: true ,  message:"Please input landmark"}]}
                                    label="">
                                        <Input placeholder="Landmark"/>
                                    </Form.Item>
                                    <Form.Item name = {'address'} label = "" rules={[{ required: true, message: "" }]}>
                                        <Input.TextArea 
                                            placeholder='Address'
                                            onChange={(e)=>{
                                                if(e.target.value.length >= 4){
                                                    setDisableOk(false);
                                                }else{
                                                    setDisableOk(true);
                                                }
                                            }}
                                        />
                                    </Form.Item>
                                    <div className='marginBottom fetch-latlng-ok-button'>
                                        <Button 
                                            className='ok-button-address'
                                            size='small'
                                            disabled={disableOk}
                                            onClick={()=>{
                                                let{
                                                    address
                                                } = form.getFieldsValue();
                                                setDisableOk(true);
                                                setDisableSetMapLocation(false);
                                                getLatLngofAddress(address,(lat,lng)=>{
                                                    reSetLatLng(lat,lng);
                                                    setCenter({lat,lng}); 
                                                },(lat,lng)=>{
                                                    console.log("Failed",{lat,lng});
                                                });
                                            }}
                                        >Add to map</Button>
                                    </div>
                                    <Form.Item>
                                        <Button className='setLocationOnMapButton'
                                        disabled={disAbleSetMapLocation}
                                        onClick={()=>{
                                            setEnableLocationPick(true);
                                        }}
                                        >Set Location on Map</Button>
                                    </Form.Item>
                                    {/* <Form.Item>
                                        <Button>Change Map Location</Button>
                                    </Form.Item> */}
                                </div>
                                <div className="submit-buttons">
                                    <Button  className="cancel-button">
                                        Cancel
                                    </Button>
                                    <Button onClick={createAddress}>
                                        {buttonName}
                                    </Button>
                                </div>
                            </Form>
                    </div>
                    <div>
                        <MapComponent 
                            latLng={latLng}
                            onMarkerDragEnd={(lat,lng)=>{
                                reSetLatLng(lat,lng);
                            }}
                            enableLocationPick={enableLocationPick}
                            setEnableLocationPick={setEnableLocationPick}
                            onCancel={(lat,lng)=>{
                                reSetLatLng(lat,lng);
                            }}
                            center={center}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}