import React, { useEffect, useState } from "react";
import { Typography } from 'antd';
import '../styles/manual-trip.style.scss';
import { useDispatch } from "react-redux";
import { getAllSchedules } from "../actions/admin.action";

const { Title } = Typography;

export const ManualTripPage = () => {

    const dispatch = useDispatch();

    const [trips,setTrips] = useState([]);

    const onSuccessSchedules=(data)=>{
        console.log({data});
        setTrips(data);
    }

    const onFailureSchedules=(msg)=>{

    }

    useEffect(()=>{
        // dispatch(getAllSchedules(onSuccessSchedules,onFailureSchedules));
    },[]);

    return (
        <div className="manual-trip-page-root">
            <div  className="manual-trip-page-form">
                <Title level={4}>Trip Details</Title>
            </div>
        </div>
    )
}