/**
 * @author Manne Hareesh
 * @description Schedules related tab
 */
import React from "react";
import { Button } from "antd";
import './render-tabs.style.scss';
import { IN_ACTIVATE_TRIPS_CREATED_URL } from "../../../utils/api-url";
import { postCall } from "../../../utils/network.util";
import { showConfirm } from "../../custom-components/custom-component";
import { checkActionAllowed } from "../../../utils/utilities";
import { ACTION_TO_SET_TRIPS_CREATED } from "../../../utils/constant";

export const markTripCreatedInactive=(scheduleId,onSuccess=()=>{},onFailure=()=>{})=>{
    var updated = postCall(IN_ACTIVATE_TRIPS_CREATED_URL, {scheduleId});
    updated.then((data) => {
        if(data.status=="SUCCESS" && data.payload.Status == "SUCCESS"){
            onSuccess();
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to update trip created.");
      })
}

export const MarkScheduleInactive = (props) => {

    const{
        scheduleId,
        onSuccess,
        onFailure
    } = props;

    if(checkActionAllowed(ACTION_TO_SET_TRIPS_CREATED)){
        return(
            <div>
                <Button onClick={()=>{
                    showConfirm(()=>{
                        markTripCreatedInactive(scheduleId,onSuccess,onFailure);
                    },"Inactive Alert","Are you sure to move trip to inactive?");
                }}>Mark inactive</Button>
            </div>
        )
    }else{
        return null;
    }
}
