import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    GET_CITY_DETAILS,
    SET_CITY_DETAILS,
    UPDATE_CITY_PHONE_NO
} from '../actions/action-types';
import { message } from 'antd';
import { isValidArray } from '../utils/utilities';

export function* getCityData(action) {
    try {
    const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
        yield put({
            type: SET_CITY_DETAILS,
            payload: apiResult.payload.Payload
        });
        onSuccess();
    }else{
        onFailure("Failed to get city details"); 
    }
}
    catch (error) {
        console.log("fetching cities:",{error});
        message.error("Failed to get city details")
    }
};

export function* updateCityPhoneNumber(action) {
    try {
    const {
            api,
            url,
            onSuccess,
            onFailure,
            apiPayload
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
    if(apiResult.status==="SUCCESS"&&isValidArray(apiResult.payload)&&apiResult.payload.Status === "SUCCESS"){
        onSuccess("Updated Phone number successfully!");
    }else{
        onFailure("Updated city phone number"); 
    }
}
    catch (error) {
        console.log("fetching city:",{error});
        message.error("Updated city phone number failed")
    }
};

function* watchCitySaga() {
    yield takeLatest(GET_CITY_DETAILS, getCityData)
}

function* watchUpdatePhoneNumberSaga() {
    yield takeLatest(UPDATE_CITY_PHONE_NO, updateCityPhoneNumber)
}

export default function* watchCityPageSaga() {
    yield all([
        fork(watchCitySaga),
        fork(watchUpdatePhoneNumberSaga)
    ])
}

