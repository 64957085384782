import React, { useState, useRef } from 'react'
import { Button, Space, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

export default function useColumnSearch({ dataIndexExt }, isWordWrapRender = false) {
    const [searchText, setSearchText] = useState('')
    const [searchedColumn, setSearchedColumn] = useState(0)
    const refSearchInput = useRef()

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters
        }) => (
                // placeholder={`Search ${dataIndex}`}
                <div style={{ padding: 8 }}>
                    <Input
                        ref={refSearchInput}
                        value={selectedKeys[0]}
                        onChange={(e) =>
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                        }
                        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        style={{ width: 188, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Search
                        </Button>
                        <Button
                            onClick={() => handleReset(clearFilters)}
                            size="small"
                            style={{ width: 90 }}
                        >
                            Reset
                        </Button>
                    </Space>
                </div>
            ),
        filterIcon: (filtered) => (
            <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => refSearchInput.current.select(), 100)
            }
        },
        render: (text) =>{
            if (isWordWrapRender) {
                return (
                <div style={{ wordWrap: 'break-word', wordBreak: 'break-word'}}>
                  {text}
                </div>);
            }
            else {
                return text
            }
        }
            // searchedColumn === dataIndex ? (
            //     <Highlighter
            //         highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            //         searchWords={[searchText]}
            //         autoEscape
            //         textToHighlight={text ? text.toString() : ''}
            //     />
            // ) : (
            //         text
            //     )
    })

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm()
        setSearchText(selectedKeys[0])
        setSearchedColumn(dataIndex)
    }

    const handleReset = (clearFilters) => {
        clearFilters()
        setSearchText('')
    }

    return getColumnSearchProps(dataIndexExt)
}