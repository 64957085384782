import React , {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { Row, Form , Radio , Typography} from 'antd';

const { Title } = Typography;

export const ScreenOne = (props) => {

const {
    screenOneForm   
} = props;

  return (
      <div>
            <Row>
                <Title level={4}>For all Cities?</Title>
            </Row>
            <div>
            <Form
                    initialValues={{

                    }}
                    name="basic"
                    form={screenOneForm}
                    >
                        <Form.Item    
                        name="isAllCities"
                        rules={[{ required: true ,  message:"Please input option"}]}
                        label="">
                            <Radio.Group>
                            <Radio value={true}>Yes</Radio>
                            <Radio value={false}>No, Only for some cities</Radio>
                            </Radio.Group>
                        </Form.Item>
            </Form>
            </div>
      </div>
  );
};
