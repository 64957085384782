import React, { useEffect, useState } from 'react';
import ReactExport from "react-export-excel";
import { Button } from 'antd';
import { getJsonKeys, isValidArray } from '../../utils/utilities';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const generateJsonToExport=(ticketDetailsObj)=>{

    let excelData = [];

    for (const key in ticketDetailsObj) {
        if (Object.hasOwnProperty.call(ticketDetailsObj, key)) {
            const element = ticketDetailsObj[key];
            excelData.push([
                element.pnr,element.phoneNumber, element.email,element.noOfSeats, element.seatNumbers,
                element.agent,element.origin,element.destination,
                element.refundAmount,element.amountPaid,element.balance
            ]);
        }
    }

    const multiDataSet = [
        {
            columns: ["Report"],
            data: [[
                "Consolidated Trip Sheet"
            ]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 2, //will put space of 5 rows,
            columns: [
                "PNR", "Phone", "Email" , "No. Of seats" , "Seat Nums",
                "Agent" , "Origin" , "Destination" , 
                "Refund amount","Amount Paid" , "Balance"
            ],
            data: excelData
        }
    ];
    

    return (
        <ExcelFile  element={<Button>Consolidated Trip Sheet</Button>} filename={"consolidated-trip-sheet"}> 
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}

export const TicketExcelReport = (props) =>{
    
    const {
        tripSheet
    } = props;

    const [ticketDetailsObj , setTicketDetailsObj]=useState({});


    useEffect(()=>{
        if(isValidArray(tripSheet)){
            let ticketObject={};
            for (let index = 0; index < tripSheet.length; index++) {
                const element = tripSheet[index];
                if(isValidArray(ticketObject[element.pnr])){
                    ticketObject[element.pnr]['amountPaid'] = ticketObject[element.pnr]['amountPaid'] + element.amount_paid;
                    ticketObject[element.pnr]['noOfSeats'] = ticketObject[element.pnr]['noOfSeats'] + 1; 
                    ticketObject[element.pnr]['seatNumbers'] = ticketObject[element.pnr]['seatNumbers']+", "+element.seat_number;
                }else{
                    ticketObject[element.pnr]={
                        pnr:element.pnr,
                        phoneNumber:element.phone_number,
                        noOfSeats:1,
                        agent:element.agent_name,
                        origin:element.pick_up_city,
                        destination:element.drop_city,
                        refundAmount:'',
                        amountPaid:element.amount_paid,
                        balance:'',
                        email: element.email_id,
                        seatNumbers: element.seat_number
                    };
                }
            }
            setTicketDetailsObj(ticketObject);
        }
    },[tripSheet]);

    return (
        <div className='marginTop'>
           {generateJsonToExport(ticketDetailsObj)}
        </div>
    )
}