import React , { useContext, useEffect, useState } from "react";
import { Button, Checkbox, Col, Collapse, Divider, InputNumber, message, Modal, Row, Select, TimePicker,Typography  } from "antd";
import './new-timing.style.scss';
import { getScheduleStopsByTripIdWithCities, resetToDefaultTimings, updateStopTimingsOfTrip } from "../../../actions/home.action";
import { checkActionAllowed, deleteIndexValue, isValid, isValidArray, isValidNumber } from "../../../utils/utilities";
import { getReadableDate, getTimeStampInReadableFormat } from "../../../utils/date.utils";
import TicMarkIcon from './../../../images/tic-mark.png';
import Icon from '@ant-design/icons';
import { showConfirm } from "../../custom-components/custom-component";
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { UPDATE_IN_NEW_TIME_TAB } from "../../../utils/constant";
import moment from "moment";
import ZoomComponent from './../../custom-components/zoom-component/zoom.component';
import { DeviceContext } from "../../device-provider/device-provider";
import {
    MinusSquareOutlined,
    PlusSquareOutlined,
    EditOutlined,
    DeleteOutlined
} from '@ant-design/icons';
import { getAddressLocations } from "../../../actions/trip-create.actions";
import { CreateStandardStopsPage } from "../../../pages/create-standard-stop.page";

const { 
    Panel
} = Collapse;

const {
    Title ,
    Text
} = Typography ;

const {
    Option
} = Select;

const getExistingLocations=(stopsWithCities)=>{
    let locations = [];
    if(isValidArray(stopsWithCities)){
        for (let index = 0; index < stopsWithCities.length; index++) {
            const element = stopsWithCities[index];
            let {
                stops
            } = element;
            for (let index = 0; index < stops.length; index++) {
                const stop = stops[index];
                locations.push(stop.displayName);
            }
        }
        return locations;
    }else{
        return [];
    }
}

const showResetBtn=(citiesWithStops)=>{
    let atleastonepointedited = false;
    citiesWithStops.forEach((cityObject)=>{

        cityObject.stops.forEach((stopObject)=>{
            
            if(stopObject.isEdit == true || stopObject.isActive == 0){
                atleastonepointedited = true;
            }

           
        })
    });
    return atleastonepointedited;
}

const showCheckBoxForEdit=()=>{
    if(checkActionAllowed(UPDATE_IN_NEW_TIME_TAB)){
        return true;
    }else{
        return false;
    }
}

const getApiDetails=(citiesWithStops,isDaySpecificExits=false,tripId)=>{
    let cities=[],pickupPoints=[],dropPoints=[];
    let atleastonepointedited = false;
    let dataError = false;
    citiesWithStops.forEach((cityObject)=>{

        cityObject.stops.forEach((stopObject)=>{

            if(!isValid(stopObject.timing) || !isValidNumber(stopObject.day)){
                dataError= true;
            }
            
            
            if(stopObject.isEdit == true || stopObject.isActive == false || stopObject.isNewStop == true){
                atleastonepointedited = true;
            }

            // Note :isDaySpecificExits is manditory to create day specific points
            if(stopObject.isEdit ||stopObject.isActive == false || stopObject.isNewStop == true || (isDaySpecificExits == false && isValid(tripId))){

                let day = stopObject.day - cityObject.day;
                if(day<0){
                    day = 0;
                }
                if(stopObject.isNewStop == true){

                    if(!isValid(stopObject.selectedStopId)){
                        dataError=true;
                    }

                    if(stopObject.isBoarding == true){
                        pickupPoints.push({
                            id:stopObject.selectedStopId,
                            timing:stopObject.timing,
                            day:day,
                            isNewStop:stopObject.isNewStop
                        });
                    }

                    if(stopObject.isDrop == true){
                        dropPoints.push({
                            id:stopObject.selectedStopId,
                            timing:stopObject.timing,
                            day:day,
                            isNewStop:stopObject.isNewStop
                        });
                    }
                }else{
                    if(isValid(stopObject.pickupLocationId)){
                        pickupPoints.push({
                            id:stopObject.pickupLocationId,
                            timing:stopObject.timing,
                            day:day,
                            isActive:stopObject.isActive
                        });
                    }
                    if(isValid(stopObject.dropLocationId)){
                        dropPoints.push({
                            id:stopObject.dropLocationId,
                            timing:stopObject.timing,
                            day:day,
                            isActive:stopObject.isActive
                        });
                    }
                }
            }
        })
    });

    if(atleastonepointedited){
        return {
            dropPoints,pickupPoints,cities,
            dataError
        }
    }else{
        return {
            dropPoints:[],pickupPoints:[],cities:[],
            dataError
        }
    }
}

export const NewTimingTab = (props) => {

    let {
        scheduleId,
        tripId,
        refetchDetails=()=>{
            setLoading(true);
            getScheduleStopsByTripIdWithCities(scheduleId,tripId,onSuccess,onFailure);
        },
        travelDate,
        scheduleDescription,
        isDaySpecificExits,
        showUpdateBtns
    } = props;

    if(isValid(travelDate)){
        scheduleDescription = scheduleDescription + `(DOJ:${getReadableDate(travelDate)})`;
    }

    const [citiesWithStops,setCitiesWithStops]=useState([]);
    const [originalStops,setOriginalStops]=useState([]);
    const [existingLocations,setExistingLocations]=useState([]);
    const [standardStopsWithCities,setStandardStopsWithCities]=useState({});
    const [isOpenNewStop,setIsOpenNewStop]=useState(false);
    const [selectedCity,setSelectedCity]=useState(null);
    const [selectedCityIndex,setSelectedCityIndex]=useState(null);
    const [selectedStopIndex,setSelectedStopIndex]=useState(null);
    const [showError , setShowError] = useState(false);

    const dispatch = useDispatch();

    useEffect(()=>{
        setLoading(true);
        getScheduleStopsByTripIdWithCities(scheduleId,tripId,onSuccess,onFailure);
    },[scheduleId,tripId]);

    const resetFromUI =()=>{
        setCitiesWithStops([...originalStops]);
    }

    const {
        isMobile
    }=useContext(DeviceContext);


    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onSuccess=(data)=>{
        setCitiesWithStops(data.citiesWithStops);
        var obj2 = JSON.parse(JSON.stringify(data.citiesWithStops)); 
        setExistingLocations(getExistingLocations(obj2));
        setOriginalStops(obj2);
        setLoading(false);
    }
    
    const onFailure=(msg)=>{
        message.error(msg);
        setLoading(false);
    }

    const onClickCityHeaderCheckBox=(value,cityIndex)=>{
        citiesWithStops[cityIndex].checked = value;
        setCitiesWithStops([...citiesWithStops]);
    }

    const onClickStopCheckBox=(value,cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops[stopIndex].isActive = value;
        setCitiesWithStops([...citiesWithStops]);
    }

    const onClickStopEditCheckBox=(value,cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops[stopIndex].isEdit = value;
        setCitiesWithStops([...citiesWithStops]);
    }

    const updateStopsDate=(value,isCity,cityIndex,stopIndex,label)=>{
        if(isCity){
            citiesWithStops[cityIndex][label] = value;
        }else{
            citiesWithStops[cityIndex].stops[stopIndex][label] = value;
        }
        setCitiesWithStops([...citiesWithStops]);
    }

    const removeStopFromUI=(cityIndex,stopIndex)=>{
        citiesWithStops[cityIndex].stops = deleteIndexValue(citiesWithStops[cityIndex].stops,stopIndex);
        setCitiesWithStops([...citiesWithStops]);
    }

    const renderStandardStops=(city)=>{
        let standardStopsOptions =[];
        let standardStops = standardStopsWithCities[city];

        if(isValidArray(standardStops)&&standardStops.length>0){
            standardStops.map((address)=>{
                if(existingLocations.indexOf(address.name) == -1){
                    standardStopsOptions.push(<Option key = {address.id} name={address.name} id={address.id} value= {address.id} data={address}>{address.name}</Option>);
                }
            });
      }
      return standardStopsOptions;
    }

    const renderNewStopRow=(cityIndex,stopIndex,city,stop)=>{
        let stopExits=false , dayExits = false , timeExits = false;
        if(isValid(stop.selectedStopId)){
            stopExits=true;
        }
        if(isValidNumber(stop.day)){
            dayExits=true;
        }
        if(isValid(stop.timing)){
            timeExits=true;
        }
        return(
            <div className="tab-block">
                <Row className={'stop-row'} >
                    {isMobile?
                        <Col span={2}></Col>
                        :
                        <Col span={4}></Col>
                    }
                    <Col span={isMobile?8:4}>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            size="small"
                            placeholder="Select stop"
                            className={showError&&!stopExits?"selectStop errorInput":"selectStop"}
                            value={isValid(stop.selectedStopId)?stop.selectedStopId:null}
                            dropdownRender={menu => (
                                <div className="createStopDiv">
                                    {menu}
                                    <Divider style={{ margin: '8px 0' }} />
                                    <Button
                                        className="createStopBtn" 
                                        size="small"
                                        onClick={()=>{
                                            setSelectedCity(city);
                                            setSelectedCityIndex(cityIndex);
                                            setSelectedStopIndex(stopIndex);
                                            setIsOpenNewStop(true);
                                        }}
                                    >
                                        Create New Stop
                                    </Button>
                                </div>
                            )}
                            onSelect= {(value,data)=>{
                                updateStopsDate(data.id,false,cityIndex,stopIndex,'selectedStopId');
                            }}
                        >
                            {renderStandardStops(city)}
                        </Select>
                    </Col>
                    <Col span={isMobile?4:null}>
                        <div className="timing-col">
                            <TimePicker 
                                size="small"
                                className={showError&&!timeExits?"remove-clear-icon errorInput":"remove-clear-icon"}
                                popupClassName={"time-picker-to-not-display-footer"}
                                use12Hours 
                                format='h:mm A'  
                                minuteStep={5} 
                                value={isValid(stop.timing)?moment(stop.timing,'HH:mm'):null}
                                onSelect={(date)=>{
                                    let time = date.format("HH:mm:ss");
                                    updateStopsDate(time,false,cityIndex,stopIndex,'timing');
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={isMobile?2:4}>
                        <div className="day-col">
                            <InputNumber
                                className={showError&&!dayExits?"dayInput errorInput":"dayInput"}
                                size="small"
                                onChange={(value)=>{
                                    updateStopsDate(value-1,false,cityIndex,stopIndex,'day');
                                }}
                            />
                        </div>
                    </Col>
                    <Col span={isMobile?2:4}>

                    </Col>
                    <Col span={2}>
                        <Button
                            type="text" 
                            className="outline-button edit-col"  
                            icon={<DeleteOutlined />}
                            onClick={()=>{
                                removeStopFromUI(cityIndex,stopIndex);
                            }}
                        ></Button>
                    </Col>
                </Row>
            </div>
        );
    }

    const renderRow=(stop,isCity,cityIndex,stopIndex)=>{

        let className ='';

        if(stop.isActive == 0){
            className = 'inactive-stop';
        }

        return(
            <Row className={isCity?"city-row":`stop-row ${className}`}>
                 {isMobile?
                    <Col span={2}></Col>
                    :
                    <Col span={4}></Col>
                }
                <Col span={isMobile?8:4}>
                    <div className="city-col">
                        <Row className="description-row">
                            <Col span={4}>
                                {renderCheckBox(stop.isActive,isCity,cityIndex,stopIndex)}
                            </Col>
                            <Col span={18} offset={2}>
                                {stop.displayName}
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col span={isMobile?4:null}>
                    {stop.isEdit?
                    <div className="timing-col">
                        <TimePicker 
                            size="small"
                            className='remove-clear-icon'  
                            popupClassName={"time-picker-to-not-display-footer"}
                            use12Hours 
                            format='h:mm A'  
                            minuteStep={5} 
                            value={moment(stop.timing,'HH:mm')}
                            onSelect={(date)=>{
                                let time = date.format("HH:mm:ss");
                                updateStopsDate(time,isCity,cityIndex,stopIndex,'timing');
                            }}
                        />
                    </div>
                    :
                    <div className="timing-col">
                        {getTimeStampInReadableFormat(stop.timing)}
                    </div>
                    }               
                </Col>
                <Col span={isMobile?2:4}>
                {stop.isEdit?
                <div className="day-col">
                    <InputNumber
                        className="dayInput"
                        size="small"
                        defaultValue={stop.day+1}
                        onChange={(value)=>{
                            updateStopsDate(value-1,isCity,cityIndex,stopIndex,'day');
                        }}
                    />
                </div>
                :
                <div className="day-col">
                    {stop.day+1}
                </div>
                }
                </Col>
                <Col span={isMobile?2:4}>
                    <div className="trip-close-col">
                        {stop.tripClosePoint===1?
                            <Icon component={() => (<img src={TicMarkIcon} style={{width:'20px'}} />)} />
                            :
                            null
                         }
                    </div>
                </Col>
                <Col span={2}>
                    <Button
                        type="text" 
                        className="outline-button edit-col"  
                        icon={<EditOutlined />}
                        onClick={()=>{
                            if(isCity){
                                // Not in use
                                // onClickCityHeaderCheckBox(true,cityIndex);
                            }else{
                                onClickStopEditCheckBox(true,cityIndex,stopIndex)
                            }
                        }}
                    ></Button>
                </Col>
            </Row>
        )
    }

    const reSetStandaredCities=(city,cityIndex)=>{
        dispatch(getAddressLocations([city],true,(data)=>{
            standardStopsWithCities[citiesWithStops[cityIndex].displayName] = data.addressLocations; 
            setStandardStopsWithCities({...standardStopsWithCities});
            setIsOpenNewStop(false);
        },(errMsg)=>{
            message.error(errMsg);
        }));
    }

    const onClickAddStop=(cityIndex,citystop)=>{
        citiesWithStops[cityIndex].stops.push({isNewStop:true,isBoarding:citystop.isBoarding,isDrop:citystop.isDrop});
        if(!isValidArray(standardStopsWithCities[citiesWithStops[cityIndex].displayName])){
           reSetStandaredCities(citiesWithStops[cityIndex].displayName,cityIndex);
        }
        setCitiesWithStops([...citiesWithStops]);
    }

    const renderAllCitiesWithStops=()=>{
        let components=[];
        if(isValidArray(citiesWithStops)&&citiesWithStops.length>0){
            citiesWithStops.forEach((citystop,cityIndex)=>{
                let collapseHeader = citystop.displayName;
                let{
                    stops
                } = citystop;
                if(isValidArray(stops)&&stops.length>0){
                    let collapseAbleComponent=[];
                    stops.forEach((stop,stopIndex)=>{
                        if(stop.isNewStop == true){
                            collapseAbleComponent.push(renderNewStopRow(cityIndex,stopIndex,collapseHeader,stop));
                        }else{
                            collapseAbleComponent.push(renderRow(stop,false,cityIndex,stopIndex));
                        }
                    });
                    components.push(<Collapse 
                    collapsible={collapseHeader} 
                    ghost className="stop-collapse new-timing-body"
                    expandIcon={({ isActive }) => isActive ? <MinusSquareOutlined /> : <PlusSquareOutlined />}
                    >
                                        <Panel 
                                        key={cityIndex}
                                        header={collapseHeader}
                                        >
                                            {collapseAbleComponent}
                                            <Row>
                                                <Col span={4}>
                                                
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <Button className="addStopBtn" size="small" onClick={()=>{
                                                            onClickAddStop(cityIndex,citystop);
                                                        }}>Add stop</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Panel>
                                    </Collapse>);
                }
            })
        }
        return components;
    }

    const onClickReset=()=>{
        showConfirm(()=>{
            if(isDaySpecificExits){
                setLoading(true);
                resetToDefaultTimings(scheduleId,tripId,resetSuccess,resetFailure);
            }else{
                resetFromUI();
            }

        },"Reset","Are you sure to reset day specific time?");
    }

    const resetSuccess=()=>{
        message.success("Successfully removed day specific timings!");
        refetchDetails(); 
    }

    const resetFailure=()=>{
        message.error("Failed removed day specific timings!");
        setLoading(false);
    }

    const onClickUpdateTimings=()=>{
            setLoading(true);
            let {
                dropPoints,pickupPoints,cities,dataError
            } = getApiDetails(citiesWithStops,isDaySpecificExits,tripId);

            if(!dataError){
                updateStopTimingsOfTrip(scheduleId,tripId,pickupPoints,dropPoints,cities,updateSuccess,updateFailure);
                setShowError(false);
            }else{
                setShowError(true);
                setLoading(false);
                message.error("Stops information is not complete");
            }
    }

    const updateSuccess=()=>{
        message.success("Successfully updated timings!");
        refetchDetails(); 
    }

    const updateFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    let isShowCheckBox=showCheckBoxForEdit();

    const renderCheckBox=(value=true,isCityHeader,cityIndex,stopIndex)=>{
        if(isShowCheckBox){
            return(
                <Checkbox 
                className="marginRight" 
                checked={value}
                onClick={(e)=>{
                    let checked = e.target.checked;
                    if(isCityHeader){
                        // Note not in use
                        onClickCityHeaderCheckBox(checked,cityIndex);
                    }else{
                        onClickStopCheckBox(checked,cityIndex,stopIndex)
                    }
                }}/>
            )
        }else{
            return null
        }
    }
    
    return (
            <ZoomComponent isMobile={isMobile}>
                <div className="new-timing-root">
                        <Row>
                            <Col>
                                <Title level={3}>
                                    Manage Stops
                                </Title>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Title level={5}>
                                    {`${scheduleDescription}`}
                                </Title>
                            </Col>
                        </Row>
                        <Row  className="heading-new-timing">
                            {isMobile?
                                <Col span={2}></Col>
                                :
                                <Col span={4}></Col>
                            }
                            <Col span={isMobile?8:4}>
                                <div className="city-col">
                                    Stop
                                </div>
                            </Col>
                            <Col span={isMobile?4:null}>
                                <div className="timing-col">
                                    Time
                                </div>
                            </Col>
                            <Col span={isMobile?2:4}>
                                <div className="day-col">
                                    Day
                                </div>
                            </Col>
                            <Col span={isMobile?2:4}>
                                <div className="">
                                    {isMobile?"TC":"Trip Close"}
                                </div>
                            </Col>
                        </Row>
                        <Divider className="dividerCol" />
                        <div className="new-timing-body">
                            {renderAllCitiesWithStops()}
                        </div>
                        {showUpdateBtns&&
                            <Row justify="center" align="middle" gutter={[16,16]}>
                                <Col>
                                {isDaySpecificExits||showResetBtn(citiesWithStops)?
                                    <Button 
                                    className="exit-btn"
                                    onClick={onClickReset}
                                    >Reset To Original Time</Button>
                                    :null
                                }
                                </Col>
                                <Col>
                                    <Button
                                        // className="outline-button"
                                        onClick={onClickUpdateTimings}
                                        >
                                            Update
                                        </Button>
                                </Col>
                            </Row>
                        }
                        <Modal
                            title={null} 
                            className="createNewStopModal"
                            modalTitle="Delay Notification"
                            visible={isOpenNewStop} 
                            width="95vw"
                            onOk={()=>{
                                setIsOpenNewStop(false)
                            }} 
                            onCancel={()=>{
                                setIsOpenNewStop(false)
                            }} 
                            centered={true}
                            footer={null}
                        >
                            <div>
                                <CreateStandardStopsPage 
                                fromModal={true} 
                                selectedCity={selectedCity} 
                                onSuccessRefresh={(id)=>{
                                    reSetStandaredCities(selectedCity,selectedCityIndex);
                                    updateStopsDate(id,false,selectedCityIndex,selectedStopIndex,'selectedStopId');
                                }}
                                operatorCode={global.operatorCode}
                                onFailure={(errMsg)=>{
                                    message.error(errMsg);
                                }}
                                />
                            </div>
                        </Modal>
                </div>
            </ZoomComponent>
            )
}