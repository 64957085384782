import React from "react";
import './bus-delay-modal.style.scss';
import { Form , Button , Row , Col, Input, message , Modal} from 'antd';
import { useDispatch } from "react-redux";
import { NOTIFICATION_DELAY_BUS_URL } from "../../../utils/api-url";
import { loadingAction } from "../../../actions/loading-actions";
import { isValid } from "../../../utils/utilities";
import { getLocalDate } from "../../../utils/date.utils";
import { useState } from "react";

const { TextArea }= Input ;


const sendDelayNotification=(delayMessage,delaytime,travelDate,tripId,setLoading)=>{
    setLoading(true); 
    return fetch(NOTIFICATION_DELAY_BUS_URL, {
      method: "POST",
      headers:
      {'Content-Type':'application/json'}
      ,
      body: JSON.stringify(
        { "delayInfo": {
            "tripId" : tripId,
            delayTime:delaytime,
            delayMessage,
            travelDate:travelDate,
            operatorCode:global.operatorCode,
            operatorName:global.operatorName
        }
        })
      }).then(response => response.json())
      .then(data => {
        setLoading(false);
          if (data === false){
            message.error("Notification could not be sent due to unexpected error");
              return false;
          }
          else{
              message.success("Notification sent successfully");
              return true;
          }
      });
  }

const DelayBody=({delayForm,travelDate,origin,destination})=>{

    let defaultDelayEmailMsg=`Dear Customer, Thank you so much for choosing ${global.operatorName}. Due to some unforseen reasons, the bus that you were scheduled to travel on ${travelDate} from ${origin} to ${destination} has been delayed by minvariable mins. We sincerely apologize for the inconvenience. We will try our very best to avoid such misfortunes in the future.Thank you so much for your understanding,- ${global.operatorName}`;
    // let defaultDelaySmsMsg=`Dear Customer, Due to unforeseen reasons, your bus on 2021-09-02 has been delayed by minvariable mins. We apologize for the inconvenience-${global.operatorName}`;

    const setDefaultValues=()=>{
        let delaytime=delayForm.getFieldValue('delaytime');
        if(isValid(delaytime)){
            delayForm.setFieldsValue({
                emailMsg:defaultDelayEmailMsg.replace('minvariable',delaytime)
                // smsMsg:defaultDelaySmsMsg.replace('minvariable',delaytime)
            })
        }else{
            message.error("Please input time in mins!")
        }
    }


    return(
        <div>
            <Form 
               form={delayForm}
            >
                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="delaytime"
                        label="Delay Time:"
                        rules={[{ required: true, message: 'Please input your Delay time!' }]}
                        >
                            <Input className="delay-time" placeholder={"Delay time"} addonAfter="minutes"/>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={setDefaultValues}>
                                SET
                        </Button>
                    </Col>
                </Row>

                <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="emailMsg"
                        label="Email Message:"
                        rules={[{ required: true, message: 'Please input your email message!' }]}
                        >
                            <TextArea className="delay-email-txt-area"/>
                        </Form.Item>
                    </Col>
                </Row>

                {/* <Row gutter={[16,16]}>
                    <Col>
                        <Form.Item
                        name="smsMsg"
                        label="SMS Message:"
                        rules={[{ required: true, message: 'Please input your sms message!' }]}
                        >
                            <TextArea maxLength={158} showCount={true}/>
                        </Form.Item>
                    </Col>
                </Row> */}
            </Form>
        </div>
    )
}

export const BusDelayModal = (props) => {
    const{
        schedule
    } =props;

    const [delayForm]=Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);

    const dispatch=useDispatch();

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const handleSubmit =  (e) => {
        e.preventDefault();
    }

    return (
        <div>
            <Button
                className="bordered-btn bus-delay-btn"  
                onClick={showModal}
            >
                Bus Delay
            </Button>
            <Modal
                    title={null} 
                    modalTitle="Delay Notification"
                    visible={isModalVisible} 
                    onOk={async ()=>{
                        return delayForm.validateFields()
                        .then(async (values) => {
                        let {
                            delaytime,
                            emailMsg
                        }=values;
                         return await sendDelayNotification(emailMsg,delaytime,schedule.start_date,schedule.trip_id,setLoading);
                        }).catch((err)=>{
                         console.log({err});
                         return false
                        })
                    }} 
                    onCancel={handleCancel} 
                    okText={"Send Notification"}
                >
                    <DelayBody 
                        delayForm={delayForm} 
                        origin={schedule.origin} 
                        destination={schedule.destination}
                        travelDate={getLocalDate(schedule.start_date)}
                    />
            </Modal>      
        </div>
    )
}
