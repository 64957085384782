import React from "react";
import {Checkbox, Col, Row, Typography , Button} from "antd";
import { useState } from "react";

const {
    Text
} = Typography;



export const SecondBlock = (props) => {

        const{
            onSubmit,
            goBack,
            handleCancel
        }=props;

        const [notify,setNotify] = useState(true);

        return (
            <div>
                <Row>
                    <Col>
                        <Text>Are you sure that you want to cancel the above seats and issue a full refund?</Text>
                    </Col>
                </Row>
                <Row gutter={[16,16]}>
                    <Col>
                        <Checkbox defaultChecked
                            onChange={(e)=>{
                                setNotify(e.target.checked);
                            }}
                        >Notify Passengers</Checkbox>
                    </Col>
                </Row>
                <Row gutter={[16,16]} justify="space-around">
                    <Col>
                        <Button className="exit-btn"
                                onClick={goBack}
                        >Go back</Button>
                    </Col>
                    <Col>
                        <Button onClick={()=>{
                            onSubmit(notify)
                        }}>Cancel Seats</Button>
                    </Col>
                </Row>
            </div>
        )
}