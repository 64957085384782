import React, { useState } from "react";
import { Button, Col, Row, message, Checkbox, Divider  } from "antd";
import {
    DownOutlined,
    MailOutlined,
    MobileOutlined,
    SendOutlined
   } from '@ant-design/icons';
import { WhatsAppTextShare } from "../custom-components/custom-component";
import { getTextOfTicket } from "./open-booked-ticket-modal.component";
import { sendTicketUpdateMailById, sendTicketUpdateSMSById } from "../../actions/ticket.action";
import { isValid, isValidArray } from "../../utils/utilities";
import { sendTrackingNotificationByTicketId } from "../bus-cancel-components/send-driver-details-modal/send-driver-details-modal.module";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";

const getVechicleDetails = (scheduleVechicleId,tripVechicleId)=>{

    let vechicleInfoExits = false;
    if(isValid(tripVechicleId)){
        vechicleInfoExits = true;
    }

    if(isValid(scheduleVechicleId)){
        vechicleInfoExits = true;
    }

    return vechicleInfoExits
}

export const NotifyButton = (props) => {

    const{
        onClick,
        color
    }=props;

    return (
        <div>
            <Button onClick={onClick} style= {{background: isValid(color)?color:''}}>
            <SendOutlined />
                Notify <DownOutlined />
            </Button>
        </div>
    );
}



export const NotifyComponent = (props) => {

    const{
        ticketInfo,
        selectedNotifyTickets
    }=props;

    const [sendTicketDetails,setSendTicketDetails] = useState(false);
    const [sendDrivingDetails,setSendDrivingDetails] = useState(false);

    const dispatch = useDispatch();

    const setLoading =(value)=>{
     dispatch(loadingAction(value));   
    }
      
      if(isValidArray(ticketInfo)&&selectedNotifyTickets.indexOf(ticketInfo.ticket.id) != -1){

        let vechicleInfoExits = getVechicleDetails(ticketInfo.schedule.vechicle_id,ticketInfo.trip.vechicle_id);
        return (
            <div>
                <Divider />
                        
                        <Row justify='center' align='center'>
                            <Col>
                                <Row gutter={[16,16]}> 
                                    <Col>
                                        <Checkbox 
                                            value={sendTicketDetails} 
                                            onChange={(e)=>{
                                                setSendTicketDetails(e.target.checked);
                                            }}
                                        >Ticket Details</Checkbox>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Checkbox 
                                            disabled={!vechicleInfoExits}
                                            value={sendDrivingDetails}
                                            onChange={(e)=>{
                                                setSendDrivingDetails(e.target.checked);
                                            }}
                                                    >Bus Tracking Details</Checkbox>
                                    </Col>
                                </Row>
                                <Row gutter={[16,16]}>
                                    <Col>
                                        <Button 
                                        size="large"
                                        icon={<MailOutlined />}
                                        onClick={()=>{    
                                            if(sendTicketDetails){
                                                setLoading(true);
                                                sendTicketUpdateMailById(ticketInfo.ticket.id,(succMsg)=>{
                                                    message.success(succMsg);
                                                    setLoading(false);
                                                    },(errMsg)=>{
                                                        setLoading(false);
                                                        message.error(errMsg);
                                                    });
                                            }   
                                            if(sendDrivingDetails){
                                                message.warning("Driver details cannot send via email!");
                                            }
                                        }}>
                                            Send Mail
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button
                                        size="large"
                                        icon={<MobileOutlined />}
                                        onClick={()=>{
                                            if(sendTicketDetails){
                                                setLoading(true);
                                                sendTicketUpdateSMSById(ticketInfo.ticket.id,(succMsg)=>{
                                                    setLoading(false);
                                                    message.success(succMsg)
                                                    },(errMsg)=>{
                                                    setLoading(false);
                                                    message.error(errMsg);
                                                });
                                            }
                                            if(sendDrivingDetails){
                                                setLoading(true);
                                                sendTrackingNotificationByTicketId(ticketInfo.ticket.id,()=>{
                                                    message.success("Driver details sms sent successfully!");
                                                    setLoading(false);
                                                },(msg)=>{
                                                    setLoading(false);
                                                    message.error(msg);
                                                });
                                            }
                                        }}>Send SMS</Button>
                                    </Col>
                                    <Col>
                                        <WhatsAppTextShare
                                            shareId="ticketSummary"
                                            zoomValue={0.5}
                                            buildText={getTextOfTicket(ticketInfo)}
                                            phoneNumber={ticketInfo.ticket.phone_number}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
            </div>
        );
      }else{
        return null;
      }
}
