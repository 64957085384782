import React from "react";
import { Button , Modal , Typography,Row,Col,Form ,message, Table} from "antd";
import { useState , useEffect} from "react";
import { AgentsSelect } from "../../search-components/agents-select/agents-select.component";
import { fetchTicketDetails } from "./bus-cancellation-modal.module";
import { getLocalDate, getLocalTime, getUIDateFormat } from "../../../utils/date.utils";
import { isValid } from "../../../utils/utilities";
import { blockAllAvailableSeats, getAgentNames, getTableDataFilterByAgent, sendBusCancellationEmail } from "../bus-cancel-tickets-table.module";
import { SecondBlock } from "./second-block.component";
import { showConfirm } from './../../custom-components/custom-component';
import { loadingAction } from "../../../actions/loading-actions";
import { useDispatch, useSelector } from "react-redux";
import './bus-cancellation-modal.style.scss';
import useColumnSearch from "../../../ui-components/table-column-search";
import { userInfoSelector } from "../../../selector/user.selector";
import {
  useHistory
} from "react-router-dom";

const {
    Text
} = Typography;

const OTHERS="Others";
const REDBUS = "Redbus";
const WEBSITE ="Website";
const ABHIBUS ="Abhibus";
const PAYTM = "Paytm";
const QWIKBUS = "QwikBus";
const MAVEN = "maven"

const DrawLine =()=>{

  return(
      <div className="bus-cancellation-underline">

      </div>
  )
}

export const BusCancellationModal = (props) => {

        const{
            schedule
        }=props;

        const [isModalVisible, setIsModalVisible] = useState(false);
        const [tickets,setTickets]=useState([]);
        const [form] = Form.useForm();
        const [selectedTickets,setSelectedTickets]=useState([]);
        const [agentName,setAgentName]=useState(null);
        const [filteredTickets,setFilteredTickets]=useState([]);
        const [agentNames,setAgentNames]=useState([]);
        const [stepNo,setStepNo]=useState(1);
        const{ userName }=useSelector(userInfoSelector);

        const dispatch = useDispatch();
        const history = useHistory();

        const handleOk = () => {
            setIsModalVisible(false);
        };

        const handleCancel = () => {
            setIsModalVisible(false);
            setSelectedTickets([]);
            setTickets([]);
            setAgentName(null);
            setFilteredTickets([]);
            setAgentNames([]);
            setStepNo(1);
        };

        const goBack=()=>{
          if(stepNo==1){
            setIsModalVisible(false);
          }else{
            setStepNo(stepNo-1);
          }
        }

        const showModal = () => {
            setIsModalVisible(true);
        };

        const handleSubmit =  (e) => {
            e.preventDefault();
            return form.validateFields()
            .then(values => {
                if(agentName!=values.agentName){
                    setAgentName(values.agentName);
                    let filteredData = getTableDataFilterByAgent(tickets,values.agentName,OTHERS,REDBUS,WEBSITE,ABHIBUS,PAYTM, QWIKBUS);
                    setFilteredTickets(filteredData);
                }
            }).catch((err)=>{
              console.log({err});
            })
        }

        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
              setSelectedTickets(selectedRows);
            },
          };

          useEffect(()=>{
            if(isModalVisible){
              fetchTicketDetails(schedule.trip_id,null,(data)=>{
                setTickets(data);
                setAgentNames(getAgentNames(data,OTHERS,REDBUS,WEBSITE,ABHIBUS, PAYTM, QWIKBUS));
              });
            }
          },[schedule,isModalVisible]);

          const setLoading=(bool)=>{
            dispatch(loadingAction(bool));
        }

    const moveToPnr=(pnr)=>{
        history.push('/view-cancel?pnr='+pnr);
    }

    const columns = [
      {
        title: 'PNR',
        dataIndex: 'pnr',
        key: 'pnr',
        ...useColumnSearch(
          {dataIndexExt:"pnr"}
      ),
      render:(text)=>{
        return (
          <a 
          onClick={()=>{
              moveToPnr(text);
          }} 
          type="link" 
          className="outline-button">
            {text}
          </a>
        )
      }
      },
      {
        title: 'Booking Time',
        dataIndex: 'booking_time',
        key: 'booking_time',
        render:(text)=>{
          if(isValid(text)){
            return  `${getUIDateFormat(text)},\xa0\xa0${getLocalTime(text)}`
          }else{
            return null
          }
        }
      },
      {
        title: 'Full Name',
        dataIndex: 'full_name',
        key: 'full_name',
        ...useColumnSearch(
          {dataIndexExt:"full_name"}
      ),
        responsive: ["sm"]
      },
      {
        title: 'Sex',
        dataIndex: 'gender',
        key: 'gender',
        responsive: ["sm"]
      },
      {
        title: "Name Age Sex",
        render: (record) => (
          <React.Fragment>
            {record.fullName}
            <br />
            {record.age}
            <br />
            {record.gender}
          </React.Fragment>
        ),
        responsive: ["xs"]
      },
      {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
      },
      {
        title: 'Agent',
        dataIndex: 'agent_name',
        key: 'agent_name',
        ...useColumnSearch(
          {dataIndexExt:"agent_name"}
      ),
      },
      {
        title: 'Boarding',
        dataIndex: 'location',
        key: 'location',
      },
      {
        title: 'Seat#',
        dataIndex: 'seat_number',
        key: 'seat_number',
      },
      {
        title: 'Fare',
        dataIndex :'ticket_cost',
        key :'ticket_cost'
      },
      {
        title: 'Drop',
        dataIndex: 'drop_location',
        key: 'drop_location',
      }
    ];


        return (
            <div>
                <Button
                    className="bordered-btn bus-cancel-btn"
                    onClick={showModal}
                >
                    Bus Cancellation
                </Button>
                <Modal
                    title={null}
                    visible={isModalVisible}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    footer={null}
                    width="90vw"
                    className="bus-cancellation-modal"
                >

                  <Row gutter={[16,16]}>
                      <Col>
                        <Text strong>Bus Cancellation</Text>
                        <DrawLine

                        />
                      </Col>
                  </Row>
                  <Row gutter={[16,16]}>
                      <Col>
                          <Text>
                              {schedule.description}
                          </Text>
                      </Col>
                  </Row>
                  <Row gutter={[16,16]}>
                      <Col>
                          <Text><Text strong>DOJ:</Text>{getLocalDate(schedule.start_date)}</Text>
                      </Col>
                  </Row>
                  {stepNo === 1&&
                     <div>
                        <Form
                          form={form}
                          >
                              <Row gutter={16} justify="center" align="middle">
                                  <Col>
                                      <AgentsSelect
                                      agents={agentNames}
                                      showLabel={true}
                                      />
                                  </Col>
                                  <Col>
                                      <Form.Item>
                                              <Button type="primary"
                                              onClick={(e)=>{
                                                  handleSubmit(e)
                                              }}>Filter</Button>
                                      </Form.Item>
                                  </Col>
                              </Row>
                          </Form>
                        <Row gutter={[16,16]}>
                          {isValid(agentName)&&
                            <Col>
                                <Table
                                    columns={columns}
                                    dataSource={filteredTickets}
                                    pagination={false}
                                    rowSelection={rowSelection}
                                    rowKey={(record) => {
                                      return record.pnr;
                                    }}
                                  />
                            </Col>
                          }
                        </Row>
                        <Row gutter={[16,16]} justify="space-around">
                            <Col>
                                <Button className="exit-btn"
                                  onClick={goBack}
                                >Go back</Button>
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                  if(selectedTickets.length>0){
                                    setStepNo(2);
                                  }else{
                                    message.warning("Please select atleast one ticket!")
                                  }
                                }}>Continue</Button>
                            </Col>
                        </Row>
                    </div>
                  }
                  {stepNo===2&&
                    <SecondBlock
                    handleCancel={handleCancel}
                    goBack={goBack}
                    onSubmit={async (notify)=>{
                      let passingAgentName = agentName;
                      if (agentName == QWIKBUS) {
                        passingAgentName = MAVEN;
                      }
                      let responce = await sendBusCancellationEmail(selectedTickets,passingAgentName,schedule.trip_id,schedule.start_date,schedule.origin,schedule.destination,notify,setLoading,userName);
                      if(responce){
                        form.resetFields();
                        showConfirm(()=>{
                          setIsModalVisible();
                          blockAllAvailableSeats(schedule.trip_id,setLoading)
                        },"Are you sure?","To block all available seats?","Block All Seats",()=>{
                          handleCancel();
                        });
                      }
                      return responce;
                    }}
                    />
                  }
                </Modal>
            </div>
        )
}
