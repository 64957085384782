import { Button, Table , Row , Col , Input , Switch} from "antd";
import React, { useEffect, useState } from "react";
import { getStaff , deleteStaff , getStaffTypes , updateStaff, deleteDriver, updateDriver, createDriver, updateSubscribedValue} from "../actions/staff-registration.action";
import '../styles/staff.style.scss';
import { StaffRegistrationForm } from './../components/staff-registration-form/staff-registration-form.component';
import { Popover } from 'antd';
import {
    EllipsisOutlined
  } from '@ant-design/icons';
import { showConfirm } from './../components/custom-components/custom-component';
import { checkActionAllowed, isValid } from "../utils/utilities";
import { StaffTypesSelect } from './../components/search-components/staff-types-select/staff-types-select.component';
import { ACTION_TO_SET_DRIVER_APP_SUBSCRIPTION, STAFF_DRIVER } from "../utils/constant";

const DrawLine =()=>{
    return(
        <div className="staff-register-underline">

        </div>
    )
}

export const StaffPage = (props) => {

    const [staffData,setStaffData]=useState([]);
    const [editId,setEditId]=useState(null);
    const [staffTypes,setStaffTypes]= useState([]);
    const [editOldValues, setEditOldValues] = useState(null);

    useEffect(()=>{
        getStaffTypes((data)=>{
            setStaffTypes(data);
        },(data)=>{
        });
    },[]);

    const deleteDriverData = (data) => {
        let driverData = {'name':data.name,'phone_number':data.phone_number};
        deleteDriver(driverData, ()=>{}, ()=>{});
    }

    const deleteStaffById = (staffId, data)=>{
        showConfirm(()=>{
            deleteStaff({staffId,'phone_number':data.phone_number},(response)=>{
                loadData();
                if (response.count==0) {
                    if (data.staff_type==STAFF_DRIVER) {
                        deleteDriverData(data)
                    }
                }
            },()=>{
    
            })
        },"Are you sure you want to delete this staff?");
    }

    const columns =[
        {
            title: 'S no',
            dataIndex: 'S no',
            key: 'S no',
            width:100,
            render: (text, object, index) => index + 1
          },
          {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width:150,
            render:(text,row,index)=>{
                if(row.id === editId){
                    return <Input value = {text} style={{width:'130px'}} onChange={(e)=>{
                        staffData[index].name = e.target.value;
                        setStaffData([...staffData]);
                    }}/>
                }else{
                    return text;
                }
            }
          },
          {
            title: 'Phone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            width:150,
            render:(text,row,index) =>{
                return (
                    <div>
                        <Popover 
                            content={<Row gutter={[16,16]}>
                                        <Col>
                                            <Button onClick={()=>{
                                                setEditId(row.id);
                                                setEditOldValues({...row});
                                            }}>Edit</Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={()=>{
                                                deleteStaffById(row.id,row);
                                            }}>Delete</Button>
                                        </Col>
                                    </Row>}
                        >
                            {row.id === editId?
                            <Input value = {text} style={{width:'130px'}}
                            onChange={(e)=>{
                                staffData[index].phone_number = e.target.value;
                                setStaffData([...staffData]);
                            }}
                            />
                            :
                            text
                            }
                            <Button  
                                type="text" 
                                className="outline-button three-dots-button"  
                                icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                            />
                        </Popover>
                    </div>
                )
            }
          },
          {
            title: 'Type',
            dataIndex: 'staff_type',
            key: 'staff_type',
            width:150,
            render:(text,row,index)=>{
                if(row.id === editId){
                    return <StaffTypesSelect 
                                data={staffTypes}
                                showLabel={false}
                                selectStaff={text}
                                style={{width:'130px'}}
                                onChange={(value)=>{
                                    staffData[index].staff_type = value;
                                    setStaffData([...staffData]);
                                }}
                                isFormItem={false}
                    />
                }else{
                    return text;
                }
            }
          }
    ];

    if(checkActionAllowed(ACTION_TO_SET_DRIVER_APP_SUBSCRIPTION)){
        columns.push({
            title: 'Driver App Subscription',
            dataIndex: 'subscribed',
            key: 'subscribed',
            render:(text,row,index)=>{
                if(row.staff_type == STAFF_DRIVER){
                    return(
                        <div>
                            <Switch 
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                                checked={text==1||text==true?true:false}
                                onChange={(value)=>{
                                    let msg = "";
                                        if(value){
                                            msg = `Are you sure to enable subcription for ${row.name} ?`;
                                        }else{
                                            msg = `Are you sure to disable subcription for ${row.name} ?`;
                                        }
                                    showConfirm(()=>{
                                        updateSubscribedValue(row.phone_number,value,()=>{
                                            loadData();
                                        },()=>{});
                                    },"Subscription Alert",msg);
                                }}
                            />
                        </div>
                    );
                }else{
                    return null;
                }

            }
          });
    }

    if(isValid(editId)){
        columns.push({
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            width:150,
            render:(text,row)=>{
                if(editId === row.id){
                    return(
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button 
                                className="cancel-button"
                                onClick={()=>{
                                    setEditId(null);
                                    setEditOldValues(null);
                                }}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button 
                                    onClick={()=>{
                                        if (editOldValues.staff_type==STAFF_DRIVER) {
                                            if (row.staff_type !== STAFF_DRIVER) {
                                                deleteDriverData(editOldValues);
                                            }
                                            else {
                                                let driverData = {'name': row.name,'phone_number':row.phone_number,'oldName': editOldValues.name,'oldPhoneNumber': editOldValues.phone_number}
                                                updateDriver(driverData,()=>{console.log("updated")},()=>{});
                                            }
                                        }
                                        else {
                                            if (row.staff_type == STAFF_DRIVER) {
                                                createDriver({'name': row.name,'phoneNumber':row.phone_number},()=>{},()=>{});
                                            }
                                        }
                                        updateStaff(
                                            {name:row.name,staffType:row.staff_type,phoneNumber:row.phone_number,id:row.id}
                                            ,()=>{
                                                setEditId(null);
                                            },
                                            ()=>{})
                                    }}
                                >Save</Button>
                            </Col>
                        </Row>)
                }else{
                    return null
                }
            }
        })
    }

    useEffect(()=>{
        loadData(); 
    },[]);

    const loadData=()=>{
        getStaff((data)=>{
            setStaffData(data) 
        });
    }

    return (
        <div className="staff-cancel-root">
            <div className="staff-cancel-inner-div">
                <div>
                    <h3>
                        Staff Registration
                    </h3>            
                    <DrawLine />
                </div>
                <div className="staff-cancel-form-div">
                    <StaffRegistrationForm 
                        loadData={loadData}    
                        staffTypes={staffTypes}
                    />
                </div>     
                <div>
                    <Table 
                        dataSource={staffData}
                        columns={columns}
                        pagination={false}
                    />
                </div>       
            </div>
        </div>
    )
}