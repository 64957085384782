import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography, Table} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { Printer } from './../../components/custom-components/custom-component';
import { getAPIDateFormat, getLocalDateAndTime, getReadableDate } from './../../utils/date.utils';
import { getRangeOptions } from './reports.module';
import { getSeatSharingReport } from "../../actions/reports.action";
import { isValid } from "../../utils/utilities";

const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;

let tableColumns=[
    {
        title:"SN",
        dataIndex:'',
        key:'description',
        render:(text,row,index) =>{
            return index+1
        }
    },
    {
        title:"Trip Description",
        key:'description',
        dataIndex:'description',
        render: (text, row, index) => {
             if (row.rowSpan != 0) {
                return {
                    children: text,
                    props: {
                      rowSpan: row.rowSpan
                    }
                };
              }
             
            return {
                children: text,
                props: {
                    rowSpan: row.rowSpan
                  }
            };
        }
    },
    {
        title:"Travel Date",
        key:"start_date", 
        dataIndex:"start_date",
        render: (text, row, index) => {
            if (row.rowSpan != 0) {
               return {
                   children: getReadableDate(text),
                   props: {
                     rowSpan: row.rowSpan
                   }
               };
             }
            
           return {
               children: getReadableDate(text),
               props: {
                   rowSpan: row.rowSpan
                 }
           };
       }
    },
    {
        title:"Seat Number",
        key:"seat_number", 
        dataIndex:"seat_number",
        render: (text, row, index) => {
            if (row.rowSpan != 0) {
               return {
                   children: text,
                   props: {
                     rowSpan: row.rowSpan
                   }
               };
             }
            
           return {
               children: text,
               props: {
                   rowSpan: row.rowSpan
                 }
           };
       }
    },
    {
        title:"PNR",
        key:"pnr", 
        dataIndex:"pnr",
    },
    {
        title:"Booking Date",
        key:"booking_time", 
        dataIndex:"booking_time",
        render : (text, record, index) => {
            if(isValid(record.booking_time)){
                return getLocalDateAndTime(record.booking_time)
            }else{
                return ''
            }
        }
    },
    {
        title:"Origin",
        key:"origin", 
        dataIndex:"origin",
    },
    {
        title:"Destination",
        key:'destination',
        dataIndex:'destination'
    },
    {
        title:"Fare",
        key:'fare',
        dataIndex:'fare'
    },
    {
        title:"Agent",
        key:'agent_name',
        dataIndex:'agent_name'
    }
];
const RenderReport=({seatSharing})=>{
    

    return(
        <div>
            <Table 
                columns={tableColumns}
                dataSource={seatSharing}
                pagination={false}
                bordered
            />
        </div>
    )
    
}

export const TicketSharingReport = () => {

    const [showReport,setShowReport]=useState(false);
    const [seatSharing,setSeatSharing]=useState([]);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        setLoading(false);  
        setSeatSharing(data);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowReport(false);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        setSeatSharing([]);
        getSeatSharingReport(fromDate,toDate,onSuccess,onFailure);
    }

    const [form] = Form.useForm();
    
    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                                rules={[
                                    { required: true , message: 'Please input your date range!'},
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                        if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                        },
                                    })
                                ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <Row gutter={16}>
                        <Col>
                        </Col>
                        <Col>
                            <Printer 
                                showComponent={false}
                                renderComponent={()=>{
                                    return (
                                        <RenderReport 
                                            seatSharing={seatSharing}
                                        />
                                    )
                                }} 
                                zoomValue={0.5}
                                />
                        </Col>
                    </Row>
                    :
                    null
                }
                <RenderReport 
                    seatSharing={seatSharing}
                />
            </div>
    )
}