
import { 
    GET_STANDARD_STOPS__URL , 
    CREATE_ADDRESS_LOCATION_URL , 
    UPDATE_ADDRESS_LOCATION_URL, 
    DELETE_ADDRESS_LOCATION_URL
} from "./../utils/api-url";
import { postCall } from "../utils/network.util";
import { GET_STANDARD_STOPS } from "./action-types";

export const getStandardStops=()=>{
        return{
          type:GET_STANDARD_STOPS,
          payload: {
              url:GET_STANDARD_STOPS__URL,
              api:postCall,
              apiPayload:{
                cities:[]
              }
          }
      }
}


export const getStandardStopsForCities=(cities,onSuccess=()=>{},onFailure=()=>{})=>{
  var created = postCall(GET_STANDARD_STOPS__URL, {cities:cities});
      created.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to create address");
    })
}

export const createAddressLocation=(values,onSuccess=()=>{},onFailure=()=>{})=>{
    var created = postCall(CREATE_ADDRESS_LOCATION_URL, {location:values});
        created.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
            onSuccess(data.payload.Payload[0]);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to create address");
      })
}

export const updateAddressLocation=(location,onSuccess=()=>{},onFailure=()=>{})=>{
    var updated = postCall(UPDATE_ADDRESS_LOCATION_URL, {location:location});
      updated.then((data) => {
        if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
            onSuccess();
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to update addresses");
      })
}

export const deleteAddressLocation=(locationId,onSuccess=()=>{},onFailure=()=>{})=>{
    var deleted = postCall(DELETE_ADDRESS_LOCATION_URL, {locationId});
    deleted.then((data) => {
        if(data){
            onSuccess();
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to delete addresses");
      })
}