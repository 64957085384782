import React, { useEffect, useState } from 'react';
import { isValidArray  } from '../../utils/utilities';
import './donut-chart.style.scss';
import { Pie } from '@ant-design/plots';

const DonutChartDashBoardComponent = (props) => {

    const {
      pieData
    }=props;

    const [centerContent , setCenteredContent] = useState("Seats");
    const [centerTitle , setCenteredTitle] = useState(null);
    const [pieFormateInfo , setPieFormateInfo] = useState([]);
    const [pieColors , setPieColors] = useState([]);

    useEffect(()=>{
        if(isValidArray(pieData)&&pieData.length>0){
            let data=[] , color=[];
            for (let index = 0; index < pieData.length; index++) {
                const element = pieData[index];

                let formatedInfo = {
                    type: element.key,
                    value: element.value
                };

                formatedInfo[element.key] = element.key;
                data.push(formatedInfo);
                color.push(element.svg.fill);   
                  
                if(element.key == "Vacant"){
                    setCenteredTitle(element.value);
                }
            }
            setPieFormateInfo(data);
            setPieColors(color);
          }else{
            setPieFormateInfo([]);
            setPieColors([]);
            setCenteredTitle(null);
          }
    },[pieData]);
        
    return (
        <div className='donut-center-align'>
            <Pie
                label={{
                  visible:true,
                  autoRotate:true,
                  formatter:(value)=>{
                    return value.angleField
                  },
                  style:{fontSize: 8, fill: 'black'}
                }}
                data={pieFormateInfo}
                color={pieColors}
                height={90}
                width={135}
                legend={{
                    visible:false
                }}
                title={{
                    visible: false,
                    text: '环图',
                }}
                description={{
                    visible: false,
                    text: '环图的外半径决定环图的大小，而内半径决定环图的厚度。',
                }}
                radius={0.9}
                innerRadius ={0.55}
                padding='auto'
                angleField='value'
                colorField='type'
                tooltip={{
                  visible:true,
                  formatter	:(info)=>{
                    return {name:info.type,value:info.value};
                  }
                }}
                autoFit
                statistic={{
                    title:{
                      customHtml:()=>{
                        return `${centerTitle}`
                      },
                      style:{fontSize: 18, fill: 'red',fontWeight:'bold'}
                    },
                    content:{
                      customHtml:()=>{
                        return centerContent
                      },
                      style:{fontSize: 10, fill: 'red',fontWeight:'bold'}
                    }
                }}
                events={{
                      // onRingClick: (event) => console.log(event),
                      // onLegendClick: (event) => console.log(event),
                      onPlotClick: (event) => {
                        // if(isValidArray(event.data)&&isValidArray(event.data.data)&&isValid(event.data.data.value)){
                        //   setCenteredTitle(event.data.data.value);
                        // }
                      }
                  }}
                />
        </div>
    );
}


export default DonutChartDashBoardComponent;
