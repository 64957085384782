import React, { useEffect, useState , useContext , useRef} from "react";
import '../styles/home-page.style.scss';
import { Row , Select , Col , DatePicker , Button , Form ,Typography}  from 'antd';
import { useDispatch , useSelector} from "react-redux";
import { getOriginCities , getDestinationCities,fetchSchedules , setSelectedScheduleSeats} from './../actions/home.action';
import { originsSelector,originSupportedDestinationsSelector , selectedScheduleSeatsSelector} from './../selector/home.selector';
import { getUrlVars, isValid, isValidArray} from './../utils/utilities';
import { getAPIDateFormat } from './../utils/date.utils';
import { OpenBookedTicketModal } from './../components/ticket-summary-components/open-booked-ticket-modal.component';
import {
    SwapOutlined
  } from '@ant-design/icons';
import { DeviceContext } from './../components/device-provider/device-provider';
import { SessionTimeOut } from './../components/session-time-out-component/session-time-out.component';
import { SearchResultsComponent } from './../components/search-results/search-results.component';
import moment from 'moment';
import { useHistory, useLocation } from "react-router-dom";
import { OverDueText } from "../components/over-due-text-component/over-due-text.component";

const {
    Text
} = Typography;

const { Option } = Select;

const SearchBusDrawLine =()=>{

    return(
        <div className="search-bus-underline">

        </div>
    )
}

const renderOrigins=(origins)=>
{
    let originsArray=[<Option value={'all'}>{'All'}</Option>];
    if(isValidArray(origins)&&origins.length>0){
        origins.forEach(element => {
            if(isValid(element)){
                originsArray.push(<Option value={element}>{element}</Option>)
            }        
        });
    }
    return originsArray;
}

const renderDestinations=(origins,destinations,origin)=>
{
    let destiArray=[];
    destiArray.push(<Option value={'all'}>{'All'}</Option>);
    
        if(isValidArray(destinations)&&destinations.length>0){
            destinations.forEach(element => {
                if(isValid(element)){
                    destiArray.push(<Option value={element}>{element}</Option>)
                }        
            });
        }else{
            destiArray = renderOrigins(origins);
        }
        
    return destiArray;
}


export const HomePage = () => {

    const dispatch = useDispatch();
    const origins = useSelector(originsSelector);
    const destinations = useSelector(originSupportedDestinationsSelector);
    const [schedules,setSchedules]=useState(undefined);
    const [origininput,setOrigin]=useState(undefined);
    const [destinationinput,setDestination]=useState(undefined);
    const [showResults,setShowResults]=useState(false);
    const [openTripRouteId,setOpenTripRouteId] = useState(undefined);
    const [inputDate,setInputDate] = useState(null);


    const [form] = Form.useForm();
    const searchRef = useRef(null);
    const history = useHistory();
    let location = useLocation();
    let {
        origin,
        destination,
        date
    } = getUrlVars(location.search);

    useEffect(()=>{
        if(isValid(origin)){
            form.setFieldsValue({origin:origin})
        }
        if(isValid(destination)){
            form.setFieldsValue({destination:destination})
        }
        if(isValid(date)){
            form.setFieldsValue({date:moment(date)})
        }
    },[]);

    let seats = useSelector(selectedScheduleSeatsSelector);

    const setSeats=(data)=>{
        dispatch(setSelectedScheduleSeats(data));
    }

    const {
        isMobile
    }=useContext(DeviceContext);

    useEffect(()=>{
        function call() {
            resetSchedules();
            dispatch(getOriginCities());
          }
          call();
    },[]);

    const onOriginChange=(value)=>{
        setOrigin(value);
        if((destinationinput !== 'all' && value === 'all')||(value !== 'all' && destinationinput === 'all')){
            form.setFieldsValue({destination:null})
        }
        dispatch(getDestinationCities(value));
    }

    const onDestionationChange=(value)=>{
        setDestination(value);
    }

    const onFinish=(values)=>{
        let origin=values.origin;
        let destination=values.destination;
        let selectedDate=getAPIDateFormat(values.date);
        setInputDate(selectedDate);
        resetSchedules();
        dispatch(fetchSchedules(origin,destination,selectedDate,false,false , (fetchedSchedules)=>{
            setSchedules(fetchedSchedules);
            setShowResults(true);
        }));
    }

    const resetSchedules=()=>{
        setSchedules(undefined);
        // setShowResults(false);
        setSeats(undefined);
        setOpenTripRouteId(undefined);
    }

    const refetchDetails=()=>{
        let values = form.getFieldsValue();
        onFinish(values,false);
    }

    return (
        <div className="home-page-root">
            <div  className="home-page-form">
                    <OverDueText />
                    <Row justify="center" align="middle">
                            <Form
                            form={form}
                            onFinish={onFinish}
                            initialValues={{origin:'all',destination:'all',date:moment().utcOffset("+05:30")}}
                            >
                            <Row gutter={[16,0]} justify="center" align="middle">
                                <Col xs={isMobile?24:null} sm={isMobile?24:null}>
                                    <Form.Item
                                                name="origin"
                                                rules={[{ required: true, message: 'Please input your origin!' }]}
                                                style={isMobile?{ marginBottom: "0px" }:{}}
                                        >
                                            <Select
                                                    size="large"
                                                    showSearch
                                                    placeholder="Select a origin"
                                                    optionFilterProp="children"
                                                    onChange={onOriginChange}
                                                    filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    bordered={true}
                                                    className="defalut-select-style"
                                                >
                                                {renderOrigins(origins)}
                                                </Select>
                                        </Form.Item>
                                </Col>
                                <Col xs={isMobile?24:null} sm={isMobile?24:null}>
                                <Row justify="center">
                                    <Form.Item
                                    style={isMobile?{ margin: "0px" }:{}}
                                    >
                                        <Button
                                        className="transparentButton"
                                        type="text"
                                        icon={<SwapOutlined className={isMobile?"transform90Degrees":""}
                                        onClick={()=>{
                                           let {
                                               origin,
                                               destination
                                           } =form.getFieldsValue();
                                           form.setFieldsValue({
                                               origin:destination,
                                               destination:origin
                                           })
                                        }}
                                        />}
                                        >
                                        </Button>
                                    </Form.Item>
                                </Row> 
                                </Col>
                                <Col xs={isMobile?24:null} sm={isMobile?24:null}>
                                        <Form.Item
                                                name="destination"
                                                rules={[{ required: true, message: 'Please input your destination!' }]}
                                        >
                                                <Select
                                                    size="large"
                                                    showSearch
                                                    placeholder="Select a destination"
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                    }
                                                    onChange={onDestionationChange}
                                                    bordered={true}
                                                    className="defalut-select-style"
                                                >
                                                {renderDestinations(origins,destinations,origininput)}
                                                </Select>
                                        </Form.Item>
                                </Col>
                                <Col xs={isMobile?24:null} sm={isMobile?24:null}>
                                    <Row gutter={16}>
                                        <Col>
                                            <Form.Item
                                                        name="date"
                                                        rules={[{ required: true, message: 'Please input your date!' }]}
                                            >
                                                        <DatePicker className="defalut-picker-style"/>
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item>
                                                    <Button type="primary" htmlType="submit" ref={searchRef}>Search</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                    <div>
                        <SearchResultsComponent 
                            schedules={schedules}
                            showResults={showResults}
                            setShowResults={setShowResults}
                            seats={seats}
                            setSeats={setSeats}
                            openTripRouteId={openTripRouteId}
                            setOpenTripRouteId={setOpenTripRouteId}
                            onFareUpdateSuccess={refetchDetails}
                            onDriverUpdateSuccess={(scheduleId,vechicleId,phoneNo)=>{
                                    let formatedSchedules = schedules;
                                    for (let index = 0; index < formatedSchedules.length; index++) {
                                        const element = formatedSchedules[index];
                                        if(element.schedule_id === scheduleId){
                                            formatedSchedules[index].vechicle_id = vechicleId;
                                            formatedSchedules[index].phone_no = phoneNo;
                                        }
                                    }
                                    setSchedules([...formatedSchedules]);
                            }}
                            onChangeDateStrip={(date)=>{
                                form.setFieldsValue({
                                    date:moment.parseZone(date)
                                });
                                let values = form.getFieldsValue();
                                onFinish(values);
                            }}
                            selectedDate={inputDate}
                            refetchDetails={refetchDetails}
                            setSchedules={setSchedules}
                        />
                    </div>
            </div>
            <OpenBookedTicketModal />
            <SessionTimeOut />
        </div>
    );
}