import React, {  } from "react";
import { Select,Form } from 'antd';
import { isValid, isValidArray } from "../../../utils/utilities";
// import { isValid,isValidArray } from "../../../utils/utilities";

const { Option } = Select;

const renderAgents=(agents , showHeadOffice , showDriver)=>{
    let agentsArray=[];
    if(isValidArray(agents)&&agents.length>0){
        agents.forEach(element => {
            if(isValidArray(element)&&isValid(element.agent_name)||isValid(element.name)){
                if(element.privilege == 'Head Office'&&!showHeadOffice){

                }else if(element.privilege == 'Driver'&&!showDriver){

                }else{
                    agentsArray.push(<Option value={element.id} agent={element}>{element.name}</Option>)
                }
            }else{
                agentsArray.push(<Option value={element} agent={element}>{element}</Option>)

            }
        });
    }
    return agentsArray;
}

export const AgentsSelect = ({
    agents=['RedBus','Head Office'],
    showLabel=false,
    isManditory=true,
    showHeadOffice=true,
    showDriver=true,
    selectedAgentId=null
}) => {

    return (
        <Form.Item
        name="agentName"
        label={showLabel?"Agent Name":''}
        rules={[{ required: isManditory, message: 'Please input your agent!' }]}
        >
             <Select
                size="large"
                showSearch
                placeholder="Select a agent"
                optionFilterProp="childrens"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                bordered={true}
                className="defalut-select-style"
                defaultValue={selectedAgentId}
            >
                {renderAgents(agents,showHeadOffice,showDriver)}
            </Select>
        </Form.Item>
    )
}