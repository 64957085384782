import { isValid } from "../../utils/utilities";
import { BLOCK_AVAILABLE_SEATS_URL } from './../../utils/api-url';
import { postCall } from "./../../utils/network.util";


 export const handleBlockAllSeats=(tripID,tripRouteId, onSuccess=()=>{},onFailure=()=>{})=>{
      var blockedSeats = postCall(BLOCK_AVAILABLE_SEATS_URL, {
        tripID: tripID,
        tripRouteId: tripRouteId,
        updatedBy: global.userName 
      });
      blockedSeats.then((data) => {
        if(data){
          onSuccess();
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }