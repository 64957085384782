import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import { config } from './config'

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato',
    fontSize: config.normalFontSize + 3,
    fontWeight: 'bold',
  }
})

const Title = ({ children , style}) => <Text style={[style,styles.title]}>{children}</Text>

export default Title
