import React from "react";
import { Button, message } from "antd";
import { showConfirm } from './../custom-components/custom-component';
import { handleReleaseSeats } from './release-seats.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_RELEASE_TICKET_ALLOWED } from './../../utils/constant';

export const ReleaseSeatsComponent = (props) => {

    const{
        selectedSchedule,
        selectedSeats,
        onSuccess
    }=props;

    // const onSuccess=()=>{
    //     fetchSeats(selectedSchedule.trip_route_id);
    // }

    const onFailure=()=>{
        message.error("Failed to block trips!")
    }

    const handleClick=()=>{
        showConfirm(()=>{
            if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
                let tripId=selectedSchedule.trip.id;
                let tripRouteId = selectedSchedule.trip_route_id;
                handleReleaseSeats(tripId,tripRouteId,selectedSeats,onSuccess,onFailure);
            }else{
                message.error("Trip not selected!")
            }
        },"Release seats","Are you sure to release seats");
    }
    
    if(checkActionAllowed(ACTION_RELEASE_TICKET_ALLOWED)){
        return (
            <div>
                <Button onClick={handleClick}>
                    Release
                </Button>
            </div>
        )
    }else{
        return null
    }
}