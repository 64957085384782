import React, { useState } from "react";
import { isValidArrayWithValues, isValid, getValuesOfJSonObject, sortJsonObject } from "../../utils/utilities";
import './dashboard.style.scss';
import { useDispatch } from "react-redux";
import { fetchTodaysTicketCount } from "../../actions/revenue.actions";
import { Area } from '@ant-design/plots';
import { getTimeFormat, getCurrentDateInIST, getMinsBetweenTwoTimes, get12HoursTime } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";
import moment from 'moment';


export const BookedTodayComponent = (props) => {
    const {
        
    } = props;

    const dispatch = useDispatch();

    const [ticketCountData, setTicketCountData] = useState([]);
    const [ticketCount, setTicketCount] = useState(0);


    const setInitialGraphObj = () => {
        let graphObject = {};
        for (let i=0; i<24;i++) {
            let graphTime, mins="00";
            graphTime=`${i.toString().padStart(2, '0')}:${mins}:00`;
            graphObject[graphTime] = {
                'booked_seat_count': 0,
                'booking_time': get12HoursTime(graphTime),
                'time': moment(graphTime, 'HH:mm')
            }
            if (i==23) {
                mins="59";
            }
            else {
                mins="30";
            }
            graphTime=`${i.toString().padStart(2, '0')}:${mins}:00`;
            graphObject[graphTime] = {
                'booked_seat_count': 0,
                'booking_time': get12HoursTime(graphTime),
                'time': moment(graphTime, 'HH:mm')
            }
        }
        return graphObject;
    }

    const formatRangeCount = (element, graphObj) => {
        let istTime = getCurrentDateInIST(element.booking_time);
        let time = getTimeFormat(istTime);
        let timeDiff = getMinsBetweenTwoTimes("00:00:00", time);
        let hrs = Math.floor(timeDiff/60), mins = timeDiff%60;
        let formattedTime;
        if (mins<=30) {
            formattedTime = `${hrs}:30`;
        }
        else {
            if (hrs==23) {
                formattedTime = `${hrs}:59`;
            }
            else {
                formattedTime = `${hrs+1}:00`;
            }
        }
        if (isValid(graphObj[formattedTime])) {
            graphObj[formattedTime]['booked_seat_count']+=element.booked_seat_count;
        }
        else {
            graphObj[formattedTime] = {
                'booked_seat_count': element.booked_seat_count,
                'booking_time': get12HoursTime(formattedTime)
            }
        }
        // graphObj[time] = {
        //     'booked_seat_count': element.booked_seat_count,
        //     'booking_time': get12HoursTime(time),
        //     'time': moment(time, 'HH:mm')
        // }
    }

    const setNoDataGraph = () => {
        return ([{
            'booked_seat_count': 0,
            'booking_time': get12HoursTime("00:00:00")
        }, {
            'booked_seat_count': 0,
            'booking_time': get12HoursTime("23:59:00")
        }])
    }

    const onSuccess=(data) => {
        let count = 0;
        // let graphObj = setInitialGraphObj();
        let graphObj = {};
        data.map((ele) => {
            formatRangeCount(ele, graphObj)
            count+=ele.booked_seat_count;
        })
        setTicketCount(count);
        let graphList = count>0?getValuesOfJSonObject(graphObj):setNoDataGraph();
        // let sortByTime= sortJsonObject(graphList,'time',true);
        // setTicketCountData(getValuesOfJSonObject(sortByTime));
        setTicketCountData(graphList)
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const config = {
        data: ticketCountData,
        xField: 'booking_time',
        yField: 'booked_seat_count',
        xAxis: {
            range: [0, 1],
            tickCount: 5,
          },
          areaStyle: () => {
            return {
              fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
            };
          },
    };

    return(
        <div className = "tickets-booked-today">
            <div className = "click-card"
                onClick={()=> {
                    setLoading(true);
                    dispatch(fetchTodaysTicketCount(onSuccess,onFailure));
                }}
            >
                Tickets Booked Today
            </div>
            {isValidArrayWithValues(ticketCountData)&&<div>
                {ticketCount} Tickets
                <Area {...config} />
                </div>}
       </div>
   )
}


