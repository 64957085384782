/**
 * @author Manne Hareesh
 * @description Reducer for the components in Category page
 */
 import {
    SET_RUNNING_CACHE,
    SET_LOGIN_USER_DETAILS,
    LOG_OUT_ACTION
 } from '../actions/action-types';
import {
    isValidArray,
    setLocalStorage,
    setAgentPortalColors,
    isValid
} from './../utils/utilities';

import { 
    AGENT_COLORS_ACCESS_TOKEN_KEY
} from './../utils/constant';

//  let userDetails-SampleInfo={
//     ac_commission: 0
//     ac_commission_percentage: 0
//     access_code: "12f06c23272300e3682a3a7ce472"
//     agent_portal_access: 1
//     allowedActions: {SEARCH_PAGE_ALLOWED: "allowed", REPORTS_PAGE_ALLOWED: "allowed", VIEW_CANCEL_PAGE_ALLOWED: "allowed", NOTIFICATION_PAGE_ALLOWED: "allowed", BUS_CANCELLATION_PAGE_ALLOWED: "allowed", …}
//     commission: 0
//     commission_percentage: 0
//     discount_percentage: 10
//     encrypted_password: "00e3682a3a7ce472"
//     hitech_commission: 0
//     hitech_commission_percentage: 0
//     id: 2
//     is_active: 1
//     is_online: 0
//     location: "Tichy"
//     name: "Head Office"
//     operator_code: "SRMS"
//     operator_name: "Sri Renganathan Travels"
//     phone_number: "9876543210"
//     privilege: "Head Office"
//     travels_id: 1
//     user_name: "agent1"
//     website_commission: 0
//     website_commission_percentage:
//  }
  
const setGlobalVariables=(userDetails)=>{
    let tripSheetBold = '';
    if(isValid(userDetails.tripSheetConfig)){
        if(userDetails.tripSheetConfig['show_table_content_as_bold'] == 1){
            tripSheetBold = 'tripSheetBold';
        }
    }
    global.operatorCode=userDetails.operator_code;
    global.travelsId=userDetails.travels_id;
    global.operatorName=userDetails.operator_name;
    global.agentId=userDetails.id;
    global.userName=userDetails.user_name;
    global.allowedActions=userDetails.allowedActions;
    global.privilege=userDetails.privilege;
    global.webSiteUrl=userDetails.website_url;
    global.name=userDetails.name;
    global.logoUrl=userDetails.logo_url;
    global.accessCode = userDetails.access_code;
    global.useRemarks = userDetails.use_remarks ;
    global.phoneNumber = userDetails.phone_number;
    global.googleMapsApi = userDetails.GOOGLE_MAPS_API;
    global.tripSheetConfig = userDetails.tripSheetConfig;
    global.overdueAmount = userDetails.overdue_amount;
    global.tripSheetBold = tripSheetBold;
    global.dynamicPricingEnabled = userDetails.dynamic_pricing_enabled;
    global.dynamicPricingProvider = userDetails.dynamic_pricing_provider;
    global.travelsPhoneNumber = userDetails.travelsPhoneNumber;
    global.adminClientUrl = userDetails.admin_client_url;
    global.cancellation_penality_percentage = userDetails.cancellation_penality_percentage;
    global.displayName = userDetails.display_name;
    global.send_payment_link = userDetails.send_payment_link;
    let agentPortalColors = userDetails.agentPortalColors;

    if(isValidArray(agentPortalColors)){
        setLocalStorage(AGENT_COLORS_ACCESS_TOKEN_KEY,JSON.stringify(agentPortalColors));
        setAgentPortalColors(agentPortalColors);
    }
}

  const initialState = {
      user: {
          userName:null,
          phoneNumber:null,
          operatorCode:null,
          operatorName:null,
          travelsId:null,
          privilege:null,
          name:null,
          location:null,
          accessCode:null,
          webSiteUrl:null,
          googleMapsApi: null,
          tripSheetConfig :null,
          displayName: null
      },
      runSplashScreen:true,
      isLoggedIn:false
  }
  
  export const userReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  

         case SET_LOGIN_USER_DETAILS:
             let userDetails=action.payload;
             let formatedUser={
                userName:userDetails.user_name,
                phoneNumber:userDetails.phone_number,
                operatorCode:userDetails.operator_code,
                operatorName:userDetails.operator_name,
                travelsId:userDetails.travels_id,
                privilege:userDetails.privilege,
                name:userDetails.name,
                location:userDetails.location,
                accessCode:userDetails.access_code,
                webSiteUrl:userDetails.website_url,
                adminClientUrl:userDetails.admin_client_url,
                logoUrl:userDetails.logo_url,
                useRemarks:userDetails.use_remarks,
                googleMapsApi: userDetails.GOOGLE_MAPS_API,
                tripSheetConfig: userDetails.tripSheetConfig,
                travelsPhoneNumber:userDetails.travelsPhoneNumber,
                cancellation_penality_percentage:userDetails.cancellation_penality_percentage,
                displayName: userDetails.display_name
             }
             
            newState.user=formatedUser;
            newState.partnerDetails = userDetails.partnerDetails;
            newState.isLoggedIn=true;
            setGlobalVariables(userDetails);
            return newState;

         case SET_RUNNING_CACHE:
             newState.runSplashScreen=action.payload;
             return newState;


        case LOG_OUT_ACTION :
            return initialState;
  
          default:
              return state;
      }
  }
  