/**
 * @author Rejo Varghese
 * @description Prepaid OTA agent dashboard
 */
import React, { useEffect, useState } from "react";
import { Row, Col, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import '../styles/prepaid-ota.style.scss';
import { getPrepaidOTAAccountData } from '../actions/agents.actions';
import { userInfoSelector } from "../selector/user.selector";
import { roundNumber } from "../utils/math.utils";
import { loadingAction } from "../actions/loading-actions";
import { loadingReducerSelector } from "../selector/loading-selectors";


const DrawLine =()=>{

    return(
        <div className="agents-underline">

        </div>
    )
}

const Title = (props) =>{
    const {userName} = props
        return (
            <Row justify="center" align="middle">
                <Col>
                    <div className="title-agent">
                        <h3>
                            {userName}
                        </h3>
                        <DrawLine />
                    </div>
                </Col>
            </Row>
        )
}


export const PrepaidOTAPage = (props) => {

    const { userName }=useSelector(userInfoSelector);
    const agentId = global.agentId;

    const [agentData, setAgentData] = useState({});

    const dispatch = useDispatch();

    useEffect(()=>{
        setLoading(true);
        getPrepaidOTAAccountData(agentId, (data)=>{
            setLoading(false);
            setAgentData(data)
        }, (errMsg)=> {
            message.error(errMsg);
            setLoading(false);
        });
    },[agentId]);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    const {
        loadingActionRunning
    } = useSelector(loadingReducerSelector);

    return (
        <div className="prepaid-page-root">
            <div className="prepaid-page-inner-div">
                <Title userName={userName}/>
                {!loadingActionRunning&&
                    <div>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span = {8}>
                                <span>
                                    Today`s Starting Balance :
                                </span>
                            </Col>
                            <Col span = {8}>{roundNumber(agentData.dayStartBalance)}</Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span={8}>
                                <span>
                                    Seats Booked Today:
                                </span>
                            </Col>
                            <Col span={8}>{roundNumber(agentData.confirmedCount)}</Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span={8}>
                                <span>
                                    Seats Cancelled Today:
                                </span>
                            </Col>
                            <Col span={8}>{roundNumber(agentData.cancelledCount)}</Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span={8}>
                                <span>
                                    Net Amount Deducted Today :
                                </span>
                            </Col>
                            <Col span={8}>{roundNumber(agentData.amountDeducted)}</Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span={8}>
                                <span>
                                    Amount Topped Up Today:
                                </span>
                            </Col>
                            <Col span={8}>{roundNumber(agentData.topUpForToday)}</Col>
                        </Row>
                        <Row justify="center" align="middle">
                            <Col offset = {4} span={8}>
                                <span style= {{fontWeight: 'bold'}}>
                                    Balance remaining in wallet :
                                </span>
                            </Col>
                            <Col span={8}>{roundNumber(agentData.balanceAmount)}</Col>
                        </Row>
                    </div>
                }
            </div>
        </div>
    )
}
