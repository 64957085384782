
import {INFORM_OTA_FOR_TRIP_CHANGES_URL } from './../../utils/api-url';
import { message } from "antd";

export const informTripChanges = (scheduleId,onSuccess) =>{
    fetch(INFORM_OTA_FOR_TRIP_CHANGES_URL,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            scheduleId : scheduleId
        })
        })
        .then((Responce)=>Responce.json())
        .then((findresponce)=>
        {
            if(findresponce.Status=="SUCCESS"){
                message.success(findresponce.Message);
                onSuccess();
            }else{
                    message.error(findresponce.Message);
                }
        })
}