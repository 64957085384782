import React, {  } from "react";
import { Button } from 'antd';
import { activateInOTAs } from "./infrom-ota.module";

export const InformOtas = (props) => {
    const{
        tripId
    }=props;
    return (
        <div>
            <Button 
            className="bordered-btn send-driver-details-btn"  
            onClick={()=>{
                activateInOTAs(tripId);
            }}
            >
                Activate in OTAs
            </Button>
        </div>
    )
}