import { SET_SEAT_GENDER_URL } from './../../utils/api-url';
import { postCall } from "./../../utils/network.util";


 export const handleSetSeatGender=(tripId,seatNumbers,gender,onSuccess=()=>{},onFailure=()=>{})=>{
      var updated = postCall(SET_SEAT_GENDER_URL, {
        tripId,
        seatNumbers, 
        gender,
        updatedBy: global.userName
      });
      updated.then((data) => {
        if(data.status ==  "SUCCESS" && data.payload.Status == "SUCCESS"){
          onSuccess(data.payload.Message);
        }else{
          onFailure(data.payload.Message);
        }
      })
      .catch((reason)=> {
        onFailure("Failed to update gender");
      })
  }