import React , {useState} from 'react';
import { Button , Modal} from 'antd';
import { validateTripAction } from './validate-trip-button.module';
import { useDispatch } from 'react-redux';
import { isValidArray } from '../../utils/utilities';
import { loadingAction } from '../../actions/loading-actions';

export const ValidateTripButton = props => {

  const {
    scheduleId
  }={...props};

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [warnings, setWarnings] = useState([]);
  const dispatch = useDispatch();

  const setLoading=(loading)=>{
    dispatch(loadingAction(loading));
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };


  const onSuccess=(responceData)=>{
      let warningsList=[];
    if(isValidArray(responceData)&&responceData.length>0){
        let i=1;
        responceData.forEach(text => {
            warningsList.push(
                <div>{i}. {text}</div>
            );
            i=i+1;
        });
    }
    setWarnings(warningsList);
    showModal();
    setLoading(false);
  }

  const onFailure=()=>{
    setLoading(false);
    handleCancel();
  }

  return (
      <div>
           <Button
                style={{background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
                onClick={()=>{
                    setLoading(true);
                    validateTripAction(scheduleId,onSuccess,onFailure);
                }}
            >
               Validate trip
           </Button>
           <Modal title="Trip warnings" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}
           width={600}
           >
                    {warnings.length>0?
                    <div>
                        {warnings}
                    </div>
                    :
                    <div>
                        Trip doesn't have any warnings
                    </div>
                    }
           </Modal>
      </div>
  );
};
