import React from 'react'
import { StyleSheet , Image } from '@react-pdf/renderer'

const styles = StyleSheet.create({
  
})

const ImageRender = ({src,style}) => (
    <Image src={src} style={{ width: "100px" }}  />
)

export default ImageRender
