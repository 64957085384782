import {  Row ,Col,Typography } from "antd";
import React from "react";
import { checkActionAllowed, isValid } from "../../utils/utilities";
import { ACTION_TO_VIEW_OVER_DUE_MSG } from "../../utils/constant";
import './over-due-text.style.scss';

const {
    Text
} = Typography
export const OverDueText = (props) => {

    const {
        
    } = props;

    if(checkActionAllowed(ACTION_TO_VIEW_OVER_DUE_MSG)&&isValid(global.overdueAmount)&&global.overdueAmount>0){
        return (
                <Row justify="left" align="left" gutter={[0,0]} className="over-due-block">
                    <Col>
                        <Text type="danger" strong className="text-block">Balance amount of {`₹${global.overdueAmount}`} is overdue. To avoid service disconnection, please pay now.</Text>
                    </Col>
                </Row>
        )
    }
    return null;
}