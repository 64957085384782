import React, { useEffect, useState } from "react";
import {message, Table, Button, Row, Col, Typography , Form , Radio , DatePicker , Checkbox} from 'antd';
import { getActiveBookings , fetchBlockedSheet,fetchBookingSheet} from "../../actions/reports.action";
import { loadingAction } from "../../actions/loading-actions";
import { useDispatch } from "react-redux";
import { isValidNumber , isValid , isValidArray} from "../../utils/utilities";
import { getRangeOptions } from './reports.module';
import { getAPIDateFormat , getLocalDateAndTime } from './../../utils/date.utils';
import { AgentsSelect } from "../../components/search-components/agents-select/agents-select.component";

const { Title } = Typography;
const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;

let xs=18, sm=18, md=12 , lg=12 , xl=10 , xxl=10;

let intializeState = {
  agentNames:[],
  totalTickets:[],
  filteredTickets:[]
};

const ticketColumns =[
    {
        title:'SN',
        key:'index',
        render : (text, record, index) => index+1
    },
    {
        title:'PNR',
        key:'pnr',
        dataIndex : 'pnr'
    },
    {
        title:'Seat #',
        key:'seat_number',
        dataIndex : 'seat_number'
    },
    {
        title:'Agent',
        key:'name',
        dataIndex : 'name'
    },
    {
        title:'Email',
        key:'email_id',
        dataIndex : 'email_id'
    },
    {
        title:'Booking time',
        key:'booking_time',
        dataIndex : 'booking_time',
        render : (text, record, index) => {
            if(isValid(record.booking_time)){
                return getLocalDateAndTime(record.booking_time)
            }else{
                return ''
            }
        }
    },
    {
        title:'Phone No',
        key:'phone_number',
        dataIndex : 'phone_number'
    },
    {
        title:'Trip description',
        key:'description',
        dataIndex : 'description'
    },
    {
        title:'DOJ',
        key:'start_date',
        dataIndex : 'start_date',
        render : (text, record, index) => {
            if(isValid(record.start_date)){
                return record.start_date.split('T')[0]
            }else{
                return ''
            }
        }
    },
    {
      title:'Amount',
      key:'amount_paid',
      dataIndex:'amount_paid'
    }
];

const getFilterDetails=({
  isSelectAllAgents,
  agentName
},
data)=>{
      let formatedData=[];
      if(isValidArray(data)&&data.length>0){
          data.forEach(element => {
              if(agentName===element.name||isSelectAllAgents){
                  formatedData.push(element);
              }
          });
      }
      return formatedData;
}



export const ActiveBookingsReport = (props) => {

  const [filtersForm] = Form.useForm();
  const [form] = Form.useForm();

  const [confirmBookings, setConfirmBookings] = useState([]);
  const [blockedTickets, setBlockedTikets] = useState([]);
  const [filters , setFilters] = useState(intializeState);

  const dispatch = useDispatch();
  const setLoading=(bool)=>{
     dispatch(loadingAction(bool));
  }
 
  const onSuccess=(data)=>{
    setLoading(false);
    setConfirmBookings(data.confirmedTickets);
    setBlockedTikets(data.blockedTickets);
  }

  const onFailure=(errorMessage)=>{
    setLoading(false);
    message.error(errorMessage);
    setConfirmBookings([]);
    setBlockedTikets([]);
    setFilters(intializeState);
  }
  const onrefresh = () => {
    setLoading(true);
    getActiveBookings(onSuccess,onFailure); 
  }

  useEffect(()=>{
    onrefresh();
  },[])

      const columns = [
        {
          title: 'Source',
          dataIndex: 'source',
          key: 'Source'
        },
        {
          title: 'Total Seats',
          dataIndex: 'totalSeats',
          key: 'Total Seats'
        },
        {
          title: 'DOJ',
          children: [
            {
              title: 'Today',
              dataIndex: 'today',
              key: 'Today'
            },
            {
              title: 'Tomorrow',
              dataIndex: 'tomorrow',
              key: 'Tomorrow'
            },
            {
              title: 'Future',
              dataIndex: 'future',
              key: 'Future'
            },
          ],
        },
        {
          title: 'Total Amount',
          dataIndex: 'totalAmount',
          key: 'Total Amount'
        },
      ];

    const onBlockSuccess=(data)=>{
        filters.agentNames=data.agents;
        filters.totalTickets = data.blockedTransactions;
        filters.filteredTickets = data.blockedTransactions;
        setFilters({...filters});
        setLoading(false);
    }

    const onBlockFailure=()=>{
        setLoading(false);
    }

    const onBookingSuccess=(data)=>{
      filters.agentNames = data.agents;
      filters.totalTickets = data.confirmedTransactions;
      filters.filteredTickets = data.confirmedTransactions;
      setFilters({...filters});
      setLoading(false);
  }

  const onBookingFailure=()=>{
      setLoading(false);
  }

    const onFinish=(values)=>{
        let {
          dateRange,
          isTicketType
      }=values;
      setLoading(true);
      const fromDate = getAPIDateFormat(dateRange[0]);
      const toDate = getAPIDateFormat(dateRange[1]);
      if(isTicketType === 'block'){
        fetchBlockedSheet(fromDate,toDate,true,onBlockSuccess,onBlockFailure);
      }else{
        fetchBookingSheet(fromDate,toDate,true,onBookingSuccess,onBookingFailure);
      }
    }

      return (
          <div className="report-inner-div">
            <Row align="middle">
              <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                  <Row gutter={[16,16]}>
                    <Col>
                      <Title level={5}>Active Online Bookings (Currently Blocked)</Title>
                    </Col>
                  </Row>
                  <Row className="marginBottom">
                      <Col>
                          <Table 
                                className="bordered-table"
                                dataSource={blockedTickets} 
                                columns={columns} 
                                pagination={false}
                                summary={(pageData ) => {
                                      let summaryTotalSeats = 0;
                                      let summaryToday = 0;
                                      let summaryTomorrow = 0;
                                      let summaryFuture=0;
                                      let summaryAmount = 0;
                                      pageData.forEach(({ totalSeats, today, tomorrow, future, totalAmount}) => {
                                        if(isValidNumber(totalSeats)){
                                          summaryTotalSeats = summaryTotalSeats + totalSeats;
                                        }
                                        if(isValidNumber(today)){
                                          summaryToday = summaryToday + today;
                                        }
                                        if(isValidNumber(tomorrow)){
                                          summaryTomorrow = summaryTomorrow + tomorrow;
                                        }
                                        if(isValidNumber(future)){
                                          summaryFuture = summaryFuture + future;
                                        }
                                        if(isValidNumber(totalAmount)){
                                          summaryAmount = summaryAmount + totalAmount;
                                        }
                                      });

                                      return(
                                          <Table.Summary fixed>
                                          <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                            <Table.Summary.Cell index={1}>{summaryTotalSeats}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={2}>{summaryToday}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={3}>{summaryTomorrow}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={4}>{summaryFuture}</Table.Summary.Cell>
                                            <Table.Summary.Cell index={5}>{summaryAmount}</Table.Summary.Cell>
                                          </Table.Summary.Row>
                                        </Table.Summary>
                                      )
                              }}
                          />
                        </Col>
                  </Row>
                  <Row gutter={[16,16]}>
                    <Col>
                      <Title level={5}>Recent Bookings(last 1 hour)</Title>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                        <Table 
                              className="bordered-table"
                              dataSource={confirmBookings} 
                              columns={columns} 
                              pagination={false}
                              summary={(pageData ) => {
                                    let summaryTotalSeats = 0;
                                    let summaryToday = 0;
                                    let summaryTomorrow = 0;
                                    let summaryFuture=0;
                                    let summaryAmount = 0;
                                    pageData.forEach(({ totalSeats, today, tomorrow, future, totalAmount}) => {
                                      if(isValidNumber(totalSeats)){
                                        summaryTotalSeats = summaryTotalSeats + totalSeats;
                                      }
                                      if(isValidNumber(today)){
                                        summaryToday = summaryToday + today;
                                      }
                                      if(isValidNumber(tomorrow)){
                                        summaryTomorrow = summaryTomorrow + tomorrow;
                                      }
                                      if(isValidNumber(future)){
                                        summaryFuture = summaryFuture + future;
                                      }
                                      if(isValidNumber(totalAmount)){
                                        summaryAmount = summaryAmount + totalAmount;
                                      }
                                    });

                                    return(
                                        <Table.Summary fixed>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell index={0}></Table.Summary.Cell>
                                          <Table.Summary.Cell index={1}>{summaryTotalSeats}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={2}>{summaryToday}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={3}>{summaryTomorrow}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={4}>{summaryFuture}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={5}>{summaryAmount}</Table.Summary.Cell>
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    )
                            }}
                        />
                      </Col>
                  </Row>

              </Col>
              <Col offset={1}>
                  <Button type="primary" onClick={onrefresh}>Refresh</Button>
              </Col>
            </Row>
            <Form
            onFinish={onFinish}
            form={form}
            >
              <Row gutter={[16,16]} className='marginTop'>
                <Col>
                  <Form.Item name="isTicketType" label="" rules={[{ required: true , message: 'Please select any option!'}]}>
                      <Radio.Group>
                          <Radio value={'block'}>Curr. Block</Radio>
                          <Radio value={'booked'}>Rec. Book</Radio>
                      </Radio.Group>
                  </Form.Item>
                </Col>
                <Col>
                    <Form.Item name="dateRange" label="DOJ" 
                        rules={[
                        { required: true , message: 'Please input your date range!'},
                        ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                      ]}>
                            <RangePicker 
                                  ranges={getRangeOptions(allowedMaxDaysRange)} 
                            />
                     </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                      <Button htmlType="submit">Search</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
            <Form
                form={filtersForm}
                initialValues={{isSelectAllSchedules:true,isSelectAllAgents:true}}
                onFinish={(values)=>{
                    let {
                      isSelectAllAgents,
                      agentName
                     } = values;
                     let filteredData=getFilterDetails({
                      isSelectAllAgents:isSelectAllAgents,
                      agentName:agentName
                  },filters.totalTickets);
                  filters.filteredTickets = filteredData;
                  setFilters({...filters});
                }}>
                  <Row gutter={[16,16]}>
                      <Col>
                        <AgentsSelect
                            agents={filters.agentNames}
                            isManditory={false}
                            showLabel={true}
                        />
                      </Col>
                      <Col>
                          <Form.Item name="isSelectAllAgents"  valuePropName="checked">
                            <Checkbox>All</Checkbox>
                          </Form.Item>                            
                      </Col>
                      <Col>
                          <Form.Item>
                                <Button htmlType="submit">Filter</Button>
                          </Form.Item>
                      </Col>                            
                  </Row>
              </Form>
              <Row>
                <Col>
                    <Table 
                      className="bordered-table"
                      columns={ticketColumns}
                      dataSource={filters.filteredTickets}
                      pagination={false}
                      summary={(pageData ) => {
                        let summaryAmount = 0;
                        pageData.forEach(({amount_paid}) => {
                          if(isValidNumber(amount_paid)){
                            summaryAmount = summaryAmount + amount_paid;
                          }
                        });

                        return(
                            <Table.Summary fixed>
                            <Table.Summary.Row>
                              <Table.Summary.Cell index={0}></Table.Summary.Cell>
                              <Table.Summary.Cell index={1}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={2}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={3}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={4}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={5}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={6}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={7}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={8}>{}</Table.Summary.Cell>
                              <Table.Summary.Cell index={9}>{summaryAmount}</Table.Summary.Cell>
                            </Table.Summary.Row>
                          </Table.Summary>
                        )
                }}
                    />
                </Col>
              </Row>
          </div>
      )
  }
            

      
