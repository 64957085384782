import React from "react";
import { Row , Col  } from "antd";
import { isValid, isValidArray, isValidNumber } from "./../../../utils/utilities";
import './trip-fare.style.scss';

export const RenderFares=({tripRouteFare,discountTripRouteFare,tripRouteFareJson,discountedTripRouteJson,supportedFare})=>{

    let fareTxt = '';
    let fares=[];
    let discountedFares = [];
    let discountedFareTxt = '';
    if(supportedFare){
        fareTxt = '₹ '+tripRouteFare;
        fares.push(tripRouteFare);
    }
    if(isValidNumber(discountTripRouteFare)&&tripRouteFare>discountTripRouteFare){
        discountedFares.push(discountTripRouteFare);
        discountedFareTxt = discountedFareTxt + '₹ '+discountTripRouteFare;
    }
    if(isValid(tripRouteFareJson)){
        tripRouteFareJson = JSON.parse(tripRouteFareJson);
        for (const key in tripRouteFareJson) {
            if (Object.hasOwnProperty.call(tripRouteFareJson, key)) {
                const currentFare = tripRouteFareJson[key];
                if(fares.indexOf(currentFare) == -1){
                    if(fares.length>0){
                        fareTxt = fareTxt + ' | ' + '₹ '+currentFare;
                    }else{
                        fareTxt = '₹ '+currentFare;
                    }
                    fares.push(currentFare);
                }
                if(isValidArray(discountedTripRouteJson)&&isValid(discountedTripRouteJson[key])){
                    let discountedFare = discountedTripRouteJson[key];
                    if(discountedFares.indexOf(discountedFare)===-1 && currentFare>discountedFare){
                        if(discountedFares.length>0){
                            discountedFareTxt = discountedFareTxt + ' | ' + '₹ '+discountedFare;
                        }else{
                            discountedFareTxt = '₹ '+discountedFare;
                        }
                        discountedFares.push(discountedFare);
                    }
                }
            }
        }
    }
    if(discountedFares.length>0){
        return (
                <Row gutter={16} className="fare-block">
                    <Col>
                        <span className="strike-text">{fareTxt}</span>
                    </Col>
                    <Col>
                        <span className="fontWeightBold">{discountedFareTxt}</span>
                    </Col>
                </Row>
        )
    }else{
        return (<span className="fontWeightBold fare-block">{fareTxt}</span>);
    }
}