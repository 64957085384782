import { getArrayfromString,phoneNumberValidation,isValidArray } from './../../utils/utilities';
import { Typography } from 'antd';

const {
    Text
} = Typography;

export const getAddressComponent=(address)=>{
    let components=[];
    let addressVars=getArrayfromString(address,'##');
    if(isValidArray(addressVars)&&addressVars.length>0){
        addressVars.forEach((row)=>{
            let values=getArrayfromString(row,'@@');
            if(isValidArray(values)&&values.length>0){
                if(values.length>=2){
                    if(phoneNumberValidation(values[1])){
                        components.push(<Text strong> {values[0]} : <a href={`tel:+91${values[1]}`}>{values[1]}</a></Text>)
                    }else{
                        components.push( <Text strong> {values[0]} : {values[1]}</Text>)
                    }
                }else{
                    if(phoneNumberValidation(values[0])){
                        components.push(<Text strong> <a href={`tel:+91${values[0]}`}>{values[0]}</a></Text>)
                    }
                    else{
                        components.push( <Text strong> {values[0]}</Text>)
                    }
                }
            }
        })
    }
    return  (
    <div>
        {components}
    </div>
    )
}