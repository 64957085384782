/**
 * @author Rejo Varghese
 * @description Downloading PDF file of schedules
 */

import React, { useState, useRef } from 'react';
import { Document, Page, StyleSheet, PDFDownloadLink } from '@react-pdf/renderer';
import PrintIcon from './../../../images/print.png';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import Row from '../../../pages/pdfs/common/Row';
import Title from '../../../pages/pdfs/common/Title';
import Subtext from '../../../pages/pdfs/common/Subtext';
import Table from '../../../pages/pdfs/common/Table';
import { isValid, isValidArrayWithValues } from '../../../utils/utilities';
import { getPickupLocationByScheduleId, getDropLocationByScheduleId } from './schedule-trip.module';
import { getViaCities } from '../../via-cities-component/via-cities.module';
import { getTimeStampInReadableFormat } from './../../../utils/date.utils';
import { getViacitiesDetails, getCities } from '../../../actions/admin.action';

const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor:'#FFFFFF'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  colblock:{
    padding: 40,
    justifyContent:'space-between'
  },
  textContent: {
    textAlign: 'left',
    minHeight:18,
    paddingTop:3,
    fontSize:10,
    borderColor: '#A6A6A4',
    paddingLeft:3,
  }
});

const oddColor = '#E7F1FE';
const evenColor = '#FFFFFF';

const renderTripTables = (tripDetails, cityList, routes, pdfColumns, description) => {
  let tripRows = [], counter = 0;
  
  const cityColumns = [
    {
      title:"City",
      key:'city',
      pdfWidth: '35%',
      pdfRender: ({index,text,record}) => text
    }
  ];
  const routeColumns = [
    {
      title:"Origin",
      key:'origin',
      pdfWidth: '35%',
      pdfRender: ({index,text,record}) => text
    },
    {
      title:"Destination",
      key:'destination',
      pdfWidth: '35%',
      pdfRender: ({index,text,record}) => text
    },
    {
      title:"Timing",
      key:'start_time',
      pdfWidth: '20%',
      pdfRender: ({index,text,record}) => text
    },{
      title:"Day",
      key:'origin_day',
      pdfWidth: '10%',
      pdfRender: ({index,text,record}) => text
    }
  ]
  if (cityList.length>0) {
    cityList.map((city,ind) => {
      let tripRows2 = [], cityCells = [];
      cityColumns.map((cityCol) => {
        cityCells.push(
          <Table.Cell style={[{width:cityCol.pdfWidth, fontWeight: 'bold', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
            {cityCol.pdfRender({text:city,})}
          </Table.Cell>
        )
        cityCells.push(
          <Table.Cell style={[{width:'35%', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
          </Table.Cell>
        )
        cityCells.push(
          <Table.Cell style={[{width:'20%', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
          </Table.Cell>
        )
        cityCells.push(
          <Table.Cell style={[{width:'10%', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
          </Table.Cell>
        )

      });
      tripRows2.push(
        <Table.Row key={counter}>
                {cityCells}
              </Table.Row>
      )
      counter++;
      tripDetails[city].map((row, index) => {
        let tripCells = [];
        tripCells.push(
          <Table.Cell style={[{width:'35%', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
        </Table.Cell> 
        )
        pdfColumns.map((column) => {
          tripCells.push(
            <Table.Cell style={[{width:column.pdfWidth, backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
            {column.pdfRender({text:row[column.key],record:row,index})}
          </Table.Cell> 
          )
        })
        tripRows2.push(
          <Table.Row key={counter}>
          {tripCells}
        </Table.Row>
        )
        counter++;
      })
      tripRows = [...tripRows,...tripRows2]
    })

    let tripCells = [];

    for (let i=0;i<4;i++) {
      let widthSize = i<2?'35%':i==2?'20%':'10%';
      tripCells.push(
        <Table.Cell style={[{width:widthSize, fontWeight: 'bold', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
        </Table.Cell> 
      )
    }

    tripRows.push(
      <Table.Row key={counter}>
      {tripCells}
    </Table.Row>
    )
    counter++;

    tripCells = []
    tripCells.push(
      <Table.Cell style={{width:'100%', minHeight:20, paddingTop:2, fontSize:14, fontWeight: 'bold', backgroundColor: counter%2!==0?oddColor:evenColor, borderColor: '#A6A6A4'}}>
          Routes
      </Table.Cell> 
    )

    tripRows.push(
      <Table.Row key={counter}>
      {tripCells}
    </Table.Row>
    )
    counter++;

    const headerRoute = [{id: 1, origin: "Origin", destination: "Destination", start_time: "Start Time", origin_day: 'Day', }]
    routes = [...headerRoute, ...routes];
    

    routes.map((row,index) => {
      let routeCells = [];
      routeColumns.map((column) => {
        routeCells.push(
          <Table.Cell style={[{width:column.pdfWidth, fontWeight: 'bold', backgroundColor: counter%2!==0?oddColor:evenColor}, styles.textContent]}>
            {index==0?column.pdfRender({text:row[column.key],record:row,index}): column.key=='origin_day'?'Day '+(row[column.key]+1):column.pdfRender({text:column.key=='start_time'?getTimeStampInReadableFormat(row[column.key]):row[column.key],record:row,index})}
          </Table.Cell>
        )
      });
      tripRows.push(
        <Table.Row key={counter}>
        {routeCells}
      </Table.Row>
      )
      counter++;
    })
    return (
      <Table style= {{borderColor: '#A6A6A4'}}>
        <Table.Header>
          <Table.HeaderCell style= {{width: '100%', backgroundColor: oddColor, minHeight: 25, textAlign: 'left', fontSize: 12, fontWeight: 'bold', borderColor: '#A6A6A4', paddingLeft: 3, paddingTop: 5}}>{description}</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {tripRows}
        </Table.Body>
      </Table>
    )
  }
}

// Create Document Component
const PdfDocument = (props) => {
  const {
    routes,
    pickup,
    drops,
    cities,
    description
  } = props;

  let tripDetails = {}, cityList = [];


  const pdfColumns = [
    {
      title:"Location",
      key:'location',
      pdfWidth: '35%',
      pdfRender: ({index,text,record}) => text
    },
    {
      title:"Timing",
      key:'timing',
      pdfWidth: '20%',
      pdfRender: ({index,text,record}) => text
    },
    {
      title:"Day",
      key:'destination_day',
      pdfWidth: '10%',
      pdfRender: ({index,text,record}) => text
    },
  ]

  if (isValidArrayWithValues(routes)&&isValidArrayWithValues(pickup)&&isValidArrayWithValues(drops)) {
    cities.map((city) => {
      cityList.push(city.city);
    })
  
    pickup.map((location) => {
      let destinationDay = location.route_origin_day>location.origin_day?location.route_origin_day:location.origin_day;
      destinationDay = destinationDay+1;
      destinationDay = "Day "+destinationDay;
      if (isValid(tripDetails[location.city])) {
        tripDetails[location.city].push({'location':location.location, 'destination_day': destinationDay, 'timing': getTimeStampInReadableFormat(location.timing)})
      }
      else {
        tripDetails[location.city] = [];
        tripDetails[location.city].push({'location':location.location, 'destination_day': destinationDay, 'timing': getTimeStampInReadableFormat(location.timing)})
      }
    })
  
    drops.map((location) => {
      let destinationDay = location.route_destination_day>location.destination_day?location.route_destination_day:location.destination_day;
      destinationDay = destinationDay+1;
      destinationDay = "Day "+destinationDay;
      if (isValid(tripDetails[location.city])) {
        let index = tripDetails[location.city].findIndex(x => x.location === location.location);
        if (index>=0) {
          if (tripDetails[location.city][index].destination_day !== destinationDay) {
            tripDetails[location.city].push({'location':location.location, 'destination_day': destinationDay, 'timing': getTimeStampInReadableFormat(location.timing)})
          }
        }
        else {
          tripDetails[location.city].push({'location':location.location, 'destination_day': destinationDay, 'timing': getTimeStampInReadableFormat(location.timing)});
        }
      }
      else {
        tripDetails[location.city] = [];
        tripDetails[location.city].push({'location':location.location, 'destination_day': destinationDay, 'timing': getTimeStampInReadableFormat(location.timing)});
      }
    })
  }

  return (<Document>
    <Page size="A4" style={styles.page}>
      <Row>
        <Subtext>
          {global.operatorName}
        </Subtext>
      </Row>
      <Row style = {{paddingTop: 10}}>
        <Title>Trip Details</Title>
      </Row>
      <Row style={styles.colblock}>
        {renderTripTables(tripDetails, cityList, routes, pdfColumns, description)}
      </Row>
    </Page>
  </Document>)
};

const ScheduleTripPdf = (props) => {
  const {
    scheduleId,
    description
  } = props;

  const buttonRef = useRef();
  const counter = useRef(0);

  const [routes,setRoutes] = useState(null);
  const [pickup,setPickup] = useState(null);
  const [drops,setDrops] = useState(null);
  const [selectedScheduleId, setSelectedScheduleId] = useState(null);
  const [loadData, setLoadData] = useState(false);
  const [cities, setCities] = useState(null);

  function resolveAfter2Seconds() {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('resolved');
        buttonRef.current.click();
      }, 500);
    });
  }
  
  async function asyncCall() {
    await resolveAfter2Seconds();
  }
  
  
  const downloadPdfs = () => {
    if (counter.current==4) {
      setLoadData(true);
      asyncCall();
    }
    else {
      setLoadData(false);
    }
  }

  const fetchReports = () => {
    if (scheduleId !== selectedScheduleId) {
      setSelectedScheduleId(scheduleId);
      getPickupLocationByScheduleId(scheduleId,(data)=> {setPickup(data); counter.current++; downloadPdfs();});
      getDropLocationByScheduleId(scheduleId,(data)=> {setDrops(data); counter.current++; downloadPdfs();});
      getViacitiesDetails(scheduleId,null,(data)=>{
        setCities(getCities(data));
        counter.current++; downloadPdfs();
        },
        ()=>{
        },0);
      getViaCities(scheduleId,(data)=> {setRoutes(data); counter.current++; downloadPdfs();});
    }
    else {
      downloadPdfs();
    }
  }
  
    return (
      <>
        {loadData?<PDFDownloadLink
            document = {<PdfDocument 
              routes = {routes}
              pickup = {pickup}
              drops = {drops}
              cities= {cities}
              description = {description}
            />}
            fileName = {'Trip-details '+description+'.pdf'}
        >
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
            <Button ghost ref= {buttonRef}>
              <Icon component={() => (<img src={PrintIcon} style={{width:'22px',padding:'1px'}} />)} />
            </Button>
        </PDFDownloadLink>:
        <Button ghost onClick = {()=> fetchReports()}>
          <Icon component={() => (<img src={PrintIcon} style={{width:'22px',padding:'1px'}} />)} />
        </Button>
        }
      </>
    )
}

export default ScheduleTripPdf