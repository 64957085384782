import {LOGIN_ACTION , SET_RUNNING_CACHE , LOG_OUT_ACTION , SET_LOGIN_USER_DETAILS,VERIFY_OTP} from './action-types';
import { USER_LOGIN_URL , VERIFY_OTP_URL} from '../utils/api-url';
import { postCall } from "../utils/network.util";

export const userLogin = (userName,password,accessCode,onSuccess=()=>{

}) => {
    return {
        type: LOGIN_ACTION,
        payload: {
            url:USER_LOGIN_URL,
            api:postCall,
            apiPayload:{
                loginInfo:{
                    userName,
                    password,
                    access_code:accessCode
                }
            },
            onSuccess:onSuccess
        }
    }
  }
   
export const logoutAction=()=>{
    return {
        type:LOG_OUT_ACTION
    }
}

export const setRunSplashScreen =(bool)=>{
    return {
        type:SET_RUNNING_CACHE,
        payload:bool
    }
}

export const setLoginUserDetails = (payload)=>{
    return {
        type:SET_LOGIN_USER_DETAILS,
        payload:payload
    }
}

export const verifyOtp = (otp,phno,onSuccess)=>{
    return {
        type:VERIFY_OTP,
        payload:{
            url:VERIFY_OTP_URL,
            api:postCall,
            apiPayload:{
                otp:otp,
                key:phno
            },
            onSuccess:onSuccess
        }
    }
}