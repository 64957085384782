import React from 'react';
import { Document, Page,  StyleSheet,PDFDownloadLink } from '@react-pdf/renderer';
import { convertDateStringToDate, convertTimeStringToDate, getLocalDate, getLocalDateAndTime, getLocalTime } from '../../utils/date.utils';
import Title from './common/Title';
import Row from './common/Row';
import Subtext from './common/Subtext';
import Table from './common/Table';
import { isValid, isValidArray } from '../../utils/utilities';
import SubTextBold from './common/SubTextBold';
import { View } from '@react-pdf/renderer';
import { CancelledTripSheetPdfComponent } from '../../components/cancelled-trip-sheet-report/cancelled-trip-sheet-report.pdf';
import { Button } from 'antd';

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor:'#FFFFFF'
  },
  boardingBlock:{
    paddingTop:10
  },
  colblock:{
    justifyContent:'space-between'
  },
  summaryTxt:{
    textAlign:'center'
  },
  summaryTxtBlock:{
    justifyContent:'center'
  },
  availableTxt:{
    textAlign:'center'
  },
  availableTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  adminBlockedSeatsTxtBlock:{
    justifyContent:'center'
  },
  adminBlockedSeatsTxt:{
    textAlign:'center'
  },
  cancellationDetailsBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  cancellationDetailsTxt:{
    textAlign:'center'
  },
  agentInfoTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
  agentInfoTxt:{
    textAlign:'center'
  },
  dropPointSummaryTxt:{
    textAlign:'center'
  },
  dropPointSummaryTxtBlock:{
    justifyContent:'center',
    paddingTop:10
  },
});


const renderTables=(agentRecords,agentRecordColumns)=>{
  let agentRows=[];
  agentRecords.forEach((row,index) => {
    let agentCells=[];
    agentRecordColumns.forEach((column)=>{
      agentCells.push(
        <Table.Cell style={{width:column.pdfWidth}}>
          {column.pdfRender({text:row[column.dataIndex],record:row,index})}
        </Table.Cell> 
      );
    });
    agentRows.push(
      <Table.Row key={row.id}>
        {agentCells}
      </Table.Row>
    )
  });
  return(
    <Table>
      <Table.Header>
          {agentRecordColumns.map(column => (
            <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
          ))}
      </Table.Header>
      <Table.Body>
            {agentRows}
      </Table.Body>
    </Table>
  )
}

// Create Document Component
const MyDocument = ({
  dropPointsColumns,
  dropPointSeatNumber,
  showSpecificAgent,
  agentsTableData,
  agentTableColums,
  showAllAgentsTable,
  adminBlockedSeats,
  isRenderAvailableSeatsShow,
  isRenderAdminBlockedAvailableSeats,
  avaliableSeats,avaliableSeatsColumns,
  tripSheetColumns,tripSheetByBoardingPoints,
  vacantSeatCount,bookedSeatsCount,notBoardedColumns,notBoardedSeats,
  selectedSchedule,vechicleInfo,showTripSheetInSingleTable}) => {
    

    let renderTripSheet=()=>{
      let allTables = [] , totalTickets = [];
      if(showTripSheetInSingleTable){
        let ticketRows=[];
        for (const key in tripSheetByBoardingPoints) {
          if (Object.hasOwnProperty.call(tripSheetByBoardingPoints, key)) {
            const ticketsArray = tripSheetByBoardingPoints[key].boradingTickets;
            totalTickets=totalTickets.concat(ticketsArray);
          }
        }
        allTables.push(
          <Row style={styles.boardingBlock}>
              <View>
                  <SubTextBold>No of tickets({totalTickets.length})</SubTextBold>
              </View>
          </Row>
        );
        totalTickets.forEach((row,index) => {
          let ticketCells=[];
          tripSheetColumns.forEach((column)=>{
            ticketCells.push(
              <Table.Cell style={{width:column.pdfWidth}}>
                {column.pdfRender({text:row[column.dataIndex],record:row,index})}
              </Table.Cell> 
            );
          });
          ticketRows.push(
            <Table.Row key={row.id}>
              {ticketCells}
            </Table.Row>
          )
        });
        allTables.push(<Table>
          <Table.Header>
              {tripSheetColumns.map(column => (
                <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
              ))}
          </Table.Header>
          <Table.Body>
                {ticketRows}
          </Table.Body>
        </Table>);
      }else{
        for (const key in tripSheetByBoardingPoints) {
          if (Object.hasOwnProperty.call(tripSheetByBoardingPoints, key)) {
            let ticketRows=[];
            const ticketsArray = tripSheetByBoardingPoints[key].boradingTickets;
            const boardingTime = tripSheetByBoardingPoints[key].boardingTime;
            const landmark = tripSheetByBoardingPoints[key].landmark;

            allTables.push(
              <Row style={styles.boardingBlock}>
                {isValid(key)&&isValid(boardingTime)&&key!=""&&
                  <View>
                      <SubTextBold style={{paddingRight:10}}>Boarding: {key}-{boardingTime}{isValid(landmark)?", Landmark: "+landmark:""}</SubTextBold>
                  </View>
                }
                  <View>
                      <SubTextBold>No of tickets({ticketsArray.length})</SubTextBold>
                  </View>
              </Row>
            )
            ticketsArray.forEach((row,index) => {
              let ticketCells=[];
              tripSheetColumns.forEach((column)=>{
                ticketCells.push(
                  <Table.Cell style={{width:column.pdfWidth}}>
                    {column.pdfRender({text:row[column.dataIndex],record:row,index})}
                  </Table.Cell> 
                );
              });
              ticketRows.push(
                <Table.Row key={row.id}>
                  {ticketCells}
                </Table.Row>
              )
            });
            allTables.push(<Table>
              <Table.Header>
                  {tripSheetColumns.map(column => (
                    <Table.HeaderCell style={{width:column.pdfWidth}}>{column.title}</Table.HeaderCell>
                  ))}
              </Table.Header>
              <Table.Body>
                    {ticketRows}
              </Table.Body>
            </Table>);
          }
        }
      }
       
      return allTables;
    }

    return(
        <Document>
          <Page style={styles.page}>
            <Row>
              <Subtext>
                {global.operatorName}
              </Subtext>
            </Row>
            <Row>
              <Title>Trip Sheet - {global.userName}</Title>
            </Row>
            <Row style={{paddingTop:10}}>
                <SubTextBold>Trip Name : </SubTextBold>
                <Subtext>{selectedSchedule.description}</Subtext>
            </Row>
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>From : </SubTextBold>
                  <Subtext> {selectedSchedule.origin}</Subtext>
                </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Travel Date : </SubTextBold>
                  <Subtext>{getLocalDate(convertDateStringToDate(selectedSchedule.trip.start_date.split('T')[0]))}</Subtext>
                </Row>
              </View>
            </Row>
            <Row style={styles.colblock}>
              <View>
                  <Row>
                    <SubTextBold>To : </SubTextBold>
                    <Subtext>{selectedSchedule.destination}</Subtext>
                  </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Dep Time : </SubTextBold>
                  <Subtext>{getLocalTime(convertTimeStringToDate(selectedSchedule.start_time))}</Subtext>
                </Row>
              </View>
            </Row>
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>Vehicle # : </SubTextBold>
                  <Subtext>{vechicleInfo.vechicle_no}</Subtext>
                </Row>
              </View>
            </Row>
            {isValid(vechicleInfo.driver_name)&&
              <Row style={styles.colblock}>
                <View>
                  <Row>
                    <SubTextBold>Driver Name : </SubTextBold>
                    <Subtext>{vechicleInfo.driver_name}</Subtext> 
                  </Row>
                </View>
                <View>
                    <Row>
                      <SubTextBold>Driver phone # : </SubTextBold>
                      <Subtext>{vechicleInfo.phone_no}</Subtext>
                    </Row>
                </View>
              </Row>
            }
            {isValid(vechicleInfo.second_driver_name)&&
              <Row style={styles.colblock}>
                <View>
                    <Row>
                        <SubTextBold>Second Driver Name : </SubTextBold>
                        <Subtext>{vechicleInfo.second_driver_name}</Subtext>
                    </Row>
                </View>
                <View>
                    <Row>
                      <SubTextBold>Second Driver phone # : </SubTextBold>
                      <Subtext>{vechicleInfo.second_driver_phone_no}</Subtext>
                    </Row>
                </View>
              </Row>
            }
            <Row style={styles.colblock}>
              <View>
                <Row>
                  <SubTextBold>No of booked seats : </SubTextBold>
                  <Subtext>{bookedSeatsCount}</Subtext>
                </Row>
              </View>
              <View>
                <Row>
                  <SubTextBold>Vacant Seats : </SubTextBold>
                  <Subtext>{vacantSeatCount}</Subtext>
                </Row>
              </View>
              </Row>
              <Row>
                  <SubTextBold>Generated day/time : </SubTextBold>
                  <Subtext>{getLocalDateAndTime(new Date())}</Subtext>
              </Row>
              {renderTripSheet()}
              <Row style={styles.summaryTxtBlock}>
                <View>
                    <SubTextBold style={styles.summaryTxt}>Summary</SubTextBold>
                </View>
              </Row>
              {isRenderAvailableSeatsShow&&
                <View>
                    <Row style={styles.availableTxtBlock}>
                      <View>
                        <SubTextBold style={styles.availableTxt}>Avaliable Seats</SubTextBold>
                      </View>
                    </Row>
                  {renderTables(avaliableSeats,avaliableSeatsColumns)}
                </View>
              }
              {isRenderAdminBlockedAvailableSeats&&
                <View>
                    <Row style={styles.adminBlockedSeatsTxtBlock}>
                      <View>
                        <SubTextBold style={styles.adminBlockedSeatsTxt}>Admin Blocked Seats</SubTextBold>
                      </View>
                    </Row>
                    {renderTables(adminBlockedSeats,avaliableSeatsColumns)}
                </View>
              }
              <CancelledTripSheetPdfComponent
                  tripId={selectedSchedule.trip_id}
              />
              {isValidArray(notBoardedSeats)&&notBoardedSeats.length>0&&
                <View>
                <Row style={styles.dropPointSummaryTxtBlock}>
                    <SubTextBold style={styles.dropPointSummaryTxt}>Not boarded Seats</SubTextBold>
                </Row>
                {renderTables(notBoardedSeats,notBoardedColumns)}
              </View>
              }
              {showAllAgentsTable&&
                <View>
                  {showSpecificAgent?
                  <Row style={styles.agentInfoTxtBlock}>
                      <SubTextBold style={styles.agentInfoTxt}>All Agents</SubTextBold>
                  </Row>
                  :
                  <Row style={styles.agentInfoTxtBlock}>
                      <SubTextBold style={styles.agentInfoTxt}>Agent info</SubTextBold>
                  </Row>
                  }
                  {renderTables(agentsTableData,agentTableColums)}
                </View>
              }
               {isValidArray(dropPointSeatNumber)&&
                <View>
                  <Row style={styles.dropPointSummaryTxtBlock}>
                      <SubTextBold style={styles.dropPointSummaryTxt}>Drop Point Summary</SubTextBold>
                  </Row>
                  {renderTables(dropPointSeatNumber,dropPointsColumns)}
                </View>
              }
          </Page>
        </Document>
      )
};

export const TripSheetPdf = ({
  agentsTableData,
  showSpecificAgent,
  agentTableColums,
  showAllAgentsTable,
  vacantSeatCount,bookedSeatsCount,
  operatorCity,selectedSchedule,vechicleInfo,
  dropPointsColumns,
  tripSheetColumns,
  tripSheetByBoardingPoints,
  avaliableSeats,
  avaliableSeatsColumns,
  isRenderAdminBlockedAvailableSeats,
  isRenderAvailableSeatsShow,
  adminBlockedSeats,
  dropPointSeatNumber,
  notBoardedColumns,
  notBoardedSeats,
  showTripSheetInSingleTable
}) =>{
  if(!isValidArray(selectedSchedule)){
    return null;
  }
    return (
        <div className='marginTop'>
            <PDFDownloadLink document={<MyDocument 
            dropPointSeatNumber={dropPointSeatNumber}
            agentsTableData={agentsTableData}
            showSpecificAgent={showSpecificAgent}
            agentTableColums={agentTableColums}
            showAllAgentsTable={showAllAgentsTable}
            adminBlockedSeats={adminBlockedSeats}
             avaliableSeats={avaliableSeats}
             tripSheetByBoardingPoints={tripSheetByBoardingPoints}
             vacantSeatCount={vacantSeatCount} 
             bookedSeatsCount={bookedSeatsCount} 
             vechicleInfo={vechicleInfo} 
             operatorCity={operatorCity} 
             selectedSchedule={selectedSchedule} 
             dropPointsColumns={dropPointsColumns}
             tripSheetColumns={tripSheetColumns}
             avaliableSeatsColumns={avaliableSeatsColumns}
             isRenderAdminBlockedAvailableSeats={isRenderAdminBlockedAvailableSeats}
             isRenderAvailableSeatsShow={isRenderAvailableSeatsShow}
             notBoardedColumns={notBoardedColumns}
             notBoardedSeats={notBoardedSeats}
             showTripSheetInSingleTable={showTripSheetInSingleTable}
             />} fileName={`trip-sheet-${selectedSchedule.description}.pdf`}>
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
              <Button>
                Export To Pdf
              </Button>
            </PDFDownloadLink>
        </div>
    )
}

// NOTE : REFERENCE
// https://react-pdf.org/repl?code=3187b0760ce02e00402a05368c0bc30050129503e0c0a061801e0044460057016c130a1d0a24801404301cc119a013c01b04280379f411001d00074e0802fa366cd84041004e6adaf096ca54febdd184afdf801a18eb361806c001932609d577a0c012ca026a17dd8002608001ed82878c24c4a4aa090208212fc201ce89edebe01c19800dc9151446a0850946a6018c48a7951c488410c88d07e1cc27e814172121dc400f4350cb99524bd3c500242c2c20046206a816a004220505020d4005c3000e4008c3c71eefe3013fc6cc000d61b720af5508dcd196d1d12ddbd1503dd38d9fdcc729872b9ddec2e055bae42a2d1e88c4fbe00062e0280480a1c77340106a7404488003336351dc067586c00f210003b9b1f8fe0d99898103530009000b2594820ab2e9753170c9071a06c1bb0024a06a174205d10293c9fe2e800dcb600662e8013400fa700034800d46c200007001d440d28980155fc72ea32a001a004e360a8541225a6236f81f8e5f0316418890a8180019446823f432108541414f90874530645c7586398327f3f91a7010149d600163b352944994d80380b258acb339dcbe71a001904262a0eb002b396f36c64e34004aee0e133d672e6cc0e4d4b77e1f0ed8409ca02c52342117459ba1aa48581743e3920000