import { takeLatest,put,call,all,fork } from 'redux-saga/effects'
import {
    CREATE_TRIP_EXPENSE,
    LOADING_ACTION,
    GET_TRIP_EXPENSE,
    SET_TRIP_EXPENSE,
    UPDATE_TRIP_EXPENSE    
} from '../actions/action-types';
import { message } from 'antd';

export function* getTripExpenseDetails(action){
    try{
        const {
            api,
            url,
            apiPayload
        } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"){
          let clientPayload=apiResult.payload;
         if(clientPayload.Status==='SUCCESS'){
           let serverPayload=clientPayload.Payload;
            yield put({
                type: SET_TRIP_EXPENSE,
                payload: serverPayload
            });
          }
        }
    }
    catch(error){
        console.error("Error in getting trip expene",error);
    }
}

function* watchGetTripExpenseSaga () {
    yield takeLatest(GET_TRIP_EXPENSE,getTripExpenseDetails)
}


export function* createTripExpense(action){
    try{
        yield put({
            type:LOADING_ACTION,
            payload:true
        })
        const {
            api,
            url ,
            apiPayload
            } = {...action.payload};
        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status==="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status==='SUCCESS'){
               message.success(clientPayload.Message);
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
              type:LOADING_ACTION,
              payload:false
          })
            console.error("Error in creating trip expense",error);
        }  
    }



function* watchCreateTripExpenseSaga (){
    yield takeLatest(CREATE_TRIP_EXPENSE,createTripExpense)
}

export function* updateTripExpense(action){
    try{
      yield put({
          type:LOADING_ACTION,
          payload:true
      })
        const {
            api,
            url ,
            apiPayload,
            } = {...action.payload};

        const apiResults = yield call(api , url,apiPayload);
        if(apiResults.status==="SUCCESS"){
            let clientPayload = apiResults.payload;
            if(clientPayload.Status==='SUCCESS'){
               message.success(clientPayload.Message);
            }else{
              message.error(clientPayload.Message);
            }
          }
          yield put({
            type:LOADING_ACTION,
            payload:false
        })
       }catch(error){
        yield put({
          type:LOADING_ACTION,
          payload:false
        })
        console.error("Error in updating trip expense",error);
        }  
    }
 
 function* watchUpdateTriExpenseSaga () {
    yield takeLatest(UPDATE_TRIP_EXPENSE,updateTripExpense)
 }


export default function*  watchTripExpensePageSagas() {
    yield all([
        fork(watchCreateTripExpenseSaga),
        fork(watchGetTripExpenseSaga),
        fork(watchUpdateTriExpenseSaga)
    ])
}


