import React  from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row ,Col , Card , Form , Input ,Button , message} from 'antd';
import { isValid } from './../utils/utilities';
import { userInfoSelector } from './../selector/user.selector';
import {CHANGE_PASSWORD_URL} from "./../utils/api-url";
import { loadingAction } from './../actions/loading-actions';
import { logoutAction } from './../actions/user.action';
import '../styles/change-password.style.scss';


const changePassword=(currentpassword,newpassword,confirmpassword,logout)=>{
  if(!isValid(currentpassword)||!isValid(newpassword)||!isValid(confirmpassword)){
    message.error("Password should not be empty");
    return ''
  }
  if(currentpassword===newpassword){
    message.error("New password should be different from current password.");
    return ""
  }else if(newpassword!==confirmpassword){
    message.error("New password and confirm password should be from same.");
    return ""
  }
 return changePasswordAction(currentpassword,newpassword,()=>{
    logout();
  });
}
const changePasswordAction = (
    currentpassword,
    newpassword,
    onSuccess
) => {
   return fetch(CHANGE_PASSWORD_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
        "newPassword":newpassword,
        "currentPassword" : currentpassword,
        "operatorCode" : global.operatorCode,
        "userName":global.userName
        })
    })
    .then(response => response.json())
    .then(data => {
        if(data.Status==="SUCCESS"){
            onSuccess();
            message.success(data.Message);
            return true;
        }else{
            message.error(data.Message)
            return false;
        }
    })
}

export const ChangePasswordPage = (props) => {

    const {
        userName
    } = useSelector(userInfoSelector);

    const dispatch=useDispatch();

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const logout=()=>{
        dispatch(logoutAction());
    }

    const onFinish = async (values) => {
        let {
            currentpassword,
            newpassword,
            confirmpassword
        }=values;
        setLoading(true);
        await changePassword(currentpassword,newpassword,confirmpassword,logout);
        setLoading(false);
      };

            return (
                <Row
                type="flex"
                justify="center"
                align="middle"
               className="change-password-root"
              >
                <Col lg={6} sm={6} xs={22}>
                  <Card title="Change Password!" bordered={false}>
                    <Form
                        initialValues={{username:userName}}
                        onFinish={onFinish}
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            rules={[{ required: true, message: 'Please input your Username!' }]}
                        >
                            <Input
                                type="text"
                                placeholder="Enter the username"
                                disabled={true}
                                className="defalut-input-style"
                            />  
                        </Form.Item>
                        <Form.Item
                        name="currentpassword"
                        label="Current Password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                        >
                            <Input
                                type="password"
                                placeholder="Enter the password"
                                className="defalut-input-style"
                            /> 
                        </Form.Item>
                        <Form.Item
                        name="newpassword"
                        label="New Password"
                        rules={[{ required: true, message: 'Please input new password!' }]}
                        >
                            <Input
                                type="password"
                                placeholder="Enter the password"
                                className="defalut-input-style"
                            /> 
                        </Form.Item>
                        <Form.Item
                        name="confirmpassword"
                        label="Confirm Password"
                        rules={[{ required: true, message: 'Please input new confirm!' }]}
                        >
                            <Input
                                type="password"
                                placeholder="Enter the password"
                                className="defalut-input-style"
                            /> 
                        </Form.Item> 
                        <Form.Item>
                            <Button type="button" htmlType="submit" >
                                Change Password
                            </Button>
                        </Form.Item>
                    </Form>
                  </Card>
                </Col>
              </Row>
            )
}

