import React ,{useEffect, useState} from "react";
import { Button, Modal , Select , Input, message} from "antd";
import TimeIncreDecre from './../../../images/time-incre-decre.png';
import Icon from '@ant-design/icons';
import './adjust-time-pop-up.style.scss';
import { isValid, isValidArray } from "../../../utils/utilities";
import { getMinsBetweenTwoTimes } from "../../../utils/date.utils";

const { Option, OptGroup } = Select;


const EditDrawLine =()=>{

    return(
        <div className="adjust-time-underline">

        </div>
    )
}

const printStopOption = (option) => {
    return <Option  value={option.display_name} data={option} key={option.display_name}>{option.display_name}</Option>
};

const getAllStops=(tripCitiesWithStops, isStops)=>{
    let formatedStops = [];
    if(isValidArray(tripCitiesWithStops)&&tripCitiesWithStops.length>0){
        tripCitiesWithStops.forEach(element => {
            let formateCityStops=[];
            if(isStops&&isValidArray(element.stops)&&element.stops.length>0){
                element.stops.forEach(stopElement => {
                    formateCityStops.push(printStopOption(stopElement));
                });
                formatedStops.push(
                    <OptGroup label={element.city_name}>
                        {formateCityStops}
                    </OptGroup>)
            }
            else {
                if (!isStops) {
                    formatedStops.push(
                        <Option value = {element.city}>{element.city}</Option>
                    )
                }
            }
        });
    }
    return formatedStops;
}

const getDestinationStops=(tripCitiesWithStops,fromStop, isStops)=>{
    let formatedStops = [];
    if(isValidArray(tripCitiesWithStops)&&tripCitiesWithStops.length>0){
        let disableOptionRender = false;
        tripCitiesWithStops.forEach(element => {
            let formateCityStops=[];
            if(isStops&&isValidArray(element.stops)&&element.stops.length>0){
                element.stops.forEach(stopElement => {
                    if(isValid(fromStop)&&stopElement.display_name === fromStop){
                        disableOptionRender = true;
                    }
                    if(disableOptionRender){
                        formateCityStops.push(printStopOption(stopElement));
                    }
                });
                if(disableOptionRender){
                    formatedStops.push(
                        <OptGroup label={element.city_name}>
                            {formateCityStops}
                        </OptGroup>);
                }
            }
            else {
                if (!isStops) {
                    if (isValid(fromStop)&&element.city == fromStop){
                        disableOptionRender = true;
                    }
                    if (disableOptionRender){
                        formatedStops.push(
                            <Option value = {element.city}>{element.city}</Option>
                        )
                    }
                }
            }
        });
    }
    return formatedStops;
}

export const AdjustTimePopUp = (props) => {

    const{
        tripCitiesWithStops,
        adjustTimeForStop,
        disableAdjustBtn,
        onUpdate,
        startTime
    }=props;

    const [stops,setStops] = useState([]);
    const [fromStop,setFromStop] = useState(null);
    const [toStop,setToStop] = useState(null);
    const [mins,setMins] = useState(10);
    const [addrsub,setaddrsub] = useState('+');

    useEffect(()=>{
        let allStops = getAllStops(tripCitiesWithStops, adjustTimeForStop);
        setStops(allStops);
    },[tripCitiesWithStops]);

    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    let maxMinsAllowedToadd = getMinsBetweenTwoTimes(startTime,'23:59:59');
    let maxMinsAllowedTosub = getMinsBetweenTwoTimes('00:00:00',startTime);

        return (
            <div>
                <Button className='time-incre-decre' onClick={showModal} disabled = {isValid(disableAdjustBtn)&&disableAdjustBtn?true:false}>
                    <Icon component={() => (<img src={TimeIncreDecre} />)} style={{fontSize:'30px'}} />
                </Button>
                <Modal 
                centered={true}
                visible={isModalVisible} 
                onOk={handleOk} 
                onCancel={handleCancel}
                okText="Update"
                footer={null}
                >
                    <div className="root-adjut-time-content">
                        <div>Adjust Time</div>
                        <EditDrawLine />
                        <div className="marginTop add-subtract-block">
                            <Select 
                                style={{width:100}}
                                defaultValue="+"
                                onChange={(value)=>{
                                    setaddrsub(value);
                                }}
                            >
                                <Option value={'+'}>Add</Option>
                                <Option value={'-'}>Subtract</Option>
                            </Select>
                            <Input className="input-min" defaultValue={10} 
                                    onChange={(e)=>{
                                        setMins(e.target.value);
                                    }}/>
                            <div>minutes</div>
                        </div>
                        <div className="marginTop from-to-block">
                            <div className="from-block">
                                <div className="marginRight from-to-block-text">to all stops from </div>
                                <Select
                                    showSearch
                                    style={{width:200}}
                                    placeholder="select stop"
                                    onChange={(value)=>{
                                        setFromStop(value);
                                    }}
                                >
                                    {stops}
                                </Select>
                            </div>
                            <div className="to-block marginTop marginBottom">
                                <div className="marginRight from-to-block-text">to</div>
                                <Select
                                    showSearch
                                    style={{width:200}}
                                    placeholder="select stop"
                                    onChange={(value)=>{
                                        setToStop(value);
                                    }}
                                >
                                    {getDestinationStops(tripCitiesWithStops,fromStop,adjustTimeForStop)}
                                </Select>
                            </div>
                        </div>
                    </div>
                    <div className="footer-adjust-time-pop-up">
                        <Button 
                        className='cancel-time-btn' 
                        onClick={()=>{
                                handleCancel(); 
                        }}>
                            Cancel
                        </Button>
                        <Button 
                        className='next-time-btn' 
                        onClick={()=>{
                            let minutes = mins;
                            if(addrsub === '-'){
                                minutes = -mins;
                            }
                            if(maxMinsAllowedToadd<mins&&addrsub==='+'){
                                message.warning(`Maximum value allowed to change is ${maxMinsAllowedToadd}`)
                            }else if(maxMinsAllowedTosub<mins&&addrsub==='-'){
                                message.warning(`Maximum value allowed to change is ${maxMinsAllowedTosub}`)
                            }
                            else{
                                onUpdate(fromStop,toStop,minutes);
                                handleCancel();
                            }
                        }}
                        >
                            {adjustTimeForStop?'Update':'Continue'}
                        </Button>
                    </div>
                </Modal>
            </div>
        )
}