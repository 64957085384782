import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table, Select} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchSmsReportSheet } from './../../actions/reports.action';
import { isValidNumber , isValidArray , isValid} from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime } from './../../utils/date.utils';
import { getRangeOptions , getPaginationDetails} from './reports.module';

const {
    Option
} = Select;

const getOperators=(data)=>{
    let formatedData=[];
    if(isValidArray(data)){
        for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
                const element = data[key];
                formatedData.push({operator_code:element.operator_code,name:element.name})
            }
        }
    }
    return formatedData;
}


const tableColumns=[
    {
        title:"SN",
        key:"index",
        render : (text, record, index) => index+1
    },
    {
        title:"SMS type",
        key:"action_type",
        dataIndex:'action_type'
    },
    {
        title:"Count",
        key:"quantity",
        dataIndex:"quantity"
    },
    {
        title:"Units",
        key:'unit',
        dataIndex:'unit'
    },
    {
        title:"SMS Count",
        key:'smsCount',
        render:(row)=>{
            return row.quantity*row.unit;
        }
    }
];

const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({smsData,formValues,pagination,showSummary})=>{

    let {
        dateRange
    }=formValues;
    if(isValidArray(smsData)&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>SMS Report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            <Row gutter={[16,16]}>
                                    <Col>
                                        <Text strong>Sms Report</Text>
                                    </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table
                                        className="bordered-table"
                                        columns={tableColumns}
                                        dataSource={smsData['data']}
                                        pagination={getPaginationDetails(pagination)}
                                        summary={(pageData ) => {
                                            if(showSummary){
                                                let summaryQuantity = 0;
                                                let summaryUnit = 0;
                                                let total = 0
                                                pageData.forEach(({ quantity,unit }) => {
                                                    if(isValidNumber(quantity)){
                                                        summaryQuantity += quantity;
                                                    }
                                                    if(isValidNumber(unit)){
                                                        summaryUnit = summaryUnit + '' +unit;
                                                    }
                                                    if(isValidNumber(unit)&&isValidNumber(quantity)){
                                                        total = total + quantity*unit;
                                                    }
                                                });
                
                                                return(
                                                    <Table.Summary fixed>
                                                    <Table.Summary.Row className="fontWeightBold">
                                                      <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                                                      <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                                                      <Table.Summary.Cell index={2}>{summaryQuantity}</Table.Summary.Cell>
                                                      <Table.Summary.Cell index={3}>{null}</Table.Summary.Cell>
                                                      <Table.Summary.Cell index={4}>{total}</Table.Summary.Cell>
                                                    </Table.Summary.Row>
                                                  </Table.Summary>
                                                )
                                            }else{
                                                return null
                                            }
                                          
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
            )
    }else{
        return null
    }
}


const renderOperators=(data)=>
{
    let options=[];
    if(isValidArray(data)&&data.length>0){
        data.forEach(element => {
            if(isValid(element)){
                options.push(<Option value={element.operator_code}>{element.name}</Option>)
            }        
        });
    }
    return options;
}

export const SmsReportComponent = () => {

    const [showReport,setShowReport]=useState(false);
    const [showOperator,setShowOperator]=useState(false);
    const [smsData,setSmsData]=useState(undefined);
    const [operators,setOperators]=useState(undefined);
    const [filteredSmsData,setFilterdSmsData]=useState(undefined);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        setLoading(false);
        setSmsData(data);
        setOperators(getOperators(data));
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowOperator(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        fetchSmsReportSheet(fromDate,toDate,onSuccess,onFailure);
    }

    const onFinishSearchOperator=(values)=>{
        let {
            operatorCode
        } = values;
        setShowReport(true);
        setFilterdSmsData(smsData[operatorCode]);
    }

    const [form] = Form.useForm();
    const [operatorForm] = Form.useForm();

    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showOperator&&
                 <Form
                 onFinish={onFinishSearchOperator}
                 form={operatorForm}
                 >
                     <Row gutter={[16,16]}>
                         <Col>
                             <Form.Item 
                             name="operatorCode" 
                             label="Select operator" 
                             rules={[{ required: true , message: 'Please input your operator!'}]}>
                                   <Select                                     
                                     className="defalut-select-style"
                                     placeholder="Select operator"
                                     >
                                         {renderOperators(operators)}
                                   </Select>
                             </Form.Item>
                         </Col>
                         <Col>
                             <Form.Item>
                                 <Button htmlType="submit">Search</Button>
                             </Form.Item>
                         </Col>
                     </Row>
                 </Form>   
                }
                {showReport&&
                <RenderReport 
                    smsData={filteredSmsData}
                    formValues={form.getFieldsValue()}
                    pagination={false}
                    showSummary={true}
                    />
                }
            </div>
    )
}