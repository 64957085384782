import { Select , Row , Col , Button ,Table , message , Switch , Form} from "antd";
import { element } from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getAgents } from "../actions/agents.actions";
import { loadingAction } from "../actions/loading-actions";
import { getSchedulesWithTripSheetPermission, insertTripSheetPermission, updateTripSheetPermission , bulkUpdateTripSheetPermissions} from "../actions/trip-sheet-permission.actions";
import { agentsSelector } from "../selector/agents.selector";
import '../styles/trip-sheet-permissions.style.scss';
import { isValid, isValidArray, isValidNumber } from "../utils/utilities";

const { Option } = Select;

const getOriginDestinationFilters=(data)=>{
    let origins = [] , destinations = [] , filteredOrigins = [] , filteredDestinations = [];
    if(isValidArray(data)){
        data.forEach(element => {
            if(origins.indexOf(element.origin) == -1){
                origins.push(element.origin);
                filteredOrigins.push({
                    text:element.origin,
                    value:element.origin
                });
            }

            if(destinations.indexOf(element.destination) == -1){
                destinations.push(element.destination);
                filteredDestinations.push({
                    text:element.destination,
                    value:element.destination
                });
            }
        });
    }

    return {
        filteredOrigins,
        filteredDestinations
    }
}

const getHeaderSwithPermission=(data)=>{
    let isEnabled = true;
    if(isValidArray(data)&&data.length>0){
        data.forEach(element => {
            if(isValidNumber(element.permission)&&element.permission == 0){
                isEnabled = false;
            }
        });
    }
    return isEnabled;
}

export const TripSheetPermissionPage = (props) => {

    const dispatch = useDispatch();
    let agents = useSelector(agentsSelector);

    const [form] = Form.useForm();


    const [activeSchedules,setActiveSchedules] = useState([]);
    const [agentId,setAgentId] = useState(null);
    const [originFilters ,setOriginFilters] = useState([]);
    const [destinationFilters ,setDestinationFilters] = useState([]);
    const [ currentDataSource , setCurrentDataSource] = useState([]);

    const location = useLocation();

    useEffect(()=>{
        if(isValid(location.state)){
            setAgentId(location.state.agentId);
            form.setFieldsValue({
                'agentId':location.state.agentId
            });
        }
    },[location])

    const onSearch=()=>{
        form.validateFields()
        .then(values => {
            let {
                agentId
            } = values;
            setAgentId(agentId);
        });
    }

    const createTripSheetPermission=(record,permission)=>{
        setLoading(true);
        let data ={
            scheduleId:record.id, 
            agentId:agentId, 
            permission:permission
        }
        insertTripSheetPermission(data,(agentTripSheetPermissionId)=>{
            localUpdateTripSheetPermission([record.id],permission,agentTripSheetPermissionId);
        },
        ()=>{

        });
    }

    const onClickUpdateTripSheetPermission=(record,permission)=>{
        setLoading(true);
        let data ={
            id:record.agent_trip_sheet_permission_id, 
            permission:permission
        }
        updateTripSheetPermission(data,()=>{
            localUpdateTripSheetPermission([record.id],permission);
        },
        ()=>{

        });
    }

    useEffect(()=>{
        dispatch(getAgents());
    },[]);

    const printOptions = () => {
        let agentOptions = [] ;
        if(isValidArray(agents)){
            agents.map((agent) => {
                agentOptions.push(<Option key = {agent.id} value= {agent.id} data={agent}>{agent.name}</Option>);
            });
        }
        return agentOptions;
    }

    const onSuccess=(data)=>{
        let {
            filteredOrigins,
            filteredDestinations
        } = getOriginDestinationFilters(data);
        setOriginFilters(filteredOrigins);
        setDestinationFilters(filteredDestinations);
        setActiveSchedules(data);
        setCurrentDataSource(data);
        setLoading(false);
    }

    const onFailure=()=>{
        message.error("Failed to get schedules");
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool))
    }

    const reload =()=>{
        setLoading(true);
        getSchedulesWithTripSheetPermission(agentId,onSuccess,onFailure)
    }

    useEffect(()=>{
        reload();
    },[agentId]);

    const localUpdateTripSheetPermission=(scheduleIds,permission,agentTripSheetPermissionId=null)=>{
        activeSchedules.forEach((element,index)=>{
            if(scheduleIds.indexOf(element.id) != -1){
                activeSchedules[index].permission = permission;
                if(isValid(agentTripSheetPermissionId)){
                    activeSchedules[index] = {
                        ...activeSchedules[index],
                        agent_trip_sheet_permission_id:agentTripSheetPermissionId,
                        agent_id:agentId
                    };
                }
            }
        });

        currentDataSource.forEach((element,index)=>{
            if(scheduleIds.indexOf(element.id) != -1){
                currentDataSource[index].permission = permission;
                if(isValid(agentTripSheetPermissionId)){
                    currentDataSource[index] = {
                        ...activeSchedules[index],
                        agent_trip_sheet_permission_id:agentTripSheetPermissionId,
                        agent_id:agentId
                    };
                }
            }
        });
        setActiveSchedules([...activeSchedules]);
        setCurrentDataSource([...currentDataSource]);
        setLoading(false);
    }

    return (
        <div className="trip-sheet-permissions-root">
            <div className={`trip-sheet-permissions-inner-div`}>
            <Form
                initialValues={{}}
                form={form}
            >
                <Row gutter={[16,16]}  align="middle">
                    <Col>
                    <Form.Item    
                        className="form-agent"
                        name="agentId"
                        rules={[{ required: true ,  message:"Please select agent name"}]}
                        label="">
                            <Select
                                placeholder="Select agent"
                                className="trip-sheet-permission-agent-selector"
                            >
                                    {printOptions()}
                            </Select>
                    </Form.Item>
                    </Col>
                    <Col>
                        <Button onClick={onSearch}>Search</Button>
                    </Col>
                </Row>
                </Form>
                <Row>
                    <Col>
                        <Table 
                            dataSource={activeSchedules}
                            columns={[
                                {
                                    title:"Origin",
                                    dataIndex:'origin',
                                    key:'origin',
                                    filters:originFilters,
                                    onFilter: (value, record) => record.origin.indexOf(value) === 0
                                },
                                {
                                    title:"Destination",
                                    dataIndex:'destination',
                                    key:'destination',
                                    filters:destinationFilters,
                                    onFilter: (value, record) => record.destination.indexOf(value) === 0
                                },
                                {
                                    title:"Trip Description",
                                    dataIndex:'description',
                                    key:'description'
                                },
                                {
                                    title:()=>{
                                        let switchValue = getHeaderSwithPermission(currentDataSource);
                                        return (
                                            <Row gutter={[16,16]}>
                                                <Col>
                                                    Permission
                                                </Col>
                                                {isValid(agentId)&&
                                                <Col>
                                                    <Switch checked={switchValue}
                                                    onChange={(value)=>{
                                                        let scheduleIds =[];
                                                            currentDataSource.forEach(element => {
                                                                    scheduleIds.push(element.id);
                                                            });
                                                        setLoading(true);
                                                        bulkUpdateTripSheetPermissions(scheduleIds,agentId,value,()=>{
                                                            reload();
                                                        },
                                                        ()=>{
                                                            message.error("Failed to update permission");
                                                        });
                                                    }}
                                                    />
                                                </Col>
                                                }
                                            </Row>
                                        )
                                    },
                                    dataIndex:'permission',
                                    key:'permission',
                                    render:(text,row)=>{
                                        if(isValid(agentId)){
                                            if(isValidNumber(text) && row.agent_id == agentId){
                                                return(
                                                    <Switch checked={text} onChange={()=>{
                                                        onClickUpdateTripSheetPermission(row,!text);
                                                    }} />
                                                )
                                            }else{
                                                text = true;
                                                return (
                                                    <Switch checked={text} onChange={()=>{
                                                        createTripSheetPermission(row,!text);
                                                    }} />
                                                )
                                            }
                                        }else{
                                            return null;
                                        }
                                    }
                                }
                            ]}
                            pagination={false}
                            onChange={(pagination, filters, sorter, extra)=>{
                                setCurrentDataSource(extra.currentDataSource);
                            }}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    )
}