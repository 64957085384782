import React, { useEffect, useState } from 'react';
import { isValidArray } from '../../../utils/utilities';
import { Button, List, Row,Col,Slider, message } from 'antd';
import { getTimeStampInReadableFormat , getMinsBetweenTwoTimes , getHoursAndMinsFromMins} from '../../../utils/date.utils';
import { insertScheduleByStops, resetTripCreate } from './../../../actions/trip-create.actions';
import { useHistory } from 'react-router-dom';
import { CloseOutlined } from '@ant-design/icons';
import regenaratePng from './../../../images/regenarate.png';
import { showConfirm } from './../../custom-components/custom-component';
import { useDispatch } from 'react-redux';
import { loadingAction } from '../../../actions/loading-actions';

let minsJson = {
    0:120,
    25:180,
    50:240,
    75:300,
    100:360
}

const marks = {
    0   : '2',
    25  : '3',
    50  : '4',
    75  : '5',
    100 : '6'
  };

const getRoutes=(tripCitiesWithStops)=>{

    let routes=[];
    if(isValidArray(tripCitiesWithStops)&&tripCitiesWithStops.length>0){
        for (let i = 0; i < tripCitiesWithStops.length; i++) {
            const origininfo = tripCitiesWithStops[i];
            for (let j = i+1; j < tripCitiesWithStops.length; j++) {
                const destinationInfo = tripCitiesWithStops[j];
                var duration = getMinsBetweenTwoTimes(origininfo.time,destinationInfo.time) , mins=0 , perdaymin=1440;
                 let originDay = origininfo.day-1 , destinationDay= destinationInfo.day-1;  
                if(originDay!=destinationDay){ 
                    if(originDay>0){
                      mins = originDay*perdaymin;
                    }
                    if(destinationDay>0){
                      mins = destinationDay*perdaymin;
                    }
                  duration=duration+mins;
                }
      
                routes.push({
                    origin:origininfo.city_name,
                    destination:destinationInfo.city_name,
                    start_time:origininfo.time,
                    end_time:destinationInfo.time,
                    origin_day:origininfo.day,
                    destination_day:destinationInfo.day,
                    durationInMins:duration,
                    duration:getHoursAndMinsFromMins(duration)
                })
            }
        }
    }

    return routes;
}

export const FinalSummary = (props) => {

    const{
        schedule,
        tripCitiesWithStops,
        onBack,
        resetAllData,
        tripClosePointStopId
    } = props;

    const [routes,setRoutes] = useState([]);
    const [minimumMinsTocreateRoute , setminimumMinsTocreateRoute] = useState(120);
    const [avoidRoutes , setAvoidRoutes] = useState([]);

    let history = useHistory();

    let scheduleDesc = schedule.description;
    const dispatch = useDispatch()

    useEffect(()=>{
        setRoutes(getRoutes(tripCitiesWithStops))
    },[tripCitiesWithStops]);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    const onSuccess = () =>{
        setLoading(false);
        resetAllData();
        dispatch(resetTripCreate());
        history.push({
            pathname: 'trip-create/success',
            state: { selectedScheduleId: schedule.id  }
        });
    }

    const onFailure= (dbError) =>{
        message.error(dbError);
        setLoading(false);
    }
    
    return (
        <div className='final-summary-root'>
                <div className='final-summary-header'>
                    <h3>Trip: </h3>
                    <span>{scheduleDesc}</span>
                </div>
                <Slider 
                    color="green"
                    className='slider-default'
                    marks={marks} 
                    defaultValue={2} 
                    style={{width:'250px'}} 
                    step={25} 
                    tooltipVisible={false} 
                    trackStyle={{

                    }}
                    onChange={(value)=>{
                        setminimumMinsTocreateRoute(minsJson[value]);
                    }}
                    />
                <div className='final-summary-sub-header'>
                    <h2>Routes</h2>
                    <Button 
                    icon={<img src={regenaratePng} />} 
                    type="text" className='outline-button refresh-button'
                    onClick={()=>{
                        setAvoidRoutes([]);
                    }}
                    ></Button>
                </div>
                <div>
                <List
                    dataSource={routes}
                    header={null}
                    renderItem={item => {
                        let routeString = `${item.origin}${item.destination}`;
                        if(Number(minimumMinsTocreateRoute)<Number(item.durationInMins)&&avoidRoutes.indexOf(routeString)===-1){
                            return(
                                <div className='route-list-main'>
                                    <div className='route-list-item'>
                                        <div className='routes-list-root'>
                                            <div className='list-item-header'>
                                                <h3 className='marginRight'>{item.origin}</h3>
                                                <h3>{item.destination}</h3>
                                            </div>
                                            <div className='list-item-sub-header'>
                                                <div className='marginRight'>{getTimeStampInReadableFormat(item.start_time)}</div>
                                                <div className='marginRight'>{`${item.duration}`}</div>
                                                <div>{getTimeStampInReadableFormat(item.end_time)}</div>
                                            </div>
                                            <div className='list-item-sub-header'>
                                                <div className='marginRight'>Day {item.origin_day}</div>
                                                <div>Day {item.destination_day}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <Button 
                                    icon={<CloseOutlined />} 
                                    type="text" 
                                    className="outline-button remove-button"
                                    onClick={()=>{
                                        showConfirm(()=>{
                                            avoidRoutes.push(`${item.origin}${item.destination}`);
                                            setAvoidRoutes([...avoidRoutes]);
                                        },"Remove route",`Are you sure to remove route from ${item.origin} to ${item.destination}`);
                                    }}
                                    ></Button>
                                </div>
                            )
                        }else{
                            return null
                        }
                    }}
                    />
                </div>
                <Row className='final-summary-footer' gutter={[16,16]}>
                    <Col>
                        <Button 
                            className='cancel-time-btn' 
                            onClick={()=>{
                                onBack(); 
                            }}>
                                Back
                        </Button>
                    </Col>
                    <Col>
                        <Button 
                            onClick={()=>{
                                setLoading(true);
                                insertScheduleByStops(tripCitiesWithStops,minimumMinsTocreateRoute,avoidRoutes,schedule,tripClosePointStopId,onSuccess,onFailure);
                            }}
                            >
                                Done
                        </Button>
                    </Col>
                </Row>
        </div>
    )
}