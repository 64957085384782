import { message } from 'antd';
import { dafidyUserCheck, TIME_STAMP_FOR_ACTIVE_BOOKINGS} from '../utils/constant';
import { isValidArray, trimString } from '../utils/utilities';
import { 
    SALES_SHEET_URL ,
    TRIP_SHEET_URL , 
    BOOKING_SHEET_URL , BOOKING_SRMS_SHEET_URL,
    SMS_REPORT_SHEET_URL,
    GET_TRIPS_BY_START_DATE_URL , 
    GET_TRIPS_BY_BOOKING_DATE_URL,
    MASTER_TRIP_SHEET_URL,
    FETCH_CANCELLED_TRIP_SHEET_URL,
    PASSENGER_INFO_SHEET_URL,
    TICKET_SEARCH_URL,
    BLOCKED_SHEET_URL,
    RESCHEDULED_TICKET_REPORT_URL,
    PERFORMANCE_URL,
    TRANSACTION_SHEET_URL,
    CANCEL_TICKET_SEARCH_URL,
    ABANDONED_TRANSACTION_SHEET_URL,
    WEB_TICKET_SALES_SHEET_URL,
    GET_TICKETS_BOOKED_AFTER_TRIP_CLOSE_POINT_URL,
    GET_SETTLEMENT_REPORT_URL,
    GET_DEMAND_DATA_SHEET_URL,
    FETCH_ACTIVE_BOOKINGS_URL,
    FETCH_SERVICE_COUNT_REPORT_URL,
    GET_GST_FILING_TICKETS_URL, FETCH_TRANSACTION_COUNT_REPORT_URL,
    GET_SEAT_SHARING_REPORT_URL, FETCH_WORKFLOW_REPORT_URL,
    FETCH_PRE_PAID_TRANSACTION_COUNT_REPORT_URL, FETCH_SRMS_PRE_PAID_TRANSACTION_COUNT_REPORT_URL,
    FETCH_TRACKING_DATA_REPORT_URL
} from './../utils/api-url';
import { postCall } from '../utils/network.util';
import { getSrmsMavenAgentId, getSrmsMavenTravelsId, APP_ENV, PROD_ENV, B2B_PROD_ENV } from '../app.config';

const getTravelsAndOperatorCode=()=>{
    let operatorCode = global.operatorCode;
    let travelsId = global.travelsId;
    if(dafidyUserCheck()){
        operatorCode=null;
        travelsId=null;
    }
    return {
        operatorCode,
        travelsId
    }
}


export const fetchcancelTickets = (fromDate,toDate,isWebTicketsOnly,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(CANCEL_TICKET_SEARCH_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            fromDate,
            toDate ,
            "operatorCode":global.operatorCode,
            "travelsId":global.travelsId,
            "isWebTicketsOnly":isWebTicketsOnly         
          })
         })
        .then(response => response.json())
        .then(data => {
            onSuccess(data);
            return true;
        }).catch((reason)=> {
            message.error(`Fetching Trip sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
  }

export const fetchTripSheet = (tripId,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(TRIP_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"tripInfo" : {"tripId": tripId},
                              "agentId":global.agentId,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            onSuccess(data);
            return true;
        }).catch((reason)=> {
            message.error(`Fetching Trip sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchCancelledTickets = (tripId,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_CANCELLED_TRIP_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"tripId": tripId,
                              "agentId":global.agentId,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            message.error(`Fetching Cancelled sheet failed`)
            onFailure();
            return false;
        }); 
}

export const fetchBookingSheet = (fromDate,toDate , isBooking,onSuccess=()=>{},onFailure=()=>{}) => {
    let payload = {
        fromDate,toDate,
        isBooking,
        agentInfo:{
         "privilege":global.privilege,
         "id":global.agentId,
        },
        "operatorCode":global.operatorCode,
        "travelsId":global.travelsId
       };
    var fetchBookSheet = postCall(BOOKING_SHEET_URL, payload);
    let promiseAcions = [fetchBookSheet];
    let privilegeList = ["Prepaid-OTA", "Dafidy user"];
    if ([PROD_ENV, B2B_PROD_ENV].includes(APP_ENV)&&privilegeList.includes(global.privilege)) {
        payload.agentInfo.id = getSrmsMavenAgentId();
        payload.travelsId = getSrmsMavenTravelsId();
        var fetchSRMSBookSheet = postCall(BOOKING_SRMS_SHEET_URL, payload);
        promiseAcions.push(fetchSRMSBookSheet);
    }
    Promise.all(promiseAcions).then((promiseData) => {
        let b2bData = promiseData[0];
        let bookedSheetData = b2bData.payload.bookedSheet;
        if (promiseAcions.length==2) {
            let srmsData = promiseData[1];
            let srmsBookedSheet = srmsData.payload.bookedSheet;
            let srmsBookedKeys = Object.keys(srmsBookedSheet);
            srmsBookedKeys.map((key) => {
                if (isValidArray(bookedSheetData[key])) {
                    bookedSheetData[key] = [...bookedSheetData[key],...srmsBookedSheet[key]]
                }
                else {
                    bookedSheetData[key] = srmsBookedSheet[key];
                }
            })
        }
        onSuccess(bookedSheetData);
    }).catch((reason) => {
        console.log("reason", reason)
        message.error(`Fetching booking sheet failed ${reason}`)
        onFailure();
    });
}


export const fetchSmsReportSheet = (fromDate,toDate , onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(SMS_REPORT_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                   startDate:fromDate,endDate:toDate, 
                   messageType:"SMS",
                   "operatorCode":global.operatorCode,
                   "travelsId":global.travelsId             
                  })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status==="SUCCESS"&&data.Payload){
                let clientPayload=data.Payload;
                onSuccess(clientPayload);        
            }else{
                message.error(data.Message);
                onFailure();
            }
        }).catch((reason)=> {
            message.error(`Fetching failed to get sms details ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchTicketsBookedAfterStartTime = (fromDate,toDate , onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(GET_TICKETS_BOOKED_AFTER_TRIP_CLOSE_POINT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                fromDate:fromDate,toDate:toDate, 
                "operatorCode":global.operatorCode,
                "travelsId":global.travelsId             
                })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status==="Success"){
                let clientPayload=data.tickets;
                onSuccess(clientPayload);        
            }else{
                message.error(data.Message);
                onFailure();
            }
        }).catch((reason)=> {
            message.error(`Fetching failed to get sms details ${reason}`)
            onFailure();
            return false;
        }); 
}


export const fetchTripsByBookingDate = (fromDate,toDate , onSuccess=()=>{},onFailure=()=>{}) => {
    let {
        operatorCode,
        travelsId
       } = getTravelsAndOperatorCode();
    fetch(GET_TRIPS_BY_BOOKING_DATE_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                   fromDate,toDate, 
                   isBookingDate:false,
                   "operatorCode":operatorCode,
                   "travelsId":travelsId             
                  })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data);
            }else{
                onFailure();
                message.error(`Fetching booking sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching booking sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}


export const fetchTripsByTravelDate = (fromDate,toDate , onSuccess=()=>{},onFailure=()=>{},partnerCode) => {
   let {
    operatorCode,
    travelsId
   } = getTravelsAndOperatorCode();
    fetch(GET_TRIPS_BY_START_DATE_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                   fromDate,toDate, "agentInfo": {
                    "privilege":global.privilege,
                    "id":global.agentId
                   } ,
                   "operatorCode":operatorCode,
                   "travelsId":travelsId,
                   "partnerCode":partnerCode             
                  })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data);
            }else{
                onFailure();
                message.error(`Fetching schedules failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching schedules failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchSalesSheet = (tripIds,fromDate,toDate,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(SALES_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"tripInfo" : {"tripIds": tripIds},
                              "fromDate":fromDate,
                               "toDate":toDate,
                               "isBookingDate":isBookingDate,
                               "operatorCode":global.operatorCode,
                               "travelsId":global.travelsId                               
                              })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data,{
                    fromDate,toDate,isBookingDate,'agentNames':data.agentNames
                });
            }else{
                onFailure();
                message.error(`Fetching sales sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching sales sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchWebTicketSalesSheet = (tripIds,fromDate,toDate,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    let {
        operatorCode,
        travelsId
       } = getTravelsAndOperatorCode();
    fetch(WEB_TICKET_SALES_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"tripIds": tripIds,
                              "fromDate":fromDate,
                               "toDate":toDate,
                               "isBookingDate":isBookingDate,
                               "operatorCode":operatorCode,
                               "travelsId":travelsId                               
                              })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status==="Success"){
                onSuccess(data.webSalesSheet,{
                    fromDate,toDate,isBookingDate,'agentNames':data.agentNames
                });
            }else{
                onFailure();
                message.error(`Fetching sales sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching sales sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchMasterTripSheet = (tripId,onSuccess=()=>{},onFailure=()=>{}) =>  {
    fetch(MASTER_TRIP_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                  "tripInfo" : {"tripId": tripId},
                  "operatorCode":global.operatorCode,
                  "travelsId":global.travelsId                 
                })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data);
            }else{
                onFailure();
                message.error(`Fetching master trip sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching master trip sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchPassengerInfoSheet = (fromDate,toDate,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(PASSENGER_INFO_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
              fromDate,toDate,isBookingDate,
              "operatorCode":global.operatorCode,
              "travelsId":global.travelsId             
            })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data.passengerInfoSheet);
            }else{
                onFailure();
                message.error(`Fetching passenger info failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching passenger info failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchTicketSearch = (
    PNR,
    Mobile_Number,
    email_id,
    Passenger_name,
    order_no,
    onSuccess=()=>{},onFailure=()=>{}
) => {
    fetch(TICKET_SEARCH_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({PNR:trimString(PNR),
            Mobile_Number:trimString(Mobile_Number),
            email_id:trimString(email_id),
            Passenger_name:trimString(Passenger_name),
            order_no:trimString(order_no),
            "operatorCode":global.operatorCode,
            "travelsId":global.travelsId     
        })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data.ticketSearchSheet);
            }else{
                onFailure();
                message.error(`Ticket search failed`)
            }
        }).catch((reason)=> {
            message.error(`Ticket search failed ${reason}`)
            onFailure();
            return false;
        }); 
  }

export const fetchTicketSearchV2 = (selectedInput, inputValue,isBooking, dateInput, onSuccess=()=>{},onFailure=()=>{}) => {
    var bodyData = {};
    bodyData[selectedInput] = trimString(inputValue);
    bodyData["isBooking"] = isBooking;
    bodyData["fromDate"] = dateInput['fromDate'];
    bodyData["toDate"] = dateInput["toDate"];
    bodyData["operatorCode"] = global.operatorCode;
    bodyData["travelsId"] = global.travelsId;
    fetch(TICKET_SEARCH_URL, {method: "POST",headers: {'Content-Type':'application/json'},body: JSON.stringify(bodyData)})
    .then(response => response.json())
    .then(data => {
        if(data){
            onSuccess(data.ticketSearchSheet);
        }else{
            onFailure();
            message.error(`Ticket search failed`)
        }
    }).catch((reason)=> {
        message.error(`Ticket search failed ${reason}`)
        onFailure();
        return false;
    });
}

export const fetchRescheduledTicketReport = (fromDate,toDate,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(RESCHEDULED_TICKET_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
          fromDate,toDate,
          "operatorCode":global.operatorCode,
          "travelsId":global.travelsId
          })
         })
        .then(response => response.json())
        .then(data => {
            if(isValidArray(data)&&data.Status==="SUCCESS"){
                onSuccess(data.Payload);
            }else{
                onFailure();
                message.error(`Fetching blocked tickets reports failed!`)
            }
        }).catch((reason)=> {
            message.error(`Fetching blocked tickets reports failed${reason}`)
            onFailure();
            return false;
        });
}


  export const fetchBlockedSheet = (fromDate,toDate,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(BLOCKED_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
          fromDate,toDate ,isBookingDate,
          "operatorCode":global.operatorCode,
          "travelsId":global.travelsId         
          })
         })
        .then(response => response.json())
        .then(data => {
            if(isValidArray(data)&&data.Status==="Success"){
                onSuccess(data.blockedSheet);
            }else{
                onFailure();
                message.error(`Fetching blocked tickets reports failed!`)
            }
        }).catch((reason)=> {
            message.error(`Fetching blocked tickets reports failed${reason}`)
            onFailure();
            return false;
        });
}


export const fetchPerformanceSheet = (tripIds,fromDate,toDate,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(PERFORMANCE_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"tripInfo" : {"tripIds": tripIds},
                              "fromDate":fromDate,
                               "toDate":toDate,
                               "isBookingDate":isBookingDate,
                               "operatorCode":global.operatorCode,
                               "travelsId":global.travelsId                               
                              })
         })
        .then(response => response.json())
        .then(data => {
            if(data){
                onSuccess(data);
            }else{
                onFailure();
                message.error(`Fetching blocked tickets reports failed!`)
            }
        }).catch((reason)=> {
            message.error(`Fetching blocked tickets reports failed${reason}`)
            onFailure();
            return false;
        });
}


export const fetchTransactionSheet = (fromDate,toDate,isAc,isBookingDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(TRANSACTION_SHEET_URL,{
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"fromDate" : fromDate,
                              "toDate": toDate,
                              "acType":isAc,
                              "isBookingDate": isBookingDate,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                             
                             })
    })
    .then(response => response.json())
    .then(data => {
        if(data.Status==="Success"){
            onSuccess(data.transactionSheet);
        }else{
            onFailure();
            message.error(`Fetching transaction reports failed!`)
        }
    }).catch((reason)=> {
        message.error(`Fetching transaction reports failed${reason}`)
        onFailure();
        return false;
    });
}


export const fetchAbandonedTransactionSheet = (selectedDate , onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(ABANDONED_TRANSACTION_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
                   date:selectedDate, 
                   "operatorCode":global.operatorCode,
                   "travelsId":global.travelsId             
                  })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status==='SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure();
                message.error(`Fetching abandoned sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching abandoned sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchSettlementSheet = ( fromDate , toDate , bookingType , partnerCode,scheduleIds,onSuccess=()=>{},onFailure=()=>{}) => {
    fetch(GET_SETTLEMENT_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            fromDate:fromDate, 
            toDate:toDate,bookingType,
            "operatorCode":global.operatorCode,
            "travelsId":global.travelsId,
            "partnerCode":partnerCode,
            "scheduleIds":scheduleIds             
            })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status==='SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure();
                message.error(`Fetching settlement sheet failed`)
            }
        }).catch((reason)=> {
            message.error(`Fetching settlement sheet failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const fetchDemandDataSheet = (fromDate, toDate, onSuccess=()=>{}, onFailure=()=>{}) => {
    fetch(GET_DEMAND_DATA_SHEET_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({
            fromDate:fromDate,
            toDate:toDate,
        })
    })
    .then(response => response.json())
    .then(data => {
        if(data.Status==='SUCCESS'){
            onSuccess(data.Payload);
        }else{
            onFailure();
            message.error(`Fetching demand data sheet failed`)
        }
    }).catch((reason)=> {
        message.error(`Fetching demand data sheet failed ${reason}`)
        onFailure();
        return false;
    });
}


export const getActiveBookings = (onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_ACTIVE_BOOKINGS_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"minutes": TIME_STAMP_FOR_ACTIVE_BOOKINGS,
                              "agentId":global.agentId,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            console.error(`Failed`, reason)
            message.error(`Fetching Active Bookings`)
            onFailure();
            return false;
        }); 
}


export const getServiceCount = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_SERVICE_COUNT_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({startDate,endDate,
                              "agentId":global.agentId,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            console.error(`Failed`, reason)
            message.error(`Fetching service count bookings failed`)
            onFailure();
            return false;
        }); 
}

export const fetchGstFilingTickets = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(GET_GST_FILING_TICKETS_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({"startDate" : startDate,
                              "endDate":endDate,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            message.error(`Fetching gst filing tickets failed ${reason}`)
            onFailure();
            return false;
        }); 
}

export const getSeatSharingReport = (fromDate,toDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(GET_SEAT_SHARING_REPORT_URL,{
        method: "POST",
        headers: {'Content-Type':'application/json'},
         body: JSON.stringify({"fromDate" : fromDate,
                              "toDate":toDate,
                              "operatorCode":global.operatorCode,
                              "travelsId":global.travelsId                           
                             })
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            message.error(`Fetching seat sharing failed ${reason}`);
            onFailure("Failed to get seat sharing report");
            return false;
        }); 
}

export const getWorkFlowAction = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_WORKFLOW_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json', 'api-key': '06ef5ee2-6f39-43b2-ad63-fb30c2145ba3'},
        body: JSON.stringify({startDate,endDate})
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            console.error(`Failed`, reason)
            message.error(`Fetching work flow data failed`)
            onFailure();
            return false;
        });
}

export const getTransactionCountAction = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_TRANSACTION_COUNT_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({startDate,endDate})
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            console.error(`Failed`, reason)
            message.error(`Fetching Transaction count failed`)
            onFailure();
            return false;
        });
}

export const getPrePaidTrasactionsSummary = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    var fetchB2B = postCall(FETCH_PRE_PAID_TRANSACTION_COUNT_REPORT_URL, {startDate, endDate});
    var fetchSRMS = postCall(FETCH_SRMS_PRE_PAID_TRANSACTION_COUNT_REPORT_URL, {startDate, endDate});
    Promise.all([fetchB2B, fetchSRMS]).then((promiseData) => {
        let b2bData = promiseData[0];
        let srmsData = promiseData[1];
        if(b2bData.status == "SUCCESS"&&srmsData.status == "SUCCESS"&&b2bData.payload.Status == "SUCCESS"&&srmsData.payload.Status == "SUCCESS") {
            let finalData = {};
            finalData['transactions'] = [...b2bData.payload.Payload.transactions,...srmsData.payload.Payload.transactions];
            onSuccess(finalData);
        }else {
            onFailure("Failed to fetch transaction summary.");
        }
    })
    .catch((reason)=> {
        console.log("reason", reason)
        onFailure("Failed to get transaction summary.");
        message.error(`Fetching Transaction count failed`)
    })
}

export const getTrackingReportDataAction = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return fetch(FETCH_TRACKING_DATA_REPORT_URL, {
        method: "POST",
        headers: {'Content-Type':'application/json'},
        body: JSON.stringify({startDate,endDate})
         })
        .then(response => response.json())
        .then(data => {
            if(data.Status === 'SUCCESS'){
                onSuccess(data.Payload);
            }else{
                onFailure(data.Message);
            }
            return true;
        }).catch((reason)=> {
            console.error(`Failed`, reason)
            message.error(`Fetching Tracking report failed`)
            onFailure();
            return false;
        });
}
