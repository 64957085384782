import React, { useState , useEffect } from 'react';
import { Form, message } from "antd";
import DynamicField from "./dynamic-trip-stops";
import { getCloneArray, isValid, isValidArray } from '../../../utils/utilities';
import {AddressForm} from "./address-form";
import { useDispatch, useSelector } from 'react-redux';
import { activeCitySelector, activeStopsSelector } from '../../../selector/trip-create.selector';
import { setActiveStop } from '../../../actions/trip-create.actions';
import { getStandardStopsForCities } from '../../../actions/address.actions';


export const TripStops = (props) => {

    const {
        addressLocation,
        tripScheduleId,
        getCityAddress,
        isModal,
        setModal,
        onCallBackNextButton,
        setEnableLocationPick,
        childRef
    } = props;


    const [editableAddress,setEditableAddress] = useState({});
    const [editableAddressIndex,setEditableAddressIndex] = useState(null);
    const [standardStops,setStandardStops] = useState([]);

    const dispatch = useDispatch();

    const [form] = Form.useForm();

    let activeStops = useSelector(activeStopsSelector);
    let activeCity = useSelector(activeCitySelector);

    useEffect(() => {
      if(!isModal){
        let clonedStops =[];
        if (isValidArray(activeStops)) {
           clonedStops = getCloneArray(activeStops);
        }
          clonedStops.push({});
          setEditableAddress({});
          setEditableAddressIndex(null);

        form.setFieldsValue({'fields':clonedStops});
        if(activeStops.length>0){
          onCallBackNextButton(false);
        }else{
          onCallBackNextButton(true);
        }
      }
    },[activeStops,isModal]);

    useEffect(()=>{
      getStandardStopsForCities([activeCity.city_name],(data)=>{
        setStandardStops(data.addressLocations);
      },()=>{
        setStandardStops([]);
        message.error(`Failed to get standard stops for ${activeCity.city_name}`);
      })
      setModal(false);
    },[activeCity])

  return (
    <div style= {{margin:10}}>
      <Form.Provider>
        <Form name="basicForm" form={form}>
          <DynamicField 
              form={form}
              addressLocation = {isValid(addressLocation)?addressLocation:[]}
              setModal = {setModal}
              isModal={isModal}
              setAddressFormData={(address,index)=>{
                setEditableAddress(address);
                setEditableAddressIndex(index);
                dispatch(setActiveStop(address,index));
              }}
              setEditableAddressIndex={setEditableAddressIndex}
              standardStops={standardStops}
          />
        </Form>
        {isModal&&
           <AddressForm
              isVisible = {isModal}
              setVisible = {setModal}
              cityName = {activeCity.city_name}
              tripScheduleId = {tripScheduleId}
              getCityAddress = {getCityAddress}
              setAddressFormData = {(address)=>{
                setEditableAddress(address);
                dispatch(setActiveStop(address,editableAddressIndex));
              }}
              addressFromData={editableAddress}
              setEnableLocationPick={setEnableLocationPick}
              childRef={childRef}
              editableAddressIndex={editableAddressIndex}
            />
        }
       
      </Form.Provider>
    </div>
  );
}