
import { 
    GET_SCHEDULES_WITH_TRIP_SHEET_PERMISSION_URL,
    INSERT_TRIP_SHEET_PERMISSION_URL,
    UPDATE_TRIP_SHEET_PERMISSION_URL,
    DELETE_TRIP_SHEET_PERMISSION_URL,
    BLUK_UPDATE_TRIP_SHEET_PERMISSION_URL
} from "./../utils/api-url";
import { postCall } from "../utils/network.util";


export const getSchedulesWithTripSheetPermission=(agentId,onSuccess=()=>{},onFailure=()=>{})=>{
  var fetched = postCall(GET_SCHEDULES_WITH_TRIP_SHEET_PERMISSION_URL, {agentId:agentId});
  fetched.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to create address");
    })
}

export const insertTripSheetPermission=(info,onSuccess=()=>{},onFailure=()=>{})=>{
  var inserted = postCall(INSERT_TRIP_SHEET_PERMISSION_URL, {data:info});
  inserted.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to create trip sheet permission");
    })
}

export const updateTripSheetPermission=(info,onSuccess=()=>{},onFailure=()=>{})=>{
  var updated = postCall(UPDATE_TRIP_SHEET_PERMISSION_URL, {data:info});
  updated.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to update trip sheet permission");
    })
}

export const deleteTripSheetPermission=(tripSheetId,onSuccess=()=>{},onFailure=()=>{})=>{
  var deleted = postCall(DELETE_TRIP_SHEET_PERMISSION_URL, {tripSheetId});
  deleted.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to delete trip sheet permission");
    })
}

export const bulkUpdateTripSheetPermissions=(scheduleIds,agentId,permission,onSuccess=()=>{},onFailure=()=>{})=>{
  var updated = postCall(BLUK_UPDATE_TRIP_SHEET_PERMISSION_URL, {scheduleIds,agentId,permission});
  updated.then((data) => {
      if(data.status === "SUCCESS" && data.payload.Status === "SUCCESS"){
          onSuccess(data.payload.Payload);
      }else{
        onFailure(data.payload.Message);
      }
    })
    .catch((reason)=> {
      onFailure("Failed to update trip sheet permission");
    })
}
