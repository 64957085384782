import {
    SET_TRIP_EXPENSE,
    RESET_ALL
} from '../actions/action-types';

const initialState = {
    tripExpense: null
}

export const tripExpenseReducer = (state = initialState,action) => {
    let newState = Object.assign({},state);
    switch (action.type) {

        case SET_TRIP_EXPENSE:
            newState["tripExpense"]= action.payload;
            return newState;

        case RESET_ALL :
            newState["tripExpense"] = null;
            return newState;

        default:
            return state;
    }
}