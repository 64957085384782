import {
    CREATE_TRIP_EXPENSE,
    GET_TRIP_EXPENSE,
    UPDATE_TRIP_EXPENSE
} from './action-types';
import {
    CREATE_TRIP_EXPENSE_URL,
    GET_TRIP_EXPENSE_URL,
    UPDATE_TRIP_EXPENSE_URL
} from '../utils/api-url';
import { postCall , getCall  } from "../utils/network.util";

export const getTripExpenseDetails=(tripId)=>{
    return {
        type: GET_TRIP_EXPENSE, 
        payload: {
            url: GET_TRIP_EXPENSE_URL,
            api: postCall,
            apiPayload:{
                tripId:tripId
            }
        }
    }
}

export const updateTripExpense= ({tripId,vehicleEmi,permits}) =>{
    return{
        type: UPDATE_TRIP_EXPENSE,
        payload: {
            url: UPDATE_TRIP_EXPENSE_URL,
            api: postCall,
            apiPayload:{
                tripId:tripId,
                vehicleEmi:vehicleEmi,
                permits:permits
            }
        }
    }
}

export const createTripExpense= ({tripId,vehicleEmi,permits}) => {
    return {
        type: CREATE_TRIP_EXPENSE,
        payload: {
            url: CREATE_TRIP_EXPENSE_URL,
            api: postCall,
            apiPayload:{
                tripId : tripId,
                vehicleEmi : vehicleEmi,
                permits : permits
            }
        }
    }
}