import { SEND_TRACKING_NOTIFICATION_BY_TICKET_ID_URL, SEND_TRACKING_NOTIFICATION_BY_TRIP_ID_URL } from "../../../utils/api-url";
import { SUCCESS_STATUS } from "../../../utils/constant";


export const sendTrackingNotificationByTripId=(tripId,onSuccess=()=>{

},onFailure=()=>{

})=>{

fetch(SEND_TRACKING_NOTIFICATION_BY_TRIP_ID_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
        "tripIds" : [tripId],
        "operatorCode" : global.operatorCode,
        "travelsId" : global.travelsId  
    })
    })
    .then(response => response.json())
    .then(data =>{
        if (data.Status== SUCCESS_STATUS) {
            onSuccess(data);
        }
        else {
            onFailure(data.Message);
        }
    }).catch((err)=>{
        console.log({err});
        onFailure("Failed to get ticket info!");
    })
}


export const sendTrackingNotificationByTicketId=(ticketId,onSuccess=()=>{

},onFailure=()=>{

})=>{

fetch(SEND_TRACKING_NOTIFICATION_BY_TICKET_ID_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
        "ticketId" : ticketId,
        "operatorCode" : global.operatorCode,
        "travelsId" : global.travelsId,
        "isForceSend" : true  
    })
    })
    .then(response => response.json())
    .then(data =>{
        if (data.Status== SUCCESS_STATUS) {
            onSuccess(data);
        }
        else {
            onFailure(data.Message);
        }
    }).catch((err)=>{
        console.log({err});
        onFailure("Failed to get ticket info!");
    })
}