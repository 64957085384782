
import { GET_STAFF_URL } from "../../../utils/api-url";
import { postCall } from "../../../utils/network.util";
import { message } from 'antd';
 
export const getStaffDetails=(onSuccess=()=>{},onFailure=()=>{},)=>{
    postCall(GET_STAFF_URL,{operatorCode:global.operatorCode})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload);
         }else{
          onFailure();
           if(findresponce.status === "SUCCESS"){
            message.error(findresponce.payload.Message);
           }else{
            message.error("Failed to create staff!");
           }
         }
      })
}