import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookedTicketObjectSelector } from './../../selector/home.selector';
import { Button, Modal,Row,Col } from 'antd';
import { isValid, isValidArray } from "../../utils/utilities";
import { TicketSummary } from './ticket-summary.component';
import { Printer } from "../custom-components/custom-component";
import { setBookedTicketObject } from './../../actions/home.action';
import { WhatsAppTextShare } from './../custom-components/custom-component';
import { getTimeStampInReadableFormat , getUIDateFormat , incrementDays} from "../../utils/date.utils";

export const getTextOfTicket =(ticketDetails)=>{
    // Note text encode and decode url https://www.url-encode-decode.com/
    let reportDate ='';
    if(isValidArray(ticketDetails.pickupDetails)&&isValid(ticketDetails.pickupDetails.timing)){
            let incrementDate = getUIDateFormat(ticketDetails.pickupDetails.travel_date);
            reportDate = reportDate +" ("+incrementDate + ")";
            reportDate=getTimeStampInReadableFormat(ticketDetails.pickupDetails.timing) + reportDate;
    }

    let returnTxt = `${ticketDetails.travels.name}%0aE-Ticket%0a‎`;
        returnTxt = returnTxt + `${ticketDetails.route.origin} - ${ticketDetails.route.destination}%0a`; 
        returnTxt = returnTxt + `DT: ${reportDate}%0a`;
        let pnr = ticketDetails.ticket.pnr;
        let phoneNumber = ticketDetails.ticket.phone_number;

        returnTxt = returnTxt + `PNR: ${pnr}%0a`;
        let travellers = ticketDetails.traveller;
        let passengerNames = null , seatNumbers = "Seat%23 ",count=0;
        if(isValidArray(travellers)&&travellers.length>0){
            travellers.forEach(element => {
                if(isValid(passengerNames)){
                    seatNumbers = seatNumbers + ","+`${element.seat_number}(${element.gender})`;
                    count=count+1;
                }else{
                    passengerNames = element.full_name;
                    seatNumbers = seatNumbers + `${element.seat_number}(${element.gender})`;
                }
            });
        }
        if(count>0){
            passengerNames=`${passengerNames}%2B${count}`;
        }
        returnTxt = returnTxt + `${passengerNames}%0a`;
        returnTxt = returnTxt + `${seatNumbers}%0a`;
        returnTxt = returnTxt + `Amount paid: Rs.${ticketDetails.ticket.amount_paid}%0a`;
        if(isValidArray(ticketDetails.pickupDetails)){
            returnTxt = returnTxt + `BP: ${ticketDetails.pickupDetails.location}/${ticketDetails.pickupDetails.phone_number}%0a`;
        }
        let ticketUrl = `${global.webSiteUrl}/manage-booking/ticket?pnr%3D${pnr}%26%26phone_number%3D${phoneNumber}`
        returnTxt = returnTxt + `${ticketUrl}%0a`;
        returnTxt = returnTxt + `-QwikBus`;
    return returnTxt;
}

export const OpenBookedTicketModal = () => {

    const [visible, setVisible] = useState(false);
    const ticketObject=useSelector(bookedTicketObjectSelector); 

    const dispatch=useDispatch()
    
    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
        dispatch(setBookedTicketObject(null));
    };

    useEffect(()=>{
        if(isValidArray(ticketObject)&&isValidArray(ticketObject.ticket)&&isValid(ticketObject.ticket.id)){
            showModal();
        }else{
            handleCancel();
        }

    },[ticketObject]);
    
    if(!isValidArray(ticketObject)){
        return null
    }
    
    return (
        <div>
            <Modal title="Ticket Details" 
            visible={visible} 
            width="90vw"
            footer={null}
            onCancel={handleCancel}
            >
                <TicketSummary 
                    ticketDetails={ticketObject}
                />
                <Row gutter={[16,16]}>
                    <Col>
                        <Printer 
                            renderComponent={()=>(
                                <TicketSummary 
                                    ticketDetails={ticketObject}
                                    isPrinter={true}
                                />
                            )}
                            zoomValue={1}
                        />
                    </Col>
                    <Col>
                        <Button onClick={handleCancel}>Close</Button>
                    </Col>
                    <Col>
                        <WhatsAppTextShare 
                            shareId="ticketSummary"
                            zoomValue={0.5}
                            buildText={getTextOfTicket(ticketObject)}
                            phoneNumber={ticketObject.ticket.phone_number}
                        />
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}