import React, { useEffect } from "react";
import { CloseCircleOutlined, PlusOutlined, EditOutlined  } from "@ant-design/icons";
import { Form, Divider, Button, Select , Space} from "antd";
import './../trip-create.style.scss'
import { MenuOutlined  } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isValidArray } from "../../../utils/utilities";
import { reOrderActiveStops, setActiveStop } from "../../../actions/trip-create.actions";
import { useDispatch } from "react-redux";
import { getStandardStops } from "../../../actions/address.actions";

const { Option , OptGroup } = Select;



function DynamicField(props) {
    const {
      addressLocation,
      setModal,
      isModal,
      setEditableAddressIndex,
      form,
      setAddressFormData,
      standardStops
    } = props;

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(getStandardStops());
    },[]);

    const createStop = "Create New Stop";


    const onSelect = (info,index) => {
      dispatch(setActiveStop(info,index));
    };

    const printAdressLocation = () => {
        let normalStops = [] ;
        addressLocation.map((address) => {
          normalStops.push(<Option key = {address.id} value= {address.name} data={address}>{address.name}</Option>);
        });
        return normalStops;
    }

    const printStandardStops = () =>{
      let standardStopsOptions =[];
      if(isValidArray(standardStops)&&standardStops.length>0){
        standardStops.map((address)=>{
          standardStopsOptions.push(<Option key = {address.id} value= {address.name} data={address}>{address.name}</Option>);
        });
      }
      return standardStopsOptions;
    }

    const fieldPrint = (index) => {
      let {
        fields
      } = form.getFieldsValue();
      let address = fields[index];
      address.markerText = index+1;
      address.draggable = true;
      setAddressFormData(address,index);
      setModal(true);
    }

    const onDragEnd = (result) => {
      // dropped outside the list
      if (!result.destination) {
        return;
      }  
      dispatch(reOrderActiveStops(result.source.index,result.destination.index));
    }

    return (
      <Form.List name="fields" initialValue = {[]}>
        {(fields, { add, remove }) => {
          return (              
            <DragDropContext 
              onDragEnd={onDragEnd}
            >
              <Droppable droppableId="droppable">
              {(provided,snapshot) => (
                <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={snapshot.isDragging?"":""}
                >
                  {fields.map((field, index) => (
                    <div className="stop-root">
                        <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index} type="TASK">
                          {(provided,snapshot) => (
                                <div 
                                key={field.key} 
                                className="address-select-stop"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                >
                                    <div className="stop-select-root">
                                      <div className="stop-number">
                                        {index+1}
                                      </div>
                                      <Form.Item
                                        name={[index, "name"]}
                                        rules={[{ required: true }]}
                                        className="address-select-form-item"
                                      >
                                          <Select 
                                            showSearch
                                            disabled={isModal}
                                            suffixIcon={<MenuOutlined />}
                                            placeholder="Select stop"
                                            style={{width:'180px'}}
                                            onSelect= {(value,info)=>{
                                              onSelect(info.data,index);
                                            }}
                                            dropdownRender={menu => (
                                              <>
                                                {menu}
                                                <Divider style={{ margin: '8px 0' }} />
                                                <Space align="center" style={{ padding: '0 8px 4px' }}>
                                                  <Button onClick={()=>{
                                                     setModal(true);
                                                     setEditableAddressIndex(index);
                                                  }} style={{ whiteSpace: 'nowrap' }}>
                                                    <PlusOutlined /> {createStop}
                                                  </Button>
                                                </Space>
                                              </>
                                            )}
                                            >
                                                {printAdressLocation()}
                                                <OptGroup label="Standard stops">{printStandardStops()}</OptGroup>
                                          </Select>
                                      </Form.Item>
                                    </div>
                                </div>
                          )}
                        </Draggable>
                        <div className="edit-delete-block">
                              <div className="dynamic-edit-button">
                                  <EditOutlined
                                    onClick={() => fieldPrint(index)}
                                  />
                              </div>
                              <div className="dynamic-delete-button">
                                  {fields.length > 1 ? (
                                    <CloseCircleOutlined
                                      onClick={() => {
                                      remove(field.name);
                                      onSelect(null,index)
                                    }}
                                    />
                                  ) : null}
                              </div>
                        </div>
                    </div>
                  ))}
                </div>
              )}
              </Droppable>
              {/* <Form.Item>
                <Button
                  className="marginTop"
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                >
                  <PlusOutlined /> Add Stops
                </Button>
              </Form.Item> */}
            </DragDropContext>
          );
        }}
      </Form.List>
    );
  }
  
  export default DynamicField;
