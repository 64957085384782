import React, {Fragment, useEffect, useState} from 'react';
import {generateSeatLayout} from "./seat-layout.module";
import Icon from '@ant-design/icons';
import SteeringWheel from './../../images/steering-wheel.svg'

export const SeatLayout = props => {
    const{
        seatGridLayout
    } ={...props}
    const{
        lowerSeatGrid,
        upperSeatGrid,
        col_count,
        maximumRowNumberByColumnForLower,
        maximumRowNumberByColumnForUpper,
        showFarePopOver
    } ={...seatGridLayout}

    const [lowerSeatList,updateLowerSeatList] = useState([]);
    const [upperSeatList,updateUpperSeatList] = useState([]);
    const [showLowerSeatLayout, updateShowLowerSeatLayout] = useState(false);
    const [showUpperSeatLayout, updateShowUpperSeatLayout] = useState(false);
    // const [showSeatLayout, updateShowSeatLayout] = useState(false);


    useEffect(() => {
        let seatArray = [];
        if(lowerSeatGrid !== undefined){
            if(lowerSeatGrid.length > 1){
                seatArray = generateSeatLayout(lowerSeatGrid,maximumRowNumberByColumnForLower,col_count);
                if(seatArray.length > 1){
                    updateLowerSeatList(seatArray);
                    updateShowLowerSeatLayout(true)
                }
            }
        }
        seatArray = [];
        if(upperSeatGrid !== undefined){
            if(upperSeatGrid.length > 1){
                seatArray = generateSeatLayout(upperSeatGrid,maximumRowNumberByColumnForUpper,col_count);
                if(seatArray.length > 1){
                    updateUpperSeatList(seatArray);
                    updateShowUpperSeatLayout(true);
                }
            }
        }
    },[seatGridLayout]);
    return (
        <Fragment>
        {
            showLowerSeatLayout &&
            <SeatContainer deck="lower deck" seats={lowerSeatList} showDeckName = {showUpperSeatLayout}/>
        }
        {
            showUpperSeatLayout &&
            <SeatContainer deck="upper deck" seats={upperSeatList} showDeckName = {showUpperSeatLayout}/>
        }
        </Fragment>
    );
};

SeatLayout.defaultProps = {

};

SeatLayout.propTypes = {

};

/* To render container */
export const SeatContainer = (props) => {
    const {
        seats,
        showDeckName
    } = {...props};

    const [showContainer, updateContainer] = useState(seats.length > 0 ? true : false);
    useEffect(() => {
        if(seats.length > 0){
           updateContainer(true);
         }
    },[seats]);

    const {deck} = props;

    return (
        <div className="layout-container">
            {showDeckName&&<div className="deck-info">
                {deck}
            </div>}
            {deck=="lower deck"?
            <div style= {{display: 'inline-block'}}>
                <div style= {{float: 'right', marginBottom: 10, marginRight: 4, marginTop: showDeckName?-20:0}}>
                    <Icon component={() => (<img src={SteeringWheel} style={{width:'23px',padding:'1px'}} />)} />
                </div>
            </div>:<div style= {{marginTop: 15}} />}
            {
            showContainer &&
            <div className="seats-container">
                {seats}
            </div>
            }
        </div>
    );
};

/* To render seats column */
export const SeatColumn = (props) => {
    return (
        <div className="seats-column">
            {props.seats}
        </div>
    );
};

/* To render empty column */
export const EmptyColumn = (props) => {
    return (
        <div className="seats-column-empty"/>
    );
};
