import React, { useContext, useEffect, useState } from  "react";
import { isValid, isValidArray, isValidString } from "../../utils/utilities";
import { Row , Col , Divider , Typography, Table} from 'antd';
import {getLocalTime,incrementDays,getAPIDateFormat, getDateTimeReadableFormat,subractTime,convertDateStringToDate , getLocalDate , getTimeStampInReadableFormat , getLocalDateAndTime , setTimeToDateObject, getUIDateFormat} from './../../utils/date.utils';
import './ticket-summary.style.scss';
import { roundNumber} from './../../utils/math.utils';
import { DeviceContext } from './../device-provider/device-provider';
import { getAddressComponent } from './ticket-summary.module';
import { getTicketById } from './../../actions/ticket.action';

let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

let gstNumber='',address='',phoneNumber='' , logoUrl=null,webSiteUrl=null;

const getGstNumber=(schedule,route,travels)=>{
    if(isValid(route.gst_number)){
        return route.gst_number;
    }else if(isValid(schedule.gst_number)){
        return schedule.gst_number;
    }else{
        return travels.gst_number
    }
}

const getListItem=(text)=>(
    <li>
        {text}
    </li>
)

const getCancellationPolicies=(ticketDetails)=>{
    let cancellationRules=[] , 
    cancellationPolicies=ticketDetails.cancellationPolicies,
    tripstartDate=ticketDetails.tripRoute.start_date,
    routestartTime=ticketDetails.route.start_time,
    penalty=null;
    // if(ticketDetails.route.is_next_day){
    //     tripstartDate = getAPIDateFormat(incrementDays(moment(tripstartDate),1));
    // }
    var tripDate = setTimeToDateObject(tripstartDate,routestartTime);

    if(isValidArray(cancellationPolicies)&&cancellationPolicies.length>0){
        cancellationPolicies.forEach((policy)=>{
            if(policy.deductionAmount === -1){
                cancellationRules.push(
                    getListItem(`Cancelling between ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.toValue))} and ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is non refundable.`)
                );
            }
            else{
                    if(policy.is_percentage === 1){
                        penalty = `${policy.deductionAmount}%`;
                    }
                    else{
                        penalty = `Rs ${policy.deductionAmount}`
                    }
                    if(policy.toValue === 0){
                        cancellationRules.push(
                            getListItem(`The penalty for cancelling before ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is ${penalty}.`)
                        );
                    }
                    else{
                        cancellationRules.push(
                            getListItem(`The penalty for cancelling between ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.toValue))} and ${getDateTimeReadableFormat(subractTime(tripDate,0,policy.fromValue))} is ${penalty}.`)
                        );
                    }
                }
        })
    }
    return <ul>
        {cancellationRules}
    </ul>
}

const columns=[
    {
        title:"Boarding Point",
        dataIndex:"boardingPoint",
        key:""
    },
    {
        title:"Address",
        dataIndex:"address",
        key:""
    },
    {
        title:"Landmark",
        dataIndex:"landmark",
        key:""
    },
    {
        title:"Contact",
        dataIndex:"phone_number",
        key:""
    }
];

const getBoardingPointInfo=(ticketDetails)=>{
    let {
        pickupDetails,
        agent
    } = ticketDetails;

    let phone = "-"
    if (isValidArray(agent)) {
        phone = agent.show_phone?agent.phone_number:'-';
    }

    if(isValidArray(pickupDetails)){
        if (phone=='-') {
            phone = pickupDetails.phone_number
        }
        return [{
            "boardingPoint":pickupDetails.location,
            "address":pickupDetails.address,
            "landmark":pickupDetails.landmark,
            "phone_number":phone
        }]
    }else{
        return [{
            "boardingPoint":"",
            "address":"",
            "landmark":"",
            "phone_number":phone
        }]
    }

}

const { Text, Title} = Typography;

const getRefundAmount=(travellers)=>{
    let refundAmount=0 , isCancelledTravellerPresent=false;
    travellers.forEach(element => {
        if(element.traveller_status === "Cancelled"){
            isCancelledTravellerPresent=true;
            refundAmount = refundAmount+(element.amount_paid-element.cancellation_penalty);
        }
    });
    return {refundAmount,isCancelledTravellerPresent}
}

const RenderTravellers=({travellers,ticketDetails})=>{

    const {
        isDesktop
    } = useContext(DeviceContext);

    let dropTime='';
    if(isValidArray(ticketDetails.dropDetails)){
        dropTime = getTimeStampInReadableFormat(ticketDetails.dropDetails.timing);
        if(ticketDetails.route.destination_day>0 || ticketDetails.dropDetails.destination_day>0){
            let destinationDayNo=0;
            if(ticketDetails.route.destination_day>ticketDetails.route.origin_day){
                destinationDayNo=ticketDetails.route.destination_day-ticketDetails.route.origin_day;
            }
            let dayValue = destinationDayNo + ticketDetails.dropDetails.destination_day;
            let incrementDate = getUIDateFormat(incrementDays(ticketDetails.tripRoute.start_date,dayValue));
            dropTime = dropTime +" ("+incrementDate + ")";
        }
    }

    if(isDesktop){
        const travellerColumns=[
            {
                title:"Seat no",
                key:"seat_number",
                dataIndex:"seat_number"
            },
            {
                title:"Passenger",
                key:"full_name",
                dataIndex:"full_name"
            },
            {
                title:"Sex",
                key:"gender",
                dataIndex:"gender"
            },
            {
                title:"Age",
                key:"age",
                dataIndex:"age"
            },
            {
                title:"Boarding time",
                key:"pickup_location",
                render : (text, record, index) => {
                    if(isValidArray(record.pickup_location)&&isValid(record.pickup_location.timing)){
                        let reportDate ='';
                        if(record.pickup_location.origin_day>0){
                            let incrementDate = getUIDateFormat(incrementDays(ticketDetails.tripRoute.start_date,record.pickup_location.origin_day));
                            reportDate = reportDate +" ("+incrementDate + ")";
                        }
                        return  getTimeStampInReadableFormat(record.pickup_location.timing) + reportDate;
                    }else{
                        return ''
                    }
                }
            },
            {
                title:"Boarding point",
                key:"location",
                render : (text, record, index) => {
                    if(isValidArray(record.pickup_location)&&isValid(record.pickup_location.location)){
                        return  record.pickup_location.location
                    }else{
                        return ''
                    }
                }
            },
            {
                title:"Drop time",
                key:"location",
                render : (text, record, index) => {
                    return dropTime
                }
            },
            {
                title:"Fare",
                key:"fare",
                dataIndex:"fare"
            },
            {
                title:"Status",
                key:"status",
                dataIndex:"traveller_status",
                render : (text, record, index) => {
                    if(text === "Cancelled"){
                        return <div style={{color:'red'}}>{text}</div>
                    }else{
                        return <div>{text}</div>
                    }
                }
            }
        ];
            return(
                    <Table 
                        className="passenger-table"
                        columns={travellerColumns}
                        dataSource={travellers}
                        pagination={false}
                    />
            )

    }

    if(isValidArray(travellers)&&travellers.length>0){

        const renderTravellers=()=>{
            let travellersComponent=[];
            for (let index = 0; index < travellers.length; index++) {
                const element = travellers[index];
                let reportTime = getTimeStampInReadableFormat(element.pickup_location.timing);
                if(element.pickup_location.origin_day>0){
                    let incrementDate = getAPIDateFormat(incrementDays(ticketDetails.tripRoute.start_date,element.pickup_location.origin_day));
                    reportTime = reportTime +" ("+incrementDate + ")";
                }
                travellersComponent.push(
                 <div className="traveller-block">
                     <Row >
                            <Col>
                                <Text style={{minWidth:'300px'}}>
                                    <Text strong> Seat No:</Text>{element.seat_number}
                                </Text>
                            </Col>
                            <Col>
                            
                            </Col>
                     </Row>
                     <Row>
                            <Col span={12}>
                                <Text>
                                      <Text strong> Passenger Name:</Text>{element.full_name}
                                </Text>
                            </Col>
                            <Col span={12}>
                                <Row gutter={16}>
                                    <Col>
                                        <Text>
                                            <Text strong> Sex:</Text>{element.gender}
                                        </Text>
                                    </Col>
                                    <Col>
                                        <Text>
                                            <Text strong> Age:</Text>{element.age}
                                        </Text>
                                    </Col>
                                </Row>
                            </Col>
                     </Row>
                     <Row >
                            <Col span={12}>
                                <Text>
                                      <Text strong> Boarding Time:</Text>{reportTime}
                                      
                                </Text>
                            </Col>
                            <Col span={12}>
                                <Text>
                                    <Text strong> Boarding Point:</Text>{element.pickup_location.location}
                                </Text>
                            </Col>
                     </Row>
                     <Row >
                            <Col span={12}>
                                <Text>
                                      <Text strong> Drop Time:</Text>{dropTime}
                                </Text>
                            </Col>
                            <Col span={12}>
                                <Text>
                                    <Text strong> Fare:</Text>{element.fare}
                                </Text>
                            </Col>
                     </Row>
                     <Row >
                            <Col span={12}>
                                <Text>
                                      <Text strong> Status:</Text><Text style={element.traveller_status=== "Cancelled"?{color:'red'}:{}}>{element.traveller_status}</Text>
                                </Text>
                            </Col>
                     </Row>
                 </div>   
                )
            }
            return travellersComponent
        }
    
        return(
            <div>
                {renderTravellers()}
            </div>
        )
    }else{
        return null;
    }
}

export const TicketSummary = ({ticketId,ticketDetails,isPrinter=false}) => {

    const [ticketInfo,setTicketInfo] = useState(undefined);
    const [loading,setLoading] = useState(false);

    const onSuccess =(data)=>{
        setTicketInfo(data);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    useEffect(()=>{
        if(isValidArray(ticketDetails)&&isValidArray(ticketDetails.ticket)&&isValid(ticketDetails.ticket.id)){
            setTicketInfo(ticketDetails);
        }else{
            if(isValid(ticketId)){
                setLoading(true);
                getTicketById(ticketId,onSuccess,onFailure)
            }
        }
    },[ticketId]);
    

    if(!loading&&isValidArray(ticketInfo)&&isValidArray(ticketInfo.ticket)&&isValid(ticketInfo.ticket.id)){
        let {
            schedule,
            ticket,
            trip,
            agent,
            traveller,
            travels,
            route,
            tripRoute,
            bus,
            pickupDetails
        } = ticketInfo;

        gstNumber=getGstNumber(schedule,route,travels);
        address=travels.address;
        phoneNumber=travels.phone_number;
        logoUrl=travels.logo_url;
        webSiteUrl=travels.website_url;

        const discountedFare = ()=>{
            if(isValid(ticket.discounted_fare)){
                return ticket.discounted_fare;
            }else{
                let discountedFare= ticket.fare;
                if(isValid(ticket.direct_discount)){
                    discountedFare=discountedFare-ticket.direct_discount;
                }
                return roundNumber(discountedFare);
            }   
        }

        let isDiscountExits=isValid(ticket.direct_discount)&&ticket.direct_discount>0?true:false;
        let {isCancelledTravellerPresent , refundAmount} = getRefundAmount(traveller);
        let bookingTime = `${getUIDateFormat(ticket.booking_time)},\xa0\xa0${getLocalTime(ticket.booking_time)}`;

        return (
            <div id="ticketSummary">
                    <Row  justify="center">
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            {isValidString(logoUrl)?
                            <img src={logoUrl} alt={global.operatorName} 
                                className="logo-in-ticket"
                            width="418px" height="58px"
                            />
                            :
                            <Title level={3}>{global.operatorName}</Title>
                            }
                        </Col> 
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Row  justify="center" style= {{marginLeft:10}}>
                                <Col>
                                    <Row>
                                        {getAddressComponent(address)}
                                    </Row>
                                    <Row>
                                        <Text className={"nextLineWordBreak"} strong>{phoneNumber}</Text>
                                    </Row>
                                    <Row>
                                        <Text strong>GST-No#{gstNumber}</Text>
                                    </Row>
                                    <div style= {{borderBottom: "2px solid rgb(212, 212, 212)"}} />
                                    <Row>
                                       <Text strong> Booked By {ticketInfo.agent.name}</Text>
                                    </Row>
                                    <Row>
                                        <Text strong>
                                            {
                                                ticketInfo.agentAddress!==''&&
                                                ticketInfo.agentAddress.address
                                            }
                                        </Text>
                                    </Row>
                                    <Row>
                                        <Text strong>
                                            {
                                                ticketInfo.agentAddress!==''&&
                                                ticketInfo.agentAddress.landmark
                                            }
                                        </Text>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div style= {{position: 'relative', marginTop:-10}}>
                        <div className= "ticket-logo-divider" />
                    </div>
                    <Row  >
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Bus Name:</Text>{bus.description}
                            </Text> 
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Transaction ID:</Text>{ticket.pnr}
                            </Text> 
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Form:</Text>{route.origin}
                            </Text> 
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>To:</Text>{route.destination}
                            </Text> 
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            {isValid(tripRoute.start_date)&&
                            <Text>
                                <Text strong>Travel Date:</Text>
                                {getLocalDate(convertDateStringToDate(tripRoute.start_date.split('T')[0]))}
                            </Text>
                            } 
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Phone Number:</Text>{ticket.phone_number}
                            </Text> 
                        </Col>
                    </Row>
                    <Row >
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Booked Date/Time(IST):</Text>
                                {bookingTime}
                            </Text> 
                        </Col>
                        <Col xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Agent Name:</Text>{agent.name}
                            </Text> 
                        </Col>
                    </Row>
                    <Row >
                        <Col  xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Agent Phone No:</Text>{agent.phone_number}
                            </Text> 
                        </Col>
                        <Col  xs={isPrinter?12:xs} sm={isPrinter?12:sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <Text>
                                <Text strong>Trip Description:</Text>{schedule.description}
                            </Text> 
                        </Col>
                    </Row>
                    <RenderTravellers 
                        travellers={traveller}
                        ticketDetails={ticketInfo}
                    />
                    <Row>
                        <Col>
                            <Text 
                            // delete={isDiscountExits?true:false}
                            >
                                <Text strong style ={{marginRight:2}}>Trip Fare:</Text>{roundNumber(ticket.fare)}
                            </Text> 
                        </Col>
                        {isDiscountExits&&
                            <Col>
                                <Text style={{marginLeft:10}}>
                                    <Text strong style ={{marginRight:2}}> Discount:</Text>{roundNumber(ticket.direct_discount)}
                                </Text>
                            </Col>
                        }
                        {isDiscountExits&&
                            <Col>
                                <Text style={{marginLeft:10}}>
                                    <Text strong style ={{marginRight:2}}> Discounted trip fare:</Text>{discountedFare()}
                                </Text>
                            </Col>
                        }
                    </Row>
                    
                    <Row>
                        <Col>
                            <Text style={{minWidth:'300px'}}>
                                <Text strong style ={{marginRight:2}}>SGST :</Text>{roundNumber(ticket.sgst)}
                            </Text>
                        </Col>
                        <Col>
                            <Text style={{minWidth:'300px', marginLeft:10}}>
                                <Text strong style ={{marginRight:2}}>CGST :</Text>{roundNumber(ticket.cgst)}
                            </Text> 
                        </Col>
                    </Row>
                    <Row >
                        <Col>
                            <Text style={{minWidth:'300px'}}>
                                <Text strong style ={{marginRight:2}}>Amount Paid:</Text>{roundNumber(ticket.amount_paid)}
                            </Text> 
                        </Col>
                    </Row>
                    {isCancelledTravellerPresent&&
                    <>
                        <Row >
                            <Col>
                                <Text style={{minWidth:'300px'}}>
                                    <Text strong style ={{marginRight:2}}>Can.Penalty(Rs.):</Text>{roundNumber(ticket.cancellation_penalty)}
                                </Text> 
                            </Col>
                        </Row>
                        <Row >
                            <Col>
                                <Text style={{minWidth:'300px'}}>
                                    <Text strong style ={{marginRight:2}}>Refund(Rs.):</Text>{roundNumber(refundAmount)}
                                </Text> 
                            </Col>
                        </Row>
                    </>
                    }
                    <Table  
                        className="boarding-points-table"
                        columns={columns}
                        dataSource={getBoardingPointInfo(ticketInfo)} 
                        pagination={false}       
                    />
                    <Row>
                        <Col>
                            <Text strong>Terms and conditions:</Text>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={{fontSize:12}}>
                            <ol>
                                <li>
                                    Passengers are expected to report at boarding point at least 15 minutes before departure time, failing which the tickets will be cancelled automatically.
                                </li>
                                <li>
                                    Management will not responsible for loss, theft or damage to the luggage or other
                                    belongings of the passengers.
                                </li>
                                <li>
                                    In case of cancellation, there will be a penalty charged to the customer based on the
                                    cancellation time as described below.
                                {getCancellationPolicies(ticketInfo)}
                                </li>
                                <li>
                                    {`Children aged 6 years (>=6 years) or children with height greater than or equal to 130 cms will be charged for full ticket.`}
                                </li>
                                <li>
                                    Luggages above 25 KG will be chargeable.
                                </li>
                                <li>
                                    For additional terms and conditions, please visit {webSiteUrl+"/tandc"}
                                </li>
                            </ol>
                        </Col>
                    </Row>
            </div>
        )
    }else{
        return (<div></div>)
    }
}