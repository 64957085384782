import { GET_VECHICLES_URL ,UPDATE_TRIPS_URL, UPDATE_TRIP_VECHICLE_PHONE_NO_URL } from './../../utils/api-url';
import { getCall, postCall } from '../../utils/network.util';
import { message } from 'antd';


export const getVechicles=(onSuccess)=>{
    var url=GET_VECHICLES_URL+"?travelsId="+global.travelsId;
    getCall(url)
    .then((findresponce)=>
    {
      if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
        onSuccess(findresponce.payload.payload.vechicleInfo);
      }else{
        onSuccess([]);
      }
    })
  }

  export const updatePhoneNoandVechicleNo=(trip,onSuccess,onFailure)=>{
      postCall(UPDATE_TRIP_VECHICLE_PHONE_NO_URL,{"trip" :trip})
      .then((findresponce) =>
        {
          if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
             message.success(findresponce.payload.Message);
             onSuccess();
           }else{
            onFailure();
             if(findresponce.status === "SUCCESS"){
              message.error(findresponce.payload.Message);
             }else{
              message.error("Failed to update!");
             }
           }
        })
  }