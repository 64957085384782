import { COPY_TRAVELLER_INFO_TO_SEAT_URL } from './../../utils/api-url';
import { postCall } from "./../../utils/network.util";


 export const handleConfirmSeats=(ticketId,travellerId,tripRouteId,seatNumber,cutRouteEnabled,onSuccess=()=>{},onFailure=()=>{})=>{
      var confirmed = postCall(COPY_TRAVELLER_INFO_TO_SEAT_URL, {
        ticketId,
        travellerId,
        tripRouteId,
        seatNumber,
        cutRouteEnabled,
        updatedBy: global.userName 
      });
      confirmed.then((data) => {
        if(data){
          onSuccess();
        }else{
          onFailure();
        }
      })
      .catch((reason)=> {
        onFailure();
      })
  }