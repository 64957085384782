import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Table} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchDemandDataSheet } from './../../actions/reports.action';
// import { Printer } from './../../components/custom-components/custom-component';
import { isValid, isValidArray } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime,getLocalDate } from './../../utils/date.utils';
import { getRangeOptions } from './reports.module';
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const demandDataColumns=[
    // {
    //     title:"Access Time",
    //     key:"access_time",
    //     dataIndex:'access_time',
    //     render: (text)=>{
    //         return (
    //             <div>{getLocalDateAndTime(text)}</div>
    //         )
    //     }
    // },
    {
        title:"Operator Code",
        key:"operator_code",
        dataIndex:'operator_code'
    },
    {
        title:"Date of Journey",
        key:"start_date",
        dataIndex:"start_date",
        render: (text)=>{
            return (
                <div>{getLocalDate(text)}</div>
            )
        }
    },
    {
        title: "Agent Name",
        key: "user_name",
        dataIndex: "user_name"
    },
    {
        title:"Origin",
        key:"origin",
        dataIndex:"origin"
    },
    {
        title:"Destination",
        key:"destination",
        dataIndex:"destination"
    },
    {
        title:"AC/ Non-AC",
        key:"isAc",
        dataIndex:"isAc",
        render:(text)=>{
            if(isValid(text)){
                return(
                    <div>AC</div>
                )
            }else{
                if (text!==null){
                return (
                    <div>Non-AC</div>
                )
                }
                else {
                    return null
                }
            }
        }
    },
    {
        title:"Number of Seats",
        key:"number_of_seats",
        dataIndex:"number_of_seats"
    },
    {
        title:"Coach Type",
        key:"coach_type",
        dataIndex : "coach_type"
    },
    {
        title:"Seat Type",
        key:"seat_type",
        dataIndex:"seat_type"
    },
    {
        title: "Is Success",
        key: "is_success",
        dataIndex: "is_success",
        render:(text)=>{
            if(isValid(text)){
                return(
                    <div>Yes</div>
                )
            }else{
                return (
                    <div>No</div>
                )
            }
        }
    },
    {
        title: "Trip Route Id",
        key: "trip_route_id",
        dataIndex: "trip_route_id"
    },
    {
        title: "Count",
        key: "count",
        dataIndex: "count"
    }
];

const { Text } =Typography;

const { RangePicker } = DatePicker;

let allowedMaxDaysRange=7;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const RenderReport=({demandData,formValues,pagination})=>{

    let {
        dateRange
    }=formValues;
    if((isValidArray(demandData))&&isValidArray(dateRange)){
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Demand Data report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text> 
                                </Col>
                            </Row>
                            <Row gutter={[16,16]} className="marginTop">
                                <Col>
                                    <Table
                                        className="bordered-table"
                                        columns={demandDataColumns}
                                        dataSource={demandData}
                                        pagination={{pagination,pageSize:100}}
                                    />
                                </Col>
                            </Row>
                        </div>
            )
    }else{
        return null
    }
}

const generateJsonToExport=(filters,demandData)=>{

    let reportsDemandDataXmlData=[];
    if(isValidArray(demandData)&&demandData.length>0){
        demandData.forEach(element => {
            // let accessTime=getLocalDateAndTime(element.access_time);
            let startDate =getLocalDate(element.start_date);
            let isAc = isValid(element.isAc)?'AC':'Non-AC';
            reportsDemandDataXmlData.push([
                element.operator_code,startDate,element.user_name,element.origin,
                element.destination,isAc,element.number_of_seats,
                element.coach_type, element.seat_type , element.count
            ])
        });
    }

    let generatedTime = getLocalDateAndTime(new Date());
    const multiDataSet = [
        {
            columns: ["Report","From Date", "To Date" , "Generated day/time"],
            data: [[
                "Demand Data Report",filters.fromDate,filters.toDate,generatedTime
            ]]
        },
        {
            xSteps: 0, // Will start putting cell with 1 empty cell on left most
            ySteps: 4, //will put space of 5 rows,
            columns: [
                "Operator Code" ,
                "Date of Journey" ,"Agent Name",
                "Origin","Destination" ,"AC/ Non-AC",
                "Number of Seats","Coach Type","Seat Type","Count"],
            // columns: [
            //     {value: "Access Time", widthCh: 20 }, {value:"Operator Code",widthCh: 20 } ,
            //     {value: "Date of Journey", widthCh: 20 } ,
            //     {value:"Origin",widthCh: 20 },{value: "Destination", widthCh: 20 } ,{value: "AC/ Non-AC",widthCh: 20 },
            //     {value: "Number of Seats", widthCh: 20 },{value: "Coach Type",widthCh: 20 }, {value: "Seat Type", widthCh: 20 }],
            data: reportsDemandDataXmlData
        }
    ];


    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"demand-data-report"}>
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}


export const DemandDataReport = () => {


    const [demandData,setDemandData]=useState(undefined);
    const [showReport,setShowReport]=useState(false);
    const [filters,setFilters]=useState({
        fromDate:undefined,
        toDate:undefined,
    });

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        let {
            demandDataSheet
        }= data;
        setLoading(false);
        setShowReport(true);
        setDemandData(demandDataSheet);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setShowReport(false);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        filters.fromDate=fromDate;
        filters.toDate=toDate;
        setFilters({...filters});
        setLoading(true);
        fetchDemandDataSheet(fromDate,toDate,onSuccess,onFailure);
    }

    const [form] = Form.useForm();
    
    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                               rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <div>
                        <Row gutter={16}>
                            <Col>
                            {
                                generateJsonToExport(filters,demandData)
                            }
                            </Col>
                            {/* <Col>
                                <Printer 
                                    showComponent={false}
                                    renderComponent={()=>{
                                        return (
                                            <RenderReport 
                                                demandData={demandData}
                                                formValues={form.getFieldsValue()}
                                                pagination={false}
                                            />
                                        )
                                    }} 
                                    zoomValue={0.5}
                                    />
                            </Col> */}
                        </Row>
                    </div>
                    :
                    null
                }
                <RenderReport 
                    demandData={demandData}
                    formValues={form.getFieldsValue()}
                    pagination={true}
                 />
            </div>
    )
}