import React , { useEffect} from 'react';
import { Table , Select , TimePicker } from 'antd';
import { getCitiesFromRoutes } from './change-routes-timings.module';
import moment from 'moment';
import { get24HoursTime , getFormattedTime } from './../../utils/date.utils';
import { isValidNumber } from '../../utils/utilities';

export const ScreenThree = (props) => {

const {
    isSelectRoutes,
    isEditTimings,
    rowKeys,
    setRowKeys,
    cities,
    setCities,
    setDisableAdjustBtn
} = props;

const {Option} = Select;


let columns =[
    {
        title: 'City',
        dataIndex: 'city',
        key: 'city'
    },
    {
        title: 'Timing',
        dataIndex: 'newTiming',
        key: 'newTiming',
        render:(text,row,index)=>{
            let city = row.city;
            if(isEditTimings&&rowKeys.indexOf(city)!=-1){
                return  <TimePicker 
                            className='remove-clear-icon'  
                            popupClassName={"time-picker-to-not-display-footer"}
                            style = {{width:100}}
                            onSelect={(value)=>{
                                cities[index].newTiming = get24HoursTime(value);
                                setCities([...cities]);
                            }}
                            use12Hours 
                            format='h:mm A'  
                            minuteStep={5} 
                            value={moment(text,'HH:mm')}
                            />
            }else{
                return getFormattedTime(text)
            }
          }
    },
    {
        title:"Day",
        dataIndex:'dayNo',
        key: 'dayNo',
        render:(text,row,index)=>{
            if(isEditTimings&&rowKeys.indexOf(row.city)!=-1){
                return   (<Select
                            showSearch
                            // disabled={disableForm}
                            value={text} 
                            placeholder=""
                            style={{ width: 100 }}
                            defaultValue={0}
                            onChange={(value)=>{
                            cities[index].dayNo=value;
                            setCities([...cities]);
                        }}
                        >
                        <Option  value={0}>Day 1</Option>{/*same day*/}
                        <Option  value={1}>Day 2</Option>{/*next day*/}
                        <Option  value={2}>Day 3</Option>
                        <Option  value={3}>Day 4</Option>
                        </Select>)                  
            }else{
                if(isValidNumber(text)){
                    return `Day ${text+1}`
                }
                return null
            }
          }
    }
];


const rowSelection = {
    selectedRowKeys: rowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
     setRowKeys(selectedRowKeys);
     if (selectedRowKeys.length>0) {
        setDisableAdjustBtn(true)
     }
     else {
        setDisableAdjustBtn(false)
     }
    },
    getCheckboxProps: record => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };


  return (
      <div style={{paddingBottom:'10px'}}>
            {/* <Row>
                New Starting Time
            </Row> */}
            <Table 
            rowSelection={isSelectRoutes?rowSelection:null}
            rowKey={record => record.city}
            dataSource={cities} 
            columns={columns}
            pagination={false}
            />
      </div>
  );
};
