import {  Row , Form, InputNumber, message } from "antd";
import React, { } from "react";
import { isValid, isValidArray, isValidNumber } from "../../utils/utilities";
import { CustomModal } from "../custom-components/custom-component";
import { 
    overRidePenality
} from './../../actions/ticket.action';

export const OverRidePenality = (props) => {

    const {
        travellIdsToOverRidePenality,
        getTicketDetails
    } = props;

    const [form] = Form.useForm();

    const handleSubmit =  (e) => {
        return form.validateFields()
        .then(values => {
            let {
                penality
            }=values;
            return penality;
        }).catch((err)=>{
          return null;
        })
    }

    const onSuccess=()=>{
        message.success("Ticket penalty override successfully!")
        getTicketDetails();
    }

    const onFailure=()=>{

    }

    if(isValidArray(travellIdsToOverRidePenality)&&travellIdsToOverRidePenality.length>0){
        return (
            <div>
                <CustomModal 
                    buttonName="Override Penalty"
                    modalTitle="Override Penalty"
                    okButtonName="Yes"
                    exitButtonName="No"
                    onButtonClick={()=>{
                        return true;
                    }}
                    onOk={async (e)=>{
                        let penality = await handleSubmit();
                        return overRidePenality(travellIdsToOverRidePenality,penality,onSuccess,onFailure)
                           
                    }}
                    children={<div>
                                    <Row>
                                        Enter the Cancellation Penalty value for each selected seat
                                    </Row>
                                    <Row>
                                        <Form
                                        form={form}
                                        >
                                            <Form.Item
                                            label=""
                                            name='penality'
                                            rules={[{ required: true, message: 'Please enter Penalty' }]}
                                            >
                                                <InputNumber placeholder="Penalty" />
                                            </Form.Item>
                                        </Form>
                                    </Row>
                                </div>}
                />
            </div>
        )
    }
    return null;
}