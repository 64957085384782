import React from "react";
import { Button } from "antd";
import { checkActionAllowed } from "../../utils/utilities";
import { ACTION_GENERATE_PAYMENT_LINK_ALLOWED } from './../../utils/constant';
import { getPaymentLink } from "./copy-payment-link.module";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";
import { blockedSeatTicketIdSelector } from "../../selector/passenger-form.selector";

export const CopyPaymentLink = (props) => {


    const dispatch = useDispatch();

    const ticketId = useSelector(blockedSeatTicketIdSelector)

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const handleClick=()=>{
        setLoading(true);
        getPaymentLink(ticketId,(url)=>{
            console.log({url});
            navigator.clipboard.writeText(url);
            setLoading(false);
        },()=>{
            setLoading(false);
        });
    }
  
    
    if(checkActionAllowed(ACTION_GENERATE_PAYMENT_LINK_ALLOWED)){
        return (
            <div>
                <Button onClick={handleClick}>
                    Copy payment link
                </Button>
            </div>
        )
    }else{
        return null
    }
}