import moment from 'moment';

export const getRangeOptions=(allowedMaxDaysRange)=>{
    let rangeOptions = {
        Today: [moment().utcOffset("+05:30"), moment().utcOffset("+05:30")],
        Yesterday: [moment().utcOffset("+05:30").subtract(1, 'days'), moment().utcOffset("+05:30").subtract(1, 'days')],
        Tomorrow: [moment().utcOffset("+05:30").add(1, 'days'), moment().utcOffset("+05:30").add(1, 'days')],
    }
    if(allowedMaxDaysRange >= 7){
        rangeOptions["This week"] = [moment().utcOffset("+05:30").startOf('week').add(1, 'days'),moment().utcOffset("+05:30").endOf('week').add(1, 'days')];
        rangeOptions["Last week"] = [moment().utcOffset("+05:30").startOf('week').subtract(1,'days').startOf('week').add(1, 'days'),moment().utcOffset("+05:30").startOf('week')]
    }
    if(allowedMaxDaysRange >= 31){
        rangeOptions["This Month"] = [moment().utcOffset("+05:30").startOf('month'), moment().utcOffset("+05:30").endOf('month')];
        rangeOptions["Last Month"] = [moment().utcOffset("+05:30").subtract(1, 'months').date(1), moment().utcOffset("+05:30").startOf('month').subtract(1, 'days')];
        rangeOptions["Next Month"] = [moment().utcOffset("+05:30").add(1, 'months').date(1), moment().utcOffset("+05:30").add(1, 'months').endOf('month')];
    }
    if(allowedMaxDaysRange >= 180){
        rangeOptions["Last 6 Months"] = [moment().utcOffset("+05:30").subtract(7, 'months').date(1), moment().utcOffset("+05:30").startOf('month').subtract(1, 'days')]
    }
    if(allowedMaxDaysRange >= 360){
        rangeOptions["Last Year"] = [moment().utcOffset("+05:30").startOf('year').subtract(1, 'days').startOf('year'), moment().utcOffset("+05:30").startOf('year').subtract(1, 'days').endOf('year')];
    }
    return rangeOptions;
}

export const getDefaultPageSize = 50;

export const getPaginationDetails=(pagination,setPage, defPageSize = 50, setPageSize)=>{
    if(pagination){
        return {
            onChange(current, pageSize) {
                setPage(current);
                setPageSize(pageSize)
            },
            defaultPageSize: defPageSize
        }
    }else{
        return false;
    }
}

// function range(start, end) {
//     const result = [];
//     for (let i = start; i < end; i++) {
//       result.push(i);
//     }
//     return result;
// }