export const selectedSeatsSelector = state =>state.passengerFormReducer.selectedSeats;

export const passengerInfoSelector=state=>{
    return{
        emailId:state.passengerFormReducer.emailId,
        phoneNumber:state.passengerFormReducer.phoneNumber,
        boardingId:state.passengerFormReducer.boardingId,
        dropId:state.passengerFormReducer.dropId,
        selectedSeats:state.passengerFormReducer.selectedSeats,
        blockedSeatTicketId:state.passengerFormReducer.blockedSeatTicketId,
        timeOfRelease:state.passengerFormReducer.timeOfRelease
    }
};

export const blockedSeatTicketIdSelector = state => state.passengerFormReducer.blockedSeatTicketId;