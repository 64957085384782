import {Button } from 'antd';
import React, {  }  from 'react';
import { loadingAction } from '../../actions/loading-actions';
import { useDispatch } from 'react-redux';
import { updateDynamicFareForTrip } from './edit-fare.module';

export const DynamicPriceBtn = (props) => {


    const {
        scheduleId,
        travelDates=[],
        tripDynamicPriceEnabled
    } = props;

    const dispatch = useDispatch();
    const setLoading=(loading)=>{
        dispatch(loadingAction(loading));
    }  

    const onSuccess=()=>{
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    if(global.dynamicPricingProvider == "Redbus" && global.dynamicPricingEnabled && tripDynamicPriceEnabled){
        return (
            <div>
                <Button
                    onClick={()=>{
                        setLoading(true);
                        updateDynamicFareForTrip(travelDates,scheduleId,onSuccess,onFailure);
                    }}
                >
                    Update dynamic fare
                </Button>
            </div>
        );
    }else{
        return null
    }
}
