import React, { useState } from "react";
import { Button, Form , Input , Row , Col, message ,Table} from 'antd';
import{fetchTicketSearch} from './../../actions/reports.action';
import { isValid } from './../../utils/utilities';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { convertTimeStringToDate , convertDateStringToDate ,getLocalDate , getLocalTime} from './../../utils/date.utils';
import { Printer } from './../../components/custom-components/custom-component';
import { getPaginationDetails, getDefaultPageSize } from './reports.module';



const RenderReport =({tickets,pagination})=>{

    const [page, setPage] = useState(1);
    const [paginationSize, setPaginationSize] = useState(getDefaultPageSize);

    const columns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => (page - 1) * paginationSize + index+1
        },
        {
            title:'PNR',
            key:'pnr',
            dataIndex : 'pnr',
            render :(text) =>{
                return(
                    <a href= {'/view-cancel?pnr='+text}
                    type="link" target="_blank" className="outline-button">{text}</a>
                )                 
            }
        },
        {
            title:'Passenger name',
            key:'full_name',
            dataIndex : 'full_name'
        },
        {
            title:'Mobile number',
            key:'phone_number',
            dataIndex : 'phone_number'
        },
        {
            title:'Email id',
            key:'email_id',
            dataIndex : 'email_id'
        },
        {
            title:'Origin',
            key:'origin',
            dataIndex : 'origin'
        },
        {
            title:'Destination',
            key:'destination',
            dataIndex : 'destination'
        },
        {
            title:'Trip name',
            key:'description',
            dataIndex : 'description'
        },
        {
            title:'Boarding time',
            key:'start_time',
            dataIndex:'start_time',
            render : (text, record, index) => {
                if(isValid(text)){
                    return getLocalTime(convertTimeStringToDate(text))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Boarding',
            key:'location',
            dataIndex : 'location'
        },
        {
            title:'Travel date',
            key:'start_date',
            dataIndex:'start_date',
            render : (text, record, index) => {
                if(isValid(text)){
                    return getLocalDate(convertDateStringToDate(text.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Booking date',
            key:'booking_time',
            dataIndex:'booking_time',
            render : (text, record, index) => {
                if(isValid(text)){
                    return getLocalDate(convertDateStringToDate(text.split('T')[0]))
                }else{
                    return ''
                }
            }
        },
        {
            title:'Seat Number',
            key:'seat_number',
            dataIndex : 'seat_number'
        },
        {
            title:'Traveller status',
            key:'traveller_status',
            dataIndex : 'traveller_status'
        },
        {
            title:'Total ticket cost',
            key:'total_ticket_cost',
            dataIndex : 'total_ticket_cost'
        },
        {
            title:'Cancellation penalty',
            key:'cancellation_penalty',
            dataIndex : 'cancellation_penalty'
        },
        {
            title:'Transaction Details',
            key:'order_no',
            dataIndex : 'order_no'
        }
    ];

    return(
        <Row>
            <Col>
                <Table
                    dataSource={tickets}
                    columns={columns}
                    pagination={getPaginationDetails(pagination, setPage, paginationSize, setPaginationSize)}
                    className="bordered-table"
                />
            </Col>
        </Row>
    )
}

export const TicketSearchReport = () => {

    const [form] = Form.useForm();
    const [tickets,setTickets]=useState(undefined);
    const [showReport,setShowReport]=useState(false);

    const dispatch=useDispatch();


    const onSuccess=(data)=>{
        setShowReport(true);
        setLoading(false);
        setTickets(data);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    const onFinish=(values)=>{
        let {
            PNR,
            Mobile_Number,
            email_id,
            Passenger_name,
            order_no
        }=values
        if(isValid(PNR)||isValid(Mobile_Number)||isValid(email_id)||isValid(Passenger_name)||isValid(order_no)){
            setLoading(true);
            setShowReport(false);
            fetchTicketSearch(PNR,Mobile_Number,email_id,Passenger_name,order_no,onSuccess,onFailure);
        }else{
            message.error("Please input atleat one filed!")
        }
    }

    return (
            <div className="report-inner-div">
                <Form 
                form={form} 
                onFinish={onFinish}
                >
                    <Row gutter={[16,0]} className="ticket-search-form-block">
                        <Col>
                            <Form.Item
                            name="PNR"
                            label="PNR"
                            rules={[
                            {
                                required: false,
                                message: 'Please input pnr',
                            },
                            ]}
                            >
                                <Input placeholder="Enter Pnr" className="defalut-input-style"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                            name="Mobile_Number"
                            label="Mobile number"
                            rules={[
                            {
                                required: false,
                                message: 'Please input mobile number',
                            },
                            ]}
                            >
                                <Input placeholder="Enter mobile number" className="defalut-input-style"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                            name="email_id"
                            label="Email id"
                            rules={[
                            {
                                required: false,
                                message: 'Please input email id',
                            },
                            ]}
                            >
                                <Input placeholder="Enter email id" className="defalut-input-style"/>
                            </Form.Item>
                        </Col>
                        
                        <Col>
                            <Form.Item
                            name="Passenger_name"
                            label="passenger name"
                            rules={[
                            {
                                required: false,
                                message: 'Please input passenger name',
                            },
                            ]}
                            >
                                <Input placeholder="Enter passenger name" className="defalut-input-style"/>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                            name="order_no"
                            label="Transaction ID"
                            rules={[
                                {
                                    required: false,
                                    message: 'Please input transaction id',
                                }
                            ]}
                            >
                                <Input placeholder="Enter transaction id" className="defalut-input-style"/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16,0]} justify="center"> 
                        <Col>
                            <Form.Item>
                                <Button htmlType={"submit"}>Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport&&
                    <div>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Printer 
                                    showComponent={false}
                                    zoomValue={0.5}
                                    renderComponent={()=>{
                                        return (
                                            <RenderReport 
                                                tickets={tickets}
                                                pagination={false}
                                            />
                                        )
                                    }} />
                            </Col>
                        </Row>
                        <Row className="marginTop" gutter={[16,16]}>
                            <Col>
                                <RenderReport 
                                    tickets={tickets}
                                    pagination={true}
                                />
                            </Col>
                        </Row>
                    </div>
                }
            </div>
    )
}