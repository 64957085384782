import React from "react";
import { Button, message } from "antd";
import { showConfirm } from './../custom-components/custom-component';
import { handleBlockByAdminSeats } from './block-by-admin.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED } from './../../utils/constant';
import './block-by-admin.style.scss';

export const BlockByAdminComponent = (props) => {

    const{
        selectedSchedule,
        selectedSeats,
        onSuccess,
        disableAdminBlock
    }=props;

    const onFailure=()=>{
        message.error("Failed to block trips!")
    }

    const handleClick=()=>{
        showConfirm(()=>{
            if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
                let tripId=selectedSchedule.trip.id;
                let tripRouteId = selectedSchedule.trip_route_id;
                handleBlockByAdminSeats(tripId,tripRouteId,selectedSeats,onSuccess,onFailure);
            }else{
                message.error("Trip not selected!")
            }
        },"Block seats","Are you sure to block seats");
    }
    
    if(checkActionAllowed(ACTION_BLOCK_BY_ADMIN_SEATS_ALLOWED)){
        return (
            <div>
                <Button onClick={handleClick} disabled={disableAdminBlock} className="admin-block">
                    Admin Block
                </Button>
            </div>
        )
    }else{
        return null
    }
}