import { message } from 'antd';
import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    CREATE_AGENT_TRANSACTION,
    GET_AGENTS,
    GET_AGENTS_TRANSACTIONS,
    LOADING_ACTION,
    SET_AGENTS
} from '../actions/action-types';

export function* getAgentsSaga(action) {
    try {
        yield put({
            type:LOADING_ACTION,
            payload:true
          })
        const {
                api,
                url,
                apiPayload
        } = {...action.payload};
          const apiResult = yield call(api,url,apiPayload);
            if(apiResult.status==="SUCCESS"&&apiResult.payload){
                let clientPayload=apiResult.payload;
                let agents = clientPayload.Payload.agents;
                yield put({
                    type:SET_AGENTS,
                    payload:{agents}
                  })
            }else{
                message.error("Failed to get agents")
            }
            yield put({
                type:LOADING_ACTION,
                payload:false
              })
    }
    catch (error) {
        yield put({
            type:LOADING_ACTION,
            payload:false
          })
        console.log("fetching :",{error});
        message.error("Failed to get agents")
    }
};


function* watchGetAgentsSaga() {
    yield takeLatest(GET_AGENTS, getAgentsSaga)
}

export function* getAgentsTransactions(action) {
  try {
      yield put({
          type:LOADING_ACTION,
          payload:true
        })
      const {
              api,
              url,
              apiPayload,
              onSuccess
      } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
          if(apiResult.status==="SUCCESS"&&apiResult.payload){
              let clientPayload=apiResult.payload;
              let agentsTransactions = clientPayload.Payload.agentsTransactions;
              onSuccess(agentsTransactions);
          }else{
              message.error("Failed to get agents")
          }
          yield put({
              type:LOADING_ACTION,
              payload:false
            })
  }
  catch (error) {
      yield put({
          type:LOADING_ACTION,
          payload:false
        })
      console.log("fetching :",{error});
      message.error("Failed to get agents")
  }
};


function* watchGetAgentTransactionsSaga() {
  yield takeLatest(GET_AGENTS_TRANSACTIONS, getAgentsTransactions)
}

export function* createAgentTransaction(action) {
  try {
      yield put({
          type:LOADING_ACTION,
          payload:true
        })
      const {
              api,
              url,
              apiPayload,
              onSuccess
      } = {...action.payload};
        const apiResult = yield call(api,url,apiPayload);
          if(apiResult.status==="SUCCESS"&&apiResult.payload){
              onSuccess();
          }else{
              message.error("Failed to create agent transactions!")
          }
          yield put({
              type:LOADING_ACTION,
              payload:false
            })
  }
  catch (error) {
      yield put({
          type:LOADING_ACTION,
          payload:false
        })
      console.log("fetching :",{error});
      message.error("Failed to create agent transactions!")
  }
};

function* watchCreatAgentTransactionSaga() {
  yield takeLatest(CREATE_AGENT_TRANSACTION, createAgentTransaction)
}


export default function* watchAgentsSagas() {
    yield all([
      fork(watchGetAgentsSaga),
      fork(watchCreatAgentTransactionSaga),
      fork(watchGetAgentTransactionsSaga)
  ])
}
  