// ticket.trip_id = schedule.trip.id;
// ticket.trip_route_id = schedule.trip.trip_route_id;
// ticket.pickupLocation = boardingPoint;
// ticket.dropLocation = droppingPoint;
// ticket.emailId = emailId;
// ticket.phoneNumber = phoneNumber;
// ticket.agent_id = currentUser.id;
// ticket.userName = currentUser.user_name;
// ticket.payment_type = paymentType;
// ticket.direct_discount = totalDiscount;
// let seats = [];
// Object.keys(seatDetails).forEach((seatNumber) => {
//   let seatInfo = seatDetails[seatNumber];
//   let seat = new Seat();
//   seat.id = seatInfo.id;
//   seat.tripId = schedule.trip.id;
//   seat.seatNumber = seatNumber;
//   seat.ladiesSeat = seatInfo.sex === "F" ? true : false;
//   seats.push(seat);
//   let traveller = new Traveller();
//   traveller.fullName = seatInfo.name;
//   traveller.gender = seatInfo.sex;
//   traveller.age = seatInfo.age;
//   travellers.push(traveller);
// });
// fetch(BASE_URL + "/api/blockFireboltTicket", {
//   method: "POST",
//   headers: { "Content-Type": "application/json" },
//   body: JSON.stringify({
//     ticket: ticket,
//     returnTicket: null,
//     seats: seats,
//     returnSeats: null,
//     travellers: travellers,
//     websiteBooking: false,
//   }),
// })



/**
 * Selected Schedule
 */
// {
// api_fare: 900
// arrival_time: "05:00:00"
// available_seats: 26
// available_window_seats: 17
// blocked_seat_numbers: "17,18"
// bus: {description: "HI-TECH< A/C Sleeper  ", seat_type: "Sleeper", coach_type: "HI-TECH<", isAc: 1, name: null, …}
// bus_description: "HI-TECH< A/C Sleeper  "
// bus_id: 36
// bus_name: null
// cancellationPolicies: []
// cgst_percentage: 2.5
// coach_type: "HI-TECH<"
// departure_time: "18:00:00"
// description: "Agra Delhi 06:00 PM HI-TECH< A/C Sleeper   "
// destination: "Delhi"
// discount: 0
// discounted_cost_with_gst: 945
// discounted_cost_without_gst: 900
// distance: 600
// dropTimings: [{…}]
// end_time: "05:00:00"
// fare: 900
// id: 310
// igst_percentage: 0
// isAc: 1
// is_active: 1
// name: ""
// operator_name: "Kavitha Travels Name"
// origin: "Agra"
// pickupTimings: [{…}]
// schedule_id: 135
// seat_type: "Sleeper"
// sgst_percentage: 2.5
// start_date: "2021-08-27T00:00:00.000Z"
// start_time: "18:00:00"
// total_api_ticket_cost: 945
// travels_id: 16
// travels_route_id: null
// travels_trip_id: null
// travels_trip_route_id: null
// trip: {id: 2307, start_date: "2021-08-27T00:00:00.000Z", available_seats: 26, available_window_seats: 17, travels_trip_id: null, …}
// trip_id: 2307
// trip_route_fare: 900
// trip_route_id: 10663
// trip_route_total_ticket_cost: 945
// visible_outside: 1
// }
import React from 'react';
import { Table } from 'antd';
import { isValidArray , isValid } from '../../utils/utilities';
import { roundNumber } from './../../utils/math.utils';
import { getFormatedTravells } from './../../pages/view-ticket.page';

export const getDetailsToCancelTicket = (oldTicket,oldPassengers,customerIntiated) =>{
  if(customerIntiated == false){
    oldTicket.is_reschedule_allowed = true;
  }
    return{
        ticket:oldTicket,
        travellers:getFormatedTravells(oldPassengers),
        userName : global.userName,
        partialCancel : false,
        operatorCode : global.operatorCode,
        sendCancellationNotification : true 
    }
}

export const getDetailsToBookTicket=(formValues,selectedSchedule,oldTicket)=>{
    let ticket={},seats=[],travellers=[];
    ticket.trip_id = selectedSchedule.trip.id;
    ticket.trip_route_id = selectedSchedule.trip.trip_route_id;
    ticket.pickupLocation = formValues.boardingPointId;
    ticket.dropLocation = formValues.dropPointId;
    ticket.emailId = formValues.emailId;
    ticket.phoneNumber = formValues.phoneNumber;
    ticket.userName = oldTicket.user_name;
    ticket.agent_id = oldTicket.agent_id;
    ticket.payment_type = oldTicket.payment_type;
    ticket.remarks = oldTicket.remarks;
    ticket.is_reschedule_allowed = false;
    formValues.passengerDetails.forEach((passenger)=>{
        travellers.push({
            fullName:passenger.fullName,
            gender:passenger.gender,
            age:passenger.age  
        });
        seats.push({
          id : passenger.id,
          tripId : selectedSchedule.trip.id,
          seatNumber : passenger.seatNumber,
          ladiesSeat : passenger.gender === "F" ? true : false
        })
    })
    return {
        ticket: ticket,
        returnTicket: null,
        seats: seats,
        returnSeats: null,
        travellers: travellers,
        websiteBooking: false,
        datetime:null,
        isSendPaymentLink:false
      }
}

export const getTicketFare=(seats,oldTicket,refundDiff,waiveCharge)=>{
  let baseFare=0,cgst=0,sgst=0 , discountedCostWithoutGst=0 , isOldTicketAsHigher = false;
  if(isValidArray(seats)&&seats.length>0){
    seats.forEach(element => {
      baseFare=baseFare+element.fare;
      cgst=cgst+element.cgstOfDiscountedPrice;
      sgst=sgst+element.sgstOfDiscountedPrice;
      discountedCostWithoutGst=discountedCostWithoutGst+element.discountedCostWithoutGst;
    });
  }
  let oldAmountPaid = oldTicket.amount_paid;
  let newAmountPaid = discountedCostWithoutGst+cgst+sgst;

  let fareDifference = roundNumber(newAmountPaid-oldAmountPaid);

  if(fareDifference<0){
    isOldTicketAsHigher=true; 
  }

  if(!refundDiff&&fareDifference<0){
    fareDifference = 0;
  }

  if(fareDifference>0&&waiveCharge){
    fareDifference=0;
  }

  return{
    baseFare:roundNumber(baseFare),
    cgst:roundNumber(cgst),
    sgst:roundNumber(sgst),
    discountedCostWithoutGst:roundNumber(discountedCostWithoutGst),
    totalCost:roundNumber(discountedCostWithoutGst+cgst+sgst),
    fareDifference :fareDifference,
    isOldTicketAsHigher :isOldTicketAsHigher
  }
}



const getBoardingLocation=(boardingPoints,boardingPointId)=>{
  let location ='';
  if(isValidArray(boardingPoints)&&boardingPoints.length>0){
    boardingPoints.forEach((row)=>{
      if(boardingPointId===row.id){
        location=row.location
      }
    })
    return location
  }else{
    return null
  }

}

export const PassengerSeatsTable=({formValues,boardingPoints})=>{
  let {
    passengerDetails,
    boardingPointId
  }=formValues;
  let location = getBoardingLocation(boardingPoints,boardingPointId);

  const columns = [
    {
      title: 'Seat no',
      dataIndex: 'seatNumber',
      key: 'seatNumber',
    },
    {
      title: "Name Age Sex",
      render: (record) => (
        <React.Fragment>
          {record.fullName}
          <br />
          {record.age}
          <br />
          {record.gender}
        </React.Fragment>
      ),
      responsive: ["xs"]
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      responsive: ["sm"]  
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
      responsive: ["sm"]
    },
    {
      title: 'Sex',
      dataIndex: 'gender',
      key: 'gender',
      responsive: ["sm"]
    },
    {
      title: 'Ticket Cost',
      dataIndex: 'discountedCostWithoutGst',
      key: 'discountedCostWithoutGst',
      render : (text, record, index) => {
        if(isValid(record.discountedCostWithoutGst)&&isValid(record.cgstOfDiscountedPrice)&&isValid(record.sgstOfDiscountedPrice)){
            return roundNumber(record.sgstOfDiscountedPrice+record.cgstOfDiscountedPrice+record.discountedCostWithoutGst)
        }else{
            return record.ticketCost
        }
    }
    },
    {
      title: 'Boarding',
      render: (record) => (
        <React.Fragment>
          {location}
        </React.Fragment>
      ),
      key: 'Boarding',
    },
  ];

  if(isValidArray(passengerDetails)){
    return <Table dataSource={passengerDetails} columns={columns} pagination={false}/>
  }else{
    return null
  }
}


export const getAnyBlockedSeatsExit=(seats)=>{
  let bool = false;
    if(isValidArray(seats)&&seats.length){
      seats.forEach(element => {
        if(element.seatStatus==="Blocked"){
          bool=true;
        }
      });

    }
  return bool;
}

export const getConfirmedPassengersCount =(oldPassengers)=>{

  let count = 0;

  if(isValidArray){
      oldPassengers.forEach((passenger)=>{
          if(passenger.traveller_status === "Confirmed"){
              count = count+1;
          }
      })
  }
  return count
}