import { UPDATE_SEAT_DETAILS , ADD_CLIENT_SIDE_DISCOUNT} from './action-types';

export const updateSelectedSeats = (seatId,seatObject,passengerInfo) => {
    return {
        type: UPDATE_SEAT_DETAILS,
        payload: {
            seatId,seatObject,passengerInfo
        }
    }
  }

  
export const addClientSideDiscount = (discountPerSeat) => {
    return {
        type: ADD_CLIENT_SIDE_DISCOUNT,
        payload: {
            discountPerSeat
        }
    }
  }
   