import { Modal, Button ,Select , Form , Input , TimePicker} from 'antd';
import React , {useState,useEffect } from 'react';
import { isValid, isValidArray } from '../../utils/utilities';
import moment  from 'moment';
import { getMinsBetweenTwoTimes } from '../../utils/date.utils';
// import { cloneTrip } from '../../../actions/trip-actions';
import { useDispatch } from 'react-redux';
import { cloneTrip } from '../../actions/trip-create.actions';

const {Option} = Select;

const printBusesOption = (option) => {
  return <Option  value={option.id} data={option} key={option.description}>{option.description}</Option>
};

export const CloneScheduleComponent = (props) => {

    const {
       busesData,       
       formData,
       onCloneSuccess
    } = props;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    useEffect(()=>{
      fillForm();
    },[formData])

    const fillForm=()=>{
      if(isValidArray(formData)&&isValid(formData.id)){
        let{
          bus_id,
          name,
          start_time
        }=formData;
        form.setFieldsValue({ 
          start_time:moment(start_time,'HH:mm') ,
          name:name,
          bus_id:bus_id
       });
      }
    }
  
    const handleOk = (e) => {
      e.preventDefault();
      form.validateFields()
      .then(values => {
        let offset=getMinsBetweenTwoTimes(formData.start_time,values.start_time);
        dispatch(cloneTrip(
          formData.id,
          offset,
          values.bus_id,
          values.name,
          ()=>{
             onCloneSuccess();
             setIsModalVisible(false);
          }
        ))
      })
      .catch(errorInfo => {
        console.log({errorInfo});
      })
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

  return (
    <>
       <Button
        style={{background:'orange' , borderColor:'orange' ,color:'#FFFFFF'}}
        // disabled={isCloneEnable}
        onClick={(e)=>{
            setIsModalVisible(!isModalVisible);
        }}>
            Clone
        </Button>
      <Modal 
      title="Clone trip" 
      visible={isModalVisible} 
      onOk={handleOk} 
      onCancel={handleCancel}
      okText={"Clone"}
      >
      <Form
          form={form} 
          name="basic"
          initialValues={{name:''}}
      >
            <Form.Item
                  label="Select bus"
                  name="bus_id"
                  rules={[{ required: true, message: 'Please input select bus!' }]}
                  >
                  <Select
                        showSearch
                        placeholder="Select bus"
                        style={{ width: 200 }}
                        onChange={(value , componentData)=>{
                                                            
                        }}
                        optionFilterProp="children"
                        filterOption={(input, option) =>  
                            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 
                            || option.props.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                    >
                      {busesData.map(printBusesOption)}
                  </Select>                            
            </Form.Item>
            <Form.Item
                      label="Trip Identifier"
                      name="name"
                      rules={[{ required: false, message: 'Please input identifier!' }]}
                    >
              <Input
                    placeholder="Trip identifier"
              />                                
            </Form.Item>
            <Form.Item
                      label="Depature Time"
                      name="start_time"
                      rules={[{ required: true, message: 'Please input depature Time!' }]}
            >
                <TimePicker 
                  use12Hours format='h:mm A'  
                  minuteStep={5} 
                  popupClassName={"time-picker-to-not-display-footer"}
                  onSelect={(value)=>{
                                form.setFieldsValue({
                                                    start_time:value
                                                    })
                          }}
                />
            </Form.Item>
        </Form>
      </Modal>
    </>
  );
};