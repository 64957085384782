import { message } from 'antd';
import { takeLatest, put , all ,fork,call } from 'redux-saga/effects';
import {
    LOGIN_ACTION,
    SET_RUNNING_CACHE,
    VERIFY_OTP
} from '../actions/action-types';
import { 
    isValid
} from './../utils/utilities';

export function* userSaga(action) {
    try {
     const {
        api,
        url,
        apiPayload,
        onSuccess
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload){
        let clientPayload=apiResult.payload;
               onSuccess(clientPayload);
                yield put({
                    type:SET_RUNNING_CACHE,
                    payload:false
                })
        }else{
            yield put({
                type:SET_RUNNING_CACHE,
                payload:false
            })
            if(isValid(apiResult.message)){
                message.error(apiResult.message);
            }else{
                message.error("Login failed!")
            }
        }
    }
    catch (error) {
        console.error("Error while login:",{error});
        yield put({
            type:SET_RUNNING_CACHE,
            payload:false
        })
    }
};


function* watchUserSaga() {
    yield takeLatest(LOGIN_ACTION, userSaga)
}
  

export function* verifyOtp(action) {
    try {
     const {
        api,
        url,
        apiPayload,
        onSuccess
    } = {...action.payload};
    const apiResult = yield call(api,url,apiPayload);
        if(apiResult.status==="SUCCESS"&&apiResult.payload.Status === "SUCCESS"){
               onSuccess();
        }else{
            if(isValid(apiResult.payload.Message)){
                message.error(apiResult.payload.Message);
            }else{
                message.error("Verifing otp failed!")
            }
        }
    }
    catch (error) {
        console.error("Error while login:",{error});
    }
};


function* watchVerifySaga() {
    yield takeLatest(VERIFY_OTP, verifyOtp)
}

export default function* watchUsersSagas() {
    yield all([
      fork(watchUserSaga),
      fork(watchVerifySaga)
  ])
}
  