import React from "react";
import { Button, message } from "antd";
import { showConfirm } from './../custom-components/custom-component';
import { handleBlockAllSeats } from './block-all-seats.module';
import { isValid, isValidArray,checkActionAllowed } from "../../utils/utilities";
import { ACTION_BLOCK_ALL_TICKETS_ALLOWED } from './../../utils/constant';
import './block-all-seats.styles.scss';

export const BlockAllSeatsComponent = (props) => {

    const{
        selectedSchedule,
        fetchSeats,
        setSelectedTripRoute
    }=props;

    const onSuccess=()=>{
        setSelectedTripRoute(selectedSchedule.trip_route_id);
        fetchSeats(selectedSchedule.trip_route_id);
    }

    const onFailure=()=>{
        message.error("Failed to block trips!")
    }

    const handleClick=()=>{
        showConfirm(()=>{
            if(isValidArray(selectedSchedule)&&isValidArray(selectedSchedule.trip)&&isValid(selectedSchedule.trip.id)){
                let tripId=selectedSchedule.trip.id;
                handleBlockAllSeats(tripId,selectedSchedule.trip_route_id,onSuccess,onFailure);
            }else{
                message.error("Trip not selected!")
            }
        },"Block all seats","Are you sure to block all available seats");
    }
    if(checkActionAllowed(ACTION_BLOCK_ALL_TICKETS_ALLOWED)){
        return (
            <div className="marginTop marginBottom">
                <Button onClick={handleClick} className="block-all-available-btn">
                    Block all available seats
                </Button>
            </div>
        )
    }else{
        return null
    }
}
