import { postCall } from '../../utils/network.util';
import { GET_TICKET_PNR_BY_ID_URL } from './../../utils/api-url';
import { message } from 'antd';

export const getPaymentLink=(ticketId,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetched = postCall(GET_TICKET_PNR_BY_ID_URL, {ticketId:ticketId});
    fetched.then((data) => {
        console.log({data});
        if (data.status === "SUCCESS" && data.payload.Status === "SUCCESS") {
            message.success("Copied payment link successfully!");
            let url=`${global.webSiteUrl}/manage-booking/ticket?pnr=${data.payload.Payload.pnr}&&phone_number=${data.payload.Payload.phoneNumber}`;
            onSuccess(url)
        } else {
            message.error(`Failed to copy payment link failed because of ${data.Message}`);
            onFailure();
        }
    })
    .catch((reason)=> {
        message.error("Failed to copy payment link failed because of", reason);
        onFailure();
    })
}