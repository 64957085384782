import React from 'react';
import './trip-create.style.scss';
import { Button, Divider } from 'antd';
import { convertMetersToKm } from './../../utils/math.utils'
import { getHoursAndMinsFromMins, getMinsFromSecs } from '../../utils/date.utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectedCitiesSelector } from '../../selector/trip-create.selector';
import { setActiveCity } from '../../actions/trip-create.actions';

export const TripBottomRibbon = (props) => {
    const {
        distance,
        time,
        setAddStop,
        setHeaderStep,
        showRibbon
    } = props;

    const dispatch = useDispatch();

    let selectedCities = useSelector(selectedCitiesSelector);
    let totalCity = selectedCities.length;
    const onChange = () => {
        dispatch(setActiveCity(selectedCities[0]));
        setAddStop(true);
        setHeaderStep(2);
    }

    if(showRibbon){
        return(
            <div className="create-trip-bottom-ribbon">
                <Divider className="trip-divider"/>
                <div className ="create-trip-ribbon-text">
                    {convertMetersToKm(distance)} KM | {getHoursAndMinsFromMins(getMinsFromSecs(time))} | {totalCity} Cities
                    <span style= {{marginLeft:50}}>
                        <Button type="primary" onClick={() => onChange()}>Add Stops</Button>
                    </span>
                </div>
            </div>
        )
    }else{
        return null;
    }
}