

import { Button ,Input,Popover , Row , Col} from "antd";
import React , { useRef, useState } from "react";
import './discount.style.scss';
import {
    EllipsisOutlined,
    EditOutlined
  } from '@ant-design/icons';
import {
    checkActionAllowed, isValid
} from './../../utils/utilities';
import { SHOW_CLIENT_SIDE_DISCOUNT } from "../../utils/constant";

const DiscountModal=({discountValue,handleCancel,handleApply})=>{

    let ref=useRef(null);

    return(
        <div className="discount-modal-root">
                    <div>
                        <Row>
                            <Col>
                                <div className='fontWeightBold'>
                                    Discount
                                </div>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Input defaultValue={discountValue} ref={ref}/>
                            </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                            <Col>
                                <Button onClick={handleCancel}>Cancel</Button>
                            </Col>
                            <Col>
                                <Button onClick={()=>{
                                    handleApply(ref.current.state.value);
                                }}>Apply</Button>
                            </Col>
                        </Row>
                    </div>
        </div>
    )
}

const AddDiscountComponent = (props) => {

    const{
        showModal,
        discountValue,
        removeDisCount
    }=props;
        if(!isValid(discountValue)){
            return (
                <div className="add-button-content">
                    <Button onClick={showModal}>Add Discount</Button>
                </div>
            )
        }else{
            return (
                <div className="add-button-content">
                    <Button onClick={removeDisCount}>Remove Discount</Button>
                </div>
            )
        }
}


const EditDiscountComponent = (props) => {
   
        const{
            showModal
        }=props;

        return (
            <div className="add-button-content">
                <Button onClick={showModal}>Edit Discount</Button>
            </div>
        )
}

export const AddDiscount =(props)=>{
    const {
        discountValue,
        setDiscountValue
    } = props;
    
      
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };
    
    const handleCancel = () =>{
        setIsModalVisible(false);
        setVisible(false);
    }

    const handleApply = (value) =>{
        setDiscountValue(value);
        handleCancel();
    }

    const removeDisCount = (value) =>{
        setDiscountValue(0);
        handleCancel();
    }
    

    if(checkActionAllowed(SHOW_CLIENT_SIDE_DISCOUNT)){
        return(
            <div 
            onMouseLeave={()=>{
                setVisible(false);
            }}
            >
                <Popover 
                    visible={visible}
                    onMouseEnter={()=>{
                      setVisible(true);
                    }}
                    onClick={()=>{
                        setVisible(true);
                    }}
                    content={isModalVisible?<DiscountModal 
                                                discountValue={discountValue} 
                                                handleCancel={handleCancel}
                                                handleApply={handleApply}
                                            /> :<AddDiscountComponent 
                                                    showModal={showModal} 
                                                    discountValue={discountValue} 
                                                    removeDisCount={removeDisCount}
                                                />}
                >
                        <Button  
                                type="text" 
                                className="outline-button three-dots-button"  
                                icon={<EllipsisOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                        />
                </Popover>
            </div>
          
        );   
    }else{
        return null;
    }
}


export const EditDiscount =(props)=>{
    const {
        discountValue,
        setDiscountValue
    } = props;
    
      
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [visible, setVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    
    const handleCancel = () =>{
        setIsModalVisible(false);
        setVisible(false);
    }

    const handleApply = (value) =>{
        setDiscountValue(value);
        handleCancel();
    }
    if(checkActionAllowed(SHOW_CLIENT_SIDE_DISCOUNT)){
        return(
            <div
            onMouseLeave={()=>{
                setVisible(false);
            }}
            >
                <Popover 
                    visible={visible}
                    onMouseEnter={()=>{
                        setVisible(true);
                    }}
                    onClick={()=>{
                        setVisible(true);
                    }}
                    content={isModalVisible? 
                        <DiscountModal 
                            discountValue={discountValue} 
                            handleCancel={handleCancel}
                            handleApply={handleApply}
                        />
                        :
                        <EditDiscountComponent showModal={showModal}/>}
                >
                        <Button  
                                type="text" 
                                className="outline-button three-dots-button"  
                                icon={<EditOutlined rotate={90}  style={{fontSize:'20px'}} />} 
                        />
                </Popover>
            </div>
          
        )
    }else{
        return null;
    }
}