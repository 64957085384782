import React, { useEffect, useState } from "react";
import { Tabs,Button, Row , Col, message} from "antd";
import './render-tabs.style.scss';
import { StickyContainer, Sticky } from 'react-sticky';
import Icon from '@ant-design/icons';
import Inactivecalender from './../../../images/inactive-calender.png';
import ActiveCalender from './../../../images/active-calender.png';
import { ScheduleActiveExtend } from "./schedule-active-extend.component";
import { ScheduleEditFareExtend } from "./schedule-edit-fare.component";
import { ScheduleTripDetailsExtend } from "./schedule-trip-details.component";
import { ScheduleAmenities } from './schedule-amenities.component';
import { checkRedbusDpEnabled, checkToViewTripDetailsTab } from "./render-tabs.module";
import { NewTimingTab } from "../../new-timing/new-timing-component/new-timing.component";
import { showUpdatebtn } from "../../../utils/constant";
import { RedBusTabComponent } from "./red-bus-tab.component";
import { InformTripChangesToOTA } from "../../inform-trip-changes-to-ota/inform-trip-changes-to-ota.component";
import { ValidateTripButton } from "../../validate-trip-button/validate-trip-button.component";
import { CloneScheduleComponent } from "../../clone-schedule-component/clone-schedule.component";
import { tripCreateReducerSelector } from "../../../selector/trip-create.selector";
import { useDispatch, useSelector } from "react-redux";
import { getBusDetailsAction } from "../../../actions/trip-create.actions";
import { ReturnScheduleComponent } from "../../return-schedule-component/return-schedule.component";
import { EditTripComponent } from "../../edit-trip/edit-trip-component";
import { MarkScheduleInactive } from "./mark-in-active.component";

const { TabPane } = Tabs;


const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={80}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar"  />
      )}
    </Sticky>
  );

export const RenderTabs = (props) => {

        const{
            selectedDate,
            scheduleData,
            isMobile,
            amenities,
            fetchAmenities,
            defaultActiveKey=null,
            reload
        } = props;

        const [isScheduleOpened , setIsscheduleOpened] = useState(false);
        const [tabkey , setTabKey] = useState(null);

        const tripCreateReducer = useSelector(tripCreateReducerSelector);

        let { busesData } = tripCreateReducer;

        const dispatch = useDispatch();

        useEffect(()=>{
            dispatch(getBusDetailsAction());
        },[]);


        let selectSchedule = <div className="select-schedule-button-root">
                                <Button 
                                className={isScheduleOpened?"active-outline-button select-schedule-button":"select-schedule-button"} 
                                onClick={()=>{
                                    setIsscheduleOpened(!isScheduleOpened);
                                    setTabKey(null);
                                }} 
                                ghost={isScheduleOpened}
                                >
                                    {isScheduleOpened?
                                    <Icon component={() => (<img src={ActiveCalender} className='calender-icon' />)} />
                                    :
                                    <Icon component={() => (<img src={Inactivecalender} className='calender-icon' />)} />
                                    }
                                </Button>
                                </div>

        let editFareKey = `${scheduleData.id}${"edit-fare"}`;
        let StopsKey = `${scheduleData.id}${"stops"}`;
        let redBusKey = `${scheduleData.id}${"redbus"}`;
        let amenitiesKey = `${scheduleData.id}${"amenities-tab"}`;
        let manageTabKey = `${scheduleData.id}${"manage"}`;

        let tripDetailsKey = `${scheduleData.id}${"trip-details"}`;
        let isEditFareOpened = editFareKey === tabkey ? true :false;
        let isStopsOpened = StopsKey === tabkey ? true :false;
        let isAmenitiesOpened = amenitiesKey === tabkey ? true :false;
        let isTripDetailsOpened = tripDetailsKey === tabkey ? true: false;
        let isManageOpened = manageTabKey === tabkey ? true :false;

        let editFare = <div className={"edit-fare-block"}>
                            <Button 
                            className={isEditFareOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                                    Edit Fare
                            </Button>
                        </div>;

                        
        let stops = <div className={"edit-fare-block"}>
                    <Button 
                    className={isStopsOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                            Stops
                    </Button>
                    </div>;

        let redBus = <div className={"edit-fare-block"}>
                        <Button 
                        className={isStopsOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                                Redbus
                        </Button>
                    </div>;

        let amenitiesTab = <div>
                            <Button
                            className={isAmenitiesOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                                    Amenities
                            </Button>
                        </div>;

        let manageTab = <div>
                            <Button
                            className={isManageOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                                    Manage
                            </Button>
                        </div>;

        let tripDetails = <div className={"edit-fare-block"}>
                            <Button className={isTripDetailsOpened?"selected-edit-fare-outline-btn":"edit-fare-outline-button"}>
                                Trip Details
                            </Button>
                        </div>;

        return(
            <div>
                <StickyContainer>
                    <Tabs 
                        tabBarExtraContent={selectSchedule} 
                        destroyInactiveTabPane={true}
                        className={'tabs-in-schedules'}
                        renderTabBar={renderTabBar}
                        defaultActiveKey={defaultActiveKey}
                        activeKey={`${tabkey}`}
                        onTabClick={(currentKey)=>{
                            setIsscheduleOpened(false);
                            if(currentKey === tabkey){
                                setTabKey(null);
                            }else{
                                setTabKey(currentKey);
                            }
                        }}
                        onChange={(currentKey)=>{

                        }}
                    >
                        <Tabs.TabPane tab={amenitiesTab} key={amenitiesKey}>
                            <ScheduleAmenities
                                scheduleData={scheduleData}
                                isAmenitiesOpened={isAmenitiesOpened}
                                amenities = {amenities}
                                fetchAmenities = {fetchAmenities}
                           />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={manageTab} key={manageTabKey}>
                            <div className="extend-block-root">

                                <Row gutter={[16,16]}>
                                    <Col>
                                        <EditTripComponent 
                                            scheduleId={scheduleData.id}
                                        />
                                    </Col>
                                    <Col>
                                        <InformTripChangesToOTA 
                                            scheduleId={scheduleData.id}
                                        />
                                    </Col>
                                    <Col>
                                        <ValidateTripButton 
                                            scheduleId={scheduleData.id}
                                        />
                                    </Col>
                                    <Col>
                                        <CloneScheduleComponent 
                                            busesData={busesData}
                                            formData={scheduleData}
                                            onCloneSuccess={reload}
                                        />
                                    </Col>
                                    <Col>
                                        <ReturnScheduleComponent
                                            busesData={busesData}
                                            formData={scheduleData}
                                            onCloneSuccess={reload}
                                        />
                                    </Col>
                                    <Col>
                                        <MarkScheduleInactive 
                                            scheduleId={scheduleData.id}
                                            onSuccess={reload}
                                            onFailure={(errmsg)=>{message.error(errmsg);}}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Tabs.TabPane>
                        {checkToViewTripDetailsTab()&&
                        <TabPane tab={tripDetails} key={tripDetailsKey}>
                           <ScheduleTripDetailsExtend
                                selectedDate={selectedDate}
                                scheduleData={scheduleData}
                                isMobile={isMobile}
                                isTripDetailsOpened={isTripDetailsOpened}
                           />
                        </TabPane>}
                        {/* <Tabs.TabPane tab="Routes" key="Routes">
                            Routes
                        </Tabs.TabPane> */}
                        <TabPane tab={editFare} key={editFareKey}>
                           <ScheduleEditFareExtend 
                                selectedDate={selectedDate}
                                scheduleData={scheduleData}
                                isMobile={isMobile}
                                isEditFareOpened={isEditFareOpened}
                           />
                        </TabPane>
                        <TabPane tab={stops} key={StopsKey}>
                             <NewTimingTab
                                scheduleId={scheduleData.id}
                                isDaySpecificExits={false}
                                scheduleDescription = {scheduleData.description}
                                showUpdateBtns={showUpdatebtn()}
                            />
                        </TabPane>
                        {checkRedbusDpEnabled(scheduleData)&&
                            <TabPane tab={redBus} key={redBusKey}>
                                <RedBusTabComponent 
                                    selectedDate={selectedDate}
                                    scheduleData={scheduleData}
                                    isMobile={isMobile}
                                />
                            </TabPane>
                        }
                    </Tabs>   
                </StickyContainer>
                {isScheduleOpened&&
                <ScheduleActiveExtend 
                    selectedDate={selectedDate}
                    scheduleData={scheduleData}
                    isMobile={isMobile}
                    isScheduleOpened={isScheduleOpened}
                />
                }
            </div>
        )
}
