import { GET_ALL_TICKET_DETAILS_URL } from "../../../utils/api-url";


export const fetchTicketDetails=(tripId,agentName,onSuccess=()=>{

},onFailure=()=>{

})=>{

fetch(GET_ALL_TICKET_DETAILS_URL, {
    method: "POST",
    headers: {'Content-Type':'application/json'},
    body: JSON.stringify({
        "tripId" : tripId,
        "agentName" : agentName,
        "operatorCode" : global.operatorCode,
        "travelsId" : global.travelsId  
    })
    })
    .then(response => response.json())
    .then(data =>{
        onSuccess(data);
    }).catch((err)=>{
        console.log({err});
        onFailure("Failed to get ticket info!");
    })
}