import React from 'react';
import { Provider } from 'react-redux';
import './styles/main.scss';
import { RouteRoot } from '../app/routes/route.root';

export const mountApp = (store = {}) => {
    return(
      <div className="root-div">
          <Provider store={store}>
              {/* <BrowserRouter> */}
                {/* <AppHeader /> */}
                <RouteRoot />
                {/* <DeviceProvider> */}
                    {/* {renderRoutes(routes)} */}
                {/* </DeviceProvider> */}
              {/* </BrowserRouter> */}
          </Provider>
      </div>
    );
}