import{
    GET_CITY_DETAILS, UPDATE_CITY_PHONE_NO
} from './action-types';
import{
    FETCH_CITY_DETAILS_URL, UPDATE_CITY_PHONE_NO_URL,
} from '../utils/api-url';
import { postCall } from "../utils/network.util";

export const getCityDetails = (onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: GET_CITY_DETAILS,
        payload: {
            url:FETCH_CITY_DETAILS_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              "travelsId":global.travelsId
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }

  export const updateCityPhoneNumber = (city,phoneNumber,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: UPDATE_CITY_PHONE_NO,
        payload: {
            url:UPDATE_CITY_PHONE_NO_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              travelsId:global.travelsId,
              city:city,
              phoneNumber:phoneNumber
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }