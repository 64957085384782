import React,{useState , useEffect} from 'react';
import {  Modal, Button, message ,Row ,Col} from 'antd';
import {SeatLayoutContainer} from './seat-layout/seat-layout-container.component';
import { arrayToString, isValid , isValidNumber } from '../../utils/utilities';
import { getSeatLayOut } from './../../actions/admin.action';
import TextArea from 'antd/lib/input/TextArea';


export const EditBlockedSeats = (props) => {

    let {
        blockedSeats ,
        selectSeat,
        selectedSeatNumbers,
        busId,
        justShowSeatLayout=false
    } = {...props};



    useEffect(() => {
      if (isValidNumber(busId)) {
        getSeatLayOut(busId,onSuccess,onFailure);
      }
    },[busId]);
    
    const [modal, updateModal] = useState(0);
    const [seatsData , setSeatsData] = useState([]);

    const onSuccess=(seats)=>{
      setSeatsData(seats.seat_layout);
    }
    const onFailure=(msg)=>{
      message.error(msg);
    }
    
    return (
      <div>
        {justShowSeatLayout?
          null
          :
          <Row>
            <Col>
              <TextArea value={arrayToString(selectedSeatNumbers)} disabled={true}/>
            </Col>
          </Row>
        }
        <Row className={justShowSeatLayout?'justShowSeatLayout':""}>
          <Col>
              <Button type="primary" 
              disabled={isValid(busId)?false:true}
              onClick={()=>{
                updateModal(true);
              }}
              className={justShowSeatLayout?"marginBottom":"marginTop marginBottom"}
              >
                {justShowSeatLayout?
                  "Seat Layout"
                :
                  "Edit Blocked Seats"
                }
              
              </Button>
          </Col>
        </Row>
       <Modal
         title="Seat layout"
         visible={modal}
         onOk={()=>{
          updateModal(false);
         }}
         onCancel={()=>{
           updateModal(false);
         }}
         okText="Ok"
         cancelText="Cancel"
       >
         {seatsData.length!=0 &&
           <SeatLayoutContainer 
              seatsData={seatsData} 
              blockedSeats={blockedSeats} 
              selectSeat={selectSeat}
              showLegend={false}
              selectedSeatNumbers={selectedSeatNumbers}
              showFarePopOver={false}
              dontAllowSelection={justShowSeatLayout}
            />
         }
       </Modal>
     </div>
    );
};
