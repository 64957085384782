import { Table , Popover , Button } from "antd";
import React, { } from "react";
import { MoreOutlined } from '@ant-design/icons';
import './standard-stops-table.style.scss';
import { useHistory } from "react-router-dom";
import { showConfirm } from './../../custom-components/custom-component';
import { deleteAddressLocation } from './../../../actions/address.actions';

export const StandardStopsTable = (props) => {

    const {
        standardStops,
        reload
    } = props;

    const history = useHistory();

    const onEditCick=(record)=>{
        history.push({
            pathname: '/create-standard-stop',
            state: { formdetails : record }
        });
    }

    const onSuccess=()=>{
        reload();
    }

    const onFailure =()=>{

    }

    const onDeleteClick=(record)=>{
        showConfirm(()=>{
            deleteAddressLocation(record.id,onSuccess,onFailure);
        },"Delete Location","Are you sure you want to delete this location?");
    }

    const columns = [
        {
            title:"Stop name",
            dataIndex:"name",
            render:(text,record)=>{
                const content = (
                    <div className="pophover-button-block">
                      <Button className="edit-button-stop-name" onClick={()=>{
                        onEditCick(record);
                      }}>Edit</Button>
                      <Button className="delete-button-stop-name" onClick={()=>{
                        onDeleteClick(record);
                      }}>Delete</Button>
                    </div>
                  );
                return(
                    <div className="stop-name-details">
                        <div className="stop-name-txt">
                            {text}
                        </div>
                        <Popover content={content}>
                            <MoreOutlined className="stop-more-icon"/>
                        </Popover>
                    </div>
                )
            }
        },
        {
            title:"City",
            dataIndex:"city",
        }
    ]

    return (
        <div>
            <Table 
                columns={columns}
                dataSource={standardStops}
                pagination={false}
            />
        </div>
    )
}