/**
 * @author Manne Hareesh
 * @description Tracking Info
 */

import { postCall } from "../../utils/network.util";
import { GET_TRACKING_INFO_BY_TRIP_ID_URL } from "./../../utils/api-url";
import { message } from "antd";

export const getPickupLocationByTripId=(tripId, onSuccess=()=>{},onFailure=()=>{})=>{
    postCall(GET_TRACKING_INFO_BY_TRIP_ID_URL,{tripId})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload.trackingInfo);
         }else{
          onFailure();
           if(findresponce.status === "SUCCESS"){
            message.error(findresponce.payload.Message);
           }else{
            message.error("Failed to get staff!");
           }
         }
      })
}