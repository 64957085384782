import React  from 'react';
import { useSelector } from 'react-redux';
import './loading.style.scss';
import { loadingReducerSelector } from '../../selector/loading-selectors';

export const LoadingComponent = (props) => {

    const {
        loadingActionRunning
    } = useSelector(loadingReducerSelector);

    if(loadingActionRunning){
        return (
            <div className="loading">
               
            </div>
        );
    }else{
         return null
    }
}
