import React  from 'react';
import PropTypes from 'prop-types';
import { Row, Form , TimePicker , Typography} from 'antd';
const { Title } = Typography;

export const ScreenTwo = (props) => {

const {
    screenTwoForm   
} = props;

  return (
      <div>
            <Row>
                <Title level={4}>New Starting Time</Title>
            </Row>
            <div>
            <Form
                    initialValues={{

                    }}
                    name="basic"
                    form={screenTwoForm}
                    >
                        <Form.Item    
                        name="newStartTiming"
                        rules={[{ required: true ,  message:"Please input new start time"}]}
                        label="">
                           <TimePicker 
                                          className='remove-clear-icon'  
                                          popupClassName={"time-picker-to-not-display-footer"}
                                          onSelect={(value)=>{
                                              screenTwoForm.setFieldsValue({
                                                newStartTiming:value
                                              })
                                            }}
                                         use12Hours 
                                         format='h:mm A'  
                                         minuteStep={5} 
                                         />
                        </Form.Item>
            </Form>
            </div>
      </div>
  );
};
