// import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import './zoom.style.scss';


function ZoomComponent(props) {
  if(props.isZoomEnabled){
    return( 
          <div className="zoom-scale">
              {props.children}
          </div>
      )
  }else{
    return(
      props.children
    )
  }
}

export default ZoomComponent;
// note :https://prc5.github.io/react-zoom-pan-pinch/?path=/docs/docs-props--page

{/* <TransformWrapper 
centerZoomedOut={true}
centerOnInit={false}
initialPositionX={0}
initialPositionY={0}
minPositionX={0}
maxPositionX={0}
minPositionY={0}
maxPositionY={0}
minScale={0.5} 
maxScale={1}
initialScale={0.5} 
panning={{excluded: ["input","div"]}}  //enable touchable properties for iput and div
zoomAnimation={{disabled: true}} 
// limitToBounds={false}
// disabled={true}
// wheel={{disabled:true}}
// pinch={{disabled:false}}
// doubleClick={{disabled:true}}	
// alignmentAnimation={{disabled:false,sizeX:0,sizeY:0}}
>
      <TransformComponent>
          {props.children}
      </TransformComponent>
  </TransformWrapper> */}