import React, {useState,useEffect} from 'react';
import {SeatLayout} from "./../../seat-layout/seat-layout.component";
import {getSeatGrid} from "./../../seat-layout/seat-layout-container.module";


export const SeatLayoutContainer = props => {

    const{
        seatsData,
        selectSeat=()=>{},
        selectedSeatNumbers,
        showFarePopOver=true,
        dontAllowSelection=false
    } = {...props};


    const [showSeatLayout, updateShowSeatLayout] = useState(false);
    const [seatGrid, updateSeatGrid] = useState([[]]);

    const onSeatCLick = (object) => {
        let bool= selectSeat(object);
        return bool;
    };

    useEffect(() => {
        if(seatsData !== undefined && seatsData.length > 0) {
            const {
                seatGridLayout
            } = getSeatGrid(seatsData,onSeatCLick,selectedSeatNumbers,showFarePopOver,dontAllowSelection);
            const seatGridSeats = seatGridLayout.lowerSeatGrid;
            if(seatGridSeats.length > 1) {
                updateSeatGrid(seatGridLayout);
                updateShowSeatLayout(true);
            }
        }
        else {
            updateSeatGrid([[]]);
            updateShowSeatLayout(false);
        }
    },[seatsData,selectedSeatNumbers]);

    return (
        <div>
            <div className="seat-layout-parent-container" id="seat-layout">
                {showSeatLayout &&
                    <SeatLayout 
                        seatGridLayout={seatGrid} 
                        showFarePopOver={showFarePopOver}
                    />
                }
            </div>
        </div>
    );
};

SeatLayoutContainer.defaultProps = {

};

SeatLayoutContainer.propTypes = {

};
