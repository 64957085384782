import { isValid, isValidNumber } from "./utilities";

export const roundNumber = (num) => {
  if(isValidNumber(num)){
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }else{
    return 0;
  }
};

export const roundToWholeNumber = (num) =>Math.round(num);

export const roundOffWithCeilToInteger = (num) =>Math.ceil(num);


export const addFloatingPoints = (a,b) =>{
    // if (isWholeNumber(a)||isWholeNumber(b)){
    //     return (a+b);
    // }
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA + intB;
    return (intC/100);
}

export const subtractFloatingPoints = (a,b) => {
    if (isWholeNumber(a)||isWholeNumber(b)){
        return (a-b);
    }
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA - intB;
    return (intC/100);
}

export const multiplyFloatingPoints = (a,b) => {
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA * intB;
    return (intC/10000);
}

export const divideFloatingPoints = (a,b) => {
    let intA = Math.round(a * 100);
    let intB = Math.round(b * 100);
    let intC = intA / intB;
    return (intC);
}

const isWholeNumber = (n) => {
   var result = (n - Math.floor(n)) !== 0;
  if (result){
    return false;
  }
   else{
    return true;
   }
}

export const floatingPointRoundOff = (number,digits) => {

  if(isNaN(number)){
    return 0.00;
  }else{
    return number.toFixed(digits);
  }
}

export const getValueFromPercentage = (inputValue,inputPercentage)=>{
  return (inputValue*inputPercentage)/100
}

export const getPercentage=(x, y)=> {
  return (x / y) * 100;
}

export const convertMetersToKm = (distanceInMeters,decimal=0)=>{
  if(isValid(distanceInMeters)){
    var distance = distanceInMeters / 1000;
    return distance.toFixed(decimal);
  }else{
    return null;
  }
}

export const convertToCurrencyNumber = (value) => {
  return isValidNumber(value)?new Intl.NumberFormat('en-IN').format(value,):value;
}