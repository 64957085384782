/**
 * @description All the sagas must be imported and combined here
 */

 import { all, fork } from 'redux-saga/effects'
 import watchUsersSagas  from './user.saga';
 import watchHomePageSagas from './home.saga';
 import watchTripExpensePageSagas from './trip-expense.saga';
 import watchAgentsSagas  from './agents.saga';
 import watchTripCreatePageSagas from './trip-create.saga';
 import watchDashboardPageSaga from './dash-board.saga';
 import watchRevenueSagas from './revenue.saga';
import watchCityPageSaga from './cities.saga';
 
 export function* rootSaga() {
   yield all([
     fork(watchUsersSagas),
     fork(watchHomePageSagas),
     fork(watchTripExpensePageSagas),
     fork(watchAgentsSagas),
     fork(watchTripCreatePageSagas),
     fork(watchDashboardPageSaga),
     fork(watchRevenueSagas),
     fork(watchCityPageSaga)
   ])
 }