import { getAPIDateFormat } from "../../../utils/date.utils";
import { isValidArray } from "../../../utils/utilities";


export const formateDataForDayWise = (data)=>{
    let  formateData={};
      if(isValidArray(data)){
        data.forEach((row)=>{
          formateData[getAPIDateFormat(row.start_date)]=row;
        });
      }
      return formateData;
}

export const getDayStatus =(date,formatedData)=>{
    let apidate = getAPIDateFormat(date);
    if(formatedData[apidate]!=undefined&&formatedData[apidate].visible_outside==1&&formatedData[apidate].is_active==1){
          return "active-status"
    }else if (formatedData[apidate]!=undefined&&formatedData[apidate].visible_outside==0&&formatedData[apidate].is_active==1) {
          return "disable-api-status"
    }else if (formatedData[apidate]!=undefined&&formatedData[apidate].is_active==0) {
          return "blocked-status"
    }
    else{
          return "not-active-status"
    }
}
