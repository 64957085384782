import { takeLatest, all ,fork , call , put} from 'redux-saga/effects';
import {
    FETCH_REVENUE_FOR_WEEK_OTA_REPORT,
    FETCH_REVENUE_REPORT, SET_REVENUE_REPORT,
    FETCH_TODAYS_TICKET_COUNT
} from '../actions/action-types';

export function* revenueSaga(action) {
    try {

     const {
        api,
        url,
        onSuccess,
        onFailure,
        apiPayload
    } = {...action.payload};

        const apiResult = yield call(api,url,apiPayload);

        if(apiResult.status==="SUCCESS"){
            let clientPayload=apiResult.payload;
           if(clientPayload.Status==='SUCCESS'){
             let serverPayload=clientPayload.Payload;
              yield put({
                  type: SET_REVENUE_REPORT,
                  payload: {revenueDetails:serverPayload.revenueDetails}
              });
              onSuccess(serverPayload.revenueDetails);
            }else{
                onFailure();
            }
          }else{
            onFailure();
          }
    }
    catch (error) {
        console.log("getting revenue details failed :",{error});
    }
};

export function* revenueForWeekSaga(action) {
    try {

     const {
        api,
        url,
        onSuccess,
        onFailure,
        apiPayload
    } = {...action.payload};

        const apiResult = yield call(api,url,apiPayload);

        if(apiResult.status==="SUCCESS"){
            let clientPayload=apiResult.payload;
           if(clientPayload.Status==='SUCCESS'){
             let serverPayload=clientPayload.Payload;
              onSuccess(serverPayload.revenue);
            }else{
              onFailure();
            }
          }else{
            onFailure();
          }
    }
    catch (error) {
        console.log("getting revenue details failed :",{error});
    }
};

export function* fetchTodaysTicketCountSaga(action) {
  try{
    const {
      api,
      url,
      onSuccess,
      onFailure,
      apiPayload
    } = {...action.payload};

    const apiResult = yield call(api,url,apiPayload);

    if (apiResult.status==="SUCCESS") {
      let clientPayload=apiResult.payload;
      if(clientPayload.Status==='SUCCESS'){
        let serverPayload=clientPayload.Payload;
        onSuccess(serverPayload);
      }
      else{
        onFailure();
      }
    }
    else{
      onFailure();
    }
  }
  catch (error) {
    console.log("Fetching todays ticket count failed: ", error);
  }
}


function* watchRevenueSaga() {
    yield takeLatest(FETCH_REVENUE_REPORT, revenueSaga)
}

function* watchRevenueForWeekSaga() {
    yield takeLatest(FETCH_REVENUE_FOR_WEEK_OTA_REPORT, revenueForWeekSaga)
}

function* watchFetchTodaysTicketCount() {
  yield takeLatest(FETCH_TODAYS_TICKET_COUNT, fetchTodaysTicketCountSaga)
}
  
export default function* watchRevenueSagas() {
    yield all([
      fork(watchRevenueSaga),
      fork(watchRevenueForWeekSaga),
      fork(watchFetchTodaysTicketCount)
    ])
}
  