import { postCall } from '../../utils/network.util';
import { isValid } from '../../utils/utilities';
import { message } from 'antd';
import { VALIDATE_TRIP_URL } from '../../utils/api-url';


export const validateTripAction=(scheduleId,onSuccess,onFailure)=>{
    if(!isValid(scheduleId)){
        message.error("Please select trip!");
    }else{
        var cloneBus = postCall(VALIDATE_TRIP_URL, {
            scheduleId : scheduleId
          });
          cloneBus.then((data) => {
            if(data.status === "SUCCESS"){
                let clientPayload = data.payload;
                if(clientPayload.Status==="SUCCESS"){
                    onSuccess(clientPayload.payload.warningList);
                }else{
                  onFailure();
                }
            }
          })
          .catch((reason)=> {
            onFailure();
          })
    }
}