import React , {useState , useRef} from "react";
import { Button, Modal } from "antd";
import { SESSION_TIME_OUT_MINS } from './../../utils/constant';
import IdleTimer from 'react-idle-timer';

export const SessionTimeOut = (props) => {

    // const [isModalVisible, setIsModalVisible] = useState(false);
    const idleTimer = useRef(null);

    // const showModal = () => {
    //   setIsModalVisible(true);
    // };
  
    // const handleOk = () => {
    //   setIsModalVisible(false);
    // };
  
    // const handleCancel = () => {
    //   setIsModalVisible(false);
    // };

    const onActive =()=>{
        console.log("active");
    }

    const onClickReload=()=>{
        window.location.reload();
    }
    
        return (
            <div>
                <IdleTimer
                    ref={idleTimer}
                    timeout={1000 * 60 * SESSION_TIME_OUT_MINS}
                    onActive={onActive}
                    onIdle={onClickReload}
                    debounce={250}
                    />
                 {/* <Modal title="Session Expired" 
                 maskClosable={false}
                 visible={isModalVisible} 
                 onOk={handleOk} 
                 onCancel={handleCancel}
                 footer={null}
                 >
                    <p>Your session expired. Please reload the page to continue using agent portal.</p>
                    <Button onClick={onClickReload}>Reload</Button>
                </Modal> */}
            </div>
        )
}