import {
    SET_CITY_DETAILS
 } from '../actions/action-types';
  
  const initialState = {
      cities: []
  }
  
  export const cityReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
          case SET_CITY_DETAILS :
                newState["cities"] = action.payload.cities;
                return newState;
          
          default:
             return state;    
      }
  }