import React, { useState } from "react";
import { Row,Col } from "antd";
import { isValid, isValidArray, isValidNumber, getValuesOfJSonObject, sortJsonObject } from "../../utils/utilities";
import DonutChartDashBoardComponent from "../donut-chart-component/donut-chart-for-dashboard";
import './dashboard.style.scss';
import { getWeekDay } from "../../utils/date.utils";
import moment from "moment";
import { convertToCurrencyNumber } from "../../utils/math.utils";

const getAgentDetails=(agentsInfo)=>{
    if(isValidArray(agentsInfo)){
        let renderDetails = [];
        let agentList = getValuesOfJSonObject(agentsInfo);
        let agentSortList = sortJsonObject(agentList, 'sum', true);
        agentSortList.toReversed().map((agent) => {
            renderDetails.push(<Row
                gutter={[16,0]}
                justify="space-between">
                <Col className="agentName" span={12}>
                    {agent.agentName} ({agent.count} seats)
                </Col>
                <Col className="agent-value" span={8}>
                    ₹{convertToCurrencyNumber(agent.sum)}
                </Col>
            </Row>);
        })
        return(<div>
            <div className="agentsTitle">Agents</div>
            {renderDetails}
        </div>)
    }else{
        return null;
    }
}

const getSeatDetails = (seatInfo) =>{
    if(isValidArray(seatInfo)&&seatInfo.length>0){
        let detailsRender = [] , totalCount = 0 ;
        for (let index = 0; index < seatInfo.length; index++) {
            const element = seatInfo[index];
            detailsRender.push(<Row 
                gutter={[16,0]}
            justify="space-between"> 
                <Col span={12}>
                    <Row>
                        <Col className="square-box" 
                            style={{
                                backgroundColor:element.svg.fill
                            }}>{}</Col>
                        <Col className="seat-text">{element.key}</Col>
                    </Row>
                </Col>
                <Col className="seat-value" span={8}>{element.value}</Col>
            </Row>);
            totalCount = totalCount + element.value;
        }
        detailsRender.push(
            <Row
            gutter={[16,0]}
            justify="space-between"
            > 
                <Col span={12}>
                    <Row>
                        <Col className="square-box" style={{
                            backgroundColor:'#FFFFFF'
                        }}>{}</Col>
                        <Col className="seat-text totalSeatsTxt">Total Seats</Col>
                    </Row>
                </Col>
                <Col className="seat-value totalSeatsValue" span={8}>{totalCount}</Col>
            </Row>
        )
        return detailsRender;
    }else{
        return null;
    }
}

export const DonutExtentionComponent =(props)=>{

    const {
        data
    } = props;

    return(
        <div className="donut-extention-block">
            {getDateStringFormate(data,"donutdate-block-big")}
            <Row>
                <Col className="revenueText">
                    Revenue
                </Col>
            </Row>
            <Row>
                <Col className="revenueValueDashBoard">
                    ₹ {convertToCurrencyNumber(data.revenue)}
                </Col>
            </Row>
            {getSeatDetails(data.pieData)}
            {getAgentDetails(data.agentSeat)}
        </div>
    )
}

const getDateStringFormate=(info,className)=>{

    if(isValid(info.payload)){
        return (
            <div className={className}>
                <div>
                    {getWeekDay(moment(info.payload.date))}
                </div>
                <div>
                    {info.first_key}
                </div>
            </div>
        )
    }else{
        return null;
    }
}

export const DonutComponent = (props) => {
    const {
        donutData
    } = props;

    const [extentionData, setExtentionData] = useState(null);
    const [arrowClass, setArrowClass] = useState('donut-no-arrow')
    
    if(isValidArray(donutData)){
        const {
            multiple_data
        } = donutData;
        return (
            <div className="donut-root-block">
                <div className="donut-block">
                    <Row gutter={[16,16]} justify="center">
                        <Col className="occupancyTitle">
                            {donutData.title}
                        </Col>
                    </Row>
                    {isValidArray(multiple_data)&&multiple_data.length>0?
                        <Row gutter={[16,16]} justify="center" align="center">
                            {isValidArray(multiple_data[0])?
                            <Col className="donut-common dateHeader" 
                                onClick={()=>{
                                    setExtentionData(multiple_data[0]);
                                    setArrowClass('donut-arrow first-arrow');
                                }}>
                                {getDateStringFormate(multiple_data[0],"donutdate-block")}
                                <DonutChartDashBoardComponent 
                                    pieData={multiple_data[0].pieData}
                                />
                                <div className="donutdate-service-block">
                                    {multiple_data[0].second_key}
                                </div>
                            </Col>
                            :null
                            }
                            {isValidArray(multiple_data[1])?
                            <Col 
                            className="donut-common donut-second dateHeader"
                            onClick={()=>{
                                setExtentionData(multiple_data[1]);
                                setArrowClass('donut-arrow second-arrow');
                            }}>
                                {getDateStringFormate(multiple_data[1],"donutdate-block")}
                                <DonutChartDashBoardComponent
                                    pieData={multiple_data[1].pieData}
                            />
                            <div className="donutdate-service-block">
                                {multiple_data[1].second_key}
                            </div>
                            </Col>
                            :null
                            }
                            {isValidArray(multiple_data[2])?
                            <Col
                            className="donut-common donut-third dateHeader"
                            onClick={()=>{
                                setExtentionData(multiple_data[2]);
                                setArrowClass('donut-arrow third-arrow');
                            }}
                            >
                                {getDateStringFormate(multiple_data[2],"donutdate-block")}
                                <DonutChartDashBoardComponent 
                                    pieData={multiple_data[2].pieData}
                                />
                                <div className="donutdate-service-block">
                                    {multiple_data[2].second_key}
                                </div>
                            </Col>
                            :null
                            }
                        </Row>
                        :null
                    }
                </div>
                <div className={arrowClass} />
                {isValidArray(extentionData)?
                    <DonutExtentionComponent 
                        data={extentionData}
                    />
                    :null
                }
            </div>
        );  
    }else{
        return null;
    }             
}


