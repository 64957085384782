import {
    SET_DASHBOARD_DATA,
 } from '../actions/action-types';
  
  const initialState = {
      dashboard: undefined
  }
  
  export const dashboardReducer = (state = initialState,action) => {
      let newState = Object.assign({},state);
      switch (action.type) {  
          
          case SET_DASHBOARD_DATA :
                newState["dashboard"] = action.payload;
                return newState;
          
          default:
             return state;    
      }
  }