import React, {useState} from "react";
import '../styles/bus-cancel.style.scss';
import { SearchScheduleComponent } from './../components/search-components/search-schedule-component/search-schedule.component';
import { Form , Button , Row , Col } from 'antd';
import {  isValidArray } from "../utils/utilities";
import { ScheduleSelect} from './../components/search-components/schedule-select/schedule-select.component';
import { TripExpenseTable } from "../components/expense-table.component/expense-table-component/expense-table.component";

const DrawLine =()=>{
    return(
        <div className="bus-cancel-underline">

        </div>
    )
}

export const TripExpensePage = () => {
    const [schedules,setSchedules]=useState(undefined);
    const [showTripExpenseTable,setTripExpenseTable]=useState(false);
    const [tripId,setTripId] = useState(null);


    const [form] = Form.useForm();
    const handleSubmit =  (e) => {
        e.preventDefault();
        return form.validateFields()
        .then(values => {
            setTripId(values.returnId)
            setTripExpenseTable(true)
        }).catch((err)=>{
          console.log({err});
        })
    }

    return (
        <div className="bus-cancel-root">
            <div className="bus-cancel-inner-div">
                <div>
                    <h3>
                        Trip Expense Details
                    </h3>   
                    <DrawLine />         
                </div>
                <div className="bus-cancel-form-div">
                    <SearchScheduleComponent 
                    isMobileFriendly={true}
                        onSearch={(data)=>{
                            form.setFieldsValue({'returnId':null});
                            setSchedules(data);
                        }}
                    />
                    {isValidArray(schedules)&&schedules.length>0&&
                    <Form 
                    form={form}
                    >
                        <Row gutter={16}>
                            <Col>
                                <ScheduleSelect 
                                isFixedWidth={true}
                                schedules={schedules}
                                optionKey="trip_id"
                                />
                            </Col>
                            <Col>
                                <Form.Item>
                                        <Button type="primary"
                                        onClick={(e)=>{
                                            handleSubmit(e)
                                        }}
                                        >Get</Button>
                                </Form.Item>      
                            </Col> 
                        </Row>
                    </Form>
                    }
                    {
                      showTripExpenseTable?
                      <TripExpenseTable 
                      tripId={tripId}
                      />
                      : null
                    }
                </div>            
            </div>
        </div>
    )
}