import React, { useEffect, useState } from "react";
import { Row,Col , Progress} from "antd";
import './dashboard.style.scss';
import { useDispatch, useSelector } from "react-redux";
import { getRevenueData } from "../../actions/revenue.actions";
import moment from "moment";
import { loadingAction } from "../../actions/loading-actions";
import { getAPIDateFormat } from "../../utils/date.utils";
import { isValid, isValidArray } from "../../utils/utilities";
import HSBar from "react-horizontal-stacked-bar-chart";
import { getPercentage, roundNumber, convertToCurrencyNumber } from "../../utils/math.utils";
let xs=24, sm=24, md=12 , lg=12 , xl=12, xxl=12;

// https://github.com/ricardodorosario/react-horizontal-stacked-bar-chart

const renderRevenueDetails=(info,currentDate)=>{
    let revenueCard = [] , currentRevenue = 0 ,maxRevenue = 0 , booked = 0 ,breakevenRevenue=10000,
    blocked = 0 ,adminBlocked = 0 , vacant = 0 , percentailBooked =0 ,tripCount = 0;
    if(isValidArray(info)&&info.length>0){
        for (let index = 0; index < info.length; index++) {
            const element = info[index];
            let trips = element.trips;
            for (const key in trips) {
                if (Object.prototype.hasOwnProperty.call(trips, key)) {
                    const element = trips[key];                    
                    if(currentDate == key){
                        // breakevenRevenue = element.breakevenRevenue;
                        currentRevenue = element.currentRevenue;
                        maxRevenue = element.maxRevenue;
                        booked = element.confirmedSeats;
                        vacant = element.availableSeats;
                        // percentailBooked = roundNumber(getPercentage(booked,booked+vacant));
                        tripCount = tripCount + 1;
                    }
                }
            }

            if(booked!=0||vacant!=0){

                let revenueClassName = "progress-bar-indicator";
                let revenuePercent = roundNumber((currentRevenue*100)/maxRevenue);

                if (revenuePercent<=20) {
                    revenueClassName+=" first-color"
                }
                else if (revenuePercent>20&&revenuePercent<=40) {
                    revenueClassName+=" second-color"
                }
                else if (revenuePercent>40&&revenuePercent<=60) {
                    revenueClassName+=" third-color"
                }
                else if (revenuePercent>60&&revenuePercent<=80) {
                    revenueClassName+=" fourth-color"
                }
                else {
                    revenueClassName+=" fifth-color"
                }
                
                let percentailBreakeven = roundNumber(getPercentage(breakevenRevenue,maxRevenue));

                revenueCard.push(<div className="trip-summary-each-trip">
                    <Row gutter={[16,16]} justify="center" align="middle">
                    <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl} 
                    className="trip-description">
                            {element.description}
                        </Col>
                        <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                            <HSBar
                                className="horizontal-bar"
                                height={8}
                                radius={10}
                                showTextIn={false}
                                showTextUp={true}
                                showTextDown={false}
                                // outlineWidth= {0.5}
                                // outlineColor= "black"
                                id="new_id"
                                // fontColor="rgb(50,20,100)"
                                data={[
                                    {
                                    name: "",
                                    value: booked,
                                    description: booked,
                                    color: "#81D653"
                                    },
                                    {
                                    name: "",
                                    value: vacant,
                                    description: vacant,
                                    color: "#E50027"
                                    }
                                ]}
                                onClick={e => console.log(e.bar)}
                                />
                                    <span>₹{convertToCurrencyNumber(currentRevenue)}</span>
                                    <span className="maxRevenue">₹{convertToCurrencyNumber(maxRevenue)}</span>
                                    <div class="progress-bar-container">
                                        <div class={revenueClassName} style={{"width":`${revenuePercent}%`}}>
                                        </div>
                                        {isValid(percentailBreakeven)&&
                                        <div class="line" style={{left:percentailBreakeven}}></div>
                                        }
                                    </div>
                        </Col>
                    </Row>
                </div>);
            }
        }        
        return (
            <div  className="daily-trip-summary-extention">
                <div className="no-of-trips-block">
                    {`Number of Trips           ${tripCount}`}
                </div>
                {revenueCard}
            </div>);
    }else{
        
        if(isValidArray(info)){            
            return (
                <div  className="daily-trip-summary-extention">
                    No active trips
                </div>
            )
        }
        return null
    }
}

export const DailySummaryComponent = (props) => {

    const {
        
    } = props

    const [currentDate,setCurrentDate] = useState(null);
    const [ revenueDetails ,setRevenueDetails ] = useState(null);

    const dispatch = useDispatch();

    const onSuccess=(data)=>{
        setRevenueDetails(data)
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }



   return(
    <div className="daily-trip-summary-root">
        <div 
        className="daily-trip-summary"
        onClick={()=>{
            setLoading(true);
            const fromDate = getAPIDateFormat(moment());
            setCurrentDate(fromDate);
            dispatch(getRevenueData(fromDate,fromDate,onSuccess,onFailure));
        }}>
            Daily Trip Summary
        </div>
        {renderRevenueDetails(revenueDetails,currentDate)}
    </div>
   );
}


