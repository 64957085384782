import { Form , Row , Col , DatePicker , Button , Table} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getRangeOptions } from './reports.module';
import { getServiceCount } from './../../actions/reports.action';
import { getAPIDateFormat } from "../../utils/date.utils";
import { loadingAction } from "../../actions/loading-actions";
import { isValidNumber } from "../../utils/utilities";

const { RangePicker } = DatePicker;
let allowedMaxDaysRange=31;

export const ServiceCountReport = (props) => {

    const [form] = Form.useForm();
    const dispatch=useDispatch();
    const [serviceCounts,setServiceCounts] = useState([]);


    const onSuccess=(data)=>{
        setServiceCounts(data.serviceCounts);
        setLoading(false);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange
        }=values;
        setLoading(true);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);  
        getServiceCount(fromDate,toDate,onSuccess,onFailure);
    }

    
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

  
      return (
          <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date range" 
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Get</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row>
                    <Col>
                            <Table 
                                columns={[
                                    {
                                        title:"Operator Name",
                                        dataIndex:"operatorName",
                                        key:"operatorName"
                                    },
                                    {
                                        title:"No of trips",
                                        dataIndex:"count",
                                        key:"count"
                                    },
                                    {
                                        title:"No of trips with seat sharing",
                                        dataIndex:"seatSharingCount",
                                        key:"seatSharingCount"
                                    }
                                ]}
                                dataSource={serviceCounts}
                                pagination={false}
                                summary={(pageData ) => {
                                        let summaryCount = 0,summarySeatSharingCount = 0;
                                        pageData.forEach(({ count,seatSharingCount }) => {
                                            if(isValidNumber(count)){
                                                summaryCount += count;
                                            }

                                            if(isValidNumber(seatSharingCount)){
                                                summarySeatSharingCount += seatSharingCount;
                                            }
                                        });
        
                                        return(
                                            <Table.Summary fixed>
                                            <Table.Summary.Row className="fontWeightBold">
                                              <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                                              <Table.Summary.Cell index={1}>{summaryCount}</Table.Summary.Cell>
                                              <Table.Summary.Cell index={2}>{summarySeatSharingCount}</Table.Summary.Cell>
                                            </Table.Summary.Row>
                                          </Table.Summary>
                                        )
                                }}
                            />
                    </Col>
                </Row>
          </div>
      )
  }
            

      
