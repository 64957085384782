import { GET_ROUTES_BY_SCHEDULE_ID_URL } from './../../utils/api-url';
import {  postCall } from '../../utils/network.util';
import { message } from 'antd';

  export const getViaCities=(scheduleId,onSuccess,onFailure)=>{
      postCall(GET_ROUTES_BY_SCHEDULE_ID_URL,{"scheduleId" :scheduleId})
      .then((findresponce) =>
        {
          if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
             onSuccess(findresponce.payload.Payload.routes);
           }else{
            onFailure();
             if(findresponce.status === "SUCCESS"){
              message.error(findresponce.payload.Message);
             }else{
              message.error("Failed to get cities!");
             }
           }
        })
  }

export const getOriginsDestinations =(data)=>{
  let origins=[] , destinations=[];
  data.forEach(element => {
    if(origins.indexOf(element.origin) === -1){
      origins.push(element.origin);
    }
    if(destinations.indexOf(element.destination) === -1){
      destinations.push(element.destination);
    }
  });
  return {
    filteredOrigins:origins,
    filteredDestinations:destinations
  };
}