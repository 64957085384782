import React, { useState } from "react";
import { Form , Button ,Row ,Col , DatePicker, Table } from 'antd';
import { getRangeOptions } from './reports.module';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { getAPIDateFormat, getReadableDate } from "../../utils/date.utils";
import { useEffect } from "react";
import moment from "moment";
import { getPrePaidTrasactionsSummary } from "../../actions/reports.action";

let allowedMaxDaysRange=31;
const { RangePicker } = DatePicker;


export const PrePaidTransactionsSummary = () => {

    const [form] = Form.useForm();
    const [dates,setDates] = useState(moment());
    const [transactionData,setTransactionData] = useState([]);

    const dispatch = useDispatch();

    const [showReport,setShowReport]=useState(false);

    const onSuccess=(data)=>{
        setTransactionData(data.transactions);
        setLoading(false);
        setShowReport(true)
    }

    const onFailure=()=>{
        setLoading(false);
        setShowReport(false);
    }

    const onFinish=(values)=>{
        let {
            dateRange
        } = values;
        setDates(dateRange);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        const fromDate = getAPIDateFormat(dates[0]);
        const toDate = getAPIDateFormat(dates[1]);
        console.log({fromDate,toDate});
        setLoading(true);
        getPrePaidTrasactionsSummary(fromDate,toDate,onSuccess,onFailure);
    },[dates]);


    let transactionsColumns = [
        {
            title:"Date",
            dataIndex:"date",
            render:(text)=>{
                return getReadableDate(text)
            }
        },
        {
            title:"Description",
            dataIndex:"description"
        },
        {
            title:"Amount",
            dataIndex:"amount"
        },
        {
            title:"Payment type",
            dataIndex:"payment_type"
        },
        {
            title:"Status",
            dataIndex:"status"
        }    
    ];

    console.log({transactionData});

    return (
            <div className="report-inner-div">
                <Form
                    onFinish={onFinish}
                    form={form}
                    >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item name="dateRange" label="Date of payment" 
                                rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                            validator(_, value) {
                                            if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                            },
                                        })
                            ]}>
                                    <RangePicker 
                                        ranges={getRangeOptions(allowedMaxDaysRange)} 
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {showReport?
                    <Row>
                        <Col>
                            <Table 
                                className="bordered-table"
                                columns={transactionsColumns}
                                dataSource={transactionData}
                                pagination={false}
                            />
                        </Col>
                    </Row>
                    :null
                }
            </div>
    )
}