import React from 'react'
import { Text, StyleSheet } from '@react-pdf/renderer'
import { config } from './config'

const styles = StyleSheet.create({
  title: {
    fontFamily: 'Lato',
    fontSize: config.sectionTitleFontSize + 3,
    fontWeight: 'bold',
    width: '100%',
    borderBottomWidth: 2,
    borderBottomColor: "#000000"
  }
})

const Title = ({ children,style }) => <Text style={[styles.title,style]}>{children}</Text>

export default Title
