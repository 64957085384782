import React, { useEffect, useState } from "react";
import './bus-location.style.scss';
import { getPickupLocationByTripId } from "./bus-location.module";
import BusLocationMapComponent from "../standard-stops/map-component/bus-location-map.component";
import { isValid, isValidArray } from "../../utils/utilities";
import { Divider } from "antd";
import { useDispatch } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";


export const BusLocationComponent = (props) => {

    const {
        tripId
    } = props;

    const [trackerData,setTrackerData] = useState({});

    const dispatch = useDispatch();
    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    useEffect(()=>{
        setLoading(true);
        getPickupLocationByTripId(tripId,(trackingInfo)=>{
            setTrackerData(trackingInfo);
            setLoading(false);
        },()=>{
            setLoading(false);
        });
    },[tripId]);
    if(isValidArray(trackerData)&&isValid(trackerData.latitude)&&isValid(trackerData.longitude)){
        return(
            <div className="tab-block">
                <div className="search-bus-location-tab-root">
                    <BusLocationMapComponent  latitude={trackerData.latitude} longitude={trackerData.longitude}/>
                </div>
            </div>
           
        );
    }else{
        return(
            <div className="tab-block">
                <div className="search-bus-location-tab-root">
                    Bus location is currently not available
                </div>
            </div>
        )
    }
}
