
import { ACTIVATE_IN_OTAS_URL } from "../../../utils/api-url";
import { postCall } from "../../../utils/network.util";
import { message } from 'antd';
 
export const activateInOTAs=(tripId,onSuccess=()=>{},onFailure=()=>{},)=>{
    postCall(ACTIVATE_IN_OTAS_URL,{operatorCode:global.operatorCode,tripId:tripId})
    .then((findresponce) =>
      {
        if(findresponce.status === "SUCCESS" && findresponce.payload.Status === "SUCCESS"){
           onSuccess(findresponce.payload.Payload);
           message.success("Informed sucessfully!");
         }else{
          onFailure();
           if(findresponce.status === "SUCCESS"){
            message.error(findresponce.payload.Message);
           }else{
            message.error("Failed toinform otas!");
           }
         }
      }).catch((err)=>{
        console.log({err});
        message.error("Failed toinform otas!");
    })
}