import{
    GET_DASHBOARD_DATA
} from './action-types';
import{
    GET_DASHBOARD_DATA_URL,
} from '../utils/api-url';
import { postCall } from "../utils/network.util";
import { getAPIDateFormat } from '../utils/date.utils';

export const getDashboardData = (date,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: GET_DASHBOARD_DATA,
        payload: {
            url:GET_DASHBOARD_DATA_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              "travelsId":global.travelsId,
              "date":getAPIDateFormat(date)
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }