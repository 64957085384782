import React, { useEffect, useState } from 'react';
import { getScheduleWithStops, setCitiesWithStops, updateStopTimings } from './../../../actions/trip-create.actions';
import { Steps , Row , Col, message, Button , TimePicker , Divider, Input , Switch, Checkbox } from 'antd';
import { getCloneArray, isValid, isValidArray } from '../../../utils/utilities';
import { addMin, getTimeStampInReadableFormat } from './../../../utils/date.utils';
import './verify-trip.style.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadingAction } from '../../../actions/loading-actions';
import moment from 'moment';
import { loadingReducerSelector } from '../../../selector/loading-selectors';
import { AdjustTimePopUp } from './../adjust-time-pop-up/adjust-time-pop-up.component';
import { FinalSummary } from './final-summary.component';

const page1='page1';
const page2='page2';

const { Step } = Steps;

const updateTiming=(tripCitiesWithStops,fromStop,toStop,mins)=>{
    let updateTime = false;
    tripCitiesWithStops.forEach((element,index1) => {
        element.stops.forEach((ele,index2)=>{
          if(fromStop === ele.display_name){
              updateTime = true;
          }  
          if(updateTime){
            let day = element.day;
              if(isValid(element.time)&&ele.is_main_stop==1){
                let momentObject = moment(element.time, 'HH:mm');
                let time = addMin(momentObject,mins).format("HH:mm:ss");
                if(time<element.time&&mins>0){
                    day = day + 1;
                }
                if(time>element.time&&mins<0){
                    day = day - 1;
                }
                tripCitiesWithStops[index1].time = time;
                tripCitiesWithStops[index1].day = day;
              }else{
                day = ele.day;
                let momentObject = moment(ele.time, 'HH:mm');
                let time = addMin(momentObject,mins).format("HH:mm:ss");
                if(time<ele.time&&mins>0){
                    day = day + 1;
                }
                if(time>ele.time&&mins<0){
                    day = day - 1;
                }
              }
              if(isValid(ele.time)){
                let momentObject = moment(ele.time, 'HH:mm');
                let time = addMin(momentObject,mins).format("HH:mm:ss");
                tripCitiesWithStops[index1].stops[index2].time = time;
                tripCitiesWithStops[index1].stops[index2].day = day;
              }
          }
          if(toStop === ele.display_name){
              updateTime= false;
          }
        })
    });
    return [...tripCitiesWithStops];
}

export const VerifyTrip = (props) => {

    const{
        scheduleId,
        resetAllData
    } = props;

    const [tripCitiesWithStops , setTripCitiesWithStops] = useState([]);
    const [cacheInfo , setCacheInfo] = useState([]);
    const [isEditTime , setIsEditTime] = useState(false);
    const [schedule , setSchedule] = useState({});
    const [page , setPage] = useState(page1);
    const [tripClosePoint , setTripClosePoint] = useState(null);

    const dispatch = useDispatch();

    const {
        loadingActionRunning
    } = useSelector(loadingReducerSelector);

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }


    const onSuccess=(data)=>{
        let{
            citiesWithStops
        } = data;
        setTripCitiesWithStops(citiesWithStops);
        setLoading(false);
        setSchedule(data.schedule);
        dispatch(setCitiesWithStops(citiesWithStops));
    }

    const onFailure=(errorMessage)=>{
        message.error(errorMessage);
        setLoading(false);
    }

    const refreshTripStops=(isGoogleTimings)=>{
        if(isValid(scheduleId)){
            setLoading(true); 
            getScheduleWithStops(scheduleId,isGoogleTimings,onSuccess,onFailure);
        }
    }

    useEffect(()=>{
        refreshTripStops(false);
    },[scheduleId]);


    const renderInfo=(element,className,onChange=()=>{},onChangeDay=()=>{},onChangeStop=()=>{},onChangeTripClosePoint=()=>{},editEnabled,showMainStop,showTripClose)=>{

        return(
            <Row gutter={[16,16]} flex='1'>
                <Col className={`city-col ${className}`} >{element.display_name}</Col>
                {isValid(element.time)?
                <Col className={`time-col ${className}`}>
                    {editEnabled?
                        <TimePicker 
                        className='remove-clear-icon remove-timer-icon'
                        popupClassName={"time-picker-to-not-display-footer"}
                        format={'hh:mm a'} 
                        use12Hours 
                        value={moment(element.time, 'HH:mm')}
                        onSelect={onChange}
                        onChange={onChange}
                        placeholder="Time"
                        />
                        :
                        getTimeStampInReadableFormat(element.time)
                    }
                </Col>
                :
                <Col className={`time-col ${className}`}>
                    {editEnabled?<TimePicker format={'hh:mm a' } use12Hours onChange={onChange} placeholder="Time" />:null}
                </Col>
                }
                {isValid(element.day)&&element.day>0?
                <Col className={`day-col ${className}`}>
                    {editEnabled?
                        <Input onChange={onChangeDay} value={element.day} style={{width:40}}/>
                        :
                        element.day
                    }
                </Col>
                :<Col className={`day-col ${className}`}>
                    {editEnabled?
                        <Input onChange={onChangeDay} style={{maxWidth:40}}/>
                        :null
                    }
                </Col>
                }
                {showMainStop?
                <Col className={`is-main-stop ${className}`}>
                    <Switch checked={element.is_main_stop} onChange={onChangeStop} size="small"/>
                </Col>
                :<Col className='is-main-stop'>
                </Col>
                }
                {showTripClose?
                    <Col className={`trip-close-point ${className}`}>
                        <Checkbox className='verify-check-box' 
                        checked={element.is_trip_close_point}
                        onChange={(e)=>{
                            onChangeTripClosePoint(e.target.checked);
                        }}
                        />
                    </Col>
                    :null
                }
            
            </Row>
        )
    }

    const onChangeStopTime=(index1,index2,time)=>{

        let compareIndex = index2 + 1;
        let validTime = null;
        let formatedTime = time.format("HH:mm:ss");

        if(isValidArray(tripCitiesWithStops[index1].stops[compareIndex])){
            if(
                tripCitiesWithStops[index1].stops[index2].day>tripCitiesWithStops[index1].stops[compareIndex].day ||
                (tripCitiesWithStops[index1].stops[compareIndex].time < formatedTime && tripCitiesWithStops[index1].stops[index2].day === tripCitiesWithStops[index1].stops[compareIndex].day)
            ){
                validTime = tripCitiesWithStops[index1].stops[compareIndex].time;
            }
        }else{
            let compare1stIndex = index1 +1;
            if(isValidArray(tripCitiesWithStops[compare1stIndex])&&isValidArray(tripCitiesWithStops[compare1stIndex].stops)){
                let nextCityStop = tripCitiesWithStops[compare1stIndex].stops[0];
                if(isValidArray(nextCityStop)){
                    if(
                        tripCitiesWithStops[index1].stops[index2].day>nextCityStop.day||
                        (nextCityStop.time < formatedTime && tripCitiesWithStops[index1].stops[index2].day === nextCityStop.day)
                    ){
                        validTime = nextCityStop.time;
                    }
                }
            }
        }
        if(!isValid(validTime)){
            tripCitiesWithStops[index1].stops[index2].time = time.format("HH:mm:ss");
            if(tripCitiesWithStops[index1].stops[index2].is_main_stop){
                    tripCitiesWithStops[index1].time = time.format("HH:mm:ss");
            }
            setTripCitiesWithStops([...tripCitiesWithStops]);
        }else{
            message.warning(`Given time should be before : ${getTimeStampInReadableFormat(validTime)}`);
        }
    }


    const onChangeStopDay=(index1,index2,day)=>{
        tripCitiesWithStops[index1].stops[index2].day = day;
        if(tripCitiesWithStops[index1].stops[index2].is_main_stop){
            tripCitiesWithStops[index1].day = day;
        }
        setTripCitiesWithStops([...tripCitiesWithStops]);
    }

    const onChangeTripClosePoint=(index1,index2,checked)=>{
        tripCitiesWithStops[index1].stops.forEach((element,index)=>{
            tripCitiesWithStops[index1].stops[index].is_trip_close_point = false;
        });

        tripCitiesWithStops[index1].stops[index2].is_trip_close_point = checked;
        setTripCitiesWithStops([...tripCitiesWithStops]);
    }

    const onChangeMainStop=(index1,index2,checked)=>{
        tripCitiesWithStops[index1].stops.forEach((element,index)=>{
            tripCitiesWithStops[index1].stops[index].is_main_stop = false;
        });
        if(checked){
            tripCitiesWithStops[index1].time = tripCitiesWithStops[index1].stops[index2].time;
            tripCitiesWithStops[index1].day = tripCitiesWithStops[index1].stops[index2].day;
        }

        tripCitiesWithStops[index1].stops[index2].is_main_stop = checked;
        setTripCitiesWithStops([...tripCitiesWithStops]);
    }

    const renderSteps=()=>{
        let steps=[] ;
        if(isValidArray(tripCitiesWithStops)){
            tripCitiesWithStops.forEach((element,index1)=>{
                let {
                    stops
                } = element;
                let stopsArray=[];
                let showMainStop = stops.length>1?true:false;
                if(isValidArray(stops)){
                    stops.forEach((ele,index2)=>{
                        stopsArray.push(renderInfo(ele,'',(time)=>{onChangeStopTime(index1,index2,time);},(e)=>{onChangeStopDay(index1,index2,e.target.value)},(checked)=>{onChangeMainStop(index1,index2,checked)},(checked)=>{onChangeTripClosePoint(index1,index2,checked)},isEditTime,showMainStop,true));
                    });
                }
                steps.push(<Step title={renderInfo(element,'fontWeightBold marginBottom',null,null,null,null,false,false,false)} disabled={true} description={stopsArray}/>);
            });
        }
        return steps;
    }

    if(loadingActionRunning){
        return null;
    }

    if(page === page2){
        return(
            <FinalSummary 
                resetAllData={resetAllData}
                schedule={schedule}
                tripCitiesWithStops={tripCitiesWithStops}
                onBack={()=>{
                    setPage(page1);
                }}
                tripClosePointStopId={tripClosePoint}
            />
        )
    }

    const onNextClick=(isUpdateClick)=>{
                            let justStops = [];
                            let isUnknownTimeExits = false;
                            let isTripClosePoint = false;
                            let isTripClosePointAtFinalCity = false;
                            tripCitiesWithStops.forEach((element,index) => {
                                if(!isValid(element.time)){
                                    isUnknownTimeExits = true;
                                }
                                element.stops.forEach(ele => {
                                    if(!isValid(ele.time)){
                                        isUnknownTimeExits = true;
                                    }
                                    ele.stop_time = ele.time;
                                     let day = ele.day - 1;

                                    justStops.push({...ele,day});
                                    if(ele.is_trip_close_point){
                                        isTripClosePoint = true;
                                        if(index == tripCitiesWithStops.length -1){
                                            isTripClosePointAtFinalCity = true;
                                        }
                                    }
                                });
                            });
                            if(isUnknownTimeExits){
                                message.warning("Please enter time for all stops!");
                            }else if(!isTripClosePoint){
                                message.warning("Please select trip close point!");
                            }else if(isTripClosePointAtFinalCity){
                                message.warning("Final destination cannot be trip close point!"); 
                            }
                            else{
                                updateStopTimings(justStops);
                                if(isUpdateClick){
                                    setIsEditTime(false);
                                }else{
                                    setPage(page2);
                                }
                            }
    }

    return (
        <div className='verify-root-div'>
            <Row align='middle' justify='center' gutter={[16,16]}>
                <Col>
                    <Button onClick={()=>{
                        refreshTripStops(true);
                    }}>Refresh</Button>
                </Col>
            </Row>
            <Row gutter={[16,16]} style={{paddingLeft:'50px'}}>
                <Col className={`city-col fontWeightBold`}>
                    Stop
                </Col>
                <Col className={`time-col fontWeightBold`}>
                    Time
                </Col>
                <Col className={`day-col fontWeightBold`}>
                    Day
                </Col>
                <Col className='is-main-stop fontWeightBold'>
                </Col>
                <Col className={`trip-close-point fontWeightBold`}>
                    Trip Close
                </Col>
            </Row>
            <Divider className="trip-divider"/>
            <Steps direction={'vertical'} current={null}>
                {renderSteps()}
            </Steps>
            {isEditTime?
                <Row gutter={[16,16]} justify="center">
                    <Col>
                        <Button className='cancel-time-btn' onClick={()=>{
                            setTripCitiesWithStops([...cacheInfo]);
                            setIsEditTime(false); 
                        }}>
                            Cancel
                        </Button>
                    </Col>
                    <Col>
                        <Button className='next-time-btn'onClick={()=>{
                            onNextClick(true);
                        }}>
                            Update
                        </Button>
                    </Col>
                </Row>
                :
                <Row gutter={[16,16]} justify="center">
                    <Col>
                        <AdjustTimePopUp 
                            tripCitiesWithStops={tripCitiesWithStops}
                            adjustTimeForStop = {true}
                            disableAdjustBtn = {false}
                            onUpdate={(fromStop,toStop,mins)=>{
                                setTripCitiesWithStops(updateTiming(tripCitiesWithStops,fromStop,toStop,mins));
                            }}
                            startTime={schedule.start_time}
                        />
                    </Col>
                    <Col>
                        <Button className='edit-time-btn' onClick={()=>{
                            setCacheInfo(getCloneArray(tripCitiesWithStops));
                            setIsEditTime(true); 
                        }}>
                            Edit
                        </Button>
                    </Col>
                    <Col>
                        <Button className='next-time-btn'
                        onClick={()=>{
                            onNextClick(false);
                        }}
                        >
                            Next
                        </Button>
                    </Col>
                </Row>
            }
        </div>
    )
}