import { Button } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import '../styles/standard-stops.style.scss';
import { StandardStopsTable } from './../components/standard-stops/standard-stops-table/standard-stops-table.component';
import { getStandardStops } from "../actions/address.actions";
import { useDispatch, useSelector } from "react-redux";
import { standardStopsSelector } from "../selector/trip-create.selector";

const DrawLine =()=>{
    return(
        <div className="standard-stops-underline">

        </div>
    )
}

export const StandardStopsPage = (props) => {

    let history = useHistory();

    const dispatch = useDispatch();

    const standardStops = useSelector(standardStopsSelector);

    useEffect(()=>{
       intialLoad();
    },[]);

    const intialLoad=()=>{
        dispatch(getStandardStops());
    }

    const onClickCreateNew=()=>{
        history.push('/create-standard-stop');
    }

    return (
        <div className="standard-stops-root">
          <div className="standard-stops-inner-div">
            <div>
                <h3>
                    Standard Stops
                </h3>            
                <DrawLine />
            </div>
            <Button onClick={onClickCreateNew}>Create new</Button>
            <StandardStopsTable standardStops={standardStops} reload={intialLoad}/>
          </div>
        </div>
    )
}