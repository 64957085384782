import React from 'react';
import { Tooltip } from 'antd';
import { getLocalDateAndTime } from '../../utils/date.utils';

const getPassenger=(passenger,index)=>{

    return(
        <div className='passengerDetailsBlock'>
            <span>Passenger {index+1}</span>
            <div>{passenger.origin} to {passenger.destination}</div>
            <div>{passenger.full_name},{passenger.gender},{passenger.seat_number}</div>
            <div>Pickup({passenger.pickup_location_name})</div>
            <div>Drop({passenger.drop_location_name})</div>
            <div>Booked By({passenger.user_name})</div>
            <div>Booked Time({getLocalDateAndTime(passenger.booking_time)})</div>
        </div>
    )
}

const getPassengerList=(passengers)=>{

    let passengersList=[];

        passengers.forEach((element,index) => {
            passengersList.push(getPassenger(element,index));

        });
    return passengersList;
}

export const  ShowMultiplePassengers =(props)=> {

    const{
        passengers
    } = props;

    if(passengers.length == 0){
        return null
    }
    
    let toolTipInfo = <div className={'passengerCountBlock'}>
                        <div className='passengerCount'>{passengers.length}</div>
                    </div>;

    return (
        <Tooltip title={getPassengerList(passengers)} trigger={"hover"}>
                {toolTipInfo}
        </Tooltip>
    );
}