import React, { useState } from "react";
import { Form , DatePicker, Button , Row, Col ,Typography , Radio , Table , Popover, message} from 'antd';
import { loadingAction } from './../../actions/loading-actions';
import { useDispatch } from "react-redux";
import { fetchTransactionSheet } from './../../actions/reports.action';
import { Printer } from './../../components/custom-components/custom-component';
import { isValidArray, isValidNumber } from "../../utils/utilities";
import { getAPIDateFormat  , getLocalDateAndTime } from './../../utils/date.utils';
import { getRangeOptions } from './reports.module';
import { subtractFloatingPoints as SFP , addFloatingPoints as AFP, roundNumber} from './../../utils/math.utils';
import ReactExport from "react-export-excel";
import { totalTransactionReportColumns , COMM_TDS , NET_AGENT_COMM , TOTAL_CANCEL_PENALITY ,NET_AMOUNT} from './reports-config';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;


const { Text } =Typography;

const { RangePicker } = DatePicker;

const generateJsonToExport=(reportData,form ,{
    isShowNetAmount,
    isShowCancellationPenality,
    isShowNewAgentComm,
    isShowTdsComm
})=>{

    let {
        dateRange,
        busType,
        isBookingDate
    } = form.getFieldsValue();
    if(!(isValidArray(dateRange)&&dateRange.length>0)){
        return null
    }
    const fromDate = getAPIDateFormat(dateRange[0]);
    const toDate = getAPIDateFormat(dateRange[1]);
    let filterData=['Total trasaction Report',global.privilege,fromDate,toDate,isBookingDate];

    let formatedReportsData=getTransactionData(reportData);


    let excelColumns =[
        "Branch Name", "Total seats" , "Total amount" ,
        "Total gst" , "Total agent comm" , "Gross amount" ,
        "Total cancel seats","Total cancel amount" , "Total cancel gst",
        "Total cancel agent comm"];


        if(isShowTdsComm){
            excelColumns.push("Comm TDS");
        }
        if(isShowNewAgentComm){
            excelColumns.push("Net agent comm");
        }
        if(isShowCancellationPenality){
            excelColumns.push("Tot cancel penalty");
        }

        excelColumns.push("Turn Over");

        if(isShowNetAmount){
            excelColumns.push("Net amount");
        }


    const multiDataSet = [
        {
            columns: ["Report","Agent Privilege","From Date", "To Date","IsBookingDate"],
            data: [filterData]
        }
    ];

    if(isValidArray(formatedReportsData)&&formatedReportsData.length>0){
        formatedReportsData.forEach(info => {
            let reportsXmlData = [];
            let {
                title,data
            } = info;
            data.forEach(element => {
                let agentComm=AFP(element['confirmedTotalCommission'],element['cancelledTotalCommission']);
                let turnOver=AFP(AFP(SFP(element['grossAmount'],element['cancelledTotalAmount']),element['cancelledTotalCommission']),element['cancelledTotalPenalty']);
                reportsXmlData.push([
                    element.name,element.totalTickets,element.totalAmount,
                    element.totalGST,agentComm,element.grossAmount,
                    element.cancelledTotalTickets,element.cancelledTotalAmount,element.cancelledTotalGST,
                    element.cancelledTotalCommission
                ]);

                if(isShowTdsComm){
                    reportsXmlData[reportsXmlData.length-1].push(element.confirmedTotalTDS);
                }
                if(isShowNewAgentComm){
                    let currentValue = roundNumber(element.cancelledTotalCommission + ((element.confirmedTotalCommission-element.confirmedTotalTDS)-element.cancelledTotalCommission));
                    reportsXmlData[reportsXmlData.length-1].push(currentValue);
                }
                if(isShowCancellationPenality){
                    reportsXmlData[reportsXmlData.length-1].push(element.cancelledTotalPenalty);
                }

                reportsXmlData[reportsXmlData.length-1].push(turnOver);

                if(isShowNetAmount){
                    let netAmount=AFP(turnOver,element.confirmedTotalTDS);
                    reportsXmlData[reportsXmlData.length-1].push(netAmount);
                }
            });
            multiDataSet.push({
                xSteps: 0, // Will start putting cell with 1 empty cell on left most
                ySteps: 2, //will put space of 5 rows,
                columns: [title],
                data:[]
            })
            multiDataSet.push({
                columns: excelColumns,
                data: reportsXmlData
            })
        });
    }


    return (
        <ExcelFile  element={<Button>Export</Button>} filename={"total-transaction-report"}>
            <ExcelSheet dataSet={multiDataSet} name="Organization"/>
        </ExcelFile>
    )
}

let allowedMaxDaysRange=31;
let xs=24, sm=24, md=12 , lg=12 , xl=12 , xxl=12;

const getTransactionData=(reportData)=>{
    let redBusTableRows = [];
    let websiteTableRows = [];
    let withCommissionTableRows = [];
    let withoutCommissionTableRows = [];
    let totalAmount = 0.0;
    let totalGrossAmount = 0.0;
    let totalTickets = 0;
    let cancelledTotalTickets = 0;
    let totalTax = 0;
    let cancelledTotalCommission = 0;
    let cancelledTotalGST = 0;
    let cancelledTotalPenalty = 0;
    let cancelledTotalAmount = 0;
    let totalTDS = 0;
    let totalCommission = 0;

    if(isValidArray(reportData)){
        for (var key in reportData) {
            if (reportData.hasOwnProperty(key)) {
              var element = reportData[key];
              if (element.name === 'Redbus' || element.name === 'Abhibus' || element.name === 'Paytm' || element.name === "QwikBus"){
                    redBusTableRows.push({...element,branchName:key});
                }else if (element.name === 'Website' || element.name === 'Web'){
                    websiteTableRows.push({...element,branchName:key});
                } else if( (element.agent_commission == 0 || element.agent_commission === null) && (element.agent_commission_percentage == 0|| element.agent_commission_percentage === null)){
                    withoutCommissionTableRows.push({...element,branchName:key});
                }
                else{
                    withCommissionTableRows.push({...element,branchName:key});
                }

                totalAmount = AFP(totalAmount,element.totalAmount);
                totalTickets = AFP(totalTickets,element.totalTickets);
                totalTax = AFP(totalTax,element.totalGST);
                totalGrossAmount = AFP(totalGrossAmount,element.grossAmount);
                totalTDS = AFP(totalTDS,element.confirmedTotalTDS);
                totalCommission = AFP(totalCommission, AFP(element.confirmedTotalCommission,element.cancelledTotalCommission));

                if(element.cancelledTotalTickets!==undefined&&element.cancelledTotalTickets!==null && element.cancelledTotalTickets!==0){
                    cancelledTotalCommission = AFP(cancelledTotalCommission,element.cancelledTotalCommission);
                    cancelledTotalGST = AFP(cancelledTotalGST,element.cancelledTotalGST);
                    cancelledTotalPenalty = AFP(cancelledTotalPenalty,element.cancelledTotalPenalty);
                    cancelledTotalAmount = AFP(cancelledTotalAmount,element.cancelledTotalAmount);
                    cancelledTotalTickets = AFP(cancelledTotalTickets,element.cancelledTotalTickets);
                }
            }
          }
    }
    return [
        {
            title : "Branch Office (With commission)",
            data : withCommissionTableRows
        },
        {
            title : "Branch Office (With out commission)",
            data : withoutCommissionTableRows
        },
        {
            title : "Online agent",
            data : redBusTableRows
        },
        {
            title : "Web site",
            data : websiteTableRows
        },
        {
            title : "Summary",
            data : [
               {
                name:"Summary",
                totalTickets:totalTickets,
                totalAmount:totalAmount,
                totalGST:totalTax,
                grossAmount:totalGrossAmount,
                cancelledTotalTickets:cancelledTotalTickets,
                cancelledTotalAmount:cancelledTotalAmount,
                cancelledTotalGST:cancelledTotalGST,
                cancelledTotalCommission:cancelledTotalCommission,
                confirmedTotalTDS:totalTDS,
                cancelledTotalPenalty:cancelledTotalPenalty,
                confirmedTotalCommission:totalCommission
                }
            ]
        }
    ];
}

const RenderTable=({
    dataSource,
    totalTransactionColumns,
    isShowTdsComm,
    isShowNewAgentComm,
    isShowCancellationPenality,
    isShowNetAmount,
    showSummary
})=>{

    return (
                    <Table 
                            className="bordered-table"
                            columns={totalTransactionColumns}
                            dataSource={dataSource}
                            pagination={false}
                            summary={(pageData ) => {
                                if(showSummary){
                                    let summaryTotalTickets = 0;
                                    let summaryTotalAmount = 0;
                                    let summaryTotalGST=0;
                                    let summaryConfirmedTotalCommission=0;
                                    let summaryGrossAmount=0;
                                    let summaryCancelledTotalTickets=0;
                                    let summaryCancelledTotalAmount=0;
                                    let summaryCancelledTotalCommission=0;
                                    let summaryCancelledTotalGST=0;
                                    let summaryTurnOver=0;
                                    let summaryCommTds=0;
                                    let summaryNetAgentComm=0;
                                    let summaryTotalCancelationPenality=0;
                                    let summaryNetAmount=0;

                                    pageData.forEach(({ grossAmount,
                                        cancelledTotalTickets,
                                        cancelledTotalAmount,
                                        cancelledTotalCommission,
                                        cancelledTotalGST,
                                        confirmedTotalTDS,
                                        cancelledTotalPenalty,
                                        totalTickets, totalAmount ,totalGST , confirmedTotalCommission}) => {
                                        if(isValidNumber(totalTickets)){
                                            summaryTotalTickets += totalTickets;
                                        }
                                        if(isValidNumber(totalAmount)){
                                            summaryTotalAmount += totalAmount;
                                        }
                                        if(isValidNumber(totalGST)){
                                            summaryTotalGST += totalGST;
                                        }

                                        if(isValidNumber(confirmedTotalCommission)){
                                            summaryConfirmedTotalCommission += confirmedTotalCommission;
                                        }
                                        if(isValidNumber(grossAmount)){
                                            summaryGrossAmount += grossAmount;
                                        }

                                        if(isValidNumber(cancelledTotalTickets)){
                                            summaryCancelledTotalTickets += cancelledTotalTickets;
                                        }

                                        if(isValidNumber(cancelledTotalAmount)){
                                            summaryCancelledTotalAmount += cancelledTotalAmount;
                                        }

                                        if(isValidNumber(cancelledTotalCommission)){
                                            summaryCancelledTotalCommission += cancelledTotalCommission;
                                        }

                                        if(isValidNumber(cancelledTotalGST)){
                                            summaryCancelledTotalGST += cancelledTotalGST;
                                        }

                                        if(isShowTdsComm&&isValidNumber(summaryCommTds)){
                                            summaryCommTds += confirmedTotalTDS;
                                        }

                                        if(isShowNewAgentComm){
                                            let currentValue = roundNumber(cancelledTotalCommission + ((confirmedTotalCommission-confirmedTotalTDS)-cancelledTotalCommission));
                                            summaryNetAgentComm += currentValue;
                                        }

                                        if(isShowCancellationPenality&&isValidNumber(cancelledTotalPenalty)){
                                            summaryTotalCancelationPenality += cancelledTotalPenalty;
                                        }

                                        let turnOver=AFP(AFP(SFP(grossAmount,cancelledTotalAmount),cancelledTotalCommission),cancelledTotalPenalty);
                                        if(isValidNumber(turnOver)){
                                            summaryTurnOver += turnOver;
                                        }

                                        let netAmount=AFP(turnOver,confirmedTotalTDS);
                                        if(isShowNetAmount&&isValidNumber(netAmount)){
                                            summaryNetAmount += netAmount;
                                        }
                                    });

                                    return(
                                        <Table.Summary fixed>
                                        <Table.Summary.Row>
                                          <Table.Summary.Cell index={0}>Summary</Table.Summary.Cell>
                                          <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                                          <Table.Summary.Cell index={2}>{summaryTotalTickets}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={3}>{roundNumber(summaryTotalAmount)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={4}>{roundNumber(summaryTotalGST)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={5}>{roundNumber(summaryConfirmedTotalCommission)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={6}>{roundNumber(summaryGrossAmount)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={7}>{roundNumber(summaryCancelledTotalTickets)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={8}>{roundNumber(summaryCancelledTotalAmount)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={9}>{roundNumber(summaryCancelledTotalGST)}</Table.Summary.Cell>
                                          <Table.Summary.Cell index={10}>{roundNumber(summaryCancelledTotalCommission)}</Table.Summary.Cell>
                                          {isShowTdsComm&&
                                            <Table.Summary.Cell index={11}>{roundNumber(summaryCommTds)}</Table.Summary.Cell>
                                          }
                                          {isShowNewAgentComm&&
                                            <Table.Summary.Cell index={12}>{roundNumber(summaryNetAgentComm)}</Table.Summary.Cell>
                                          }
                                          {isShowCancellationPenality&&
                                            <Table.Summary.Cell index={13}>{roundNumber(summaryTotalCancelationPenality)}</Table.Summary.Cell>
                                          }
                                          <Table.Summary.Cell index={14}>{roundNumber(summaryTurnOver)}</Table.Summary.Cell>
                                          {isShowNetAmount&&
                                          <Table.Summary.Cell index={15}>{roundNumber(summaryNetAmount)}</Table.Summary.Cell>
                                          }
                                        </Table.Summary.Row>
                                      </Table.Summary>
                                    )
                                }else{
                                    return null
                                }

                            }}
                        />
    );
}

const RenderReport=({
    reportData,
    formValues,
    isShowNetAmount,
    isShowCancellationPenality,
    isShowNewAgentComm,
    isShowTdsComm})=>{

    const totalTransactionColumns=[
        {
            title:'SN',
            key:'index',
            render : (text, record, index) => index+1
        },
        {
            title:"Branch Name",
            key:'name',
            dataIndex:'name'
        },
        {
            title:"Total seats",
            key:'totalTickets',
            dataIndex:'totalTickets'
        },
        {
            title:"Total amount",
            key:'totalAmount',
            dataIndex:'totalAmount'
        },
        {
            title:"Total gst",
            key:'totalGST',
            dataIndex:'totalGST'
        },
        {
            title:"Total agent comm",
            key:'confirmedTotalCommission',
            render : (text, record, index) =>  AFP(record['confirmedTotalCommission'],record['cancelledTotalCommission'])
        },
        {
            title:"Gross amount",
            key:'grossAmount',
            dataIndex:'grossAmount'
        },
        {
            title:"Total cancel seats",
            key:'cancelledTotalTickets',
            dataIndex:'cancelledTotalTickets'
        },
        {
            title:"Total cancel amount",
            key:'cancelledTotalAmount',
            dataIndex:'cancelledTotalAmount'
        },
        {
            title:"Total cancel gst",
            key:'cancelledTotalGST',
            dataIndex:'cancelledTotalGST'
        },
        {
            title:"Total cancel agent comm",
            key:'cancelledTotalCommission',
            dataIndex:'cancelledTotalCommission'
        }
    ];

        if(isShowTdsComm){
            totalTransactionColumns.push({
                title:"Comm TDS",
                key:"confirmedTotalTDS",
                dataIndex:"confirmedTotalTDS"
            })
        }
        if(isShowNewAgentComm){
            totalTransactionColumns.push({
                title:"Net agent comm",
                key:"confirmedTotalTDS",
                render : (text, record, index) => roundNumber(record['cancelledTotalCommission'] + ((record['confirmedTotalCommission']-record['confirmedTotalTDS'])-record['cancelledTotalCommission']))
            })
        }
        if(isShowCancellationPenality){
            totalTransactionColumns.push({
                title:"Tot cancel penalty",
                key:"cancelledTotalPenalty",
                dataIndex:"cancelledTotalPenalty"
            })
        }

        totalTransactionColumns.push({
            title:() => {
                return <div>
                        <Popover content={'gross amount- cancelled total amount + cancelled total commission + cancelled total penality'} title={null}>
                        Turn Over
                        </Popover>

                      </div>
            },
            key:'turnOver',
            render : (text, record, index) => AFP(AFP(SFP(record['grossAmount'],record['cancelledTotalAmount']),record['cancelledTotalCommission']),record['cancelledTotalPenalty'])
        })

    if(isShowNetAmount){
        totalTransactionColumns.push({
                title:"Net amount",
                key:'netAmount',
                render : (text, record, index) =>  AFP(AFP(AFP(SFP(record['grossAmount'],record['cancelledTotalAmount']),record['cancelledTotalCommission']),record['cancelledTotalPenalty']),record.confirmedTotalTDS)
            })
    }

    let {
        dateRange
    }=formValues;
    if(isValidArray(reportData)&&isValidArray(dateRange)){
        let tableComponents=[];
        let formatedData = getTransactionData(reportData);

        formatedData.forEach(element => {
            tableComponents.push(<div key={element.title}>
                                        <Row>
                                            <Text>{element.title}</Text>
                                        </Row>
                                        <RenderTable
                                        dataSource={element.data}
                                        totalTransactionColumns={totalTransactionColumns}
                                        isShowTdsComm={isShowTdsComm}
                                        isShowNewAgentComm={isShowNewAgentComm}
                                        isShowCancellationPenality={isShowCancellationPenality}
                                        isShowNetAmount={isShowNetAmount}
                                        showSummary={element.title==="Summary"?false:true}
                                        />
                                </div>);
        });
        return(
                        <div>
                            <Row justify="center">
                                <Col>
                                    <Text strong>Booking report</Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>From Date : </Text>
                                        {getAPIDateFormat(dateRange[0])}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>To Date : </Text>
                                        {getAPIDateFormat(dateRange[1])}
                                    </Text>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Agent Privilege : </Text>
                                        {global.privilege}
                                    </Text>
                                </Col>
                                <Col xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
                                    <Text>
                                        <Text strong>Generated day/time : </Text>{ getLocalDateAndTime(new Date())}
                                    </Text>
                                </Col>
                            </Row>
                            {tableComponents}
                        </div>
            )
    }else{
        return null
    }
}

export const TotalTransactionReport = () => {


    let dynamicColums=totalTransactionReportColumns[global.operatorCode];
    let isShowTdsComm = isValidArray(dynamicColums)&&dynamicColums.indexOf(COMM_TDS)!=-1?true:false;
    let isShowNewAgentComm = isValidArray(dynamicColums)&&dynamicColums.indexOf(NET_AGENT_COMM)!=-1?true:false;
    let isShowCancellationPenality = isValidArray(dynamicColums)&&dynamicColums.indexOf(TOTAL_CANCEL_PENALITY)!=-1?true:false;
    let isShowNetAmount = isValidArray(dynamicColums)&&dynamicColums.indexOf(NET_AMOUNT)!=-1?true:false;

    const [showReport,setShowReport]=useState(false);
    const [reportData,setReportData]=useState(undefined);

    const dispatch=useDispatch();

    const onSuccess=(data)=>{
        setReportData(data.branchInfo);
        setLoading(false);
        setShowReport(true);
    }

    const onFailure=()=>{
        setLoading(false);
    }

    const setLoading=(bool)=>{
        dispatch(loadingAction(bool));
    }

    const onFinish=(values)=>{
        let {
            dateRange,
            busType,
            isBookingDate
        }=values;
        setShowReport(false);
        const fromDate = getAPIDateFormat(dateRange[0]);
        const toDate = getAPIDateFormat(dateRange[1]);
        setLoading(true);
        // if(busType==="all"){
        //     busType=undefined;
        // }else if(busType==='ac'){
        //     busType=true;
        // }else{
        //     busType=false;
        // }
        fetchTransactionSheet(fromDate,toDate,busType,isBookingDate,onSuccess,onFailure);
    }

    const [form] = Form.useForm();

    return (
            <div className="report-inner-div">
                <Form
                onFinish={onFinish}
                form={form}
                initialValues={{busType:'allBuses',isBookingDate:true}}
                >
                    <Row gutter={[16,16]}>
                        <Col>
                            <Form.Item
                            name="dateRange"
                            label="Date range"
                            rules={[
                                { required: true , message: 'Please input your date range!'},
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || (value[1].diff(value[0],"days") <= allowedMaxDaysRange)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(`Please select a date range with in ${allowedMaxDaysRange} days`));
                                    },
                                  })
                                ]}>
                                    <RangePicker
                                        ranges={getRangeOptions(allowedMaxDaysRange)}
                                    />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                  name="busType"
                                  label=""
                                  rules={[{ required: true , message: 'Please input your bus type!'}]}
                            >
                                    <Radio.Group name="radiogroup">
                                        <Radio value={'allBuses'}>All buses</Radio>
                                        <Radio value={'ac'}>A/C buses</Radio>
                                        <Radio value={'noAc'}>Non-A/C buses</Radio>
                                    </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                  name="isBookingDate"
                                  label=""
                                  rules={[{ required: true , message: 'Please input your selection!'}]}
                            >
                                    <Radio.Group name="radiogroup">
                                        <Radio value={true}>Booking date</Radio>
                                        <Radio value={false}>Travel date</Radio>
                                    </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button htmlType="submit">Search</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <Row gutter={16}>
                    <Col>
                         {generateJsonToExport(reportData,form,{
                             isShowNetAmount,
                             isShowCancellationPenality,
                             isShowNewAgentComm,
                             isShowTdsComm
                         })}
                    </Col>
                    <Col>
                        {showReport&&
                            <Printer
                            showComponent={false}
                            renderComponent={()=>{
                                return (
                                    <RenderReport
                                        formValues={form.getFieldsValue()}
                                        pagination={false}
                                        reportData={reportData}
                                        isShowNetAmount={isShowNetAmount}
                                        isShowCancellationPenality={isShowCancellationPenality}
                                        isShowNewAgentComm={isShowNewAgentComm}
                                        isShowTdsComm={isShowTdsComm}
                                    />
                                )
                            }}
                            zoomValue={0.5}
                            />
                        }
                    </Col>
                </Row>
                   <RenderReport
                        formValues={form.getFieldsValue()}
                        pagination={false}
                        reportData={reportData}
                        isShowNetAmount={isShowNetAmount}
                        isShowCancellationPenality={isShowCancellationPenality}
                        isShowNewAgentComm={isShowNewAgentComm}
                        isShowTdsComm={isShowTdsComm}
                    />
            </div>
    )
}
