import { Button, Table , message, Modal,Row,Col,Input } from "antd";
import React, { useEffect , useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadingAction } from "../../actions/loading-actions";
import '../../styles/cities.style.scss';
import { getCityDetails, updateCityPhoneNumber } from "../../actions/cities.actions";
import { citiesObjectSelector } from "../../selector/cities.selector";
import { isValidArray } from "../../utils/utilities";

const DrawLine =()=>{
    return(
        <div className="cities-underline">

        </div>
    )
}


export const CitiesPage = (props) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedCity, setSelectedCity] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState(null);

    const dispatch = useDispatch();

    const onSuccess=()=>{
        setLoading(false);
    }

    const citiesObj = useSelector(citiesObjectSelector);
    
    const onFailure=(errMsg)=>{
        message.error(errMsg);
        setLoading(false);
    }

    useEffect(()=>{
        setLoading(true);
        dispatch(getCityDetails(onSuccess,onFailure));
    },[]);


    const setLoading = (value)=>{
        dispatch(loadingAction(value));
    }

    const handleCancel=()=>{
        setIsModalVisible(false);
    }

    const handleOk=()=>{
        setLoading(true);
        dispatch(updateCityPhoneNumber(selectedCity.name,phoneNumber,()=>{
            message.success("Updated Phone number successfully!");
            setLoading(false);
        },()=>{
            setLoading(false);
        }));
    }

    return (
        <div className="staff-cancel-root">
            <div className="staff-cancel-inner-div">
                <div>
                    <h3>
                        Cities
                    </h3>   
                    <DrawLine /> 
                    <Table 
                        columns={[
                            {
                                title:"City Name",
                                key:'name',
                                dataIndex:'name'
                            },
                            {
                                title:"Action",
                                key:'name',
                                dataIndex:'name',
                                render:(txt,row)=>{

                                    return(
                                        <div>
                                            <Button
                                            onClick={()=>{
                                                setIsModalVisible(true);
                                                setSelectedCity(row);
                                            }}
                                            >Update</Button>
                                        </div>
                                    )
                                }
                            }
                        ]}
                        dataSource={citiesObj}
                    />        
                </div>
                <div>
                <Modal
                    title={isValidArray(selectedCity)?`City : ${selectedCity.name}`:''}
                    visible={isModalVisible} 
                    onOk={handleOk} 
                    onCancel={handleCancel} 
                    okText={"Update"}
                >
                    <Row>
                        <Col>
                            Boarding & Drop point PhNo# : 
                        </Col>
                        <Col>
                            <Input onChange={(e)=>{
                                setPhoneNumber(e.target.value);
                            }}/>
                        </Col>
                    </Row>
                    </Modal>     
                </div>
            </div>
        </div>
    )
}