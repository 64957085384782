import { DASHBOARD_OCCUPANCY_URL } from "../../utils/api-url";
import { postCall } from "../../utils/network.util";
import { isValid, isValidArray } from "../../utils/utilities";


export const getMonthlyOccupancy=(startDate,endDate,onSuccess=()=>{},onFailure=()=>{})=>{
    var fetchedData = postCall(DASHBOARD_OCCUPANCY_URL, {
        startDate:startDate,
        endDate:endDate,
        operator_code:global.operatorCode,
        travelsId:global.travelsId
      });
      fetchedData.then((data) => {
        if(data.status === "SUCCESS"){
            let clientPayload = data.payload;
            if(clientPayload.Status==="SUCCESS"){
                onSuccess(clientPayload.Payload.occupancy);
            }else{
                onFailure(clientPayload.Message);
            }
        }
      })
      .catch((reason)=> {
        console.error({reason});
        onFailure("Failed to get monthly occupancy!");
      })
}

export const getActiveStatus=(openCalenderData,apidate)=>{    

    
    if(isValid(openCalenderData.totalSeats)&&isValid(openCalenderData.totalSeats[apidate])){
        let confirmedSeats = 0;
        if(isValid(openCalenderData.confirmSeats)&&isValid(openCalenderData.confirmSeats[apidate])){
            confirmedSeats = openCalenderData.confirmSeats[apidate];
        }        
        
        let percentageValue = confirmedSeats / openCalenderData.totalSeats[apidate];
                return {
                    className:'',
                    onlyDateClassName:'',
                    styles:{
                        background: `rgba(17, 110, 238, ${percentageValue})`
                    },
                    confirmedSeats
                }
    }else{
        return {
            className : "not-active-status",
            onlyDateClassName : '',
            styles :{

            },
            confirmedSeats:0
        }
    }
}