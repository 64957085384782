import{
    FETCH_REVENUE_FOR_WEEK_OTA_REPORT,
    FETCH_REVENUE_REPORT,
    FETCH_TODAYS_TICKET_COUNT
} from './action-types';
import{
    FETCH_REVENUE_FOR_WEEK_OTA_URL,
    FETCH_REVENUE_REPORT_URL,
    FETCH_TODAYS_TICKET_COUNT_URL
} from '../utils/api-url';
import { postCall } from "../utils/network.util";
import { getAPIDateFormat } from '../utils/date.utils';

export const getRevenueData = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: FETCH_REVENUE_REPORT,
        payload: {
            url:FETCH_REVENUE_REPORT_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              "travelsId":global.travelsId,
              "startDate":startDate,
              "endDate":endDate
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }

  export const getRevenueForWeekOTA = (startDate,endDate,onSuccess=()=>{},onFailure=()=>{}) => {
    return {
        type: FETCH_REVENUE_FOR_WEEK_OTA_REPORT,
        payload: {
            url:FETCH_REVENUE_FOR_WEEK_OTA_URL,
            api:postCall,
            apiPayload:{
              operatorCode:global.operatorCode,
              "travelsId":global.travelsId,
              "startDate":startDate,
              "endDate":endDate
            },
            onSuccess:onSuccess,
            onFailure:onFailure
        }
    }
  }

export const fetchTodaysTicketCount = (onSuccess=()=>{}, onFailure=()=>{}) => {
  return {
    type: FETCH_TODAYS_TICKET_COUNT,
    payload: {
      url: FETCH_TODAYS_TICKET_COUNT_URL,
      api: postCall,
      apiPayload: {
        "travelsId":global.travelsId,
      },
      onSuccess: onSuccess,
      onFailure: onFailure
    }
  }
}