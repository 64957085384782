/**
 * @description The redux store creation methods
 */

 import { createStore , applyMiddleware} from 'redux';
 import { composeWithDevTools } from 'redux-devtools-extension';
 import { APP_ENV, DEV_ENV , TEST_ENV} from '../app.config';
 import { combinedReducer } from '../reducers/combine.reducer';
 import createSagaMiddleware from 'redux-saga';
 
 export const getStore = (reducer = combinedReducer,initialState = {},sagaMiddleware) => {
     if(APP_ENV === DEV_ENV || APP_ENV === TEST_ENV) {
         const middlewares = [sagaMiddleware]
         const middlewareEnhancer = applyMiddleware(...middlewares)
 
         const enhancers = [middlewareEnhancer]
         const composedEnhancers = composeWithDevTools(...enhancers)
         return createStore(
             reducer,
             initialState,
             composedEnhancers
         );
     }
     else {
         return createStore(
             reducer,
             initialState,
             applyMiddleware(sagaMiddleware)
         );
     }
 }
 
 export const getSagaMiddleWare = () => {
     return createSagaMiddleware();
 }
 